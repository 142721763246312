/** Base classname */
export var LINK_TILES_BASE_CLASSNAME = 'ws10-link-tiles';
/** Classname for link tiles container */
export var LINK_TILES_LIST_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__list");
/** Classname for item */
export var LINK_TILES_ITEM_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__item");
/** Classname for disabled item link */
export var LINK_TILES_ITEM_DISABLED_CLASSNAME = "".concat(LINK_TILES_ITEM_CLASSNAME, "--disabled");
/** Classname for icon */
export var LINK_TILES_ICON_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__icon");
/** Classname for link */
export var LINK_TILES_LINK_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__link");
/** Classname for link label */
export var LINK_TILES_LINK_LABEL_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__link-label");
/** Classname for Highlight badge */
export var LINK_TILES_HIGHLIGHT_BADGE_CLASSNAME = "".concat(LINK_TILES_BASE_CLASSNAME, "__highlight-badge");
/** Classname for a container with five items in a row on large+ */
export var LINK_TILES_CONTAINER_5_ITEMS_CLASSNAME = "".concat(LINK_TILES_LIST_CLASSNAME, "-items-per-row-on-large--5");
/** Classname for a container with four items in a row on large+ */
export var LINK_TILES_CONTAINER_4_ITEMS_CLASSNAME = "".concat(LINK_TILES_LIST_CLASSNAME, "-items-per-row-on-large--4");
/** Classname for a container with three items in a row on large+ */
export var LINK_TILES_CONTAINER_3_ITEMS_CLASSNAME = "".concat(LINK_TILES_LIST_CLASSNAME, "-items-per-row-on-large--3");
/** Enum for ColumnNumbersEnum */
export var ColumnNumbersEnum;
(function (ColumnNumbersEnum) {
    ColumnNumbersEnum["THREE"] = "3";
    ColumnNumbersEnum["FOUR"] = "4";
    ColumnNumbersEnum["FIVE"] = "5";
})(ColumnNumbersEnum || (ColumnNumbersEnum = {}));
/** Const for columnNumbers */
export var columnNumbers = [
    ColumnNumbersEnum.THREE,
    ColumnNumbersEnum.FOUR,
    ColumnNumbersEnum.FIVE,
];
