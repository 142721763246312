/**
 * Base classname
 */
export var SYSTEM_ICON_BASE_CLASS = 'ws10-icon';
/**
 * Classname
 */
export var SYSTEM_ICON_CLASS = "".concat(SYSTEM_ICON_BASE_CLASS, "--system");
/**
 * Classname
 */
export var MID_RENDER_ICON_CLASS = "".concat(SYSTEM_ICON_BASE_CLASS, "--mid");
/**
 * Classname
 */
export var ICON_SIZE_SMALL = 'small';
/**
 * Classname
 */
export var ICON_SIZE_MEDIUM = 'medium';
/**
 * Classname
 */
export var ICON_SIZE_LARGE = 'large';
/**
 * Classname
 */
export var ICON_SIZES = [ICON_SIZE_SMALL, ICON_SIZE_MEDIUM, ICON_SIZE_LARGE];
