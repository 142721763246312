/**
 * Base class name
 */
export var FORM_TEXTAREA_CLASSNAME = 'ws10-form-textarea';
/**
 * class name for error state
 */
export var FORM_TEXTAREA_CLASSNAME_ERROR = "".concat(FORM_TEXTAREA_CLASSNAME, "--error");
/**
 * class name for warn state
 */
export var FORM_TEXTAREA_CLASSNAME_WARN = "".concat(FORM_TEXTAREA_CLASSNAME, "--warn");
/**
 * class name for success state
 */
export var FORM_TEXTAREA_CLASSNAME_SUCCESS = "".concat(FORM_TEXTAREA_CLASSNAME, "--success");
/**
 * classname if disabled
 */
export var FORM_TEXTAREA_CLASSNAME_DISABLED = "".concat(FORM_TEXTAREA_CLASSNAME, "--disabled");
/**
 * class name for auto height
 */
export var FORM_TEXTAREA_AUTO_HEIGHT_CLASSNAME = "".concat(FORM_TEXTAREA_CLASSNAME, "--auto-height");
/**
 * class name of textarea
 */
export var FORM_TEXTAREA_TEXTAREA_CLASSNAME = "".concat(FORM_TEXTAREA_CLASSNAME, "__textarea");
/**
 * Notification icon container classname
 */
export var FORM_TEXTAREA_NOTIFICATION_ICON_CLASSNAME = "".concat(FORM_TEXTAREA_CLASSNAME, "__notification_icon-container");
