var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-textarea--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":53}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-form-textarea--disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-form-textarea--auto-height";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "maxlength=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdMaxLength") || (depth0 != null ? lookupProperty(depth0,"stdMaxLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdMaxLength","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":55}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " placeholder=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdPlaceholder") || (depth0 != null ? lookupProperty(depth0,"stdPlaceholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdPlaceholder","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":64}}}) : helper))) != null ? stack1 : "")
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"\n    ws10-form-textarea\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":60}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":59}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAutoHeight") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":64}}})) != null ? stack1 : "")
    + "\n\">\n    <textarea rows=\"5\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdMaxLength") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":63}}})) != null ? stack1 : "")
    + "\n        id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":21}}}) : helper)))
    + "\"\n        class=\"ws10-form-textarea__textarea\"\n        name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdName") || (depth0 != null ? lookupProperty(depth0,"stdName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdName","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":25}}}) : helper)))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":43}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPlaceholder") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":72}}})) != null ? stack1 : "")
    + "\n    >"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdValue") || (depth0 != null ? lookupProperty(depth0,"stdValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdValue","hash":{},"data":data,"loc":{"start":{"line":14,"column":5},"end":{"line":14,"column":17}}}) : helper)))
    + "</textarea>\n\n    <span class=\"ws10-form-textarea__notification_icon-container\">\n"
    + ((stack1 = container.invokePartial(require("../notification-icon/_notification-icon.hbs"),depth0,{"name":"_notification-icon","hash":{"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </span>\n</div>\n";
},"usePartial":true,"useData":true});