var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './form-select.scss';
import renderTemplate from './_form-select.hbs';
import { Pattern, removeUndefinedFieldsFromObject, getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { FORM_SELECT_CLASSNAME, FORM_SELECT_CLASSNAME_DISABLED, FORM_SELECT_CLASSNAME_ERROR, FORM_SELECT_CLASSNAME_SUCCESS, FORM_SELECT_CLASSNAME_WARN, FORM_SELECT_NOTIFICATION_ICON_CLASSNAME, FORM_SELECT_SELECT_CLASSNAME, } from './Constants';
import { getValueFromItems } from '@vfde-brix/ws10/form-select/getValueFromItems';
import { addFormElementEventHandlers, FORM_ELEMENT_STATES, } from '@vfde-brix/ws10/form-text-input';
import { createNotificationIcon, } from '@vfde-brix/ws10/notification-icon';
/**
 * FormSelect class
 */
var FormSelect = /** @class */ (function (_super) {
    __extends(FormSelect, _super);
    function FormSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FormSelect.prototype.initEvents = function () {
        var formSelect = this.containerElement.getElementsByClassName(FORM_SELECT_SELECT_CLASSNAME)[0];
        /**
         * If error or success state, the state is not reset on change, while validation in Business Logic will render anyway
         */
        addFormElementEventHandlers(this.getProperties(), formSelect);
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FormSelect.prototype.getDefaultProperties = function (newProperties) {
        newProperties.optType = 'select';
        /**
         * For reasons we have the same information "What is selected" twice: once in the stdValue and once at the option which has optState=selected...
         * But for me it is important that from the business logic every form element has a stdValue, then you don't have to worry about where to get the value
         *
         * At least here we can see that both values show the same.
         * Let's assume that the item.state is the more correct value, because in case of doubt it would already be rendered in the dome.
         */
        newProperties.stdValue = getValueFromItems(newProperties.items);
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FormSelect.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * returns always an empty object or with the necessary properties.
     * Dynamic: this.properties.optState
     * Static: empty object
     */
    FormSelect.prototype.getNotificationIconProperties = function () {
        if (this.properties) {
            return {
                optState: this.properties.optState,
                business: NO_PATTERN_BUSINESS_LOGIC,
            };
        }
        return NO_PATTERN_BUSINESS_LOGIC;
    };
    /**
     * Returns the input element
     */
    FormSelect.prototype.getInputElement = function () {
        return this.containerElement.getElementsByClassName(FORM_SELECT_SELECT_CLASSNAME)[0];
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    FormSelect.prototype.initDomElements = function () {
        var notificationIconProps = this.getNotificationIconProperties();
        this.notificationIconContainerElement = this.containerElement
            .getElementsByClassName(FORM_SELECT_NOTIFICATION_ICON_CLASSNAME)[0];
        /* istanbul ignore else */
        if (this.notificationIconContainerElement) {
            this.notificationIconComponent = createNotificationIcon(this.notificationIconContainerElement, notificationIconProps);
        }
    };
    /**
     * Get properties in case component comes from CMS
     */
    FormSelect.prototype.readDom = function (business) {
        this.initDomElements();
        var element = this.containerElement.getElementsByClassName(FORM_SELECT_CLASSNAME)[0];
        var select = element.getElementsByClassName(FORM_SELECT_SELECT_CLASSNAME)[0];
        var propertiesFromClassName = getPropertiesFromClassName(element.className, {
            optState: FORM_ELEMENT_STATES,
        });
        var optState = propertiesFromClassName.optState;
        var optDisabled = element.classList.contains(FORM_SELECT_CLASSNAME_DISABLED);
        var id = select.id, name = select.name;
        var items = Array.from(this.containerElement.getElementsByTagName('option')).map(function (option) {
            var itemPropertiesFromClassName = getPropertiesFromClassName(option.className, {
                optState: ['disabled', 'selected'],
            });
            var stdOptionLabel = option.textContent.trim();
            var stdOptionValue = option.value;
            var item = {
                stdOptionLabel: stdOptionLabel,
                stdOptionValue: stdOptionValue,
                optState: itemPropertiesFromClassName.optState,
            };
            return removeUndefinedFieldsFromObject(item);
        });
        var stdValue = getValueFromItems(items);
        var properties = {
            stdId: id,
            stdName: name,
            stdValue: stdValue,
            optDisabled: optDisabled,
            optState: optState,
            items: items,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormSelect.prototype.handleUpdateState = function (optState) {
        var _a;
        var stateClasses = {
            error: FORM_SELECT_CLASSNAME_ERROR,
            warn: FORM_SELECT_CLASSNAME_WARN,
            success: FORM_SELECT_CLASSNAME_SUCCESS,
        };
        var baseElement = this.containerElement.getElementsByClassName(FORM_SELECT_CLASSNAME)[0];
        (_a = baseElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        if (optState) {
            baseElement.classList.add(stateClasses[optState]);
        }
        this.handleUpdateNotificationIcon(optState);
    };
    /**
     * Update the notification icon based on the component's `optState` property
     */
    FormSelect.prototype.handleUpdateNotificationIcon = function (optState) {
        this.notificationIconComponent.update({ optState: optState }, false);
    };
    FormSelect.prototype.didReceiveProps = function (newProps, oldProperties) {
        var optState = newProps.optState;
        /* istanbul ignore else */
        if (oldProperties.optState !== optState) {
            this.handleUpdateState(optState);
        }
    };
    return FormSelect;
}(Pattern));
export { FormSelect };
/**
 * a factory function to create the component
 */
export var createFormSelect = function (containerElement, businessLogicProperties) {
    var formSelect = new FormSelect(containerElement, businessLogicProperties);
    formSelect.init();
    return formSelect;
};
