var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/prefer-for-of */
import './upload.scss';
import renderTemplate from './_upload.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '../core';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { BUTTON_DEFAULT_PROPS, BUTTON_ICON_ONLY_CONTAINER_CLASSNAME, BUTTON_ICON_ONLY_PROPS, BUTTON_REUPLOAD_PROPS, DELETE_TITLE_ATTR, ERROR_LABEL_PROPS, ErrorType, FILE_ICON_CONTAINER_CLASSNAME, FILE_SIZE_CONVER_BASE, FORM_TEXT_INPUT_CONTAINER_CLASSNAME, FORM_TEXT_INPUT_PROPS, LABEL_CONTAINER_CLASSNAME, LENGTH_ERROR_NOTIFICATION_CONTAINER_CLASSNAME, LENGTH_ERROR_NOTIFICATION_DEFAULT_PROPS, LOADING_ANIMATION_CONTAINER_CLASSNAME, LOADING_TITLE_ATTR, REUPLOAD_BUTTON_CONTAINER_CLASSNAME, REUPLOAD_INPUT_CONTAINER_CLASSNAME, REUPLOAD_INPUT_PROPS, SIZE_ERROR_NOTIFICATION_CONTAINER_CLASSNAME, SIZE_ERROR_NOTIFICATION_DEFAULT_PROPS, TICK_ARIA_LABEL_ATTR, TICK_CONTAINER_CLASSNAME, ULPOAD_HEADLINE_CONTAINER_CLASSNAME, UPLOAD_BASE_CLASSNAME, UPLOAD_BUTTON_CONTAINER_CLASSNAME, UPLOAD_FILE_CONTAINER_CLASSNAME, UPLOAD_FILE_DATA_CONTAINER_CLASSNAME, UPLOAD_FILE_ERR_CONTAINER_CLASSNAME, UPLOAD_FILE_NAME_CONTAINER_CLASSNAME, UPLOAD_FILE_SIZE_CONTAINER_CLASSNAME, UPLOAD_SUBHEADLINE_CONTAINER_CLASSNAME, } from './Constants';
import { createFormTextInput, } from '../form-text-input';
import { createButtonIconOnly, } from '../button-icon-only';
import { createNotification, } from '../notification';
import { createFormLabel, } from '../form-label';
import { LOADING_ANIMATION_BASE_CLASSNAME } from '../loading-animation';
/**
 * ws10 Upload class
 * This component is a 'Block' component.
 */
var Upload = /** @class */ (function (_super) {
    __extends(Upload, _super);
    function Upload() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /** reUploadTextInputComponent  */
        _this.reUploadTextInputComponents = [];
        /** reUploadButtonComponents */
        _this.reUploadButtonComponents = [];
        return _this;
    }
    /**
     * getDefaultProperties
     * @param newProperties
     * @returns IUploadItemProperties
     */
    Upload.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    Upload.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
        // this.formTextInputComponent.getInputElement().setAttribute('multiple', '');
        // this.formTextInputComponent.getInputElement().setAttribute('accept', '.pdf,.png,.jpg');
        this.setUploadInputAttributes(this.formTextInputComponent, true);
        if (this.reUploadTextInputComponents.length) {
            for (var i = 0; i < this.reUploadTextInputComponents.length; i++) {
                this.reUploadTextInputComponents[i].getInputElement().setAttribute('accept', '.pdf,.png,.jpg');
            }
        }
    };
    /**
     * read dom
     * @param uploadBusinessLogic
     * @returns IUploadItemProperties
     */
    Upload.prototype.readDom = function () {
        /** uploadContaier - the wrapper of the whole component  */
        var uploadContainer = this.containerElement.getElementsByClassName(UPLOAD_BASE_CLASSNAME)[0];
        /** stdHeadLine - to get content of the stdHeadLine container */
        var stdHeadline = this.containerElement.getElementsByClassName(ULPOAD_HEADLINE_CONTAINER_CLASSNAME)[0].innerHTML.trim();
        /** stdsubHeadline - to get the content of stdSubHeadline container */
        var stdSubHeadline = this.containerElement.getElementsByClassName(UPLOAD_SUBHEADLINE_CONTAINER_CLASSNAME)[0].innerHTML.trim();
        /** containerFormTextInputContainer - the wrapper of the formTextInputComponent  */
        var containerFormTextInputContainer = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CONTAINER_CLASSNAME)[0];
        /** uploadedFilesContainer - array of the wrappers of each file details */
        var uploadedFilesContainer = __spreadArray([], __read(this.containerElement.getElementsByClassName(UPLOAD_FILE_CONTAINER_CLASSNAME)), false);
        /** uploadButtonContainer - the wrapper of the uploadButton component */
        var uploadButtonContainer = this.containerElement.getElementsByClassName(UPLOAD_BUTTON_CONTAINER_CLASSNAME)[0];
        /** sizeErrorNotificationContainer - the wrapper of the sizeErrorNotification component  */
        var sizeErrorNotificationContainer = this.containerElement.getElementsByClassName(SIZE_ERROR_NOTIFICATION_CONTAINER_CLASSNAME)[0];
        /** lengthErrorNotificationContainer - the wrapper of the lengthErrorNotification component  */
        var lengthErrorNotificationContainer = this.containerElement.getElementsByClassName(LENGTH_ERROR_NOTIFICATION_CONTAINER_CLASSNAME)[0];
        /** errrorNotificationContainer */
        var errorNofiticationContainer = sizeErrorNotificationContainer || lengthErrorNotificationContainer;
        /** errorNotification - notificationComponent */
        var errorNotification = errorNofiticationContainer && createNotification(errorNofiticationContainer, NO_PATTERN_BUSINESS_LOGIC);
        /** optErrorNotification - notificationComponent props */
        var optErrorNotification = errorNotification && errorNotification.getProperties();
        /** setting the size of the Error notification component */
        optErrorNotification ? optErrorNotification.optSize = 'medium' : null;
        /** uploadFiles - array to collect the files in the component */
        var uploadFiles = [];
        /** totalFilesSize - counter to count the total size of files */
        var totalFilesSize = 0;
        for (var i = 0; i < uploadedFilesContainer.length; i++) {
            /** optErrorExists - flag to decide there is an error in the file or not */
            var optErrorExists = !!uploadedFilesContainer[i].classList.contains(UPLOAD_FILE_ERR_CONTAINER_CLASSNAME);
            /** fileName  */
            var fileName = uploadedFilesContainer[i].getElementsByClassName(UPLOAD_FILE_NAME_CONTAINER_CLASSNAME)[0].innerHTML.trim();
            /** fileSize */
            var fileSize = uploadedFilesContainer[i].getElementsByClassName(UPLOAD_FILE_SIZE_CONTAINER_CLASSNAME)[0].innerHTML.trim();
            /** fileDeleteButtonContainer - the wrapper of delete button component */
            var fileDeleteButtonContainer = uploadedFilesContainer[i].getElementsByClassName(BUTTON_ICON_ONLY_CONTAINER_CLASSNAME)[0];
            /** fileDeleteButtonComponent - buttonIconOnlyComponent */
            var fileDeleteButtonComponent = fileDeleteButtonContainer && createButtonIconOnly(fileDeleteButtonContainer, NO_PATTERN_BUSINESS_LOGIC);
            /** stdFileBtn - buttonIconOnly props */
            var stdFileBtn = fileDeleteButtonComponent && fileDeleteButtonComponent.getProperties();
            /** optErrorLabel - the description of the error in the file */
            var optErrorLabel = null;
            /** reuploadFormTextInput */
            var containerReUploadFormTextInput = null;
            /** optReUploadBtn */
            var optReUploadBtn = null;
            if (optErrorExists) {
                /** reuploadErrorLabel - the wrapper of the formLabel component */
                var reuploadErrorLabel = uploadedFilesContainer[i].getElementsByClassName(LABEL_CONTAINER_CLASSNAME)[0];
                /** reUploadErrorLabelComponent - ErrorFormLable Component */
                var reUploadErrorLabelComponent = reuploadErrorLabel && createFormLabel(reuploadErrorLabel, NO_PATTERN_BUSINESS_LOGIC);
                /** set the FormLabel component props into optErrorLabel */
                optErrorLabel = reuploadErrorLabel && reUploadErrorLabelComponent.getProperties();
                /** reuploadTextInputContainer - the wrapper of the reuploadTExtInput Component */
                var reuploadTextInputContainer = uploadedFilesContainer[i].getElementsByClassName(REUPLOAD_INPUT_CONTAINER_CLASSNAME)[0];
                /** reuploadTextInputComponent - textInput component */
                var reuploadTextInputComponent = reuploadTextInputContainer && createFormTextInput(reuploadTextInputContainer, NO_PATTERN_BUSINESS_LOGIC);
                /** set the accept attr to the input element */
                reuploadTextInputComponent && reuploadTextInputComponent.getInputElement().setAttribute('accept', '.pdf,.png,.jpg');
                /** set the formTextInput properties to reUploadFormTextInput  */
                containerReUploadFormTextInput = reuploadTextInputComponent && reuploadTextInputComponent.getProperties();
                /** reuploadButtoncontainer - the wrapper of the reuploadButton component */
                var reuploadButtonContainer = uploadedFilesContainer[i].getElementsByClassName(REUPLOAD_BUTTON_CONTAINER_CLASSNAME)[0];
                /** reuploadButtonComponent - button component */
                var reuploadButtonComponent = reuploadButtonContainer && createButton(reuploadButtonContainer, NO_PATTERN_BUSINESS_LOGIC);
                /** set the props of the reuploadButtonComponent into optReUploadBtn */
                optReUploadBtn = reuploadButtonComponent && reuploadButtonComponent.getProperties();
            }
            /** optLoading */
            var optLoading = !!uploadedFilesContainer[i].getElementsByClassName(LOADING_ANIMATION_CONTAINER_CLASSNAME)[0];
            /** optViewTick */
            var optViewTick = !!uploadedFilesContainer[i].getElementsByClassName(TICK_CONTAINER_CLASSNAME)[0];
            /** remove the () from fileSize string */
            fileSize = Number(fileSize.substring(1, fileSize.length - 1).match(/(\d+)/)[0]);
            /** uploadFile - create the file object to pust it in the uploadFiles array */
            var uploadFile = { fileName: fileName, fileSize: fileSize, optErrorExists: optErrorExists, stdFileBtn: stdFileBtn, optErrorLabel: optErrorLabel, optLoading: optLoading, optViewTick: optViewTick, containerReUploadFormTextInput: containerReUploadFormTextInput, optReUploadBtn: optReUploadBtn };
            /** push the fileDetails into uploadFiles array */
            uploadFiles.push(uploadFile);
            /** add the uploadFile object fileSize to the totalFileSize */
            totalFilesSize += uploadFile.fileSize;
        }
        /** implement the duration of the optLoading and optViewTick */
        // uploadFiles.map(elem => {
        //     if (elem.optLoading) {
        //         setTimeout(() => {
        //             elem.optLoading = false;
        //             elem.optViewTick && setTimeout(() => {
        //                 elem.optViewTick = false;
        //             }, 100);
        //         }, elem.optLoadingTime || 2000);
        //     }
        // });
        this.setLoadingAndTick(uploadFiles);
        /** uploadFormTextInput - formTextInput component */
        var uploadFormTextInput = containerFormTextInputContainer && createFormTextInput(containerFormTextInputContainer, NO_PATTERN_BUSINESS_LOGIC);
        // /** set attr to the input element to accept more than one file */
        // uploadFormTextInput.getInputElement().setAttribute('multiple', '');
        // /** set trr to the input element to accept specific file types */
        // uploadFormTextInput.getInputElement().setAttribute('accept', '.pdf,.png,.jpg');
        this.setUploadInputAttributes(uploadFormTextInput, true);
        /** containerFormtextInput - formTextInput component props */
        var containerFormTextInput = uploadFormTextInput && uploadFormTextInput.getProperties();
        /** uploadButton - button component */
        var uploadButton = uploadButtonContainer && createButton(uploadButtonContainer, NO_PATTERN_BUSINESS_LOGIC);
        /** button - uploadButton component props */
        var containerButton = uploadButton && uploadButton.getProperties();
        return removeUndefinedFieldsFromObject({
            stdHeadline: stdHeadline,
            stdSubHeadline: stdSubHeadline,
            totalFilesSize: totalFilesSize,
            containerButton: containerButton,
            containerFormTextInput: containerFormTextInput,
            uploadFiles: uploadFiles,
            business: NO_PATTERN_BUSINESS_LOGIC,
            optErrorNotification: optErrorNotification,
        });
    };
    Upload.prototype.setUploadInputAttributes = function (formTextInputComponent, multiple) {
        multiple && formTextInputComponent.getInputElement().setAttribute('multiple', '');
        formTextInputComponent.getInputElement().setAttribute('accept', '.pdf,.png,.jpg');
    };
    Upload.prototype.setLoadingAndTick = function (uploadFiles) {
        uploadFiles.map(function (elem) {
            if (elem.optLoading) {
                setTimeout(function () {
                    elem.optLoading = false;
                    elem.optViewTick && setTimeout(function () {
                        elem.optViewTick = false;
                    }, 100);
                }, elem.optLoadingTime || 2000);
            }
        });
    };
    /**
     * createUploadButton
     */
    Upload.prototype.createUploadButton = function (buttonContainer) {
        var buttonProps = this.getUploadButtonProperties();
        buttonContainer = this.containerElement.getElementsByClassName(UPLOAD_BUTTON_CONTAINER_CLASSNAME)[0];
        createButton(buttonContainer, buttonProps);
        this.buttonContainer && this.buttonContainer.getElementsByTagName('button')[0].setAttribute('aria-label', buttonProps.stdContent);
    };
    /**
     * createUploadInput
     */
    Upload.prototype.createUploadInput = function (inputContainer) {
        var formTextInputProperties = this.getFormTextInputProperties();
        return createFormTextInput(inputContainer, formTextInputProperties);
    };
    /**
     * createErrorNotification
     */
    Upload.prototype.createErrorNotification = function (notificationContainer, errorType) {
        var notificationProps = this.getNotificationProperties(errorType);
        createNotification(notificationContainer, notificationProps);
    };
    /**
     * createDeleteButton
     */
    Upload.prototype.createDeleteButton = function (deleteButtonContainer) {
        var filesWithDeleteButtonIndexes = [];
        this.properties.uploadFiles && this.properties.uploadFiles.map(function (elem, index) {
            !elem.optLoading && !elem.optViewTick && filesWithDeleteButtonIndexes.push(index);
        });
        for (var i = 0; i < deleteButtonContainer.length; i++) {
            var deleteButtonProps = this.getButtonIconOnlyProperties(i);
            createButtonIconOnly(deleteButtonContainer[i], deleteButtonProps);
            deleteButtonContainer[i].getElementsByTagName('button')[0].setAttribute('title', DELETE_TITLE_ATTR);
            deleteButtonContainer[i].getElementsByTagName('button')[0].setAttribute('aria-describedby', this.properties.uploadFiles[filesWithDeleteButtonIndexes[i]].fileName.replace(/\s+/g, ''));
        }
    };
    /**
     * setLoadingAnimationAttributes
     */
    Upload.prototype.setLoadingAnimationAttributes = function (loadingAnimationContainer) {
        if (loadingAnimationContainer.length) {
            for (var i = 0; i < loadingAnimationContainer.length; i++) {
                loadingAnimationContainer[i].getElementsByClassName(LOADING_ANIMATION_BASE_CLASSNAME)[0].setAttribute('aria-describedby', this.properties.uploadFiles[i].fileName.replace(/\s+/g, ''));
                loadingAnimationContainer[i].getElementsByClassName(LOADING_ANIMATION_BASE_CLASSNAME)[0].setAttribute('title', LOADING_TITLE_ATTR);
            }
        }
    };
    /**
     * setSizeErrorLinkAttributes
     */
    Upload.prototype.setSizeErrorLinkAttributes = function (sizeErrorNotificationContainer) {
        var notificationStdLabel = sizeErrorNotificationContainer.getElementsByTagName('a')[0].innerText;
        var ariaValue = notificationStdLabel && notificationStdLabel.trim();
        ariaValue && sizeErrorNotificationContainer.getElementsByTagName('a')[0].setAttribute('aria-label', ariaValue);
    };
    /**
     * setTickAttributes
     */
    Upload.prototype.setTickAttributes = function (tickContainer) {
        if (tickContainer.length) {
            for (var i = 0; i < tickContainer.length; i++) {
                tickContainer[i].getElementsByTagName('svg')[0].setAttribute('role', 'img');
                tickContainer[i].getElementsByTagName('svg')[0].setAttribute('aria-label', TICK_ARIA_LABEL_ATTR);
            }
        }
    };
    /**
     * setUploadFileContainerAttributes
     */
    Upload.prototype.setUploadFileContainerAttributes = function (uploadFileContainer) {
        if (uploadFileContainer.length) {
            for (var i = 0; i < uploadFileContainer.length; i++) {
                uploadFileContainer[i].setAttribute('id', this.properties.uploadFiles[i].fileName.replace(/\s+/g, ''));
            }
        }
    };
    /**
     * createErrorLabel
     * @param errorLabelcontainer
     */
    Upload.prototype.createErrorLabel = function (errorLabelcontainer, filesWithErrorsIndexes) {
        if (errorLabelcontainer.length) {
            for (var i = 0; i < errorLabelcontainer.length; i++) {
                var labelContainerProps = this.getFormLableProperties(filesWithErrorsIndexes[i]);
                createFormLabel(errorLabelcontainer[i], labelContainerProps);
            }
        }
    };
    /**
     * createReuploadButton
     */
    Upload.prototype.createReuploadButton = function (reuploadButtonContainer, filesWithErrorsIndexes) {
        if (reuploadButtonContainer.length) {
            for (var i = 0; i < reuploadButtonContainer.length; i++) {
                var reuploadButtonProps = this.getReUploadButtonProperties(filesWithErrorsIndexes[i], i);
                this.reUploadButtonComponents[i] = createButton(reuploadButtonContainer[i], reuploadButtonProps);
                reuploadButtonContainer[i].getElementsByClassName(BUTTON_BASE_CLASSNAME)[0].setAttribute('aria-label', reuploadButtonProps.stdContent);
                reuploadButtonContainer[i].getElementsByClassName(BUTTON_BASE_CLASSNAME)[0].setAttribute('aria-describedby', this.properties.uploadFiles[filesWithErrorsIndexes[i]].fileName.replace(/\s+/g, ''));
            }
        }
    };
    /**
     * createReuploadInput
     */
    Upload.prototype.createReuploadInput = function (reuploadInputContainer, filesWithErrorsIndexes) {
        if (reuploadInputContainer.length) {
            for (var i = 0; i < reuploadInputContainer.length; i++) {
                var reUploadTextInputProperties = this.getReUplodFormTextInputProperties(filesWithErrorsIndexes[i], i);
                this.reUploadTextInputComponents[i] = createFormTextInput(reuploadInputContainer[i], reUploadTextInputProperties);
            }
        }
    };
    /**
     * initDomElemnts
     */
    Upload.prototype.initDomElements = function () {
        this.buttonContainer = this.containerElement.getElementsByClassName(UPLOAD_BUTTON_CONTAINER_CLASSNAME)[0];
        this.buttonContainer && this.createUploadButton(this.buttonContainer);
        this.reuploadButtonContainer = this.containerElement.getElementsByClassName(REUPLOAD_BUTTON_CONTAINER_CLASSNAME);
        this.uploadFileContainer = this.containerElement.getElementsByClassName(UPLOAD_FILE_DATA_CONTAINER_CLASSNAME);
        this.formTextInputContainer = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CONTAINER_CLASSNAME)[0];
        this.sizeErrorNotificationContainer = this.containerElement.getElementsByClassName(SIZE_ERROR_NOTIFICATION_CONTAINER_CLASSNAME)[0];
        this.lengthErrorNotificationContainer = this.containerElement.getElementsByClassName(LENGTH_ERROR_NOTIFICATION_CONTAINER_CLASSNAME)[0];
        this.reUploadInputContainer = this.containerElement.getElementsByClassName(REUPLOAD_INPUT_CONTAINER_CLASSNAME);
        this.formTextInputComponent = this.createUploadInput(this.formTextInputContainer);
        this.sizeErrorNotificationContainer && this.createErrorNotification(this.sizeErrorNotificationContainer, ErrorType.SizeError);
        this.lengthErrorNotificationContainer && this.createErrorNotification(this.lengthErrorNotificationContainer, ErrorType.LengthError);
        this.buttonIconOnlyContainer = this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_CONTAINER_CLASSNAME);
        this.tickContainer = this.containerElement.getElementsByClassName(TICK_CONTAINER_CLASSNAME);
        this.loadingAnimationContainer = this.containerElement.getElementsByClassName(LOADING_ANIMATION_CONTAINER_CLASSNAME);
        this.labelContainer = this.containerElement.getElementsByClassName(LABEL_CONTAINER_CLASSNAME);
        this.fileIconContainer = this.containerElement.getElementsByClassName(FILE_ICON_CONTAINER_CLASSNAME);
        this.loadingAnimationContainer.length && this.setLoadingAnimationAttributes(this.loadingAnimationContainer);
        this.tickContainer.length && this.setTickAttributes(this.tickContainer);
        this.sizeErrorNotificationContainer && this.setSizeErrorLinkAttributes(this.sizeErrorNotificationContainer);
        this.buttonIconOnlyContainer.length && this.createDeleteButton(this.buttonIconOnlyContainer);
        var filesWithErrorsIndexes = [];
        if (this.properties.uploadFiles && this.properties.uploadFiles.length) {
            this.properties.uploadFiles.map(function (elem, index) {
                elem.optErrorExists ? filesWithErrorsIndexes.push(index) : null;
                if (elem.optLoading) {
                    setTimeout(function () {
                        elem.optLoading = false;
                        elem.optViewTick && setTimeout(function () {
                            elem.optViewTick = false;
                        }, 100);
                    }, elem.optLoadingTime || 2000);
                }
            });
        }
        this.labelContainer.length && this.createErrorLabel(this.labelContainer, filesWithErrorsIndexes);
        this.uploadFileContainer.length && this.setUploadFileContainerAttributes(this.uploadFileContainer);
        this.reuploadButtonContainer.length && this.createReuploadButton(this.reuploadButtonContainer, filesWithErrorsIndexes);
        this.reUploadInputContainer.length && this.createReuploadInput(this.reUploadInputContainer, filesWithErrorsIndexes);
    };
    /** getUploadbuttonproperties */
    Upload.prototype.getUploadButtonProperties = function () {
        var _this = this;
        var business = {
            onClick: function () {
                _this.formTextInputComponent.getInputElement().click();
            },
        };
        var uploadFiles = this.properties.uploadFiles && this.properties.uploadFiles.length ? this.properties.uploadFiles : false;
        var fileWithError = uploadFiles && uploadFiles.find(function (file) { return file.optErrorExists; });
        var errorExist = !!fileWithError;
        var buttonProps = __assign(__assign({}, BUTTON_DEFAULT_PROPS), { optDisabled: (this.properties && this.properties.uploadFiles && this.properties.uploadFiles.length === 3) || errorExist, business: business });
        return buttonProps;
    };
    /** getReUploadbuttonproperties */
    Upload.prototype.getReUploadButtonProperties = function (fileIndex, inputIndex) {
        var _this = this;
        var business = {
            onClick: function () {
                _this.reUploadTextInputComponents[inputIndex].getInputElement().click();
            },
        };
        var buttonProps = __assign(__assign({}, BUTTON_REUPLOAD_PROPS), { business: business });
        return buttonProps;
    };
    /**
     * getNotificationProperties
     * @returns NotificationProperties
     *@param errorType : string
     * @description gets the properties of error notification
     */
    Upload.prototype.getNotificationProperties = function (errorType) {
        var notificationProps = errorType === ErrorType.SizeError ? SIZE_ERROR_NOTIFICATION_DEFAULT_PROPS
            : LENGTH_ERROR_NOTIFICATION_DEFAULT_PROPS;
        return notificationProps;
    };
    /**
     * @param fileIndex getButtonIconOnlyProperties
     * @returns ButtonIconOnlyProperties
     * @description returns the properties of the delete file button
     */
    Upload.prototype.getButtonIconOnlyProperties = function (fileIndex) {
        var _this = this;
        var business = { onClick: function () {
                var _a, _b;
                _this.properties.totalFilesSize -= _this.properties.uploadFiles[fileIndex].fileSize;
                _this.properties.uploadFiles.splice(fileIndex, 1);
                if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onDelete) {
                    _this.properties.business.onDelete(fileIndex);
                }
            } };
        var buttonIconOnlyProps = __assign(__assign({}, BUTTON_ICON_ONLY_PROPS), { business: business });
        return buttonIconOnlyProps;
    };
    /**
     * byteToMB
     * @param fileSize
     * @description takes the file size in Bytes and converts it to MB
     * @returns fileSize
     */
    Upload.prototype.byteToMB = function (fileSize) {
        return fileSize / FILE_SIZE_CONVER_BASE / FILE_SIZE_CONVER_BASE;
    };
    /**
     * getFormLableProperties
     * @description gets the properties of error label for each files
     * @returns FormLabelProperties
     */
    Upload.prototype.getFormLableProperties = function (id) {
        var formLabelProps = __assign(__assign({}, ERROR_LABEL_PROPS), { stdIdRelatedElement: "".concat(id) });
        return formLabelProps;
    };
    /**
     * handleChangeReUploadInput
     * @param event : the event on the input
     * @param id : the id of the file which will be replaced
     * @param inputIndex : the index of HTML INPUT ELEMENT
     */
    Upload.prototype.handleChangeReUploadInput = function (event, id, inputIndex) {
        var _a, _b;
        var reUploadInput = this.reUploadTextInputComponents[inputIndex].getInputElement();
        this.properties.totalFilesSize -= this.properties.uploadFiles[id].fileSize;
        this.properties.uploadFiles[id] = __assign(__assign({}, this.properties.uploadFiles[id]), { optErrorExists: false, fileName: reUploadInput.files[0].name, fileSize: this.byteToMB(reUploadInput.files[0].size) });
        this.properties.totalFilesSize += this.byteToMB(reUploadInput.files[0].size);
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onReUpload) {
            this.properties.business.onReUpload(event, reUploadInput.files);
        }
    };
    /**
     * handleChangeInput
     * @param event : the event on input
     */
    Upload.prototype.handleChangeInput = function (event) {
        var _this = this;
        var _a, _b;
        var input = this.formTextInputComponent.getInputElement();
        this.properties.uploadFiles = this.properties.uploadFiles || [];
        if (input.files.length) {
            var totalSize = this.properties.totalFilesSize || 0;
            if (input.files.length + this.properties.uploadFiles.length <= 3) {
                for (var i = 0; i < input.files.length; i++) {
                    var deleteButtonProps = this.getButtonIconOnlyProperties(i);
                    this.properties.uploadFiles.push({ fileName: input.files[i].name,
                        fileSize: this.byteToMB(input.files[i].size),
                        stdFileBtn: deleteButtonProps,
                        optViewTick: false,
                        optLoading: true,
                        optLoadingTime: 2000 });
                    totalSize += this.byteToMB(input.files[i].size);
                    this.properties.totalFilesSize = totalSize;
                }
                if (this.properties.totalFilesSize > 4) {
                    this.properties.optErrorNotification = this.getNotificationProperties(ErrorType.SizeError);
                }
            }
            else {
                this.properties.optErrorNotification = this.getNotificationProperties(ErrorType.LengthError);
            }
            this.properties.uploadFiles.map(function (elem, index) {
                if (elem.optLoading) {
                    setTimeout(function () {
                        _this.properties.uploadFiles[index].optLoading = false;
                        elem.optViewTick && setTimeout(function () {
                            elem.optViewTick = false;
                        }, 100);
                    }, elem.optLoadingTime || 2000);
                }
            });
        }
        var value = input.files;
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onUpload) {
            this.properties.business.onUpload(event, value);
        }
    };
    /**
     * getInputProperties
     *@return IFormTextInputProperties | IFormElementSharedBusinessLogic
     @description gets the properties of the uploadComponent main input element
     * */
    Upload.prototype.getFormTextInputProperties = function () {
        var _this = this;
        var business = {
            onChange: function (event) { return _this.handleChangeInput(event); },
        };
        var formTextInputProps = __assign(__assign({}, FORM_TEXT_INPUT_PROPS), { business: business });
        return formTextInputProps;
    };
    /**
     * getReUplodFormTextInputProperties
     * @param id : index of file will have reuploadInput
     * @param inputIndex : index of input element in HTMLCOLLECTION
     * @returns IFormTextInputProperties | IFormElementSharedBusinessLogic
     */
    Upload.prototype.getReUplodFormTextInputProperties = function (id, inputIndex) {
        var _this = this;
        var business = {
            onChange: function (event) { return _this.handleChangeReUploadInput(event, id, inputIndex); },
        };
        var formTextInputProps = __assign(__assign({}, REUPLOAD_INPUT_PROPS), { stdId: id, business: business });
        return formTextInputProps;
    };
    return Upload;
}(Pattern));
export { Upload };
/**
 * This function returns an instance of Accordion
 */
export var createUploadComponent = function (containerElement, businessLogicOrProperties) {
    var upload = new Upload(containerElement, businessLogicOrProperties);
    upload.init();
    return upload;
};
