/**
 * Classname to hide stuff
 */
export var CLASSNAME_HIDDEN = 'ws10-is-hidden';
/**
 * Classname to disable page scrolling
 */
export var CLASSNAME_NO_SCROLL = 'ws10-no-scroll';
/**
 * Classname for normal text block
 */
export var CLASSNAME_TEXT = 'ws10-text';
