var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './offer-summary-card.scss';
import offerSummaryCardTemplate from './_offer-summary-card.hbs';
import { Pattern, removeUndefinedFieldsFromObject, NO_PATTERN_BUSINESS_LOGIC, Price, stripHtml, toggleElementVisibility, } from '@vfde-brix/ws10/core';
import { OFFER_SUMMARY_CARD_ADDITIONAL_TEXT_CLASSNAME, OFFER_SUMMARY_CARD_BUTTONS_CLASSNAME, OFFER_SUMMARY_CARD_HIGHLIGHT_BADGE_CLASSNAME, OFFER_SUMMARY_CARD_HEADLINE_CLASSNAME, OFFER_SUMMARY_CARD_LEGAL_TEXT_CLASSNAME, OFFER_SUMMARY_CARD_PRICES_CLASSNAME, OFFER_SUMMARY_CARD_SUBLINE_TEXT_CLASSNAME, OFFER_SUMMARY_CARD_BUTTON_CLASSNAME, OFFER_SUMMARY_CARD_PROMO_PRICE_CLASSNAME, OFFER_SUMMARY_CARD_PRICE_LABEL_CLASSNAME, OFFER_SUMMARY_CARD_ADDITIONAL_PRICE_CLASSNAME, OFFER_SUMMARY_CARD_PRICE_VALUE_CLASSNAME, OFFER_SUMMARY_CARD_PRICE_TABLE_CAPTION_CLASSNAME, OFFER_SUMMARY_CARD_CONTENT_MIDDLE_CLASSNAME, } from './Constants';
import { createHeadline, HEADLINE_BASE_CLASSNAME, } from '@vfde-brix/ws10/headline';
import { createHighlightBadge, HIGHLIGHT_BADGE_BASE_CLASSNAME, } from '@vfde-brix/ws10/highlight-badge';
import { createPromoPrice, PromoPrice, PROMO_PRICE_BASE_CLASSNAME, } from '@vfde-brix/ws10/promo-price';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { isEqual, cloneDeep, } from 'lodash';
import { mergeProperties } from '../core/utils/merge';
/**
 * OfferSummaryCard class
 */
var OfferSummaryCard = /** @class */ (function (_super) {
    __extends(OfferSummaryCard, _super);
    /**
     * OfferSummaryCard constructor
     */
    function OfferSummaryCard(containerElement, properties) {
        var _this = _super.call(this, containerElement, properties) || this;
        _this.originalProperties = null;
        _this.highlightBadge = null;
        _this.headline = null;
        _this.promoPrices = [];
        _this.buttons = [];
        _this.stickyPriceBar = null;
        return _this;
    }
    /**
     * Return default properties
     */
    OfferSummaryCard.prototype.getDefaultProperties = function (newProperties) {
        var _a;
        (_a = newProperties.containerPromoPrices) === null || _a === void 0 ? void 0 : _a.forEach(function (containerPromoPrice) {
            containerPromoPrice.containerPromoPrice = PromoPrice.setDefaultProps(containerPromoPrice.containerPromoPrice);
        });
        return newProperties;
    };
    /**
     * Write DOM
     */
    OfferSummaryCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = offerSummaryCardTemplate(this.properties);
    };
    /**
     * Inits all child components and assigns
     * them to the class properties.
     * Called after writeDom and before readDom.
     */
    OfferSummaryCard.prototype.initDom = function (isWriteDom) {
        var _this = this;
        var _a, _b;
        // Mount single-container-components
        this.highlightBadge = this.mountHighlightBadge(isWriteDom, (_a = this.properties) === null || _a === void 0 ? void 0 : _a.containerHighlightBadge);
        this.headline = this.mountHeadline(isWriteDom, (_b = this.properties) === null || _b === void 0 ? void 0 : _b.containerHeadline);
        // Mount multi-container-components.
        // Why we pass the business objects only to the mounting methods for multi-container-components:
        // Multi-Container components are always mounted using 'readDom'. This is because as soon
        // as a component is added or removed the OfferSummaryCard re-renders all components of that kind
        // using Handlebars partial rendering. After that, we just apply the business logic from our code.
        // That is also the reason, why these methods don't have a 'writeDom' param because they are always mounted using 'readDom'.
        this.promoPrices = this.mountPromoPrices(this.getPromoPriceTableRows()
            .map(function (priceContainer, i) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerPromoPrices) === null || _b === void 0 ? void 0 : _b[i].containerPromoPrice) === null || _c === void 0 ? void 0 : _c.business) !== null && _d !== void 0 ? _d : NO_PATTERN_BUSINESS_LOGIC; }));
        this.buttons = this.mountButtons(this.getButtonContainers()
            .map(function (buttonContainer, i) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerButtons) === null || _b === void 0 ? void 0 : _b[i]) === null || _c === void 0 ? void 0 : _c.business) !== null && _d !== void 0 ? _d : NO_PATTERN_BUSINESS_LOGIC; }));
    };
    /**
     * Read properties from DOM
     */
    OfferSummaryCard.prototype.readDom = function (business) {
        var _a, _b;
        var stdPriceTableCaption = (_a = this.getPriceTableCaption()) === null || _a === void 0 ? void 0 : _a.textContent.trim();
        var sublineTextContainer = this.getSublineTextContainer();
        var legalTextContainer = this.getLegalTextContainer();
        var additionalTextContainer = this.getAdditionalTextContainer();
        var txtSubline = this.getChildrenHtml(sublineTextContainer);
        var txtLegalText = this.getChildrenHtml(legalTextContainer);
        var txtAdditionalText = this.getChildrenHtml(additionalTextContainer);
        var containerHighlightBadge = (_b = this.highlightBadge) === null || _b === void 0 ? void 0 : _b.getProperties();
        var containerHeadline = this.headline.getProperties();
        var promoPriceContainers = this.getPromoPriceTableRows();
        var containerPromoPrices = this.promoPrices.map(function (promoPrice, i) { return ({
            txtLabel: promoPriceContainers[i].getElementsByClassName(OFFER_SUMMARY_CARD_PRICE_LABEL_CLASSNAME)[0].innerHTML.trim(),
            containerPromoPrice: promoPrice.getProperties(),
        }); });
        var containerAdditionalPrices = this.getAdditionalPriceTableRows().map(function (priceContainer) {
            var txtLabel = priceContainer.getElementsByClassName(OFFER_SUMMARY_CARD_PRICE_LABEL_CLASSNAME)[0].innerHTML.trim();
            var priceValue = priceContainer.getElementsByClassName(OFFER_SUMMARY_CARD_PRICE_VALUE_CLASSNAME)[0].innerHTML.trim();
            return {
                txtLabel: txtLabel,
                price: Price.fromString(priceValue),
            };
        });
        var containerButtons = this.buttons.map(function (button) { return button.getProperties(); });
        var properties = {
            stdPriceTableCaption: stdPriceTableCaption,
            txtSubline: txtSubline,
            txtLegalText: txtLegalText,
            txtAdditionalText: txtAdditionalText,
            containerHighlightBadge: containerHighlightBadge,
            containerHeadline: containerHeadline,
            containerPromoPrices: containerPromoPrices,
            containerAdditionalPrices: containerAdditionalPrices,
            containerButtons: containerButtons,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Called after the initial readDom/writeDom
     */
    OfferSummaryCard.prototype.afterInit = function () {
        this.originalProperties = this.getProperties();
    };
    /**
     * Returns the original/first properties that the OfferSummaryCard was mounted with (either statically via readDom or dynamically via writeDom)
     * @returns The original properties object (deeply cloned)
     */
    OfferSummaryCard.prototype.getOriginalProperties = function () {
        return cloneDeep(this.originalProperties);
    };
    /**
     * Returns the innerHTML of each child element of the given element
     * @param element The element
     * @returns Array of innerHTML strings of each child
     */
    OfferSummaryCard.prototype.getChildrenHtml = function (element) {
        return Array.from(element.children).map(function (child) { return child.innerHTML; });
    };
    /**
     * Not implemented update method.
     * Use invidual update methods instead.
     * @throws Error
     */
    OfferSummaryCard.prototype.update = function () {
        throw new Error('Not implemented. Use invidual update methods instead.');
    };
    // endregion
    // #endregion
    // #region Component mounting (VSCode)
    // region Component mounting (Webstorm)
    /**
     * Mounts the highlightBadge
     * @param isWriteDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param highlightBadgeProperties The highlightBadge properties (required when writeDom=true)
     */
    OfferSummaryCard.prototype.mountHighlightBadge = function (isWriteDom, highlightBadgeProperties) {
        return this.mountSingleContainerComponent(this.getHighlightBadgeContainer(), HIGHLIGHT_BADGE_BASE_CLASSNAME, isWriteDom, createHighlightBadge, isWriteDom ? highlightBadgeProperties : NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts the headline
     * @param isWriteDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param headlineProperties The headline properties (required when writeDom=true)
     */
    OfferSummaryCard.prototype.mountHeadline = function (isWriteDom, headlineProperties) {
        return this.mountSingleContainerComponent(this.getHeadlineContainer(), HEADLINE_BASE_CLASSNAME, isWriteDom, createHeadline, isWriteDom ? headlineProperties : NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts the component in a container which can only include
     * a single component (i. e. highlightBadge and headline)
     * @param containerElement The containerElement
     * @param componentClassName The class name of the component element within containerElement
     * @param writeDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param factory The component factory function
     * @param propertiesOrBusiness The component properties (required when writeDom=true) or business (required when writeDom=false)
     */
    OfferSummaryCard.prototype.mountSingleContainerComponent = function (containerElement, componentClassName, writeDom, factory, propertiesOrBusiness) {
        var component;
        if (writeDom) {
            // mount component dynamically using 'writeDom'
            component = propertiesOrBusiness && factory(containerElement, propertiesOrBusiness);
        }
        else {
            // mount component statically using 'readDom'
            var componentElement = containerElement.getElementsByClassName(componentClassName)[0];
            component = componentElement && factory(componentElement.parentElement, propertiesOrBusiness);
        }
        return component;
    };
    /**
     * Mounts the promoPrices
     * @param promoPriceBusinessObjects The promoPrice business objects
     */
    OfferSummaryCard.prototype.mountPromoPrices = function (promoPriceBusinessObjects) {
        return this.mountMultiContainerComponents(this.getPromoPriceTableRows(), PROMO_PRICE_BASE_CLASSNAME, createPromoPrice, promoPriceBusinessObjects);
    };
    /**
     * Mounts the buttons
     * @param buttonBusinessObjects The button business objects
     */
    OfferSummaryCard.prototype.mountButtons = function (buttonBusinessObjects) {
        return this.mountMultiContainerComponents(this.getButtonContainers(), BUTTON_BASE_CLASSNAME, createButton, buttonBusinessObjects);
    };
    /**
     * Mounts the components in a container which may
     * include multiple components (i. e. promoPrices and buttons).
     * This mounting is always done using 'readDom'.
     * That's why the function accepts the business object only and not the full properties.
     * @param containerElements Array of containerElements
     * @param componentClassName The class name of the component element within containerElement
     * @param factory The component factory function
     * @param businessObjects The components business objects
     */
    OfferSummaryCard.prototype.mountMultiContainerComponents = function (containerElements, componentClassName, factory, businessObjects) {
        var components = [];
        containerElements.forEach(function (containerElement, i) {
            var componentElement = containerElement.getElementsByClassName(componentClassName)[0];
            var component = componentElement && factory(componentElement.parentElement, businessObjects[i]);
            components.push(component);
        });
        return components;
    };
    // endregion
    // #endregion
    // #region Component updating (VSCode)
    // region Component updating (Webstorm)
    /**
     * Updates the highlightBadge individually
     */
    OfferSummaryCard.prototype.updateHighlightBadge = function (newHighlightBadgeProperties) {
        this.updateSingleContainerComponent(this.getHighlightBadgeContainer.bind(this), newHighlightBadgeProperties, 'containerHighlightBadge', 'highlightBadge', this.mountHighlightBadge.bind(this));
        return this;
    };
    /**
     * Updates the headline individually
     */
    OfferSummaryCard.prototype.updateHeadline = function (newHeadlineProperties) {
        this.updateSingleContainerComponent(this.getHeadlineContainer.bind(this), newHeadlineProperties, 'containerHeadline', 'headline', this.mountHeadline.bind(this));
        return this;
    };
    /**
     * Updates the component in a container which can only include
     * a single component (i. e. highlightBadge and headline)
     * @param getContainerElement A function which returns the containerElement
     * @param newProperties The new component properties for update
     * @param propertiesPropertyName The name of the properties property
     * @param componentPropertyName The name of the component property
     * @param mountingMethod The method to mount the component
     */
    OfferSummaryCard.prototype.updateSingleContainerComponent = function (getContainerElement, newProperties, propertiesPropertyName, componentPropertyName, mountingMethod) {
        var _a;
        var container = getContainerElement();
        toggleElementVisibility(container, !!newProperties);
        if (!newProperties) {
            // remove component props and instance, clear container content
            this.properties[propertiesPropertyName] = newProperties;
            this[componentPropertyName] = null;
            container.textContent = '';
            return;
        }
        // use previousProps or originalProps
        var baseProps = (_a = this.properties[propertiesPropertyName]) !== null && _a !== void 0 ? _a : this.getOriginalProperties()[propertiesPropertyName];
        var propsToUpdate = mergeProperties(baseProps, newProperties);
        // update component
        if (this[componentPropertyName]) {
            // update existing component
            this[componentPropertyName].update(propsToUpdate);
        }
        else {
            // render new component
            this[componentPropertyName] = mountingMethod(true, __assign(__assign({}, propsToUpdate), { business: propsToUpdate.business || NO_PATTERN_BUSINESS_LOGIC }));
        }
        // backsync the properties of the component to the OfferSummaryCard properties
        this.properties[propertiesPropertyName] = this[componentPropertyName].getProperties();
    };
    /**
     * Get updated properties for multi components, merges with previous or default props
     * Can be toggled with mergeDefault
     */
    OfferSummaryCard.prototype.getUpdatedMultiComponentProperties = function (newProperties, propertiesPropertyName) {
        var _this = this;
        // If properties don't need to be updated just return old props
        if (!newProperties) {
            return this.properties[propertiesPropertyName];
        }
        // Merge properties with previous properties if previous properties don't exist (removed) merge them with default properties
        return newProperties.map(function (newProps, index) {
            var _a;
            // use previous properties
            var previousProperties = _this.properties[propertiesPropertyName][index];
            if (previousProperties) {
                return mergeProperties(previousProperties, newProps);
            }
            // if previous properties are not given, use original properties
            var originalPropertiesList = _this.getOriginalProperties()[propertiesPropertyName];
            var originalProperties = (_a = originalPropertiesList === null || originalPropertiesList === void 0 ? void 0 : originalPropertiesList[index]) !== null && _a !== void 0 ? _a : originalPropertiesList === null || originalPropertiesList === void 0 ? void 0 : originalPropertiesList[0];
            return mergeProperties(originalProperties, newProps);
        });
    };
    /**
     * Updates the promoPrices and additionalPrices which re-renders the whole prices container.
     */
    OfferSummaryCard.prototype.updatePrices = function (promoPrices, additionalPrices) {
        var _this = this;
        var containerPromoPrices = this.getUpdatedMultiComponentProperties(promoPrices, 'containerPromoPrices');
        var containerAdditionalPrices = this.getUpdatedMultiComponentProperties(additionalPrices, 'containerAdditionalPrices');
        var properties = {
            stdPriceTableCaption: this.properties.stdPriceTableCaption,
            containerPromoPrices: containerPromoPrices,
            containerAdditionalPrices: containerAdditionalPrices,
        };
        this.properties.containerPromoPrices = containerPromoPrices;
        this.properties.containerAdditionalPrices = containerAdditionalPrices;
        this.updateMultiComponentContainer(this.getPricesContainer(), OFFER_SUMMARY_CARD_PRICES_CLASSNAME, properties, 'promoPrices', function () { return _this.mountPromoPrices(containerPromoPrices.map(function (price) { return price.containerPromoPrice.business; })); }, function (index, props) {
            // backsync the properties of each component to the OfferSummaryCard properties
            _this.properties.containerPromoPrices[index].containerPromoPrice = props;
        });
        var hasPrices = !!(containerPromoPrices.length || containerAdditionalPrices.length);
        // show or hide the whole middle content depending on if there is at least one price
        toggleElementVisibility(this.getMiddleContent(), hasPrices);
        return this;
    };
    /**
     * Updates the buttons which re-renders the whole buttons container.
     */
    OfferSummaryCard.prototype.updateButtons = function (buttons) {
        var _this = this;
        var containerButtons = this.getUpdatedMultiComponentProperties(buttons, 'containerButtons');
        var properties = {
            containerButtons: containerButtons,
        };
        this.properties.containerButtons = containerButtons;
        this.updateMultiComponentContainer(this.getButtonsContainer(), OFFER_SUMMARY_CARD_BUTTONS_CLASSNAME, properties, 'buttons', function () { return _this.mountButtons(containerButtons.map(function (button) { return button.business; })); }, function (index, props) {
            // backsync the properties of each component to the OfferSummaryCard properties
            _this.properties.containerButtons[index] = props;
        });
        return this;
    };
    /**
     * Updates the components in a container which may
     * include multiple components (i. e. promoPrices and buttons)
     * @param containerElement The container element
     * @param containerClassName The class name of the container in the offer-price in which the update is done
     * @param properties The new properties
     * @param componentsPropertyName The name of the components property
     * @param mountComponents A callback function which mounts all the components in the container.
     * @param backSyncProperties A callback function which is called for each component to backsync the properties
     */
    OfferSummaryCard.prototype.updateMultiComponentContainer = function (containerElement, containerClassName, properties, componentsPropertyName, mountComponents, backSyncProperties) {
        // rerender the container
        containerElement.innerHTML = this.renderPartialOfferSummaryCard(properties, containerClassName);
        // mount the components
        this[componentsPropertyName] = mountComponents();
        this[componentsPropertyName].forEach(function (component, index) {
            // backsync the properties of each component to the OfferSummaryCard properties
            backSyncProperties(index, component.getProperties());
        });
    };
    /**
     * Updates the sublineText individually
     */
    OfferSummaryCard.prototype.updateSubline = function (newSubline) {
        this.updateText('txtSubline', newSubline, this.getSublineTextContainer.bind(this));
        return this;
    };
    /**
     * Updates the legalText individually
     * @todo add array logic
     */
    OfferSummaryCard.prototype.updateLegalText = function (newLegalText) {
        this.updateText('txtLegalText', newLegalText, this.getLegalTextContainer.bind(this));
        return this;
    };
    /**
     * Updates the additionalText individually
     */
    OfferSummaryCard.prototype.updateAdditionalText = function (newAdditionalText) {
        this.updateText('txtAdditionalText', newAdditionalText, this.getAdditionalTextContainer.bind(this));
        return this;
    };
    /**
     * Updates a text property individually
     * (stdSubline, txtLegalText, txtAdditionalText)
     */
    OfferSummaryCard.prototype.updateText = function (propertyName, value, getContainerElement) {
        var _this = this;
        var _a;
        if (isEqual(this.properties[propertyName], value)) {
            return;
        }
        this.properties[propertyName] = value;
        var containerElement = getContainerElement();
        var hasValue = !!((_a = this.properties[propertyName]) === null || _a === void 0 ? void 0 : _a.length);
        toggleElementVisibility(containerElement, hasValue);
        if (!hasValue) {
            containerElement.textContent = '';
            return;
        }
        var html = value.map(function (val) { return _this.wrapTextInParagraph(val); }).join('');
        containerElement.innerHTML = html;
    };
    /**
     * Wraps the given text in a <p> tag
     */
    OfferSummaryCard.prototype.wrapTextInParagraph = function (text) {
        return "<p>".concat(text, "</p>");
    };
    // endregion
    // #endregion
    // #region Rendering (VSCode)
    // region Rendering (Webstorm)
    /**
     * Renders the OfferSummaryCard partially and returns only the innerHTML of the container with the given class
     * @param properties The OfferSummaryCard properties
     * @param containerClassName The classname of the container
     * @returns The innerHTML of the found container
     */
    OfferSummaryCard.prototype.renderPartialOfferSummaryCard = function (properties, containerClassName) {
        return this.renderPartially(offerSummaryCardTemplate, properties, ".".concat(containerClassName))[0];
    };
    // endregion
    // #endregion
    // #region HTML Getter Methods (VSCode)
    // region HTML Getter Methods (Webstorm)
    OfferSummaryCard.prototype.getHighlightBadgeContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_HIGHLIGHT_BADGE_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getMiddleContent = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_CONTENT_MIDDLE_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getHeadlineContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_HEADLINE_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getPricesContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_PRICES_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getPriceTableCaption = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_PRICE_TABLE_CAPTION_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getPromoPriceTableRows = function () {
        return Array.from(this.getPricesContainer().getElementsByClassName(OFFER_SUMMARY_CARD_PROMO_PRICE_CLASSNAME));
    };
    OfferSummaryCard.prototype.getAdditionalPriceTableRows = function () {
        return Array.from(this.getPricesContainer().getElementsByClassName(OFFER_SUMMARY_CARD_ADDITIONAL_PRICE_CLASSNAME));
    };
    OfferSummaryCard.prototype.getButtonsContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_BUTTONS_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getButtonContainers = function () {
        return Array.from(this.getButtonsContainer().getElementsByClassName(OFFER_SUMMARY_CARD_BUTTON_CLASSNAME));
    };
    OfferSummaryCard.prototype.getSublineTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_SUBLINE_TEXT_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getLegalTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_LEGAL_TEXT_CLASSNAME)[0];
    };
    OfferSummaryCard.prototype.getAdditionalTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_ADDITIONAL_TEXT_CLASSNAME)[0];
    };
    // endregion
    // #endregion
    // #region Toggle Methods (VSCode)
    // region Toggle Methods (Webstorm)
    /**
     * Toggles the highlightBadgeContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleHighlightBadgeContainer = function (shouldShow) {
        toggleElementVisibility(this.getHighlightBadgeContainer(), shouldShow);
    };
    /**
     * Toggles the sublineTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleSublineTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getSublineTextContainer(), shouldShow);
    };
    /**
     * Toggles the legalTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleLegalTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getLegalTextContainer(), shouldShow);
    };
    /**
     * Toggles the additionalTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleAdditionalTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getAdditionalTextContainer(), shouldShow);
    };
    /**
     * Toggles the middle content visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleMiddleContentContainer = function (shouldShow) {
        toggleElementVisibility(this.getMiddleContent(), shouldShow);
    };
    /**
     * Toggles the pricesContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.togglePricesContainer = function (shouldShow) {
        toggleElementVisibility(this.getPricesContainer(), shouldShow);
    };
    /**
     * Toggles the buttonsContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferSummaryCard.prototype.toggleButtonsContainer = function (shouldShow) {
        toggleElementVisibility(this.getButtonsContainer(), shouldShow);
    };
    // endregion
    // #endregion
    /**
     * Connects the StickyPriceBar to the OfferSummaryCard.
     * The StickyPriceBar should be hidden when the OfferSummaryCard is in the viewport
     * or the viewport is below the OfferSummaryCard component.
     * The headline of the OfferSummaryCard is used as reference.
     * @param stickyPriceBar sticky price bar component
     */
    OfferSummaryCard.prototype.connectStickyPriceBar = function (stickyPriceBar) {
        var _this = this;
        if (this.stickyPriceBar) {
            throw new Error('A stickyPriceBar was already connected');
        }
        this.stickyPriceBar = stickyPriceBar;
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                var entryRect = entry.boundingClientRect;
                var shouldBeVisible = !entry.isIntersecting && entryRect.bottom > window.innerHeight;
                _this.stickyPriceBar.toggleVisibility(shouldBeVisible);
            });
        });
        var headline = this.containerElement.getElementsByClassName(OFFER_SUMMARY_CARD_HEADLINE_CLASSNAME)[0];
        observer.observe(headline);
    };
    /**
     * Converts the given promoPriceItems to stickyPriceBarItems
     * @param promoPriceItems A single promoPriceItem or an array of promoPriceItems.
     * @returns An array of StickyPriceBarItems
     */
    OfferSummaryCard.convertPromoPriceItemsToStickyPriceBarItems = function (promoPriceItems) {
        if (!promoPriceItems) {
            return [];
        }
        return (Array.isArray(promoPriceItems) ? promoPriceItems : [promoPriceItems])
            .filter(Boolean)
            .map(function (promoPriceItem) { return ({
            optPriceConditionBefore: promoPriceItem.containerPromoPrice.optPriceConditionBefore,
            price: promoPriceItem.containerPromoPrice.price,
            stdText: stripHtml(promoPriceItem.txtLabel),
        }); });
    };
    /**
     * Converts the given additionalPriceItems to stickyPriceBarItems
     * @param additionalPriceItems A single additionalPriceItem or an array of additionalPriceItems
     * @returns An array of StickyPriceBarItems
     */
    OfferSummaryCard.convertAdditionalPriceItemsToStickyPriceBarItems = function (additionalPriceItems) {
        if (!additionalPriceItems) {
            return [];
        }
        return (Array.isArray(additionalPriceItems) ? additionalPriceItems : [additionalPriceItems])
            .filter(Boolean)
            .map(function (promoPriceItem) { return ({
            price: promoPriceItem.price,
            stdText: stripHtml(promoPriceItem.txtLabel),
        }); });
    };
    return OfferSummaryCard;
}(Pattern));
export { OfferSummaryCard };
/**
 * This function returns a new instance of OfferSummaryCard
 */
export var createOfferSummaryCard = function (containerElement, businessLogicOrProperties) {
    var offerSummaryCard = new OfferSummaryCard(containerElement, businessLogicOrProperties);
    offerSummaryCard.init();
    return offerSummaryCard;
};
