var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './promotional-summary-card.scss';
import renderTemplate from './_promotional-summary-card.hbs';
import { Pattern, removeUndefinedFieldsFromObject, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CHECKBOX_CLASSNAME, PROMOTIONAL_SUMMARY_CARD_HEADLINE_CLASSNAME, PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_ICON_TEXT_CLASSNAME, } from './Constants';
import { createIconText, } from '@vfde-brix/ws10/icon-text';
import { createFormSelectionControl, } from '@vfde-brix/ws10/form-selection-control';
/**
 * WS10 PromotionalSummaryCard
 */
var PromotionalSummaryCard = /** @class */ (function (_super) {
    __extends(PromotionalSummaryCard, _super);
    /**
     * Constructor
     */
    function PromotionalSummaryCard(containerElement, properties) {
        var _this = _super.call(this, containerElement, properties) || this;
        _this.headlineContainer = null;
        _this.headlineIconText = null;
        _this.checkboxElements = null;
        _this.iconTextElements = null;
        _this.formSelectionControlItems = null;
        _this.iconTextItems = null;
        return _this;
    }
    /**
     * Get all elements and mount components
     */
    PromotionalSummaryCard.prototype.initDom = function () {
        this.getElements();
        this.mountComponents();
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    PromotionalSummaryCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    PromotionalSummaryCard.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    PromotionalSummaryCard.prototype.readDom = function (business) {
        var _a;
        var containerIconTextHeadline = ((_a = this.headlineIconText) === null || _a === void 0 ? void 0 : _a.getProperties()) || null;
        var containerFormSelectionControls = this.formSelectionControlItems
            .map(function (formSelectionControlItem) { return (__assign({}, formSelectionControlItem.getProperties())); });
        var containerIconTexts = this.iconTextItems
            .map(function (iconTextItem) { return (__assign({}, iconTextItem.getProperties())); });
        var properties = removeUndefinedFieldsFromObject({
            containerIconTextHeadline: containerIconTextHeadline,
            containerFormSelectionControls: containerFormSelectionControls,
            containerIconTexts: containerIconTexts,
            business: business,
        });
        return properties;
    };
    /**
     * Reads the elements from DOM
     * and assigns them to the
     * class properties
     */
    PromotionalSummaryCard.prototype.getElements = function () {
        this.headlineContainer =
            this.containerElement.getElementsByClassName(PROMOTIONAL_SUMMARY_CARD_HEADLINE_CLASSNAME)[0];
        this.checkboxElements =
            __spreadArray([], __read(this.containerElement.getElementsByClassName(PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CHECKBOX_CLASSNAME)), false);
        this.iconTextElements =
            __spreadArray([], __read(this.containerElement.getElementsByClassName(PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_ICON_TEXT_CLASSNAME)), false);
    };
    /**
     * Mount child components
     */
    PromotionalSummaryCard.prototype.mountComponents = function () {
        this.headlineIconText = this.mountHeadlineIconText();
        this.formSelectionControlItems = this.mountformSelectionControlItems();
        this.iconTextItems = this.mountIconTextItems();
    };
    /**
     * Mounts the headline iconText component
     */
    PromotionalSummaryCard.prototype.mountHeadlineIconText = function () {
        return createIconText(this.headlineContainer, 
        // if there are properties already, it is writeDom, otherwise readDom
        this.properties ? this.properties.containerIconTextHeadline : NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts FormSelectionControl components
     */
    PromotionalSummaryCard.prototype.mountformSelectionControlItems = function () {
        var _this = this;
        var formSelectionControlItems = [];
        this.checkboxElements.map(function (checkboxElement, index) {
            var _a;
            var formSelectionControlProperties = _this.getFormSelectionControlProperties((_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerFormSelectionControls[index]);
            formSelectionControlItems.push(createFormSelectionControl(checkboxElement, formSelectionControlProperties || {
                onChange: function (event, value) {
                    var _a, _b, _c;
                    (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onCheckboxChange) === null || _c === void 0 ? void 0 : _c.call(_b, event, value);
                },
            }));
        });
        return formSelectionControlItems;
    };
    /**
     * Mounts IconText components
     */
    PromotionalSummaryCard.prototype.mountIconTextItems = function () {
        var _this = this;
        var iconTextItems = [];
        this.iconTextElements.map(function (iconTextElement, index) {
            iconTextItems.push(createIconText(iconTextElement, 
            // if there are properties already, it is writeDom, otherwise readDom
            _this.properties ? _this.properties.containerIconTexts[index] : NO_PATTERN_BUSINESS_LOGIC));
        });
        return iconTextItems;
    };
    /**
     * get form selection control parameters from DOM
     */
    PromotionalSummaryCard.prototype.getFormSelectionControlProperties = function (formSelectionControlProperties) {
        var _this = this;
        var onChange = function (event, value) {
            var _a, _b, _c;
            (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onCheckboxChange) === null || _c === void 0 ? void 0 : _c.call(_b, event, value);
        };
        if (formSelectionControlProperties) {
            return __assign(__assign({}, formSelectionControlProperties), { business: {
                    onChange: onChange,
                } });
        }
        return undefined;
    };
    return PromotionalSummaryCard;
}(Pattern));
export { PromotionalSummaryCard };
/**
 * This function returns an instance of PromotionalSummaryCard
 */
export var createPromotionalSummaryCard = function (containerElement, businessLogicOrProperties) {
    var promotionalSummaryCard = new PromotionalSummaryCard(containerElement, businessLogicOrProperties);
    promotionalSummaryCard.init();
    return promotionalSummaryCard;
};
