var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './icon-text-list.scss';
import renderTemplate from './_icon-text-list.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { ICON_TEXT_LIST_BASE_CLASSNAME, ICON_TEXT_LIST_HEADLINE_CLASSNAME, ICON_TEXT_LIST_ITEM_CLASSNAME, ICON_TEXT_LIST_SPACED_CLASSNAME, } from './Constants';
import { createIconText, } from '@vfde-brix/ws10/icon-text/IconText';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
/**
 * Icon-text class
 */
var IconTextList = /** @class */ (function (_super) {
    __extends(IconTextList, _super);
    /**
     * Constructor
     */
    function IconTextList(containerElement, properties) {
        var _this = _super.call(this, containerElement, properties) || this;
        _this.iconTextListElement = null;
        _this.headlineContainer = null;
        _this.listItems = null;
        _this.listItemIconTexts = null;
        return _this;
    }
    /**
     * Get defaults
     */
    IconTextList.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optSpaced === undefined) {
            newProperties.optSpaced = true;
        }
        return newProperties;
    };
    /**
     * Render
     */
    IconTextList.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.getElements();
        this.mountComponents();
    };
    /**
     * Read from DOM
     */
    IconTextList.prototype.readDom = function (business) {
        var _this = this;
        var _a;
        this.getElements();
        this.moveHiddenClassFromNestedDivToListItem();
        this.mountComponents();
        var containerHeadline = ((_a = this.headlineIconText) === null || _a === void 0 ? void 0 : _a.getProperties()) || null;
        var containerItems = this.listItems
            .map(function (listItem, index) { return (__assign(__assign({}, _this.listItemIconTexts[index].getProperties()), { optHidden: listItem.classList.contains(CLASSNAME_HIDDEN) })); });
        var optSpaced = this.iconTextListElement.classList.contains(ICON_TEXT_LIST_SPACED_CLASSNAME);
        var properties = removeUndefinedFieldsFromObject({
            containerHeadline: containerHeadline,
            containerItems: containerItems,
            optSpaced: optSpaced,
            business: business,
        });
        return properties;
    };
    /**
     * Reads the elements from DOM
     * and assigns them to the
     * class properties
     */
    IconTextList.prototype.getElements = function () {
        this.iconTextListElement = this.containerElement.getElementsByClassName(ICON_TEXT_LIST_BASE_CLASSNAME)[0];
        this.headlineContainer = this.containerElement.getElementsByClassName(ICON_TEXT_LIST_HEADLINE_CLASSNAME)[0] || null;
        this.listItems = Array.from(this.containerElement.getElementsByClassName(ICON_TEXT_LIST_ITEM_CLASSNAME));
    };
    /**
     * Mount child components
     */
    IconTextList.prototype.mountComponents = function () {
        this.headlineIconText = this.mountHeadlineIconText();
        this.listItemIconTexts = this.mountListItemIconTexts();
    };
    /**
     * Mounts the headline iconText component
     */
    IconTextList.prototype.mountHeadlineIconText = function () {
        if (!this.headlineContainer) {
            return null;
        }
        return createIconText(this.headlineContainer, NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts the listItems iconText components
     */
    IconTextList.prototype.mountListItemIconTexts = function () {
        var e_1, _a;
        var iconTexts = [];
        try {
            for (var _b = __values(this.listItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                var listItem = _c.value;
                iconTexts.push(createIconText(listItem, NO_PATTERN_BUSINESS_LOGIC));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return iconTexts;
    };
    /**
     * Moves the possibly present 'ws10-is-hidden' class from the firstElementChild
     * (the <div> from the iconText OR the <div> around the <div> from the iconText
     * which is rendered by the CMS only) to the <li> itself
     */
    IconTextList.prototype.moveHiddenClassFromNestedDivToListItem = function () {
        /* istanbul ignore next */
        this.listItems.forEach(function (listItem) {
            if (listItem.firstElementChild.classList.contains(CLASSNAME_HIDDEN)) {
                listItem.classList.add(CLASSNAME_HIDDEN);
                listItem.firstElementChild.classList.remove(CLASSNAME_HIDDEN);
            }
        });
    };
    /**
     * Throws a RangeError if no item exists on the given index
     * @param index The index to check
     */
    IconTextList.prototype.assertItemExists = function (index) {
        if (!this.listItems[index]) {
            throw new RangeError("Item not found on index '".concat(index, "'"));
        }
    };
    // #region Public methods
    /**
     * Toggles the visibility of a single IconText component by index.
     * If no IconText component remains visible, this method will hide
     * the IconTextList component itself.
     * @param index The index of the IconText to toggle
     * @param shouldShow Optional boolean to show or hide the listItem
     * at the given index. Undefined will toggle the items' visiblity.
     * @param toggleComponent If this option is true (default)
     * and the last remaining listItem was hidden, the whole IconTextList
     * component will also be hidden.
     * @param toggleComponentWithPrecedingHeadline If this option is true,
     * and the IconTextList component is hidden, the IconTextLists preceding
     * headline will also be hidden.
     */
    IconTextList.prototype.toggleItem = function (index, shouldShow, toggleComponent, toggleComponentWithPrecedingHeadline) {
        if (toggleComponent === void 0) { toggleComponent = true; }
        if (toggleComponentWithPrecedingHeadline === void 0) { toggleComponentWithPrecedingHeadline = true; }
        this.assertItemExists(index);
        var listItem = this.listItems[index];
        var shouldShowIconText = typeof shouldShow === 'boolean' ? shouldShow : !this.isItemVisible(index);
        // the firstElementChild can be an additional <div> which is exclusively rendered
        // from CMS with classes for spacing and visiblity around the icon-text elements
        // so we always remove the hidden class from it, and attach it to the list element instead
        listItem.firstElementChild.classList.remove(CLASSNAME_HIDDEN);
        listItem.classList.toggle(CLASSNAME_HIDDEN, !shouldShowIconText);
        if (toggleComponent) {
            // if there is at least one visible iconText in the list we must show the whole component
            this.updateVisibility(toggleComponentWithPrecedingHeadline);
        }
    };
    /**
     * Updates the visibility of the whole component, depending on if there is at least one visible item.
     * This is useful to use, if the iconTextList is hidden initially
     * and you want to show it only if at least one item is visible.
     * @param toggleComponentWithPrecedingHeadline If this option is true,
     * and the IconTextList component is hidden, the IconTextLists preceding
     * headline will also be hidden.
     */
    IconTextList.prototype.updateVisibility = function (toggleComponentWithPrecedingHeadline) {
        if (toggleComponentWithPrecedingHeadline === void 0) { toggleComponentWithPrecedingHeadline = true; }
        var shouldShowIconTextList = this.isSomeItemVisible();
        this.toggleContainer(!shouldShowIconTextList, toggleComponentWithPrecedingHeadline);
    };
    /**
     * Same as `toggleItem`, but toggles multiple items at once
     * using one array for included indexes and one array for excluded indexes.
     * @param includeIndexes Array of indexes to toggle.
     * If an empty array is passed, or the param is omitted, all items will be toggled.
     * @param excludeIndexes Array of indexes to ignore.
     * If an empty array is passed, or the param is omitted, no item will be excluded.
     * @param shouldShow Optional boolean to show or hide the listItem
     * at the given index. Undefined will toggle the items' visiblity.
     * @param toggleComponent If this option is true (default)
     * and the last remaining listItem was hidden, the whole IconTextList
     * component will also be hidden.
     * @param toggleComponentWithPrecedingHeadline If this option is true,
     * and the IconTextList component is hidden, the IconTextLists preceding
     * headline will also be hidden.
     */
    IconTextList.prototype.toggleItems = function (includeIndexes, excludeIndexes, shouldShow, toggleComponent, toggleComponentWithPrecedingHeadline) {
        var _this = this;
        if (includeIndexes === void 0) { includeIndexes = []; }
        if (excludeIndexes === void 0) { excludeIndexes = []; }
        if (toggleComponent === void 0) { toggleComponent = true; }
        if (toggleComponentWithPrecedingHeadline === void 0) { toggleComponentWithPrecedingHeadline = true; }
        if (!includeIndexes.length) {
            // default to all listItems
            includeIndexes = this.listItems.map(function (listItem, index) { return index; });
        }
        // remove exluded indexes from included indexes
        includeIndexes = includeIndexes.filter(function (index) { return !excludeIndexes.includes(index); });
        // first validate each index
        // to avoid partial toggling
        // e. g. if only one index is invalid
        includeIndexes.forEach(function (index) {
            _this.assertItemExists(index);
        });
        // then toggle the items
        includeIndexes.forEach(function (index) {
            _this.toggleItem(index, shouldShow, toggleComponent, toggleComponentWithPrecedingHeadline);
        });
    };
    /**
     * Checks if the item with the given index is visible
     * @param index The index to check
     * @returns True if element is visible, otherwise false
     */
    IconTextList.prototype.isItemVisible = function (index) {
        this.assertItemExists(index);
        var listItem = this.listItems[index];
        return !listItem.classList.contains(CLASSNAME_HIDDEN);
    };
    /**
     * Returns an array of item visibilities
     */
    IconTextList.prototype.getItemVisibilities = function () {
        var _this = this;
        return this.listItems.map(function (listItem, index) { return _this.isItemVisible(index); });
    };
    /**
     * Checks if some item is visible
     * @returns Boolean
     */
    IconTextList.prototype.isSomeItemVisible = function () {
        return this.getItemVisibilities().some(function (visibility) { return visibility; });
    };
    /**
     * Executes the given callback function for each listItem
     * and returns the first index of the listItem for which
     * the callback function returned `true`.
     * If no listItem was found, `-1` is returned.
     * @param callback The callback function. It receives
     * to params: the properties object and the HTMLLIElement.
     */
    IconTextList.prototype.findItemIndex = function (callback) {
        for (var i = 0, iLen = this.listItems.length; i < iLen; i++) {
            if (callback(this.properties.containerItems[i], this.listItems[i])) {
                return i;
            }
        }
        return -1;
    };
    /**
     * Updates the headline icon text component
     * Please note: this only works if there is an exisiting iconText component.
     * You cannot add a new headline with this method!
     * @param props The props for update
     */
    IconTextList.prototype.updateHeadline = function (props) {
        if (!this.headlineIconText) {
            throw new Error('Update not possible without initially existing component');
        }
        this.headlineIconText.update(props);
        // sync back updated properties of the iconText to the iconTextList
        this.properties.containerHeadline = this.headlineIconText.getProperties();
    };
    /**
     * Updates an iconText component of a listItem
     * @param index The index of the item to update
     * @param props The props for update
     */
    IconTextList.prototype.updateItem = function (index, props) {
        this.assertItemExists(index);
        this.listItemIconTexts[index].update(props);
        // sync back updated properties of the iconText to the iconTextList
        this.properties.containerItems[index] = __assign(__assign({}, this.listItemIconTexts[index].getProperties()), { optHidden: this.properties.containerItems[index].optHidden });
    };
    return IconTextList;
}(Pattern));
export { IconTextList };
/**
 * Icon text factory
 */
export var createIconTextList = function (containerElement, businessLogicOrProperties) {
    var iconTextList = new IconTextList(containerElement, businessLogicOrProperties);
    iconTextList.init();
    return iconTextList;
};
