/**
 * Base class name
 */
export var ICON_TEXT_BASE_CLASSNAME = 'ws10-icon-text';
/**
 * Container for icon component
 */
export var ICON_TEXT_ICON_CONTAINER_CLASSNAME = "".concat(ICON_TEXT_BASE_CLASSNAME, "__icon-container");
/**
 * Icon as SVG class name
 */
export var ICON_TEXT_ICON_CLASSNAME = "".concat(ICON_TEXT_BASE_CLASSNAME, "__icon");
/**
 * Icon as IMG class name
 */
export var ICON_TEXT_IMG_CLASSNAME = "".concat(ICON_TEXT_BASE_CLASSNAME, "__image");
/**
 * Text class name
 */
export var ICON_TEXT_TEXT_CLASSNAME = "".concat(ICON_TEXT_BASE_CLASSNAME, "__text");
/**
 * Inverse modifier
 */
export var ICON_TEXT_INVERSE_CLASSNAME = "".concat(ICON_TEXT_BASE_CLASSNAME, "--inverse");
/**
 * Alignment left
 */
export var ICON_TEXT_ALIGNMENT_LEFT = 'left';
/**
 * Alignment center
 */
export var ICON_TEXT_ALIGNMENT_CENTER = 'center';
/**
 * Alignment left
 */
export var ICON_TEXT_ALIGNMENTS = [ICON_TEXT_ALIGNMENT_LEFT, ICON_TEXT_ALIGNMENT_CENTER];
