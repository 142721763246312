var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Executes the given callback function on the window's `load` event
 * or immediately, if the document's readyState is already `complete`.
 * @param callback The callback function to be executed
 * @param args Optional arguments to be passed to the callback function when called
 */
export var onLoad = function (callback) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var executeCallback = function () {
        callback.apply(void 0, __spreadArray([], __read(args), false));
    };
    if (document.readyState === 'complete') {
        // if window is loaded already, execute the callback immediately
        executeCallback();
    }
    else {
        // if window is not loaded already, wait for the load event
        window.addEventListener('load', function () {
            executeCallback();
        });
    }
};
/**
 * Executes the given callback function on the document's `DOMContentLoaded` event
 * or immediately, if the document's readyState is already `interactive`.
 * @param callback The callback function to be executed
 * @param args Optional arguments to be passed to the callback function when called
 */
export var onDOMContentLoaded = function (callback) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var executeCallback = function () {
        callback.apply(void 0, __spreadArray([], __read(args), false));
    };
    if (document.readyState === 'interactive') {
        // if document is interactive already, execute the callback immediately
        executeCallback();
    }
    else {
        // if document is not interactive yet, wait for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', function () {
            executeCallback();
        });
    }
};
