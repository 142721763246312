/**
 * Any constants you need belong in this file. These are normally used for selectors.
 * The advantage of this is that you can use these same constant in your validator, tests
 * and main ts file.
 */
/**
 * Base class name
 */
export var PROMOTIONAL_CARD_BASE_CLASSNAME = 'ws10-promotional-card';
/**
 * Card class name
 */
export var PROMOTIONAL_CARD_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__card");
/**
 * Label class name
 */
export var PROMOTIONAL_CARD_LABEL_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__label");
/**
 * Sublabel class name
 */
export var PROMOTIONAL_CARD_SUBLABEL_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__sublabel");
/**
 * Icon class name
 */
export var PROMOTIONAL_CARD_ICON_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__icon");
/**
 * Flag-badge class name
 */
export var PROMOTIONAL_CARD_FLAG_BADGE_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__flag-badge");
/**
 * Confirmation-text class name
 */
export var PROMOTIONAL_CARD_CONFIRMATION_TEXT_CLASSNAME = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__confirmation-text");
/**
 * Confirmation-text class name when it is shown
 */
export var PROMOTIONAL_CARD_CONFIRMATION_TEXT_SHOW_CLASSNAME = "".concat(PROMOTIONAL_CARD_CONFIRMATION_TEXT_CLASSNAME, "--show");
/**
 * Confirmation-text class name when it is shown
 */
export var PROMOTIONAL_CARD_FORM_SELECTION_CONTROL_CONTAINER = "".concat(PROMOTIONAL_CARD_BASE_CLASSNAME, "__form-selection-control");
/** Enum to define the variant */
export var PromotionalCardVariant;
(function (PromotionalCardVariant) {
    PromotionalCardVariant["Standalone"] = "standalone";
    PromotionalCardVariant["Nested"] = "nested";
    PromotionalCardVariant["Transparent"] = "transparent";
})(PromotionalCardVariant || (PromotionalCardVariant = {}));
/**
 * Array of all variants
 */
export var PROMOTIONAL_CARD_VARIANTS = [
    PromotionalCardVariant.Standalone,
    PromotionalCardVariant.Nested,
    PromotionalCardVariant.Transparent,
];
/** Default opt tag */
export var PROMOTIONAL_CARD_DEFAULT_TAG = 'h5';
