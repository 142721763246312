/**
 * Parsing className for to given variation map
 */
export var getPropertiesFromClassName = function (className, variationMap) {
    var variations = {};
    var variationList = className.split('--');
    for (var i = 0; i < variationList.length; i++) {
        variationList[i] = variationList[i].split(' ')[0].replace(/\n/g, '');
    }
    for (var key in variationMap) {
        var possibleVariations = variationMap[key];
        var matches = possibleVariations.filter(function (value) { return variationList.includes(value); });
        variations[key] = matches[0];
    }
    return variations;
};
