/**
 * Unordered Horizontal List Base Class Name
 */
export var UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME = 'ws10-unordered-horizontal-list';
/**
 * Unordered Horizontal List Headline Class Name
 */
export var UNORDERED_HORIZONTAL_LIST_HEADLINE_CLASSNAME = "".concat(UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME, "__headline");
/**
 * Unordered Horizontal List Item Class Name
 */
export var UNORDERED_HORIZONTAL_LIST_ITEM = "".concat(UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME, "__item");
/**
 * Unordered Horizontal List Inverse Variation Class Name
 */
export var UNORDERED_HORIZONTAL_LIST_INVERSE_CLASSNAME = "".concat(UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME, "--inverse");
/**
 * Unordered Horizontal List Break After Headline Class Name
 */
export var UNORDERED_HORIZONTAL_LIST_BREAK_AFTER_HEADLINE_CLASSNAME = "".concat(UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME, "--break-after-headline");
