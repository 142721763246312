var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, Price, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { BUTTON_BASE_CLASSNAME, createButton, } from '../button';
import './text-header.scss';
import renderTemplate from './_text-header.hbs';
import { TEXT_HEADER_CLASSNAME_BUTTON_CONTAINER, TEXT_HEADER_CLASSNAME_HEADLINE, TEXT_HEADER_CLASSNAME_PRICE_CENT, TEXT_HEADER_CLASSNAME_PRICE_EURO, TEXT_HEADER_CLASSNAME_PRICE_LABEL, TEXT_HEADER_CLASSNAME_PRICE_TABLE, TEXT_HEADER_CLASSNAME_SUBLINE, RED_GREDIANT_BACKGROUND, DARK_RED_BACKGROUND, LIGHT_GRAY_BACKGROUND, WHITE_BACKGROUND, TEXT_HEADER_BASE_CLASSNAME, } from './Constants';
import { BUTTON_COLOR_PRIMARY, BUTTON_COLOR_SECONDARY, } from '@vfde-brix/ws10/button';
/**
 * ws10 Text Header class
 * This component is a 'Block' component.
 */
var TextHeader = /** @class */ (function (_super) {
    __extends(TextHeader, _super);
    function TextHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    TextHeader.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    TextHeader.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.changeButtonVariation();
    };
    /**
     * change the button color depending on the background color of the text-header
     */
    TextHeader.prototype.changeButtonVariation = function () {
        var _a;
        var darkRedBackground = this.containerElement.getElementsByClassName(TEXT_HEADER_BASE_CLASSNAME)[0].classList.contains(DARK_RED_BACKGROUND);
        var redGradiantBackground = this.containerElement.getElementsByClassName(TEXT_HEADER_BASE_CLASSNAME)[0].classList.contains(RED_GREDIANT_BACKGROUND);
        var whiteBackground = this.containerElement.getElementsByClassName(TEXT_HEADER_BASE_CLASSNAME)[0].classList.contains(WHITE_BACKGROUND);
        var lightGreyBackground = this.containerElement.getElementsByClassName(TEXT_HEADER_BASE_CLASSNAME)[0].classList.contains(LIGHT_GRAY_BACKGROUND);
        var buttonProperty = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.button;
        if ((darkRedBackground || redGradiantBackground) && this.properties.button) {
            var newProperties = __assign(__assign({}, this.properties), { button: __assign(__assign({}, buttonProperty), { optColor: BUTTON_COLOR_SECONDARY, optInverse: true }) });
            this.update(newProperties);
        }
        else if ((whiteBackground || lightGreyBackground) && this.properties.button) {
            var newProperties = __assign(__assign({}, this.properties), { button: __assign(__assign({}, buttonProperty), { optColor: BUTTON_COLOR_PRIMARY }) });
            this.update(newProperties);
        }
    };
    /* istanbul ignore next
      Abstract pattern class enforces an implementation initEvents even when no events are needed
      For now: Ignore the empty method in coverage BRIX-87
      */
    TextHeader.prototype.initEvents = function (isInitial) {
        var _this = this;
        if (isInitial) {
            // Add event to the container and catch the element in the handler otherwise events are gone after an update
            // business logic is living on the button cause text header itself cant have business logic
            if (this.properties.button && this.properties.business.onClick) {
                this.containerElement.addEventListener('click', function (e) {
                    if (e.target.closest(".".concat(BUTTON_BASE_CLASSNAME))) {
                        _this.properties.business.onClick(e);
                    }
                });
            }
        }
    };
    /**
     * getting properties from DOM
     */
    TextHeader.prototype.readDom = function (textHeaderBusinessLogic) {
        var _a, _b;
        var stdHeadline = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_HEADLINE)[0].innerHTML.trim();
        var stdSubline = (_a = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_SUBLINE)[0]) === null || _a === void 0 ? void 0 : _a.innerHTML.trim();
        var buttonContainer = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_BUTTON_CONTAINER)[0];
        var optBackgroundColor = (_b = this.containerElement
            .getElementsByClassName(TEXT_HEADER_BASE_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.getAttribute('data-text-header-background');
        var priceRowContainer = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_PRICE_TABLE)[0];
        // eslint-disable-next-line @typescript-eslint/ban-types
        var stdPrice = [];
        var optAriaLive;
        // if we have a price get each price
        if (priceRowContainer) {
            var euroContainer = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_PRICE_EURO);
            for (var i = 0; i < euroContainer.length; i++) {
                var euro = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_PRICE_EURO)[i].innerHTML.trim();
                var cent = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_PRICE_CENT)[i].innerHTML.trim();
                var label = this.containerElement.getElementsByClassName(TEXT_HEADER_CLASSNAME_PRICE_LABEL)[i].innerHTML.trim();
                stdPrice.push({
                    price: new Price(euro, cent),
                    label: label,
                });
            }
            if (priceRowContainer.hasAttribute('aria-live')) {
                optAriaLive = priceRowContainer.getAttribute('aria-live');
            }
        }
        // if we dont have a price set array to undefined otherwise its not removed in the return
        if (stdPrice.length === 0) {
            stdPrice = undefined;
        }
        var mountedButton = buttonContainer && createButton(buttonContainer, NO_PATTERN_BUSINESS_LOGIC);
        var button = mountedButton && mountedButton.getProperties();
        return removeUndefinedFieldsFromObject({
            stdHeadline: stdHeadline,
            stdSubline: stdSubline,
            optBackgroundColor: optBackgroundColor,
            stdPrice: stdPrice,
            button: button,
            optAriaLive: optAriaLive,
            business: textHeaderBusinessLogic,
        });
    };
    return TextHeader;
}(Pattern));
export { TextHeader };
/**
 * This function returns an instance of interaction list
 */
export var createTextHeader = function (containerElement, businessLogicOrProperties) {
    var textHeader = new TextHeader(containerElement, businessLogicOrProperties);
    textHeader.init();
    return textHeader;
};
