var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <li class=\"ws10-column ws10-column--4 ws10-column--large-2\">\n"
    + ((stack1 = container.invokePartial(require("./_icon-strip-item.hbs"),depth0,{"name":"_icon-strip-item","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-icon-strip\">\n    <div class=\"ws10-icon-strip__container\">\n        <div class=\"ws10-grid\">\n            <ul class=\"ws10-icon-strip__items-container ws10-grid__inner\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":9,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});