/**
 * ClassName for base Text Header element
 */
export var TEXT_HEADER_BASE_CLASSNAME = 'ws10-text-header';
/**
 * ClassName for Headline
 */
export var TEXT_HEADER_CLASSNAME_HEADLINE = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__headline");
/**
 * ClassName for Subline
 */
export var TEXT_HEADER_CLASSNAME_SUBLINE = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__subline");
/**
 * ClassName for price label
 */
export var TEXT_HEADER_CLASSNAME_PRICE_LABEL = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__price-label");
/**
 * ClassName for Button
 */
export var TEXT_HEADER_CLASSNAME_BUTTON_CONTAINER = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__button");
/**
 * ClassName for Euro Value
 */
export var TEXT_HEADER_CLASSNAME_PRICE_EURO = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__price-euro");
/**
 * ClassName for Cent Value
 */
export var TEXT_HEADER_CLASSNAME_PRICE_CENT = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__price-cent");
/**
 * ClassName for Price Table
 */
export var TEXT_HEADER_CLASSNAME_PRICE_TABLE = "".concat(TEXT_HEADER_BASE_CLASSNAME, "__price-table");
/**
 * DARKRED BACKGROUND
 */
export var DARK_RED_BACKGROUND = "".concat(TEXT_HEADER_BASE_CLASSNAME, "--dark-red");
/**
 * RED GREDIANT BACKGROUND
 */
export var RED_GREDIANT_BACKGROUND = "".concat(TEXT_HEADER_BASE_CLASSNAME, "--red-gradiant");
/**
 * WHITE BACKGROUND
 */
export var WHITE_BACKGROUND = "".concat(TEXT_HEADER_BASE_CLASSNAME, "--white");
/**
 * LIGHTGRAY BACKGROUND
 */
export var LIGHT_GRAY_BACKGROUND = "".concat(TEXT_HEADER_BASE_CLASSNAME, "--light-grey");
