var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './icon.scss';
import renderTemplate from './_icon.hbs';
import { getPropertiesFromClassName, HandlebarRenderer, Pattern, } from '@vfde-brix/ws10/core';
import { MID_RENDER_ICON_CLASS, SYSTEM_ICON_CLASS, } from '@vfde-brix/ws10/icon/Constants';
import { getSystemIconColor, getSystemIconName, } from '@vfde-brix/ws10/system-icon';
/**
 * Icon Component for all button types
 */
var Icon = /** @class */ (function (_super) {
    __extends(Icon, _super);
    function Icon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Icon.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optSystemIconColor === undefined) {
            newProperties.optSystemIconColor = 'color-primary-100';
        }
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    Icon.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * get properties from dom
     */
    Icon.prototype.readDom = function (business) {
        var systemIcon = this.containerElement.getElementsByClassName(SYSTEM_ICON_CLASS);
        var midRenderIcon = this.containerElement.getElementsByClassName(MID_RENDER_ICON_CLASS);
        if (systemIcon.length !== 0) {
            var stdSystemIconName = getSystemIconName(this.containerElement);
            var optSystemIconColor = getSystemIconColor(this.containerElement);
            var propertiesFromClassName = getPropertiesFromClassName(systemIcon[0].className, {
                optSize: ['small', 'medium', 'large'],
            });
            var optSize = propertiesFromClassName.optSize;
            var properties = {
                stdSystemIconName: stdSystemIconName,
                optSystemIconColor: optSystemIconColor,
                optSize: optSize,
                business: business,
            };
            return properties;
        }
        /* istanbul ignore else */
        if (midRenderIcon.length !== 0) {
            var imgMidRenderIconPath = midRenderIcon[0].getElementsByTagName('img')[0].getAttribute('src');
            var propertiesFromClassName = getPropertiesFromClassName(midRenderIcon[0].className, {
                optSize: ['small', 'medium', 'large'],
            });
            var optSize = propertiesFromClassName.optSize;
            var properties = {
                imgMidRenderIconPath: imgMidRenderIconPath,
                optSize: optSize,
                business: business,
            };
            return properties;
        }
        // if stdSystemIconName and imgMidRenderIconPath, we should remove imgMidRenderIconPath,
        // because the that case the img is hidden by scss
    };
    return Icon;
}(Pattern));
export { Icon };
/**
 * This function returns an instance of Button
 */
export var createIcon = function (containerElement, businessLogicOrProperties) {
    var icon = new Icon(containerElement, businessLogicOrProperties);
    icon.init();
    return icon;
};
