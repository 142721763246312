var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-filter-pill--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAlignment") || (depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAlignment","hash":{},"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":83}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./_filter-pill-item.hbs"),depth0,{"name":"_filter-pill-item","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-filter-pill"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":90}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"listFilterPillItems") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":13}}})) != null ? stack1 : "")
    + "    <button class=\"ws10-filter-pill__toggle ws10-filter-pill__toggle--more\" type=\"button\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdMoreLabel") || (depth0 != null ? lookupProperty(depth0,"stdMoreLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdMoreLabel","hash":{},"data":data,"loc":{"start":{"line":5,"column":90},"end":{"line":5,"column":106}}}) : helper)))
    + "</button>\n    <button class=\"ws10-filter-pill__toggle ws10-filter-pill__toggle--less\" type=\"button\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLessLabel") || (depth0 != null ? lookupProperty(depth0,"stdLessLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLessLabel","hash":{},"data":data,"loc":{"start":{"line":6,"column":90},"end":{"line":6,"column":106}}}) : helper)))
    + "</button>\n</div>\n";
},"usePartial":true,"useData":true});