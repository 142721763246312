var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './feature-card.scss';
import renderTemplate from './_feature-card.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { createPromoPrice, PromoPrice, } from '@vfde-brix/ws10/promo-price';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { createHighlightBadge } from '@vfde-brix/ws10/highlight-badge';
import { HEADLINE_BASE_CLASSNAME as HEADLINE_CLASSNAME } from '@vfde-brix/ws10/headline';
import { FEATURE_CARD_BASE_CLASSNAME, FEATURE_CARD_BUTTON_CLASSNAME, FEATURE_CARD_CHECKED_CLASSNAME, FEATURE_CARD_DISABLED_CLASSNAME, FEATURE_CARD_UNSELECTABLE_CLASSNAME, FEATURE_CARD_DEFAULT_CTA_TEXT, FEATURE_CARD_DEFAULT_CTA_TEXT_DISABLED, FEATURE_CARD_DEFAULT_CTA_TEXT_SELECTED, FEATURE_CARD_DEFAULT_CTA_TEXT_UNSELECTABLE, FEATURE_CARD_HIGHLIGHT_BADGE_CLASSNAME, FEATURE_CARD_IMAGE_CLASSNAME, FEATURE_CARD_LINK_CLASSNAME, FEATURE_CARD_LIST_CLASSNAME, FEATURE_CARD_PROMO_PRICE_CLASSNAME, FEATURE_CARD_HEADLINE_CLASSNAME, buttonProperties, } from './Constants';
/**
 * FeatureCard Component
 */
var FeatureCard = /** @class */ (function (_super) {
    __extends(FeatureCard, _super);
    function FeatureCard() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /**
         * Handle click via event delegation
         */
        _this.handleOnClick = function (event) {
            var target = event.target;
            _this.handleButtonClick(target, event);
            _this.handleLinkClick(target, event);
        };
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FeatureCard.prototype.getDefaultProperties = function (newProperties) {
        newProperties.featureCardItems.forEach(function (featureCardItem) {
            if (featureCardItem.promoPrice) {
                featureCardItem.promoPrice = PromoPrice.setDefaultProps(featureCardItem.promoPrice);
            }
        });
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    FeatureCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initPromoPrices(this.properties);
        this.initButtons(this.properties);
        this.initHighlightBadges();
    };
    /**
     * Init the events
     */
    FeatureCard.prototype.initEvents = function () {
        var _a = __read(this.containerElement.getElementsByClassName(FEATURE_CARD_LIST_CLASSNAME), 1), featureCardList = _a[0];
        featureCardList.addEventListener('click', this.handleOnClick);
    };
    FeatureCard.prototype.handleLinkClick = function (target, event) {
        var moreContainer = target.closest(".".concat(FEATURE_CARD_LINK_CLASSNAME));
        if (!moreContainer) {
            return;
        }
        var onLinkClick = this.properties.business.onLinkClick;
        var featureCardElement = moreContainer.closest(".".concat(FEATURE_CARD_BASE_CLASSNAME));
        var id = featureCardElement.dataset.id;
        var moreLink = moreContainer.firstElementChild;
        onLinkClick && onLinkClick(event, id, moreLink.getAttribute('href'), moreLink.dataset.trackingName);
    };
    FeatureCard.prototype.handleButtonClick = function (target, event) {
        var featureCardButton = target.closest(".".concat(BUTTON_BASE_CLASSNAME));
        if (!featureCardButton) {
            // ignore clicks on everything but the button
            return;
        }
        var featureCardElement = featureCardButton.closest(".".concat(FEATURE_CARD_BASE_CLASSNAME));
        var statusClass = this.getStatusClass(featureCardElement);
        if ([FEATURE_CARD_UNSELECTABLE_CLASSNAME, FEATURE_CARD_DISABLED_CLASSNAME].indexOf(statusClass) !== -1) {
            // ignore clicks on unselectable or disabled feature-cards
            return;
        }
        var wasChecked = featureCardElement.classList.contains(FEATURE_CARD_CHECKED_CLASSNAME);
        var isChecked = !wasChecked;
        var itemProps = this.getItemPropertiesFromFeatureCardElement(featureCardElement);
        itemProps.optChecked = isChecked;
        featureCardElement.classList.toggle(FEATURE_CARD_CHECKED_CLASSNAME);
        var _a = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_BUTTON_CLASSNAME), 1), button = _a[0];
        var buttonComponent = createButton(button, NO_PATTERN_BUSINESS_LOGIC);
        var newButtonText = this.getButtonTextFromDom(featureCardElement, this.properties);
        var newButtonProps = {
            optAriaChecked: isChecked,
            stdContent: newButtonText,
        };
        buttonComponent.update(newButtonProps, true);
        var onButtonClick = this.properties.business.onButtonClick;
        if (onButtonClick) {
            // call onClick business callback with ID of the feature-card
            var id = featureCardElement.dataset.id;
            onButtonClick(event, id, isChecked);
        }
    };
    /**
     * Get properties from DOM
     */
    FeatureCard.prototype.readDom = function (business) {
        var e_1, _a;
        var _b = __read(this.containerElement.getElementsByClassName(FEATURE_CARD_LIST_CLASSNAME), 1), featureCardList = _b[0];
        var properties = {
            featureCardItems: [],
            business: business,
        };
        var stdCtaText = featureCardList.getAttribute('data-cta-text');
        var stdCtaSelectedText = featureCardList.getAttribute('data-cta-selected-text');
        var stdCtaUnselectableText = featureCardList.getAttribute('data-cta-unselectable-text');
        var stdCtaDisabledText = featureCardList.getAttribute('data-cta-disabled-text');
        if (stdCtaText) {
            properties.stdCtaText = stdCtaText;
        }
        if (stdCtaSelectedText) {
            properties.stdCtaSelectedText = stdCtaSelectedText;
        }
        if (stdCtaUnselectableText) {
            properties.stdCtaUnselectableText = stdCtaUnselectableText;
        }
        if (stdCtaDisabledText) {
            properties.stdCtaDisabledText = stdCtaDisabledText;
        }
        var featureCardElements = this.getFeatureCardElements();
        try {
            for (var featureCardElements_1 = __values(featureCardElements), featureCardElements_1_1 = featureCardElements_1.next(); !featureCardElements_1_1.done; featureCardElements_1_1 = featureCardElements_1.next()) {
                var featureCardElement = featureCardElements_1_1.value;
                var img = featureCardElement.getElementsByClassName(FEATURE_CARD_IMAGE_CLASSNAME)[0];
                var _c = __read(featureCardElement.getElementsByClassName(HEADLINE_CLASSNAME), 1), headline = _c[0];
                var _d = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_LINK_CLASSNAME), 1), textLinkContainer = _d[0];
                var textLink = textLinkContainer === null || textLinkContainer === void 0 ? void 0 : textLinkContainer.firstElementChild;
                var _e = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_PROMO_PRICE_CLASSNAME), 1), promoPriceContainer = _e[0];
                var _f = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_HIGHLIGHT_BADGE_CLASSNAME), 1), highlightBadgeContainer = _f[0];
                var _g = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_BUTTON_CLASSNAME), 1), buttonContainer = _g[0];
                var statusClass = this.getStatusClass(featureCardElement);
                var itemProps = removeUndefinedFieldsFromObject({
                    stdId: featureCardElement.dataset.id,
                    imgSrc: img === null || img === void 0 ? void 0 : img.src,
                    stdImageAlt: img === null || img === void 0 ? void 0 : img.alt,
                    stdHeadline: headline.innerHTML.trim(),
                    stdLinkText: textLink === null || textLink === void 0 ? void 0 : textLink.innerHTML.trim(),
                    optChecked: statusClass === FEATURE_CARD_CHECKED_CLASSNAME,
                    optDisabled: statusClass === FEATURE_CARD_DISABLED_CLASSNAME,
                    optIncluded: statusClass === FEATURE_CARD_UNSELECTABLE_CLASSNAME,
                    promoPrice: promoPriceContainer && createPromoPrice(promoPriceContainer, NO_PATTERN_BUSINESS_LOGIC).getProperties(),
                    highlightBadge: highlightBadgeContainer && createHighlightBadge(highlightBadgeContainer, NO_PATTERN_BUSINESS_LOGIC).getProperties(),
                    button: buttonContainer && createButton(buttonContainer, NO_PATTERN_BUSINESS_LOGIC).getProperties(),
                    stdTrackingName: textLink === null || textLink === void 0 ? void 0 : textLink.dataset.trackingName,
                    linkHref: textLink === null || textLink === void 0 ? void 0 : textLink.href,
                });
                properties.featureCardItems.push(itemProps);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (featureCardElements_1_1 && !featureCardElements_1_1.done && (_a = featureCardElements_1.return)) _a.call(featureCardElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.initPromoPrices(properties);
        this.initButtons(properties);
        this.initHighlightBadges();
        return properties;
    };
    /**
     * Init promo-price child components
     * @param properties The feature-card properties
     */
    FeatureCard.prototype.initPromoPrices = function (properties) {
        var featureCardElements = this.getFeatureCardElements();
        for (var i = 0, iLen = properties.featureCardItems.length; i < iLen; i++) {
            var featureCardElement = featureCardElements[i];
            var featureCardItem = properties.featureCardItems[i];
            var _a = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_PROMO_PRICE_CLASSNAME), 1), promoPriceContainer = _a[0];
            promoPriceContainer && createPromoPrice(promoPriceContainer, featureCardItem.promoPrice);
        }
    };
    /**
     * Init highlight-badge child components
     */
    FeatureCard.prototype.initHighlightBadges = function () {
        var featureCardElements = this.getFeatureCardElements();
        featureCardElements.forEach(function (item) {
            var _a = __read(item.getElementsByClassName(FEATURE_CARD_HIGHLIGHT_BADGE_CLASSNAME), 1), highlightBadgeContainer = _a[0];
            highlightBadgeContainer && createHighlightBadge(highlightBadgeContainer, NO_PATTERN_BUSINESS_LOGIC);
        });
    };
    /**
     * Init button child components
     * @param properties The feature-card properties
     */
    FeatureCard.prototype.initButtons = function (properties) {
        var featureCardElements = this.getFeatureCardElements();
        for (var i = 0, iLen = properties.featureCardItems.length; i < iLen; i++) {
            var featureCardElement = featureCardElements[i];
            var _a = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_HEADLINE_CLASSNAME), 1), headline = _a[0];
            var _b = __read(featureCardElement.getElementsByClassName(FEATURE_CARD_BUTTON_CLASSNAME), 1), buttonContainer = _b[0];
            var buttonText = this.getButtonTextFromDom(featureCardElements[i], properties);
            var optDisabled = featureCardElement.classList.contains(FEATURE_CARD_DISABLED_CLASSNAME)
                || buttonText === FEATURE_CARD_DEFAULT_CTA_TEXT_DISABLED;
            var buttonProps = Object.assign({
                stdContent: buttonText,
                stdAriaLabelledBy: headline.id,
                optDisabled: optDisabled,
                optAriaChecked: featureCardElement.classList.contains(FEATURE_CARD_CHECKED_CLASSNAME),
                business: NO_PATTERN_BUSINESS_LOGIC,
            }, buttonProperties);
            createButton(buttonContainer, buttonProps);
        }
    };
    /**
     * Get the correct button text depending on the status of the given feature-card
     * (disabled, unselectable, checked or default).
     * @param featureCardElement The feature-card element
     */
    FeatureCard.prototype.getButtonTextFromDom = function (featureCardElement, properties) {
        var statusClass = this.getStatusClass(featureCardElement);
        switch (statusClass) {
            case FEATURE_CARD_DISABLED_CLASSNAME:
                return properties.stdCtaDisabledText || FEATURE_CARD_DEFAULT_CTA_TEXT_DISABLED;
            case FEATURE_CARD_UNSELECTABLE_CLASSNAME:
                return properties.stdCtaUnselectableText || FEATURE_CARD_DEFAULT_CTA_TEXT_UNSELECTABLE;
            case FEATURE_CARD_CHECKED_CLASSNAME:
                return properties.stdCtaSelectedText || FEATURE_CARD_DEFAULT_CTA_TEXT_SELECTED;
            default:
                return properties.stdCtaText || FEATURE_CARD_DEFAULT_CTA_TEXT;
        }
    };
    /**
     * Returns the modifier class with the highest priority for the given feature-card element.
     * @param featureCardElement The feature card element
     * @returns The class name
     */
    FeatureCard.prototype.getStatusClass = function (featureCardElement) {
        if (featureCardElement.classList.contains(FEATURE_CARD_CHECKED_CLASSNAME)) {
            return FEATURE_CARD_CHECKED_CLASSNAME;
        }
        if (featureCardElement.classList.contains(FEATURE_CARD_UNSELECTABLE_CLASSNAME)) {
            return FEATURE_CARD_UNSELECTABLE_CLASSNAME;
        }
        if (featureCardElement.classList.contains(FEATURE_CARD_DISABLED_CLASSNAME)) {
            return FEATURE_CARD_DISABLED_CLASSNAME;
        }
        return '';
    };
    /**
     * Returns an array of all feature-card elements
     * @returns Array of feature-card elements
     */
    FeatureCard.prototype.getFeatureCardElements = function () {
        return __spreadArray([], __read(this.containerElement.getElementsByClassName(FEATURE_CARD_BASE_CLASSNAME)), false);
    };
    /**
     * Finds the index for the given feature-card element among all other feature-cards
     * and returns the FeatureCardItemProperties for this element.
     * @param featureCardElement The feature-card element to retreive the properties from
     * @returns The FeatureCardItemProperties object
     */
    FeatureCard.prototype.getItemPropertiesFromFeatureCardElement = function (featureCardElement) {
        var featureCardElements = this.getFeatureCardElements();
        var index = featureCardElements.indexOf(featureCardElement);
        return this.properties.featureCardItems[index];
    };
    return FeatureCard;
}(Pattern));
export { FeatureCard };
/**
 * Returns a new instance of the FeatureCard block
 */
export var createFeatureCard = function (containerElement, businessLogicOrProperties) {
    var featureCard = new FeatureCard(containerElement, businessLogicOrProperties);
    featureCard.init();
    return featureCard;
};
