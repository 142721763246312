/**
 * Base class name
 */
export var INTERACTION_LIST_BASE_CLASSNAME = 'ws10-interaction-list';
export var INTERACTION_LIST_FLAT_CLASSNAME = "".concat(INTERACTION_LIST_BASE_CLASSNAME, "--flat");
export var INTERACTION_LIST_CARD_CLASSNAME = "".concat(INTERACTION_LIST_BASE_CLASSNAME, "--card");
/**
 * Interaction list link class name
 */
export var INTERACTION_LIST_ITEM_CLASSNAME = "".concat(INTERACTION_LIST_BASE_CLASSNAME, "-item");
/**
 * Interaction list item label
 */
export var LABEL_CLASSNAME = "".concat(INTERACTION_LIST_ITEM_CLASSNAME, "__label");
/**
 * Interaction list item label icon
 */
export var LABEL_ICON_CLASSNAME = "".concat(INTERACTION_LIST_ITEM_CLASSNAME, "__icon--label");
/**
 * Interaction list item action icon
 */
export var ACTION_ICON_CLASSNAME = "".concat(INTERACTION_LIST_ITEM_CLASSNAME, "__icon--action");
export var XLINK_HREF_ATTRIBUTE = 'xlink:href';
/**
 * Variants of a interaction list
 */
export var InteractionListVariant;
(function (InteractionListVariant) {
    InteractionListVariant["Flat"] = "flat";
    InteractionListVariant["Card"] = "card";
})(InteractionListVariant || (InteractionListVariant = {}));
/**
 * Array of possible Variants
 */
export var INTERACTION_LIST_VARIANTS = [
    InteractionListVariant.Flat,
    InteractionListVariant.Card,
];
