var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { createButton, } from '@vfde-brix/ws10/button';
import { createButtonIconOnly, } from '@vfde-brix/ws10/button-icon-only';
import { attempt, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, FULLWIDTH_NOTIFICATION_BUTTON_CLASSNAME, FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME, FULLWIDTH_NOTIFICATION_COLOR_CLASS_VARIANTS, FULLWIDTH_NOTIFICATION_FOOTER_TAGNAME, FULLWIDTH_NOTIFICATION_HEADER_TAGNAME, FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID, FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME, FULLWIDTH_NOTIFICATION_NAVBAR_WRAPPER_CLASSNAME, FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_BOTTOM, FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP, FULLWIDTH_NOTIFICATION_POSITION_CLASS_VARIANTS, FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP, FULLWIDTH_NOTIFICATION_SESSION_KEY, FULLWIDTH_NOTIFICATION_STATE_CLOSED, FULLWIDTH_NOTIFICATION_STATE_OPENED, FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME, FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, } from './Constants';
/**
 * This function is being used to return the innerHTML of the element passed as an argument after replacing all 2+ spaces, tabs and line breaks.
 */
export var getContent = function (contentElement) { return contentElement === null || contentElement === void 0 ? void 0 : contentElement.innerHTML.replace(/([\s]{2,})|\t|\r?\n|\r/gm, ''); };
/**
 * This function is being used to extract color from the element passed as an argument.
 */
export var getColor = function (contentElement) {
    var e_1, _a;
    var classes = __spreadArray([], __read(contentElement.classList), false);
    var variants = FULLWIDTH_NOTIFICATION_COLOR_CLASS_VARIANTS;
    try {
        for (var variants_1 = __values(variants), variants_1_1 = variants_1.next(); !variants_1_1.done; variants_1_1 = variants_1.next()) {
            var variant = variants_1_1.value;
            if (classes.includes(variant)) {
                return variant.replace("".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--"), '');
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (variants_1_1 && !variants_1_1.done && (_a = variants_1.return)) _a.call(variants_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return '';
};
/**
 * This function is being used to extract position from the element passed as an argument.
 */
export var getPosition = function (contentElement) {
    var e_2, _a;
    var classes = __spreadArray([], __read(contentElement.classList), false);
    var variants = FULLWIDTH_NOTIFICATION_POSITION_CLASS_VARIANTS;
    try {
        for (var variants_2 = __values(variants), variants_2_1 = variants_2.next(); !variants_2_1.done; variants_2_1 = variants_2.next()) {
            var variant = variants_2_1.value;
            if (classes.includes(variant)) {
                return variant.replace("".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--"), '');
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (variants_2_1 && !variants_2_1.done && (_a = variants_2.return)) _a.call(variants_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return '';
};
/**
 * getButtonPropertiesFromDOM
 *
 * readDom helper function
 */
export var getButtonPropertiesFromDOM = function (containerElement) {
    return attempt(function () {
        var buttonItem = containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_BUTTON_CLASSNAME));
        var button = createButton(buttonItem, NO_PATTERN_BUSINESS_LOGIC);
        return button.getProperties();
    });
};
/**
 * getButtonPropertiesFromDOM
 *
 * readDom helper function
 */
export var getButtonIconOnlyPropertiesFromDOM = function (containerElement) {
    return attempt(function () {
        var buttonIconOnlyItem = containerElement.getElementsByClassName(FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME)[0];
        var buttonIconOnly = createButtonIconOnly(buttonIconOnlyItem, NO_PATTERN_BUSINESS_LOGIC);
        return buttonIconOnly.getProperties();
    });
};
/**
 * Sets the data in the session storage to a designated boolean value.
 */
export var setSessionStorageData = function (isShown, stdFullwidthNotificationId) {
    var newValue = isShown ? FULLWIDTH_NOTIFICATION_STATE_OPENED : FULLWIDTH_NOTIFICATION_STATE_CLOSED;
    var itemString = sessionStorage.getItem(FULLWIDTH_NOTIFICATION_SESSION_KEY);
    if (stdFullwidthNotificationId) {
        var storedObject = attempt(function () { return itemString ? JSON.parse(itemString) : {}; }, {});
        storedObject[stdFullwidthNotificationId] = newValue;
        sessionStorage.setItem(FULLWIDTH_NOTIFICATION_SESSION_KEY, JSON.stringify(storedObject));
    }
};
/**
 * Gets data from session storage.
 */
export var getSessionStorageData = function (stdFullwidthNotificationId) {
    var itemString = sessionStorage.getItem(FULLWIDTH_NOTIFICATION_SESSION_KEY);
    if (!itemString) {
        setSessionStorageData(true, stdFullwidthNotificationId);
        return true;
    }
    var storedObject = attempt(function () { return JSON.parse(itemString); }, null);
    var storageItem = storedObject === null || storedObject === void 0 ? void 0 : storedObject[stdFullwidthNotificationId];
    var hasSession = !!storageItem;
    if (!hasSession) {
        setSessionStorageData(true, stdFullwidthNotificationId);
        return true;
    }
    var isOpened = storageItem === FULLWIDTH_NOTIFICATION_STATE_OPENED;
    return isOpened;
};
/* istanbul ignore next */
/**
 * This function is being used to handle the position of the navbar. This is called when the user scrolls on the page.
 */
export var navbarPositionHelper = function (navbarContainer, containerElement, mainContainer) {
    mainContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    navbarContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    if (containerElement.classList.contains(FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR)) {
        belowNavbarHelper(mainContainer, containerElement);
    }
    else if (containerElement.classList.contains(FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP)) {
        handleStickyTopPosition(navbarContainer, containerElement, mainContainer);
    }
    else if (containerElement.classList.contains(FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR)) {
        handleAboveNavbarPosition(containerElement, navbarContainer, mainContainer);
    }
    /**
     * We need to remove transition-top only if we have no sticky-bottom
     * Purpose: to avoid chunky drop-down animation of #top main page content when closing sticky-bottom
     */
    var hasStickyBottom = !!document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_BOTTOM));
    if (!hasStickyBottom) {
        mainContainer.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    }
};
/* istanbul ignore next */
/**
 * Helper function called when the FullWidth Notification is sticky bottom.
 */
export var stickyBottomHelper = function (mainContainer, containerElement) {
    mainContainer.style.marginBottom = "".concat(containerElement.scrollHeight, "px");
};
/**
 * Helper function called when the FullWidth Notification is placed above the navbar.
 */
export var aboveNavbarHelper = function (mainContainer, containerElement) {
    containerElement.style.position = 'absolute';
    containerElement.style.top = '0';
};
/* istanbul ignore next */
/**
 * Helper function called when the FullWidth Notification is placed below the navbar.
 */
export var belowNavbarHelper = function (mainContainer, containerElement) {
    containerElement.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    var navbarContainer = mainContainer.querySelector(FULLWIDTH_NOTIFICATION_HEADER_TAGNAME);
    var navbarWrapperContainer = mainContainer.querySelector(".".concat(FULLWIDTH_NOTIFICATION_NAVBAR_WRAPPER_CLASSNAME));
    navbarContainer.parentNode.insertBefore(containerElement.parentElement, navbarContainer.nextSibling);
    containerElement.style.position = 'absolute';
    var secondContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP, ", .").concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR));
    var contentContainer = containerElement.parentElement.nextElementSibling;
    containerElement.style.top = "".concat((navbarContainer.clientHeight + (secondContainer ? secondContainer.clientHeight : 0)), "px");
    mainContainer.style.marginTop = secondContainer ? "".concat(secondContainer.scrollHeight, "px") : '0';
    contentContainer.style.marginTop = "".concat(containerElement.scrollHeight, "px");
    navbarWrapperContainer.style.paddingBottom = '0';
};
/* istanbul ignore next */
/**
 * Helper function to help positioning of sticky top.
 */
var handleStickyTopPosition = function (navbarContainer, containerElement, mainContainer) {
    navbarContainer.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    if (navbarContainer.style.position === 'fixed' && !containerElement.classList.contains('closed')) {
        navbarContainer.style.top = "".concat(containerElement.scrollHeight, "px");
    }
    else {
        mainContainer.style.marginTop = "".concat(containerElement.scrollHeight, "px");
        navbarContainer.style.top = '0';
    }
    var secondContainer = document.body.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR));
    if (secondContainer) {
        navbarPositionHelper(navbarContainer, secondContainer, mainContainer);
    }
};
/* istanbul ignore next */
/**
 * Helper function to help positioning of above navbar.
 */
var handleAboveNavbarPosition = function (containerElement, navbarContainer, mainContainer) {
    navbarContainer.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
    containerElement.style.position = 'absolute';
    if (navbarContainer.style.position === 'fixed') {
        mainContainer.style.marginTop = "".concat(containerElement.scrollHeight, "px");
    }
    else {
        containerElement.style.position = 'absolute';
        containerElement.style.top = '0';
        mainContainer.style.marginTop = "".concat(containerElement.scrollHeight, "px");
    }
    var secondContainer = document.body.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR));
    if (secondContainer) {
        navbarPositionHelper(navbarContainer, secondContainer, mainContainer);
    }
};
/**
 * Helper function called when the FullWidth Notification is placed above the footer.
 */
export var footerHelper = function (mainContainer, containerElement) {
    var footerElement = mainContainer.querySelector(FULLWIDTH_NOTIFICATION_FOOTER_TAGNAME);
    footerElement.parentNode.insertBefore(containerElement.parentElement, footerElement);
};
/**
 * This function is being used to handle the scroll event.
 */
export var scrollHandler = function (position) {
    var navbarContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME));
    var containerElement = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "--").concat(position));
    var mainContainer = document.querySelector("#".concat(FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID));
    if (navbarContainer &&
        (position === FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP
            || position === FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR
            || position === FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR)) {
        navbarPositionHelper(navbarContainer, containerElement, mainContainer);
    }
};
