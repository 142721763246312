var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-label=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdAriaLabel") || (depth0 != null ? lookupProperty(depth0,"stdAriaLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdAriaLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":53}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optSize":"size-150","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-highlight-badge__icon ws10-highlight-badge__icon--left"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"ws10-highlight-badge__icon--trigger\"\n                aria-describedby=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0)) != null ? lookupProperty(stack1,"stdTooltipId") : stack1), depth0))
    + "\"\n                tabindex=\"0\"\n                focusable=\"true\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optSize":"size-150","stdName":"info-circle","stdClassName":"ws10-highlight-badge__icon"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n"
    + ((stack1 = container.invokePartial(require("../tooltip/_tooltip.hbs"),(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"_tooltip","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"ws10-highlight-badge ws10-highlight-badge--"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"optColor") : depth0), depth0))
    + " ws10-reset-cms ws10-highlight-badge--"
    + alias1(((helper = (helper = lookupProperty(helpers,"optSize") || (depth0 != null ? lookupProperty(depth0,"optSize") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"optSize","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":122}}}) : helper)))
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"stdAriaLabel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":61}}})) != null ? stack1 : "")
    + "\n>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <span class=\"ws10-highlight-badge__text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":63}}}) : helper))) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"usePartial":true,"useData":true});