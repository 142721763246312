/**
 * Base class name
 */
export var FILTER_PILLS_BASE_CLASSNAME = 'ws10-filter-pill';
/**
 * Filter pills class name
 */
export var FILTER_PILLS_OPEN_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "--open");
/**
 * class and state constant for the container being centered
 */
export var ITEM_CONTAINER_ALIGN_VALUE_CENTER = 'center';
/**
 * class and state constant for the container being right aligned
 */
export var ITEM_CONTAINER_ALIGN_VALUE_RIGHT = 'right';
/**
 * list of items that can be used as a filterpill container's alignment
 */
export var ITEM_CONTAINER_ALIGN_VALUES = [
    ITEM_CONTAINER_ALIGN_VALUE_CENTER,
    ITEM_CONTAINER_ALIGN_VALUE_RIGHT,
];
/**
 * constant for filter pill item being selected
 */
export var FILTER_PILL_ITEM_STATE_SELECTED = 'selected';
/**
 * constant for filter pill item being unselected
 */
export var FILTER_PILL_ITEM_STATE_UNSELECTED = 'unselected';
/**
 * constant for filter pill item being disabled
 */
export var FILTER_PILL_ITEM_STATE_DISABLED = 'disabled';
/**
 * Item can be selected and disabled (means, selection cannot be undone)
 */
export var FILTER_PILL_ITEM_STATE_SELECTED_DISABLED = 'selected-disabled';
/**
 * list of items that can be used as a single filterpill item's state
 */
export var ITEM_STATE_VALUES = [
    FILTER_PILL_ITEM_STATE_SELECTED,
    FILTER_PILL_ITEM_STATE_UNSELECTED,
    FILTER_PILL_ITEM_STATE_DISABLED,
    FILTER_PILL_ITEM_STATE_SELECTED_DISABLED,
];
/**
 * Classname for filter pills item
 */
export var FILTER_PILLS_ITEM_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__item");
/**
 * ClassName when item is selected
 */
export var FILTER_PILLS_ITEM_SELECTED_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "--").concat(FILTER_PILL_ITEM_STATE_SELECTED);
/**
 * ClassName when item is disabled
 */
export var FILTER_PILLS_ITEM_DISABLED_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "--").concat(FILTER_PILL_ITEM_STATE_DISABLED);
/**
 * Selected disabled classname
 */
export var FILTER_PILLS_ITEM_SELECTED_DISABLED_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "--").concat(FILTER_PILL_ITEM_STATE_SELECTED_DISABLED);
/**
 * CLASSNAME_ITEM_ICON
 */
export var FILTER_PILLS_ITEM_ICON_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "__icon");
/**
 * CLASSNAME_ITEM_ICON
 */
export var FILTER_PILLS_ITEM_TEXT_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "__text");
/**
 * ClassName for showing more options button
 */
export var FILTER_PILLS_TOGGLE_MORE_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__toggle--more");
/**
 * ClassName for showing less options button
 */
export var FILTER_PILLS_ITEM_TOGGLE_LESS_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__toggle--less");
