/**
 * Base class name
 */
export var BASE_CLASSNAME = 'ws10-app-store-buttons';
/**
 * apple store button
 */
export var APPLE_STORE_BUTTON = "".concat(BASE_CLASSNAME, "__apple-store");
/**
 * google store button
 */
export var GOOGLE_STORE_BUTTON = "".concat(BASE_CLASSNAME, "__google-store");
/**
 * gallary store button
 */
export var GALLARY_STORE_BUTTON = "".concat(BASE_CLASSNAME, "__gallary-store");
/**
 * this class is used to set the flex direction of the buttons in standarad buttons
 */
export var FLEX_DIRECTION_STANDARD = "".concat(BASE_CLASSNAME, "__flex-direction-standard");
/**
 * this class is used for inversed buttons
 */
export var INVERSED_BUTTON = "".concat(BASE_CLASSNAME, "__inverse-");
/**
 * standarad buttons
 */
export var STANDARAD_BUTTON = "".concat(BASE_CLASSNAME, "__button-standard");
/**
 * small button
 */
export var BUTTON_SMALL = 'small';
/**
 * standard button
 */
export var BUTTON_STANDARD = 'standard';
