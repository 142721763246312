var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_app-store-buttons.hbs';
import './app-store-buttons.scss';
import { Pattern, } from '@vfde-brix/ws10/core';
import { APPLE_STORE_BUTTON, BASE_CLASSNAME, BUTTON_SMALL, BUTTON_STANDARD, FLEX_DIRECTION_STANDARD, GALLARY_STORE_BUTTON, GOOGLE_STORE_BUTTON, INVERSED_BUTTON, } from './Constants';
/**
 * App Store Buttons
 */
var AppStoreButtons = /** @class */ (function (_super) {
    __extends(AppStoreButtons, _super);
    function AppStoreButtons() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    AppStoreButtons.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    AppStoreButtons.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    AppStoreButtons.prototype.initEvents = function () {
        var e_1, _a;
        var buttonCollection = this.containerElement.getElementsByTagName('a');
        var _loop_1 = function (buttonElement) {
            buttonElement.addEventListener('keypress', function (event) {
                if ((event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar')) {
                    event.preventDefault();
                    buttonElement.click();
                }
            });
        };
        try {
            for (var buttonCollection_1 = __values(buttonCollection), buttonCollection_1_1 = buttonCollection_1.next(); !buttonCollection_1_1.done; buttonCollection_1_1 = buttonCollection_1.next()) {
                var buttonElement = buttonCollection_1_1.value;
                _loop_1(buttonElement);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (buttonCollection_1_1 && !buttonCollection_1_1.done && (_a = buttonCollection_1.return)) _a.call(buttonCollection_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     *    this function takes the class name and return the store link
     *     We send class name here to be more clean
     */
    AppStoreButtons.prototype.getStoreUrl = function (className) {
        var storeUrl = this.containerElement.getElementsByClassName(className)[0];
        if (storeUrl) {
            return storeUrl === null || storeUrl === void 0 ? void 0 : storeUrl.getAttribute('href');
        }
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    AppStoreButtons.prototype.readDom = function (appStoreButtonsBusinessLogic) {
        var containerElement = this.containerElement.getElementsByClassName(BASE_CLASSNAME)[0];
        var linkHrefAppStoreUrl = this.getStoreUrl(APPLE_STORE_BUTTON);
        var linkHrefGooglePlayUrl = this.getStoreUrl(GOOGLE_STORE_BUTTON);
        var linkHrefAppGallaryUrl = this.getStoreUrl(GALLARY_STORE_BUTTON);
        var stdSize;
        if (containerElement.className === "".concat(BASE_CLASSNAME, " ").concat(FLEX_DIRECTION_STANDARD)) {
            stdSize = BUTTON_STANDARD;
        }
        else {
            stdSize = BUTTON_SMALL;
        }
        var optInverse = this.containerElement.getElementsByTagName('a')[0].classList.contains(INVERSED_BUTTON + stdSize);
        var properties = {
            linkHrefAppStoreUrl: linkHrefAppStoreUrl,
            linkHrefGooglePlayUrl: linkHrefGooglePlayUrl,
            linkHrefAppGallaryUrl: linkHrefAppGallaryUrl,
            optInverse: optInverse,
            stdSize: stdSize,
            business: appStoreButtonsBusinessLogic,
        };
        return properties;
    };
    return AppStoreButtons;
}(Pattern));
export { AppStoreButtons };
/**
 * This function returns an instance of AppStoreButtons
 */
export var createAppStoreButtons = function (containerElement, businessLogicOrProperties) {
    var instance = new AppStoreButtons(containerElement, businessLogicOrProperties);
    instance.init();
    return instance;
};
