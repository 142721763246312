var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { createButton, } from '@vfde-brix/ws10/button';
import { createHeadline, } from '@vfde-brix/ws10/headline';
import { createImage, IMAGE_BASE_CLASSNAME, } from '@vfde-brix/ws10/image';
import { COLUMN_WIDTH_VARIATIONS, MEDIA_TEXT_BASE_CLASSNAME, MEDIA_TEXT_IMAGE_POSITION_VARIATIONS, SEGMENT_BUTTONS_CONTAINER, SEGMENT_COPYTEXT, SEGMENT_HEADLINE, } from '@vfde-brix/ws10/media-text/Constants';
import { createVideo, VIDEO_BASE_CLASSNAME, } from '@vfde-brix/ws10/video';
import { createYoutubeVideo, YOUTUBE_VIDEO_BASE_CLASSNAME, } from '@vfde-brix/ws10/youtube-video';
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import './media-text.scss';
import renderTemplate from './_media-text.hbs';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var MediaText = /** @class */ (function (_super) {
    __extends(MediaText, _super);
    function MediaText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    MediaText.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    MediaText.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Get Properties from DOM
     */
    MediaText.prototype.readDom = function (business) {
        var e_1, _a;
        var _b;
        var properties = {
            items: [],
            business: business,
        };
        var mediaTextElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(MEDIA_TEXT_BASE_CLASSNAME)), false);
        try {
            // Loop through each data switch item in the DOM
            for (var mediaTextElements_1 = __values(mediaTextElements), mediaTextElements_1_1 = mediaTextElements_1.next(); !mediaTextElements_1_1.done; mediaTextElements_1_1 = mediaTextElements_1.next()) {
                var mediaTextElement = mediaTextElements_1_1.value;
                var containerMedia = void 0;
                var imageElement = mediaTextElement.getElementsByClassName(IMAGE_BASE_CLASSNAME);
                if (imageElement.length !== 0) {
                    var image = createImage(mediaTextElement, NO_PATTERN_BUSINESS_LOGIC);
                    containerMedia = image.getProperties();
                }
                var videoElement = mediaTextElement.getElementsByClassName(VIDEO_BASE_CLASSNAME);
                if (videoElement.length !== 0) {
                    var video = createVideo(mediaTextElement, NO_PATTERN_BUSINESS_LOGIC);
                    containerMedia = video.getProperties();
                }
                var youtubeVideoElement = mediaTextElement.getElementsByClassName(YOUTUBE_VIDEO_BASE_CLASSNAME);
                if (youtubeVideoElement.length !== 0) {
                    var youtubeVideo = createYoutubeVideo(mediaTextElement, NO_PATTERN_BUSINESS_LOGIC);
                    containerMedia = youtubeVideo.getProperties();
                }
                var headlineContainerDivElement = mediaTextElement.getElementsByClassName(SEGMENT_HEADLINE)[0];
                var headlineComponent = void 0;
                if (headlineContainerDivElement) {
                    headlineComponent = createHeadline(headlineContainerDivElement, NO_PATTERN_BUSINESS_LOGIC);
                }
                var containerHeadline = void 0;
                if (headlineComponent) {
                    containerHeadline = headlineComponent.getProperties();
                }
                var txtCopytext = (_b = mediaTextElement.getElementsByClassName(SEGMENT_COPYTEXT)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var mediaPosition = getPropertiesFromClassName(mediaTextElement.className, {
                    mediaPosition: MEDIA_TEXT_IMAGE_POSITION_VARIATIONS,
                }).mediaPosition;
                var columnWidth = getPropertiesFromClassName(mediaTextElement.className, { columnWidth: COLUMN_WIDTH_VARIATIONS }).columnWidth;
                var containerButtons = void 0;
                var buttons = __spreadArray([], __read(mediaTextElement.getElementsByClassName(SEGMENT_BUTTONS_CONTAINER)), false);
                if (buttons.length) {
                    containerButtons = buttons.map(function (button) { return createButton(button, NO_PATTERN_BUSINESS_LOGIC).getProperties(); });
                }
                properties.items.push(removeUndefinedFieldsFromObject({
                    optMediaPosition: mediaPosition,
                    optColumnWidth: columnWidth,
                    containerMedia: containerMedia,
                    containerHeadline: containerHeadline,
                    txtCopytext: txtCopytext,
                    containerButtons: containerButtons,
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (mediaTextElements_1_1 && !mediaTextElements_1_1.done && (_a = mediaTextElements_1.return)) _a.call(mediaTextElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return removeUndefinedFieldsFromObject(properties);
    };
    return MediaText;
}(Pattern));
export { MediaText };
/**
 * This function returns an instance of ImageText
 */
export var createMediaText = function (containerElement, businessLogicOrProperties) {
    var mediaText = new MediaText(containerElement, businessLogicOrProperties);
    mediaText.init();
    return mediaText;
};
