var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { CLASSNAME_LIST_CARD_HEADLINE, CLASSNAME_LIST_CARD_ITEM, CLASSNAME_LIST_CARD_MORE_INFO_TEXT, CLASSNAME_LIST_CARD_TEXT, } from './Constants';
import './list-card.scss';
import renderTemplate from './_list-card.hbs';
/**
 * List card component block
 */
var ListCard = /** @class */ (function (_super) {
    __extends(ListCard, _super);
    function ListCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    ListCard.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     */
    ListCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Read DOM to get the properties from DOM elements
     */
    ListCard.prototype.readDom = function (noPatternBusinessLogic) {
        var e_1, _a;
        var _b;
        var properties = {
            listCardItems: [],
            business: noPatternBusinessLogic,
        };
        var listCardItemElements = this.containerElement.getElementsByClassName(CLASSNAME_LIST_CARD_ITEM);
        try {
            for (var listCardItemElements_1 = __values(listCardItemElements), listCardItemElements_1_1 = listCardItemElements_1.next(); !listCardItemElements_1_1.done; listCardItemElements_1_1 = listCardItemElements_1.next()) {
                var listCardItemElement = listCardItemElements_1_1.value;
                var linkHref = listCardItemElement.getAttribute('href');
                var optTarget = listCardItemElement.getAttribute('target');
                var imgPath = listCardItemElement.getElementsByTagName('img')[0].getAttribute('src');
                var stdHeadline = listCardItemElement.getElementsByClassName(CLASSNAME_LIST_CARD_HEADLINE)[0].textContent.trim();
                var txtText = (_b = listCardItemElement.getElementsByClassName(CLASSNAME_LIST_CARD_TEXT)[0]) === null || _b === void 0 ? void 0 : _b.textContent.trim();
                var stdMoreInfoText = listCardItemElement.getElementsByClassName(CLASSNAME_LIST_CARD_MORE_INFO_TEXT)[0].textContent.trim();
                var listCardItemProperties = removeUndefinedFieldsFromObject({
                    linkHref: linkHref,
                    optTarget: optTarget,
                    imgPath: imgPath,
                    stdHeadline: stdHeadline,
                    txtText: txtText,
                    stdMoreInfoText: stdMoreInfoText,
                    business: noPatternBusinessLogic,
                });
                properties.listCardItems.push(listCardItemProperties);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (listCardItemElements_1_1 && !listCardItemElements_1_1.done && (_a = listCardItemElements_1.return)) _a.call(listCardItemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    return ListCard;
}(Pattern));
export { ListCard };
/**
 * This function returns an instance of ListCard
 */
export var createListCard = function (containerElement, businessLogicOrProperties) {
    var listCard = new ListCard(containerElement, businessLogicOrProperties);
    listCard.init();
    return listCard;
};
