var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optPreload") || (depth0 != null ? lookupProperty(depth0,"optPreload") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optPreload","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":76}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "metadata";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "poster=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imgPreviewSrc") || (depth0 != null ? lookupProperty(depth0,"imgPreviewSrc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imgPreviewSrc","hash":{},"data":data,"loc":{"start":{"line":1,"column":131},"end":{"line":1,"column":148}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<video class=\"ws10-video\" controls preload=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optPreload") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":99}}})) != null ? stack1 : "")
    + "\"  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imgPreviewSrc") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":102},"end":{"line":1,"column":156}}})) != null ? stack1 : "")
    + ">\n    <source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaSrc") || (depth0 != null ? lookupProperty(depth0,"mediaSrc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaSrc","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":29}}}) : helper)))
    + "\" type=\"video/mp4\">\n    <p>Dein Browser unterstützt kein HTML5-Video. Hier ist ein <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mediaSrc") || (depth0 != null ? lookupProperty(depth0,"mediaSrc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mediaSrc","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":84}}}) : helper)))
    + "\">Link zum Video</a> stattdessen.</p>\n</video>\n";
},"useData":true});