export var OPTION_PICKER_TYPE_TEXT = 'text';
export var OPTION_PICKER_TYPE_IMAGE = 'image';
export var OPTION_PICKER_TYPE_ICON = 'icon';
export var OPTION_PICKER_TYPE_COLOR = 'color';
/**
 * Type Variations
 */
export var OPTION_PICKER_TYPES = [
    OPTION_PICKER_TYPE_TEXT,
    OPTION_PICKER_TYPE_IMAGE,
    OPTION_PICKER_TYPE_ICON,
    OPTION_PICKER_TYPE_COLOR,
];
/**
 * Base class name
 */
export var OPTION_PICKER_BASE_CLASSNAME = 'ws10-option-picker';
/**
 * Type Text Modifier
 */
export var OPTION_PICKER_TYPE_TEXT_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "--").concat(OPTION_PICKER_TYPE_TEXT);
/**
 * Type Image Modifier
 */
export var OPTION_PICKER_TYPE_IMAGE_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "--").concat(OPTION_PICKER_TYPE_IMAGE);
/**
 * Type Icon Modifier
 */
export var OPTION_PICKER_TYPE_ICON_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "--").concat(OPTION_PICKER_TYPE_ICON);
/**
 * Type Color Modifier
 */
export var OPTION_PICKER_TYPE_COLOR_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "--").concat(OPTION_PICKER_TYPE_COLOR);
/**
 * Legend class name
 */
export var OPTION_PICKER_LEGEND_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__legend");
/**
 * Item class name
 */
export var OPTION_PICKER_ITEM_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__item");
/**
 * Input class name
 */
export var OPTION_PICKER_INPUT_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__input");
/**
 * Image class name
 */
export var OPTION_PICKER_IMAGE_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__image");
/**
 * Color swatch class name
 */
export var OPTION_PICKER_COLOR_SWATCH_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__color-swatch");
/**
 * Icon container class name
 */
export var OPTION_PICKER_ICON_CONTAINER_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__icon-container");
/**
 * Primary text class name
 */
export var OPTION_PICKER_PRIMARY_TEXT_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__text--primary");
/**
 * Secondary text class name
 */
export var OPTION_PICKER_SECONDARY_TEXT_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__text--secondary");
/**
 * Flag badge container class name
 */
export var OPTION_PICKER_FLAG_BADGE_CLASSNAME = "".concat(OPTION_PICKER_BASE_CLASSNAME, "__flag-badge");
