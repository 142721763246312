var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"ws10-icon-strip__item\" rel=\"noopener noreferrer\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":1,"column":65},"end":{"line":1,"column":77}}}) : helper)))
    + "\" target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optTarget") || (depth0 != null ? lookupProperty(depth0,"optTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTarget","hash":{},"data":data,"loc":{"start":{"line":1,"column":87},"end":{"line":1,"column":100}}}) : helper)))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-100","stdName":(depth0 != null ? lookupProperty(depth0,"stdIconName") : depth0),"stdClassName":"ws10-icon-strip-icon"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span class=\"ws10-icon-strip__span\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":52}}}) : helper)))
    + "</span>\n</a>\n\n";
},"usePartial":true,"useData":true});