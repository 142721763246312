/**
 * Base class name
 */
export var ICON_TEXT_LIST_BASE_CLASSNAME = 'ws10-icon-text-list';
/**
 * Class name for the headline
 */
export var ICON_TEXT_LIST_HEADLINE_CLASSNAME = "".concat(ICON_TEXT_LIST_BASE_CLASSNAME, "__headline");
/**
 * Class name for the list
 */
export var ICON_TEXT_LIST_LIST_CLASSNAME = "".concat(ICON_TEXT_LIST_BASE_CLASSNAME, "__list");
/**
 * Class name for the single items
 */
export var ICON_TEXT_LIST_ITEM_CLASSNAME = "".concat(ICON_TEXT_LIST_BASE_CLASSNAME, "__list-item");
/**
 * Modifier for spaced mode
 */
export var ICON_TEXT_LIST_SPACED_CLASSNAME = "".concat(ICON_TEXT_LIST_BASE_CLASSNAME, "--spaced");
