/**
 * Any constants you need belong in this file. These are normally used for selectors.
 * The advantage of this is that you can use these same constant in your validator, tests
 * and main ts file.
 */
/**
 * Base class name
 */
export var FORM_SUGGEST_INPUT_BASE_CLASSNAME = 'ws10-form-suggest-input';
/**
 * Error classname
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_ERROR = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--error");
/**
 * Warning classname
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_WARN = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--warn");
/**
 * Success classname
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_SUCCESS = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--success");
/**
 * Class name for input field container
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_INPUT_CONTAINER = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "__input");
/**
 * Class name for result list wrapper
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_WRAPPER = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "__result-wrapper");
/**
 * Class name for result list
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "__result");
/**
 * ID prefix for result list
 */
export var FORM_SUGGEST_INPUT_RESULT_LIST_ID_PREFIX = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "-result");
/**
 * Class name for input in focus
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_INPUT_FOCUSED = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--focused");
/**
 * Class name for input disabled
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_INPUT_DISABLED = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--disabled");
/**
 * Class name for result list with max-hight
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_LIMITED = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--limited");
/**
 * Class name for input with icons
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_ICONIZED = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--iconized");
/**
 * Class name for input when it has results
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_HAS_RESULTS = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "--has-results");
/**
 * Class name for result item
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "__result-item");
/**
 * Class name for result item (active)
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ACTIVE = "".concat(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM, "--active");
/**
 * Class name for result item icon
 */
export var FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ICON = "".concat(FORM_SUGGEST_INPUT_BASE_CLASSNAME, "__result-item__icon");
/**
 * event key Enter
 */
export var FORM_SUGGEST_INPUT_EVENT_KEY_ENTER = 'Enter';
/**
 * event key ArrowDown
 */
export var FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN = 'ArrowDown';
/**
 * event key ArrowUp
 */
export var FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_UP = 'ArrowUp';
/**
 * event key Tab
 */
export var FORM_SUGGEST_INPUT_EVENT_KEY_TAB = 'Tab';
/**
 * event key Esc
 */
export var FORM_SUGGEST_INPUT_EVENT_KEY_ESCAPE = 'Escape';
/**
 * Class name for input in focus
 */
export var FORM_SUGGEST_INPUT_MAX_ITEMS_BEFORE_SCROLLBAR = 8;
/**
 * System icon when the dropdown is closed
 */
export var FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_CLOSED = 'chevron-down';
/**
 * System icon when the dropdown is opened
 */
export var FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_OPENED = 'close';
