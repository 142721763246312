var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTooltip, Tooltip, } from '@vfde-brix/ws10/tooltip';
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { HIGHLIGHT_BADGE_SIZES, HIGHLIGHT_BADGE_COLORS, HIGHLIGHT_BADGE_BASE_CLASSNAME, HIGHLIGHT_BADGE_ICON_LEFT, HIGHLIGHT_BADGE_TEXT_CLASSNAME, } from './Constants';
import './highlight-badge.scss';
import renderTemplate from './_highlight-badge.hbs';
import { getSystemIconName } from '../system-icon';
/**
 * HighlightBadge
 */
var HighlightBadge = /** @class */ (function (_super) {
    __extends(HighlightBadge, _super);
    function HighlightBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    HighlightBadge.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    HighlightBadge.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        if (this.properties.containerTooltip) {
            createTooltip(this.containerElement, NO_PATTERN_BUSINESS_LOGIC);
        }
    };
    /**
     * get properties from dom
     */
    HighlightBadge.prototype.readDom = function (highlightBusinessLogic) {
        var badge = this.containerElement.getElementsByClassName(HIGHLIGHT_BADGE_BASE_CLASSNAME)[0];
        var className = badge.className;
        var stdAriaLabel = this.getAriaLabel(badge);
        var stdIcon = this.getIconName(badge);
        var txtContent = this.getContent(badge);
        var sizes = getPropertiesFromClassName(className, { optSize: HIGHLIGHT_BADGE_SIZES });
        var colors = getPropertiesFromClassName(className, { optColor: HIGHLIGHT_BADGE_COLORS });
        var containerTooltip = Tooltip.getPropertiesFromDom(this.containerElement);
        var properties = removeUndefinedFieldsFromObject(__assign(__assign(__assign(__assign({}, this.properties), colors), sizes), { stdAriaLabel: stdAriaLabel, stdIcon: stdIcon, txtContent: txtContent, containerTooltip: containerTooltip, business: highlightBusinessLogic }));
        return properties;
    };
    /**
     * Get Highlight Badge text content from DOM
     */
    HighlightBadge.prototype.getAriaLabel = function (badge) {
        var _a;
        return (_a = badge.getAttribute('aria-label')) !== null && _a !== void 0 ? _a : undefined;
    };
    /**
     * Get Icon name from DOM
     */
    HighlightBadge.prototype.getIconName = function (badge) {
        var iconSvg = badge.getElementsByClassName(HIGHLIGHT_BADGE_ICON_LEFT)[0];
        return getSystemIconName(iconSvg);
    };
    /**
     * Get Highlight Badge text content from DOM
     */
    HighlightBadge.prototype.getContent = function (badge) {
        var content = badge.getElementsByClassName(HIGHLIGHT_BADGE_TEXT_CLASSNAME)[0] || badge.getElementsByTagName('p')[0];
        return content === null || content === void 0 ? void 0 : content.innerHTML.trim();
    };
    /**
     * Get highlightBadge properties from DOM
     */
    HighlightBadge.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createHighlightBadge);
    };
    return HighlightBadge;
}(Pattern));
export { HighlightBadge };
/**
 * factory function
 */
export var createHighlightBadge = function (containerElement, businessLogicOrProperties) {
    var highlightBadge = new HighlightBadge(containerElement, businessLogicOrProperties);
    highlightBadge.init();
    return highlightBadge;
};
