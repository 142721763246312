/**
 * Base class name
 */
export var UNORDERED_VERTICAL_LIST_BASE_CLASSNAME = 'ws10-unordered-vertical-list';
/**
 * List item class name
 */
export var UNORDERED_VERTICAL_LIST_ITEM_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__item");
/**
 * List item icon class name
 */
export var UNORDERED_VERTICAL_LIST_ICON_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__icon");
/**
 * List item headline class name
 */
export var UNORDERED_VERTICAL_LIST_HEADLINE_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__headline");
/**
 * List item headline class name
 */
export var UNORDERED_VERTICAL_LIST_HEADLINE_TEXT_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_HEADLINE_CLASSNAME, "__text");
/**
 * List item text class name
 */
export var UNORDERED_VERTICAL_LIST_TEXT_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__text");
/**
 * List item link class name
 */
export var UNORDERED_VERTICAL_LIST_LINK_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__link");
/**
 * List item inverse variation class name
 */
export var UNORDERED_VERTICAL_LIST_INVERSE_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_ITEM_CLASSNAME, "--inverse");
/**
 * List item sizes
 */
export var UNORDERED_VERTICAL_LIST_ITEM_SIZES = [
    'default', 'small',
];
/**
 * List item tooltip container class name
 */
export var TOOLTIP_CONTAINER_CLASSNAME = "".concat(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, "__tooltip-trigger");
