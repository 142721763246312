/**
 * Base class name for form-select
 */
export var FORM_SELECT_CLASSNAME = 'ws10-form-select';
/**
 * classname with state error
 */
export var FORM_SELECT_CLASSNAME_ERROR = "".concat(FORM_SELECT_CLASSNAME, "--error");
/**
 * classname with warning
 */
export var FORM_SELECT_CLASSNAME_WARN = "".concat(FORM_SELECT_CLASSNAME, "--warn");
/**
 * classname with success
 */
export var FORM_SELECT_CLASSNAME_SUCCESS = "".concat(FORM_SELECT_CLASSNAME, "--success");
/**
 * classname with disabled
 */
export var FORM_SELECT_CLASSNAME_DISABLED = "".concat(FORM_SELECT_CLASSNAME, "--disabled");
/**
 * class name of actual input html element
 */
export var FORM_SELECT_SELECT_CLASSNAME = "".concat(FORM_SELECT_CLASSNAME, "__select");
/**
 * Notification icon container classname
 */
export var FORM_SELECT_NOTIFICATION_ICON_CLASSNAME = "".concat(FORM_SELECT_CLASSNAME, "__notification_icon-container");
/**
 * System icon container classname
 */
export var FORM_SELECT_SYSTEM_ICON_CLASSNAME = "".concat(FORM_SELECT_CLASSNAME, "__system_icon-container");
