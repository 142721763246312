/**
 * filter undefined keys out from object
 */
export var removeUndefinedFieldsFromObject = function (object) {
    Object.keys(object).forEach(function (key) {
        if (object[key] === undefined) {
            delete object[key];
        }
    });
    return object;
};
/**
 * filter undefined keys out from object
 * for loop wins
 * https://jsben.ch/lWsqz
 */
export var removeUnsetProps = function (object) {
    for (var key in object) {
        if (undefined === object[key] || object[key] === null) {
            delete object[key];
        }
    }
    return object;
};
