var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-fullwidth-notification--with-icon";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        data-cta-text=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdButtonText") || (depth0 != null ? lookupProperty(depth0,"stdButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdButtonText","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":40}}}) : helper)))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        data-link-href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkButtonHref") || (depth0 != null ? lookupProperty(depth0,"linkButtonHref") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkButtonHref","hash":{},"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":42}}}) : helper)))
    + "\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        data-id=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdFullwidthNotificationId") || (depth0 != null ? lookupProperty(depth0,"stdFullwidthNotificationId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdFullwidthNotificationId","hash":{},"data":data,"loc":{"start":{"line":9,"column":17},"end":{"line":9,"column":49}}}) : helper))) != null ? stack1 : "")
    + "\"\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-100","optSize":"size-150","stdClassName":"ws10-fullwidth-notification__icon","stdName":(depth0 != null ? lookupProperty(depth0,"stdIconName") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"ws10-fullwidth-notification__button\">\n                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-fullwidth-notification__subtext\">\n                    <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtSubTextContent") || (depth0 != null ? lookupProperty(depth0,"txtSubTextContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"txtSubTextContent","hash":{},"data":data,"loc":{"start":{"line":34,"column":23},"end":{"line":34,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"ws10-fullwidth-notification__close\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-fullwidth-notification ws10-fullwidth-notification--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optPosition") || (depth0 != null ? lookupProperty(depth0,"optPosition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optPosition","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":84}}}) : helper)))
    + " ws10-fullwidth-notification--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optColor") || (depth0 != null ? lookupProperty(depth0,"optColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optColor","hash":{},"data":data,"loc":{"start":{"line":1,"column":114},"end":{"line":1,"column":126}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIconName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":126},"end":{"line":1,"column":191}}})) != null ? stack1 : "")
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkButtonHref") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdFullwidthNotificationId") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "    >\n    <div class=\"ws10-fullwidth-notification__inner\">\n        <div class=\"ws10-fullwidth-notification__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIconName") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"ws10-fullwidth-notification__text\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":23,"column":19},"end":{"line":23,"column":35}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"txtSubTextContent") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optCloseButton") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});