import { BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL, BREAKPOINT_XLARGE, LARGE_MIN_WIDTH, MEDIUM_MIN_WIDTH, MOBILE_MAX_WIDTH, MOBILE_ONLY, SMALL_MIN_WIDTH, XLARGE_MIN_WIDTH, } from '@vfde-brix/ws10/styles';
import { inRange } from 'lodash';
/**
 * Get viewport
 */
export var getViewportWidth = function () { return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); };
/**
 * Get viewport name
 */
export var getViewportName = function () {
    var viewportWidth = getViewportWidth();
    switch (true) {
        case inRange(viewportWidth, 0, SMALL_MIN_WIDTH):
            return MOBILE_ONLY;
        case inRange(viewportWidth, SMALL_MIN_WIDTH, MEDIUM_MIN_WIDTH):
            return BREAKPOINT_SMALL;
        case inRange(viewportWidth, MEDIUM_MIN_WIDTH, LARGE_MIN_WIDTH):
            return BREAKPOINT_MEDIUM;
        case inRange(viewportWidth, LARGE_MIN_WIDTH, XLARGE_MIN_WIDTH):
            return BREAKPOINT_LARGE;
        case inRange(viewportWidth, XLARGE_MIN_WIDTH, Infinity):
            return BREAKPOINT_XLARGE;
    }
};
/**
 * Returns the MediaQueryList object for the desktop media query
 * @returns MediaQueryList
 */
export var getDesktopMediaQueryList = function () { return window.matchMedia("(min-width: ".concat(LARGE_MIN_WIDTH, "px)")); };
/**
 * Returns MediaQueryList object for tablet media query
 */
export var getMinTabletMediaQueryList = function () { return window.matchMedia("(min-width: ".concat(MEDIUM_MIN_WIDTH, "px)")); };
/**
 * Returns a boolean indicating if the user
 * is currently in desktop or mobile viewport
 * @returns True or false
 */
export var isDesktopViewport = function () { return getDesktopMediaQueryList().matches; };
/**
 * Returns a boolean indicating if the user is currently in minimum tablet viewport
 */
export var isMinTabletViewport = function () { return getMinTabletMediaQueryList().matches; };
/**
 * Returns the MediaQueryList object for the mobile media query
 */
export var getMobileMediaQueryList = function () { return window.matchMedia("(max-width: ".concat(MOBILE_MAX_WIDTH, "px)")); };
/**
 * Returns true if mobile viewport
 */
export var isMobileViewport = function () { return getMobileMediaQueryList().matches; };
