import { ButtonLinkColorVariant, OptIconPosition, } from '../button-link';
import { NO_PATTERN_BUSINESS_LOGIC } from '../core';
/**
* Upload Class base name
*/
export var UPLOAD_BASE_CLASSNAME = 'ws10-upload';
/**
 *  Headline container className
 */
export var ULPOAD_HEADLINE_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-headline");
/**
 * SubHeadline container className
 */
export var UPLOAD_SUBHEADLINE_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-text");
/**
 * button container className
 */
export var UPLOAD_BUTTON_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-button-container");
/**
 * upload input hidden className
 */
export var UPLOAD_INPUT_HIDDEN_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-form-text-input-container--hidden");
/**
 * file container className
 */
export var UPLOAD_FILE_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file");
/**
 * upload file data container className
 */
export var UPLOAD_FILE_DATA_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file-data-container");
/**
 * file container className in error case
 */
export var UPLOAD_FILE_ERR_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file--err");
/**
 * fileName container className
 */
export var UPLOAD_FILE_NAME_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file-data__name");
/**
 * fileSize container className
 */
export var UPLOAD_FILE_SIZE_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file-data__size");
/**
 * textInput Container className
 */
export var FORM_TEXT_INPUT_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-form-text-input-container");
/**
 * SizeError Container className
 */
export var SIZE_ERROR_NOTIFICATION_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-size-error-notification-container");
/**
 * lengthError Container className
 */
export var LENGTH_ERROR_NOTIFICATION_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-length-error-notification-container");
/**
 * reUpload button Container className
 */
export var REUPLOAD_BUTTON_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-reupload-button-container");
/**
 * reUpload Input Container className
 */
export var REUPLOAD_INPUT_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-reupload-form-text-input-container");
/**
 * buttonIconOnly Container className
 */
export var BUTTON_ICON_ONLY_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-button-icon-only-container");
/**
 * tick Container className
 */
export var TICK_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-tick-container");
/**
 * loading Animation Container className
 */
export var LOADING_ANIMATION_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-loading-animation-container");
/**
 * label Container className
 */
export var LABEL_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-label-container");
/**
 * filIcon Container ClassName
 */
export var FILE_ICON_CONTAINER_CLASSNAME = "".concat(UPLOAD_BASE_CLASSNAME, "-file__icon");
/**
 * FILE_SIZE_CONVER_BASE
 */
export var FILE_SIZE_CONVER_BASE = 1024;
/**
 * DELETE_LOADING_TITLE_ATTR
 */
export var DELETE_TITLE_ATTR = 'Datei löschen';
/**
 * LOADING_TITLE_ATTR
 */
export var LOADING_TITLE_ATTR = 'lädt noch hoch';
/**
 * TICK_ARIA_LABEL_ATTR
 */
export var TICK_ARIA_LABEL_ATTR = 'Datei erfolgreich hochgeladen';
/** Error Type - enum */
export var ErrorType;
(function (ErrorType) {
    ErrorType["SizeError"] = "SizeError";
    ErrorType["LengthError"] = "LengthError";
})(ErrorType || (ErrorType = {}));
/**
 * BUTTON_DEFAUL_PROPS - upload button default properties
 */
export var BUTTON_DEFAULT_PROPS = {
    optColor: 'secondary',
    stdContent: 'Datei auswählen',
    optWidthVariation: 'fullwidth',
    optSize: 'standard',
    icon: {
        optPosition: 'left',
        stdIconName: 'add-plus',
    },
    business: NO_PATTERN_BUSINESS_LOGIC,
};
/** BUTTON_REUPLOAD_PROPS */
export var BUTTON_REUPLOAD_PROPS = {
    optColor: 'secondary',
    stdContent: 'Datei ersetzen',
    optSize: 'small',
    optWidthVariation: 'auto',
    business: NO_PATTERN_BUSINESS_LOGIC,
};
/**
 * BUTTON_ICON_ONLY_PROPS
 */
export var BUTTON_ICON_ONLY_PROPS = {
    stdAriaLabel: 'Delete',
    stdIconName: 'delete',
    optColor: 'primary-inverse',
    optShape: 'transparent',
    business: {},
};
/**
 * ERROR_LABEL_PROPS
 */
export var ERROR_LABEL_PROPS = {
    txtText: 'Etwas ist schief gelaufen. Bitte versuch es nochmal.',
    optState: 'error',
    stdIdRelatedElement: 'foo',
    business: {},
    optDisabled: false,
};
/**
 * REUPLOAD_INPUT_PROPS
 */
export var REUPLOAD_INPUT_PROPS = {
    optType: 'file',
    optDisabled: false,
    stdId: '',
    stdName: 'reUploadInput',
    stdValue: '',
    business: {},
};
/**
 * formTextInputProps
 */
export var FORM_TEXT_INPUT_PROPS = {
    optType: 'file',
    optDisabled: false,
    stdId: 'uploadInput',
    stdName: 'uploadInput',
    stdValue: '',
    business: {},
};
/**
 * sizeErrrorNotification - default props
 */
export var SIZE_ERROR_NOTIFICATION_DEFAULT_PROPS = {
    optState: 'error',
    stdHeadline: 'Dateien zu groß',
    txtContent: 'Insgesamt darfst Du maximal 4 MB hochladen. Verkleiner die Dateien und lade die Dateien hier erneut hoch.',
    optBackground: 'white',
    optSize: 'medium',
    containerButtons: [
        {
            business: NO_PATTERN_BUSINESS_LOGIC,
            linkHref: '/',
            optDisabled: false,
            optIconPosition: OptIconPosition.RIGHT,
            optColorVariant: ButtonLinkColorVariant.Primary200,
            optTarget: '_blank',
            stdLabel: 'So verkleinerst Du Dateien',
            stdSystemIconName: 'chevron-right',
            type: 'ButtonLink',
        },
    ],
    business: NO_PATTERN_BUSINESS_LOGIC,
};
/**
 * lengthErrorNotification - default props
 */
export var LENGTH_ERROR_NOTIFICATION_DEFAULT_PROPS = {
    optState: 'error',
    stdHeadline: 'Zu viele Dateien',
    txtContent: 'Du kannst nur bis zu 3 Dateien hochladen.',
    optBackground: 'white',
    optSize: 'small',
    business: NO_PATTERN_BUSINESS_LOGIC,
};
