var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './icon-text.scss';
import renderTemplate from './_icon-text.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { ICON_TEXT_ALIGNMENT_CENTER, ICON_TEXT_ALIGNMENT_LEFT, ICON_TEXT_BASE_CLASSNAME, ICON_TEXT_ICON_CONTAINER_CLASSNAME, ICON_TEXT_INVERSE_CLASSNAME, ICON_TEXT_TEXT_CLASSNAME, } from './Constants';
import { createIcon, } from '@vfde-brix/ws10/icon';
/**
 * Icon-text class
 */
var IconText = /** @class */ (function (_super) {
    __extends(IconText, _super);
    function IconText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get defaults
     */
    IconText.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optAlignment === undefined) {
            newProperties.optAlignment = ICON_TEXT_ALIGNMENT_LEFT;
        }
        return newProperties;
    };
    /**
     * Render
     */
    IconText.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * This helper function to initializing IconComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    IconText.prototype.initDomElements = function () {
        var iconProperties = this.getIconProperties();
        this.iconContainerElement = this.containerElement.getElementsByClassName(ICON_TEXT_ICON_CONTAINER_CLASSNAME)[0];
        this.iconComponent = createIcon(this.iconContainerElement, iconProperties);
    };
    /**
     * Read from DOM
     */
    IconText.prototype.readDom = function (iconTextBusinessLogic) {
        var _a;
        this.initDomElements();
        var iconTextElement = this.containerElement.getElementsByClassName(ICON_TEXT_BASE_CLASSNAME)[0];
        var iconProperties = this.iconComponent.getProperties();
        var txtContent = (_a = iconTextElement.getElementsByClassName(ICON_TEXT_TEXT_CLASSNAME)[0]) === null || _a === void 0 ? void 0 : _a.innerHTML.trim();
        var optAlignment = getPropertiesFromClassName(iconTextElement.className, {
            optAlignment: [ICON_TEXT_ALIGNMENT_LEFT, ICON_TEXT_ALIGNMENT_CENTER],
        });
        var optInverse = iconTextElement.classList.contains(ICON_TEXT_INVERSE_CLASSNAME);
        var optSize = iconProperties.optSize, imgMidRenderIconPath = iconProperties.imgMidRenderIconPath, stdSystemIconName = iconProperties.stdSystemIconName, optSystemIconColor = iconProperties.optSystemIconColor;
        var properties = removeUndefinedFieldsFromObject(__assign(__assign({}, optAlignment), { optIconSize: optSize, optSystemIconColor: optSystemIconColor, imgMidRenderIconPath: imgMidRenderIconPath, stdSystemIconName: stdSystemIconName, optInverse: optInverse, txtContent: txtContent, business: iconTextBusinessLogic }));
        return properties;
    };
    /**
     * Icon component properties
     */
    IconText.prototype.getIconProperties = function () {
        var business = {};
        if (this.properties) {
            return {
                optSize: this.properties.optIconSize,
                optSystemIconColor: this.properties.optSystemIconColor,
                imgMidRenderIconPath: this.properties.imgMidRenderIconPath,
                stdSystemIconName: this.properties.stdSystemIconName,
                business: business,
            };
        }
        return business;
    };
    /**
     * Get button properties from DOM
     */
    IconText.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createIconText);
    };
    return IconText;
}(Pattern));
export { IconText };
/**
 * Icon text factory
 */
export var createIconText = function (containerElement, businessLogicOrProperties) {
    var iconText = new IconText(containerElement, businessLogicOrProperties);
    iconText.init();
    return iconText;
};
