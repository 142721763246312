var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './belt-container.scss';
import renderTemplate from './_belt-container.hbs';
import { Pattern, } from '@vfde-brix/ws10/core';
/**
 * WS10 BeltContainer
 */
var BeltContainer = /** @class */ (function (_super) {
    __extends(BeltContainer, _super);
    function BeltContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    BeltContainer.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    BeltContainer.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    BeltContainer.prototype.readDom = function (business) {
        /**
         * Actually, this component should never be mounted and rendered.
         *
         * That would mean that the complete tree would be rebuilt...
         * Therefore left extra empty
         */
        return undefined;
    };
    return BeltContainer;
}(Pattern));
export { BeltContainer };
/**
 * This function returns an instance of Belt Container
 */
export var createBeltContainer = function (containerElement, properties) {
    var beltContainer = new BeltContainer(containerElement, properties);
    beltContainer.init();
    return beltContainer;
};
