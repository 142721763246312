/**
 * css classname
 */
export var HEADLINE_BASE_CLASSNAME = 'ws10-headline';
/**
 * Count variation
 */
export var HEADLINE_CLASSNAME_COUNT_ME = "".concat(HEADLINE_BASE_CLASSNAME, "--count-me");
/**
 * array of all headline tags
 */
export var HEADLINE_TAGS = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
];
/**
 * array of all alignments
 */
export var HEADLINE_ALIGNMENTS = [
    'left',
    'center',
];
/**
 * array of all alignments
 */
export var HEADLINE_MOBILE_STYLES = [
    'mobile-left',
    'mobile-center',
    'mobile-hidden',
];
