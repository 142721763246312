/**
 * Formats the given string as a mobile number
 * Removes any non digits and converts 0049, 49 into 0
 * Adds a zero to the front of the number if missing
 */
export var formatStringAsMobileNumber = function (value) {
    // Remove any non digit
    value = value.replace(/(\D)/g, '');
    // If starts with 49 or 0049
    value = value.replace(/^(49)|^(0049)/, '0');
    // If no 0 in the beginning, add one
    if (value.length > 2 && value[0] !== '0') {
        value = "0".concat(value);
    }
    return value;
};
