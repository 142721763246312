var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import renderTemplate from './_just-text.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
import { getContainerSpacing } from '../core/utils/getContainerSpacing';
/**
 * The JustText can be used to render text with editorial defined templates.
 * @todo Collect feedback on rename this
 */
var JustText = /** @class */ (function (_super) {
    __extends(JustText, _super);
    function JustText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    JustText.prototype.getDefaultProperties = function (newProperties) {
        // container tag is div, but property is span. Container should win
        // @TODO Is that needed in interface at all?
        if (this.containerElement.tagName.toLowerCase() !== newProperties.tag.toLowerCase()) {
            newProperties.tag = this.containerElement.tagName.toLowerCase();
        }
        if (newProperties.optHidden === undefined) {
            newProperties.optHidden = false;
        }
        if (newProperties.footnoteNumber === undefined) {
            newProperties.footnoteNumber = null;
        }
        return newProperties;
    };
    /**
     * Render just text - This is the first component that uses replace. Please of a look on that
     */
    JustText.prototype.writeDom = function () {
        var domFragment = document.createElement('div');
        domFragment.innerHTML = renderTemplate(this.properties);
        var renderedElement = domFragment.getElementsByTagName(this.properties.tag)[0];
        var className = renderedElement.className;
        var html = renderedElement.innerHTML;
        this.containerElement.className = className;
        this.containerElement.innerHTML = html;
    };
    /**
     * Fill IJustText when rendered by e.g. cms
     */
    JustText.prototype.readDom = function (justTextBusinessLogic) {
        var footnoteNumber;
        var content;
        var optHidden = this.containerElement.className.indexOf(CLASSNAME_HIDDEN) !== -1;
        var footnoteNumberElement = this.containerElement.getElementsByTagName('sup')[0];
        var spacing = getContainerSpacing(this.containerElement.className);
        if (footnoteNumberElement) {
            footnoteNumber = parseInt(footnoteNumberElement.textContent, 10);
            content = this.getContentWithoutFootnoteNumber();
        }
        else {
            content = this.containerElement.innerHTML;
        }
        var propertiesFromDOM = {
            tag: this.containerElement.tagName.toLowerCase(),
            content: this.trimContent(content),
            footnoteNumber: footnoteNumber,
            business: justTextBusinessLogic,
            optHidden: optHidden,
            spacing: spacing,
        };
        if (this.containerElement.className !== '') {
            propertiesFromDOM.className = this.trimContent(this.containerElement.className);
        }
        return removeUndefinedFieldsFromObject(propertiesFromDOM);
    };
    /**
     * Get the content without footnoteNumber
     */
    JustText.prototype.getContentWithoutFootnoteNumber = function () {
        var content = this.containerElement.innerHTML;
        var matchSup = /<sup(.|\n)*?>(.|\n)*?<\/sup>/gm;
        var contentWithOutFootnotes = content.replace(matchSup, '');
        return contentWithOutFootnotes;
    };
    /**
     * Trim content
     */
    JustText.prototype.trimContent = function (content) {
        return content.replace(/\s+/g, ' ').trim();
    };
    return JustText;
}(Pattern));
export { JustText };
/**
 * export helper function to create ICopyText
 */
export var createJustText = function (containerElement, properties) {
    var justText = new JustText(containerElement, properties);
    justText.init();
    return justText;
};
