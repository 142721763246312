/**
 * Base class name
 */
export var RELATED_LINKS_BASE_CLASSNAME = 'ws10-related-links';
/**
 * ClassName for related_Links
 */
export var RELATED_LINKS_INTERACTION_LIST_CLASSNAME = "".concat(RELATED_LINKS_BASE_CLASSNAME, "__interaction-list");
/**
 * ClassName for related_Links_item
 */
export var RELATED_LINKS_ITEM = "".concat(RELATED_LINKS_BASE_CLASSNAME, "__item");
/**
 * ClassName for related_Links_headline
 */
export var RELATED_LINKS_HEADLINE_CLASSNAME = "".concat(RELATED_LINKS_BASE_CLASSNAME, "__headline");
/**
 * ClassName for related Links sub headline
 */
export var RELATED_LINKS_SUBHEADLINE_CLASSNAME = "".concat(RELATED_LINKS_BASE_CLASSNAME, "__sub-headline");
/**
 * Variants of a Related Links
 */
export var RelatedLinksVariant;
(function (RelatedLinksVariant) {
    RelatedLinksVariant["Flat"] = "flat";
    RelatedLinksVariant["Card"] = "card";
})(RelatedLinksVariant || (RelatedLinksVariant = {}));
