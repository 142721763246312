/**
 * Background Colors
 */
export var BackgroundColors;
(function (BackgroundColors) {
    BackgroundColors["DarkRed"] = "darkRed";
    BackgroundColors["RedGradiant"] = "redGradiant";
    BackgroundColors["LightGrey"] = "lightGrey";
    BackgroundColors["White"] = "white";
})(BackgroundColors || (BackgroundColors = {}));
