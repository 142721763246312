var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-item-summary-card__system-icon\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":"true","optColor":"color-monochrome-600","optSize":"size-200","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-item-summary-card__system-icon"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <picture class=\"ws10-item-summary-card__picture\">\n                <source srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"imgSrcMobile") : stack1), depth0))
    + "\" media=\"(max-width: 599px)\">\n                <img class=\"ws10-item-summary-card__image\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"imgSrcDesktop") : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"stdImgAlt") : stack1), depth0))
    + "\"/>\n            </picture>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"ws10-item-summary-card__tooltip\" tabindex=\"0\">\n"
    + ((stack1 = container.invokePartial(require("../tooltip/_tooltip.hbs"),(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"_tooltip","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            <span\n                                aria-describedby=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0)) != null ? lookupProperty(stack1,"stdTooltipId") : stack1), depth0))
    + "\"\n                                tabindex=\"0\"\n                                focusable=\"true\" class=\"ws10-item-summary-card__tooltip-icon--trigger ws10-tooltip__trigger ws10-center-vertical\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-600","optSize":"size-150","stdName":"info-circle","stdClassName":"ws10-item-summary-card__tooltip-icon"},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </span>\n                        </span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"ws10-item-summary-card__subline ws10-text-large\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdSubline") || (depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSubline","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":42}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"ws10-item-summary-card__copytext ws10-text\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"containerCopytext") || (depth0 != null ? lookupProperty(depth0,"containerCopytext") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"containerCopytext","hash":{},"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":39,"column":49}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-item-summary-card__action\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"textLinkItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"buttonLinkItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":62,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-item-summary-card__text-link-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"textLinkItems") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":51,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ws10-item-summary-card__text-link\">\n                                    <a class=\"ws10-text-link\" href="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":49,"column":67},"end":{"line":49,"column":79}}}) : helper)))
    + ">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdLinkText") || (depth0 != null ? lookupProperty(depth0,"stdLinkText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLinkText","hash":{},"data":data,"loc":{"start":{"line":49,"column":80},"end":{"line":49,"column":97}}}) : helper))) != null ? stack1 : "")
    + "</a>\n                                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-item-summary-card__button-link-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonLinkItems") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":28},"end":{"line":60,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <div class=\"ws10-item-summary-card__button-link\">\n"
    + ((stack1 = container.invokePartial(require("../button-link/_button-link.hbs"),depth0,{"name":"_button-link","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"ws10-item-summary-card__icon-button\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),depth0,{"name":"_button-icon-only","hash":{"optShape":"transparent","optSize":"standard","stdIconName":"delete"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\n    <div class=\"ws10-item-summary-card__item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"imgSrcDesktop") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"ws10-item-summary-card__middle-container\">\n            <div class=\"ws10-item-summary-card__content\">\n                <div class=\"ws10-item-summary-card__headline-container\">\n                    <h4 class=\"ws10-h4 ws10-item-summary-card__headline\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":43}}}) : helper))) != null ? stack1 : "")
    + "\n                    </h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":36,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerCopytext") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":41,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"textLinkItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"||",((stack1 = (depth0 != null ? lookupProperty(depth0,"buttonLinkItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optIconButton") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":70,"column":15}}})) != null ? stack1 : "")
    + "    <div/>\n    <div class=\"ws10-item-summary-card__divider-line\"></div>\n</li>\n";
},"usePartial":true,"useData":true});