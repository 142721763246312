var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './auto-suggestion.scss';
import renderTemplate from './_auto-suggestion.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { AUTO_SUGGESTION_BUTTON_CONTAINER, AUTO_SUGGESTION_BASE_CLASSNAME, CLASSNAME_AUTO_SUGGESTION_ITEM, AUTO_SUGGESTION_ITEM_IMAGE_CLASSNAME, AUTO_SUGGESTION_ITEM_LINK_CLASSNAME, AUTO_SUGGESTION_ITEM_TEXT_CLASSNAME, AUTO_SUGGESTION_SECTIONS_CLASSNAME, AUTO_SUGGESTION_SECTION_HEADER_CLASSNAME, AUTO_SUGGESTION_SECTION_ITEM_CLASSNAME, AUTO_SUGGESTION_PROMO_PRICE_CLASSNAME, } from './Constants';
import { createButton, } from '@vfde-brix/ws10/button';
import { createPromoPrice, } from '@vfde-brix/ws10/promo-price';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var AutoSuggestion = /** @class */ (function (_super) {
    __extends(AutoSuggestion, _super);
    function AutoSuggestion() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    AutoSuggestion.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    AutoSuggestion.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    AutoSuggestion.prototype.initEvents = function () {
        var _this = this;
        this.baseElement = this.containerElement.getElementsByClassName(AUTO_SUGGESTION_BASE_CLASSNAME)[0];
        this.containerElement.addEventListener('keydown', function (event) {
            var _a, _b;
            var elementInFocus = document.activeElement;
            var listItems = __spreadArray([], __read(_this.baseElement.getElementsByClassName(AUTO_SUGGESTION_SECTION_ITEM_CLASSNAME)), false);
            for (var i = 0; i < listItems.length; i++) {
                var activeParentListItem = elementInFocus.closest(".".concat(AUTO_SUGGESTION_SECTION_ITEM_CLASSNAME));
                var preItem = (_a = listItems[i - 1]) === null || _a === void 0 ? void 0 : _a.getElementsByClassName(elementInFocus.classList[0])[0];
                if (event.key === 'ArrowUp' && listItems[i] === activeParentListItem && preItem) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    preItem.dispatchEvent(new Event('focus'));
                    preItem.focus();
                }
                var nextItem = (_b = listItems[i + 1]) === null || _b === void 0 ? void 0 : _b.getElementsByClassName(elementInFocus.classList[0])[0];
                if (event.key === 'ArrowDown' && listItems[i] === activeParentListItem && nextItem) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    nextItem.dispatchEvent(new Event('focus'));
                    nextItem.focus();
                }
            }
            if (event.type === 'keydown' && event.key === 'Escape') {
                elementInFocus.dispatchEvent(new Event('blur'));
                elementInFocus.blur();
            }
        });
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    AutoSuggestion.prototype.readDom = function (business) {
        var e_1, _a, e_2, _b;
        var _c;
        this.baseElement = this.containerElement.getElementsByClassName(AUTO_SUGGESTION_BASE_CLASSNAME)[0];
        var buttonContainerElemnent = this.baseElement.getElementsByClassName(AUTO_SUGGESTION_BUTTON_CONTAINER)[0];
        var buttonComponent;
        if (buttonContainerElemnent) {
            buttonComponent = createButton(buttonContainerElemnent, NO_PATTERN_BUSINESS_LOGIC);
        }
        var containerButton;
        if (buttonComponent) {
            containerButton = buttonComponent.getProperties();
        }
        var sections = __spreadArray([], __read(this.baseElement.getElementsByClassName(AUTO_SUGGESTION_SECTIONS_CLASSNAME)), false);
        var listSections = [];
        var properties = {
            listSections: listSections,
            containerButton: containerButton,
            business: business,
        };
        try {
            for (var sections_1 = __values(sections), sections_1_1 = sections_1.next(); !sections_1_1.done; sections_1_1 = sections_1.next()) {
                var sectionElement = sections_1_1.value;
                var stdHeader = (_c = sectionElement.getElementsByClassName(AUTO_SUGGESTION_SECTION_HEADER_CLASSNAME)[0]) === null || _c === void 0 ? void 0 : _c.textContent.trim();
                var listItems = [];
                var section = {
                    stdHeader: stdHeader,
                    listItems: listItems,
                };
                var elementsHTML = sectionElement.getElementsByClassName(CLASSNAME_AUTO_SUGGESTION_ITEM);
                try {
                    for (var elementsHTML_1 = (e_2 = void 0, __values(elementsHTML)), elementsHTML_1_1 = elementsHTML_1.next(); !elementsHTML_1_1.done; elementsHTML_1_1 = elementsHTML_1.next()) {
                        var itemHTMLElement = elementsHTML_1_1.value;
                        var item = getItemProperties(itemHTMLElement);
                        section.listItems.push(item);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (elementsHTML_1_1 && !elementsHTML_1_1.done && (_b = elementsHTML_1.return)) _b.call(elementsHTML_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                properties.listSections.push(removeUndefinedFieldsFromObject(section));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (sections_1_1 && !sections_1_1.done && (_a = sections_1.return)) _a.call(sections_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return removeUndefinedFieldsFromObject(properties);
    };
    return AutoSuggestion;
}(Pattern));
export { AutoSuggestion };
function getItemProperties(itemHTMLElement) {
    var promoPriceContainerElement = itemHTMLElement.getElementsByClassName(AUTO_SUGGESTION_PROMO_PRICE_CLASSNAME)[0];
    var promoPriceComponent;
    if (promoPriceContainerElement) {
        promoPriceComponent = createPromoPrice(promoPriceContainerElement, NO_PATTERN_BUSINESS_LOGIC);
    }
    var containerPromoPrice;
    if (promoPriceComponent) {
        containerPromoPrice = promoPriceComponent.getProperties();
    }
    var txtText = itemHTMLElement.getElementsByClassName(AUTO_SUGGESTION_ITEM_TEXT_CLASSNAME)[0].innerHTML.replace(/\s+/g, ' ').trim();
    var imgImage = itemHTMLElement.getElementsByClassName(AUTO_SUGGESTION_ITEM_IMAGE_CLASSNAME)[0].src;
    var stdAltTag = itemHTMLElement.getElementsByClassName(AUTO_SUGGESTION_ITEM_IMAGE_CLASSNAME)[0].alt;
    var linkRef = itemHTMLElement.getElementsByClassName(AUTO_SUGGESTION_ITEM_LINK_CLASSNAME)[0].href;
    var properties = {
        txtText: txtText,
        imgImage: imgImage,
        stdAltTag: stdAltTag,
        linkRef: linkRef,
        containerPromoPrice: containerPromoPrice,
    };
    return removeUndefinedFieldsFromObject(properties);
}
/**
 * All components should have a factory function to create the component
 * This function returns an instance of AutoSuggestion
 */
export var createAutoSuggestion = function (containerElement, businessLogicOrProperties) {
    var autoSuggestion = new AutoSuggestion(containerElement, businessLogicOrProperties);
    autoSuggestion.init();
    return autoSuggestion;
};
