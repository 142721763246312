/**
 * Alignment of a tooltip
 */
export var TooltipAlignment;
(function (TooltipAlignment) {
    TooltipAlignment["LEFT"] = "left";
    TooltipAlignment["RIGHT"] = "right";
    TooltipAlignment["CENTER"] = "center";
    // eslint-disable-next-line @typescript-eslint/naming-convention
    TooltipAlignment["SIDE_LEFT"] = "side-left";
    // eslint-disable-next-line @typescript-eslint/naming-convention
    TooltipAlignment["SIDE_RIGHT"] = "side-right";
})(TooltipAlignment || (TooltipAlignment = {}));
/**
 * Size variants of a tooltip
 */
export var TooltipSizeVariant;
(function (TooltipSizeVariant) {
    TooltipSizeVariant["Standard"] = "standard";
    TooltipSizeVariant["Small"] = "small";
})(TooltipSizeVariant || (TooltipSizeVariant = {}));
/**
 * Base class name
 */
export var TOOLTIP_BASE_CLASSNAME = 'ws10-tooltip';
/**
 * ID of standard tooltip bubble
 */
export var ID_BUBBLE_TEMPLATE = 'template_tooltip_bubble';
/**
 * ID of standard tooltip nose
 */
export var ID_NOSE_TEMPLATE = 'template_tooltip_nose';
/**
 * ClassName bubble element
 */
export var TOOLTIP_CLASSNAME_BUBBLE_ELEMENT = "".concat(TOOLTIP_BASE_CLASSNAME, "-bubble");
/**
 * ClassName bubble element small
 */
export var TOOLTIP_CLASSNAME_BUBBLE_ELEMENT_SMALL = "".concat(TOOLTIP_CLASSNAME_BUBBLE_ELEMENT, "--small");
/**
 * ClassName headline element
 */
export var TOOLTIP_CLASSNAME_HEADLINE = "".concat(TOOLTIP_CLASSNAME_BUBBLE_ELEMENT, "__headline");
/**
 * ClassName content element
 */
export var TOOLTIP_CLASSNAME_CONTENT = "".concat(TOOLTIP_CLASSNAME_BUBBLE_ELEMENT, "__content");
/**
 * ClassName nose element
 */
export var TOOLTIP_CLASSNAME_NOSE_ELEMENT = "".concat(TOOLTIP_BASE_CLASSNAME, "-nose");
/**
 * ClassName nose element small
 */
export var TOOLTIP_CLASSNAME_NOSE_ELEMENT_SMALL = "".concat(TOOLTIP_CLASSNAME_NOSE_ELEMENT, "--small");
/**
 * ClassName nose element small side left
 */
export var TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_LEFT = "".concat(TOOLTIP_CLASSNAME_NOSE_ELEMENT, "--sideleft");
/**
 * ClassName nose element small side fight
 */
export var TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_RIGHT = "".concat(TOOLTIP_CLASSNAME_NOSE_ELEMENT, "--sideright");
/**
 * ClassName if standard tooltip flipped
 */
export var TOOLTIP_CLASSNAME_STANDARD_NOSE_FLIPPED = "".concat(TOOLTIP_CLASSNAME_NOSE_ELEMENT, "--flipped");
/**
 * ClassName if small tooltip flipped
 */
export var TOOLTIP_CLASSNAME_SMALL_NOSE_FLIPPED = "".concat(TOOLTIP_CLASSNAME_NOSE_ELEMENT_SMALL, "--flipped");
/**
 * ClassName tooltip trigger element
 */
export var TOOLTIP_CLASSNAME_TRIGGER_ELEMENT = "".concat(TOOLTIP_BASE_CLASSNAME, "__trigger");
/**
 * Nose height
 */
export var TOOLTIP_NOSE_HEIGHT = 8;
/**
 * Nose width
 */
export var TOOLTIP_NOSE_WIDTH = 24;
/**
 * Nose border
 */
export var TOOLTIP_NOSE_BORDER = 5;
/**
 * nose offset
 */
export var TOOLTIP_NOSE_OFFSET = 24;
/**
 * Min margin from nase to the edge of bubble element
 */
export var TOOLTIP_MIN_SIDE_MARGIN = 10;
/**
 * Class name animation brix fade
 */
export var TOOLTIP_CLASSNAME_ANIMATION_FADE = 'ws10-tooltip-fade';
/**
 * Class name animation brix fade in
 */
export var TOOLTIP_CLASSNAME_ANIMATION_UNFADE = 'ws10-tooltip-fade-in';
