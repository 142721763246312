var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-text-header__container-content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":134}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond2.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPrice") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"button") : depth0),{"name":"ifCond2","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":29,"column":24}}})) != null ? stack1 : "")
    + "            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " ws10-text-header__container-content--with-subline";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h2 class=\"ws10-text-header__subline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdSubline") || (depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSubline","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":74}}}) : helper))) != null ? stack1 : "")
    + "</h2>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-text-header__container-price-and-button"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPrice") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":72},"end":{"line":11,"column":152}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPrice") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"button") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " ws10-text-header__container-price-and-button--with-price";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-text-header__price-table ws10-text-header__price-table--color\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAriaLive") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":103},"end":{"line":13,"column":176}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPrice") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":20,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-live=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAriaLive") || (depth0 != null ? lookupProperty(depth0,"optAriaLive") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAriaLive","hash":{},"data":data,"loc":{"start":{"line":13,"column":134},"end":{"line":13,"column":149}}}) : helper)))
    + "\" aria-atomic=\"true\"";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ws10-text-header__price-value\">\n                                    <span class=\"ws10-text-header__price-euro\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + "</span>,<span class=\"ws10-text-header__price-cent\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":16,"column":144},"end":{"line":16,"column":193}}})) != null ? stack1 : "")
    + "</span>\n                                </div>\n                                <div class=\"ws10-text-header__price-currency\"> &euro; </div>\n                                <div class=\"ws10-text-header__price-label\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":19,"column":75},"end":{"line":19,"column":84}}}) : helper)))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    return "00";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-text-header__button\">\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),(depth0 != null ? lookupProperty(depth0,"button") : depth0),{"name":"_button","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-text-header ws10-text-header--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optBackgroundColor") || (depth0 != null ? lookupProperty(depth0,"optBackgroundColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optBackgroundColor","hash":{},"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":69}}}) : helper)))
    + " ws10-belt-container\" data-text-header-background=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optBackgroundColor") || (depth0 != null ? lookupProperty(depth0,"optBackgroundColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optBackgroundColor","hash":{},"data":data,"loc":{"start":{"line":1,"column":120},"end":{"line":1,"column":142}}}) : helper)))
    + "\">\n    <div class=\" ws10-belt-container__belt\">\n       <div class=\"ws10-text-header__container\">\n         <h1 class=\"ws10-text-header__headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":65}}}) : helper))) != null ? stack1 : "")
    + "</h1>\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond2.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSubline") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"stdPrice") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"button") : depth0),{"name":"ifCond2","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":31,"column":20}}})) != null ? stack1 : "")
    + "       </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});