/**
 * Base class name
 */
export var SYSTEM_ICON_BASE_CLASSNAME = 'ws10-system-icon';
/**
 * Size Variations
 */
export var SYSTEM_ICON_SIZES = [
    'size-100',
    'size-125',
    'size-150',
    'size-200',
    'size-225',
];
/**
 * Color Variations
 */
export var SYSTEM_ICON_COLORS = [
    'color-primary-100',
    'color-primary-100-inverse',
    'color-primary-200',
    'color-primary-200-inverse',
    'color-monochrome-100',
    'color-monochrome-100-inverse',
    'color-monochrome-300',
    'color-monochrome-300-inverse',
    'color-monochrome-400',
    'color-monochrome-400-inverse',
    'color-monochrome-600',
    'color-monochrome-600-inverse',
    'color-inherit',
];
// #region System Icons (VSCode)
// region System Icons (Webstorm)
// #region System Icon Constants (VSCode)
// region System Icon Constants (Webstorm)
// The following icons are separate constants to allow tree-shaking
export var SYSTEM_ICON_2G = '2g';
export var SYSTEM_ICON_360_VIEW = '360-view';
export var SYSTEM_ICON_3G = '3g';
export var SYSTEM_ICON_4G_PLUS_PLUS = '4g-plus-plus';
export var SYSTEM_ICON_4G_PLUS = '4g-plus';
export var SYSTEM_ICON_4G = '4g';
export var SYSTEM_ICON_5G_PLUS = '5g-plus';
export var SYSTEM_ICON_5G = '5g';
export var SYSTEM_ICON_AB_TEST = 'ab-test';
export var SYSTEM_ICON_ABRUPT_CORNERING = 'abrupt-cornering';
export var SYSTEM_ICON_ACCELERATION_HIGH = 'acceleration-high';
export var SYSTEM_ICON_ACCELERATION_LOW = 'acceleration-low';
export var SYSTEM_ICON_ACCELERATION_MIDDLE = 'acceleration-middle';
export var SYSTEM_ICON_ACCELEROMETER_HIGH = 'accelerometer-high';
export var SYSTEM_ICON_ACCELEROMETER_MIDDLE = 'accelerometer-middle';
export var SYSTEM_ICON_ACCESSORIES = 'accessories';
export var SYSTEM_ICON_ACCOUNTABILITY = 'accountability';
export var SYSTEM_ICON_ACCOUNTS_REUNITE = 'accounts-reunite';
export var SYSTEM_ICON_ACE = 'ace';
export var SYSTEM_ICON_ACTIVITY = 'activity';
export var SYSTEM_ICON_ADD_BUSINESS_USER = 'add-business-user';
export var SYSTEM_ICON_ADD_FRIEND = 'add-friend';
export var SYSTEM_ICON_ADD_ONS = 'add-ons';
export var SYSTEM_ICON_ADD_PLUS = 'add-plus';
export var SYSTEM_ICON_ADD_SIM = 'add-sim';
export var SYSTEM_ICON_ADMIN = 'admin';
export var SYSTEM_ICON_ADULT = 'adult';
export var SYSTEM_ICON_AGB = 'agb';
export var SYSTEM_ICON_ALARM_ASSISTANT_FIRE = 'alarm-assistant-fire';
export var SYSTEM_ICON_ALARM_ASSISTANT_LEAKAGE = 'alarm-assistant-leakage';
export var SYSTEM_ICON_ALARM_ASSISTANT = 'alarm-assistant';
export var SYSTEM_ICON_ALARM = 'alarm';
export var SYSTEM_ICON_ALL_REWARDS = 'all-rewards';
export var SYSTEM_ICON_AMBULANCE = 'ambulance';
export var SYSTEM_ICON_ANDROID = 'android';
export var SYSTEM_ICON_ANTI_SHOCK = 'anti-shock';
export var SYSTEM_ICON_APP_ANYTIME = 'app-anytime';
export var SYSTEM_ICON_APP_CHANNEL_MANAGEMENT = 'app-channel-management';
export var SYSTEM_ICON_APP_CHANNEL_VARIETY = 'app-channel-variety';
export var SYSTEM_ICON_APP_CLOUD_DVR = 'app-cloud-dvr';
export var SYSTEM_ICON_APP_DISCOVERY = 'app-discovery';
export var SYSTEM_ICON_APP_EPG_BACKWARDS = 'app-epg-backwards';
export var SYSTEM_ICON_APP_EPG = 'app-epg';
export var SYSTEM_ICON_APP_HD = 'app-hd';
export var SYSTEM_ICON_APP_KIDS = 'app-kids';
export var SYSTEM_ICON_APP_MEDIATHEKEN = 'app-mediatheken';
export var SYSTEM_ICON_APP_MOBILE_ENTERTAINMENT = 'app-mobile-entertainment';
export var SYSTEM_ICON_APP_MULTISCREEN = 'app-multiscreen';
export var SYSTEM_ICON_APP_RESTART = 'app-restart';
export var SYSTEM_ICON_APP_SIDELOAD = 'app-sideload';
export var SYSTEM_ICON_APP_SWIPE_TO_TV = 'app-swipe-to-tv';
export var SYSTEM_ICON_APP_TV_FOR_YOU = 'app-tv-for-you';
export var SYSTEM_ICON_APP_TV = 'app-tv';
export var SYSTEM_ICON_APP_UHD = 'app-uhd';
export var SYSTEM_ICON_APP_VIDEOSTORE = 'app-videostore';
export var SYSTEM_ICON_APP_VOD = 'app-vod';
export var SYSTEM_ICON_APPS = 'apps';
export var SYSTEM_ICON_AR = 'ar';
export var SYSTEM_ICON_ARROW_DOWN = 'arrow-down';
export var SYSTEM_ICON_ARROW_LEFT = 'arrow-left';
export var SYSTEM_ICON_ARROW_RIGHT = 'arrow-right';
export var SYSTEM_ICON_ARROW_UP = 'arrow-up';
export var SYSTEM_ICON_ATTACHMENT = 'attachment';
export var SYSTEM_ICON_AUTO_TOPUP = 'auto-topup';
export var SYSTEM_ICON_BACKSPACE = 'backspace';
export var SYSTEM_ICON_BAD_SMILEY = 'bad-smiley';
export var SYSTEM_ICON_BAG = 'bag';
export var SYSTEM_ICON_BARCODE = 'barcode';
export var SYSTEM_ICON_BATTERY_EMPTY = 'battery-empty';
export var SYSTEM_ICON_BATTERY_LOW = 'battery-low';
export var SYSTEM_ICON_BATTERY_ON_CHARGE = 'battery-on-charge';
export var SYSTEM_ICON_BATTERY_REALLY_LOW = 'battery-really-low';
export var SYSTEM_ICON_BATTERY = 'battery';
export var SYSTEM_ICON_BIKE = 'bike';
export var SYSTEM_ICON_BILL = 'bill';
export var SYSTEM_ICON_BIOMETRIC_AUTHENTICATION = 'biometric-authentication';
export var SYSTEM_ICON_BIRTHDAY_GREETING = 'birthday-greeting';
export var SYSTEM_ICON_BLOCK = 'block';
export var SYSTEM_ICON_BLOG = 'blog';
export var SYSTEM_ICON_BLUETOOTH = 'bluetooth';
export var SYSTEM_ICON_BOLD = 'bold';
export var SYSTEM_ICON_BOLT = 'bolt';
export var SYSTEM_ICON_BOOK = 'book';
export var SYSTEM_ICON_BOOKMARK = 'bookmark';
export var SYSTEM_ICON_BOOSTING_PAUSE = 'boosting-pause';
export var SYSTEM_ICON_BOOSTING = 'boosting';
export var SYSTEM_ICON_BOX = 'box';
export var SYSTEM_ICON_BRACELET_ACTIVE = 'bracelet-active';
export var SYSTEM_ICON_BRACELET_DISABLED = 'bracelet-disabled';
export var SYSTEM_ICON_BRACELET_GENERIC = 'bracelet-generic';
export var SYSTEM_ICON_BRACELET_NOT_FOUND = 'bracelet-not-found';
export var SYSTEM_ICON_BRACELET_OFF = 'bracelet-off';
export var SYSTEM_ICON_BRACELET_ON = 'bracelet-on';
export var SYSTEM_ICON_BRACELET_PUT_ON = 'bracelet-put-on';
export var SYSTEM_ICON_BRACELET_REMOVE = 'bracelet-remove';
export var SYSTEM_ICON_BRAKING = 'braking';
export var SYSTEM_ICON_BRIEFCASE = 'briefcase';
export var SYSTEM_ICON_BROADBAND = 'broadband';
export var SYSTEM_ICON_BULLET_LIST = 'bullet-list';
export var SYSTEM_ICON_BUNDLES = 'bundles';
export var SYSTEM_ICON_BUSINESS_PHONE_FIXED_LINE = 'business-phone-fixed-line';
export var SYSTEM_ICON_BUSINESS = 'business';
export var SYSTEM_ICON_CABLE_ERROR = 'cable-error';
export var SYSTEM_ICON_CABLE_FIBRE = 'cable-fibre';
export var SYSTEM_ICON_CALCULATOR = 'calculator';
export var SYSTEM_ICON_CALENDAR_12 = 'calendar-12';
export var SYSTEM_ICON_CALENDAR_14 = 'calendar-14';
export var SYSTEM_ICON_CALENDAR_3 = 'calendar-3';
export var SYSTEM_ICON_CALENDAR_30 = 'calendar-30';
export var SYSTEM_ICON_CALENDAR_ITEM = 'calendar-item';
export var SYSTEM_ICON_CALENDAR_MONTH = 'calendar-month';
export var SYSTEM_ICON_CALENDAR = 'calendar';
export var SYSTEM_ICON_CALL_LOG = 'call-log';
export var SYSTEM_ICON_CALL = 'call';
export var SYSTEM_ICON_CAMERA_INACTIVE = 'camera-inactive';
export var SYSTEM_ICON_CAMERA = 'camera';
export var SYSTEM_ICON_CANCEL_CONTRACT = 'cancel-contract';
export var SYSTEM_ICON_CAR_KEY = 'car-key';
export var SYSTEM_ICON_CARER = 'carer';
export var SYSTEM_ICON_CASE_STUDY = 'case-study';
export var SYSTEM_ICON_CELEBRATE = 'celebrate';
export var SYSTEM_ICON_CHANGE_CONTRACT = 'change-contract';
export var SYSTEM_ICON_CHARGE_TO_BILL = 'charge-to-bill';
export var SYSTEM_ICON_CHARITY_GIVING = 'charity-giving';
export var SYSTEM_ICON_CHART_BAR = 'chart-bar';
export var SYSTEM_ICON_CHART_LINE = 'chart-line';
export var SYSTEM_ICON_CHAT = 'chat';
export var SYSTEM_ICON_CHECK_CONTRACT = 'check-contract';
export var SYSTEM_ICON_CHERRY = 'cherry';
export var SYSTEM_ICON_CHEVRON_DOWN = 'chevron-down';
export var SYSTEM_ICON_CHEVRON_LEFT = 'chevron-left';
export var SYSTEM_ICON_CHEVRON_RIGHT = 'chevron-right';
export var SYSTEM_ICON_CHEVRON_UP = 'chevron-up';
export var SYSTEM_ICON_CHILD_TRACKER = 'child-tracker';
export var SYSTEM_ICON_CHROMECAST = 'chromecast';
export var SYSTEM_ICON_CLAPPING = 'clapping';
export var SYSTEM_ICON_CLOCK = 'clock';
export var SYSTEM_ICON_CLOSE_PROJECT_WITH_COMMENT = 'close-project-with-comment';
export var SYSTEM_ICON_CLOSE = 'close';
export var SYSTEM_ICON_CLOUD_DOWNLOAD = 'cloud-download';
export var SYSTEM_ICON_CLOUD_UPLOAD = 'cloud-upload';
export var SYSTEM_ICON_CLUSTER_OF_EVENTS = 'cluster-of-events';
export var SYSTEM_ICON_COAXIALCABLE = 'coaxialcable';
export var SYSTEM_ICON_COFFEE = 'coffee';
export var SYSTEM_ICON_COLOUR_PALETTE = 'colour-palette';
export var SYSTEM_ICON_COMMENT_CLOSED_PROJECT = 'comment-closed-project';
export var SYSTEM_ICON_COMMENT = 'comment';
export var SYSTEM_ICON_COMMENTS = 'comments';
export var SYSTEM_ICON_COMMUNITY = 'community';
export var SYSTEM_ICON_COMPASS = 'compass';
export var SYSTEM_ICON_COMPLAINTS = 'complaints';
export var SYSTEM_ICON_COMPLETED = 'completed';
export var SYSTEM_ICON_CONFIDENTIAL = 'confidential';
export var SYSTEM_ICON_CONNECTED_CAR = 'connected-car';
export var SYSTEM_ICON_CONNECTED_DEVICES = 'connected-devices';
export var SYSTEM_ICON_CONNECTED = 'connected';
export var SYSTEM_ICON_CONNECTIVITY = 'connectivity';
export var SYSTEM_ICON_CONSULTANT = 'consultant';
export var SYSTEM_ICON_CONTACTLESS_PAYMENT = 'contactless-payment';
export var SYSTEM_ICON_CONTACTS = 'contacts';
export var SYSTEM_ICON_CONTROL_PANEL = 'control-panel';
export var SYSTEM_ICON_CONVERGED_PROPOSITION = 'converged-proposition';
export var SYSTEM_ICON_CONVERT_CURRENCY = 'convert-currency';
export var SYSTEM_ICON_COPY = 'copy';
export var SYSTEM_ICON_CPU = 'cpu';
export var SYSTEM_ICON_DARK_MODE = 'dark-mode';
export var SYSTEM_ICON_DASHBOARD = 'dashboard';
export var SYSTEM_ICON_DATA = 'data';
export var SYSTEM_ICON_DEALS = 'deals';
export var SYSTEM_ICON_DELETE = 'delete';
export var SYSTEM_ICON_DELIVERY = 'delivery';
export var SYSTEM_ICON_DESIGN_SPRINT = 'design-sprint';
export var SYSTEM_ICON_DEVICE_DIAGNOSTICS = 'device-diagnostics';
export var SYSTEM_ICON_DEVICE_GUIDE = 'device-guide';
export var SYSTEM_ICON_DEVICE_OVERVIEW = 'device-overview';
export var SYSTEM_ICON_DEVICE_THICKNESS = 'device-thickness';
export var SYSTEM_ICON_DIAGNOSTICS = 'diagnostics';
export var SYSTEM_ICON_DIAL_PAD = 'dial-pad';
export var SYSTEM_ICON_DISCONNECT = 'disconnect';
export var SYSTEM_ICON_DISH = 'dish';
export var SYSTEM_ICON_DISLIKE = 'dislike';
export var SYSTEM_ICON_DOCUMENT_DOC = 'document-doc';
export var SYSTEM_ICON_DOCUMENT_JPG = 'document-jpg';
export var SYSTEM_ICON_DOCUMENT_PDF = 'document-pdf';
export var SYSTEM_ICON_DOCUMENT_PNG = 'document-png';
export var SYSTEM_ICON_DOCUMENT_PPT = 'document-ppt';
export var SYSTEM_ICON_DOCUMENT_RTF = 'document-rtf';
export var SYSTEM_ICON_DOCUMENT_SVG = 'document-svg';
export var SYSTEM_ICON_DOCUMENT_XLS = 'document-xls';
export var SYSTEM_ICON_DOCUMENT_ZIP = 'document-zip';
export var SYSTEM_ICON_DOCUMENT = 'document';
export var SYSTEM_ICON_DOORBELL_CAMERA = 'doorbell-camera';
export var SYSTEM_ICON_DOUBLE_STACKED_PALLET = 'double-stacked-pallet';
export var SYSTEM_ICON_DOWNLOAD = 'download';
export var SYSTEM_ICON_DRINKS = 'drinks';
export var SYSTEM_ICON_DRONE = 'drone';
export var SYSTEM_ICON_DSL = 'dsl';
export var SYSTEM_ICON_DWDM = 'dwdm';
export var SYSTEM_ICON_E_SIM = 'e-sim';
export var SYSTEM_ICON_EDIT = 'edit';
export var SYSTEM_ICON_END_CALL = 'end-call';
export var SYSTEM_ICON_ENGINEER = 'engineer';
export var SYSTEM_ICON_ENTERPRISE = 'enterprise';
export var SYSTEM_ICON_ENTERTAINMENT = 'entertainment';
export var SYSTEM_ICON_ENVIRONMENT = 'environment';
export var SYSTEM_ICON_ERROR_CIRCLE = 'error-circle';
export var SYSTEM_ICON_ERROR = 'error';
export var SYSTEM_ICON_ESIM_LOGO = 'esim-logo';
export var SYSTEM_ICON_ESSENTIALS_BROADBAND = 'essentials-broadband';
export var SYSTEM_ICON_ETHERNET = 'ethernet';
export var SYSTEM_ICON_EURO_CURRENCY = 'euro-currency';
export var SYSTEM_ICON_EXTEND_CONTRACT = 'extend-contract';
export var SYSTEM_ICON_EXTENDER = 'extender';
export var SYSTEM_ICON_FACE_RECOGNITION = 'face-recognition';
export var SYSTEM_ICON_FAILED = 'failed';
export var SYSTEM_ICON_FALL_DETECTION = 'fall-detection';
export var SYSTEM_ICON_FAMILY = 'family';
export var SYSTEM_ICON_FASHION = 'fashion';
export var SYSTEM_ICON_FAVORITE = 'favorite';
export var SYSTEM_ICON_FEATHER = 'feather';
export var SYSTEM_ICON_FILTER = 'filter';
export var SYSTEM_ICON_FIND_AND_RING_MY_DEVICE = 'find-and-ring-my-device';
export var SYSTEM_ICON_FIND_MY_CAR = 'find-my-car';
export var SYSTEM_ICON_FIRMWARE_UPDATE = 'firmware-update';
export var SYSTEM_ICON_FITNESS_HEALTH = 'fitness-health';
export var SYSTEM_ICON_FITNESS_TRACKER = 'fitness-tracker';
export var SYSTEM_ICON_FOCUS = 'focus';
export var SYSTEM_ICON_FOLDER = 'folder';
export var SYSTEM_ICON_FORWARD = 'forward';
export var SYSTEM_ICON_FOX = 'fox';
export var SYSTEM_ICON_GAME_CONSOLE = 'game-console';
export var SYSTEM_ICON_GENERIC_DEVICE_IOT = 'generic-device-iot';
export var SYSTEM_ICON_GENIUS = 'genius';
export var SYSTEM_ICON_GIGA_DEPOT = 'giga-depot';
export var SYSTEM_ICON_GIGA_TV_A = 'giga-tv-a';
export var SYSTEM_ICON_GIGA_TV_B = 'giga-tv-b';
export var SYSTEM_ICON_GIGACUBE = 'gigacube';
export var SYSTEM_ICON_GPS_COVERAGE = 'gps-coverage';
export var SYSTEM_ICON_GRAVITY = 'gravity';
export var SYSTEM_ICON_GRID = 'grid';
export var SYSTEM_ICON_GUEST_WIFI = 'guest-wifi';
export var SYSTEM_ICON_GURU = 'guru';
export var SYSTEM_ICON_HAPPY_SMILEY = 'happy-smiley';
export var SYSTEM_ICON_HEART = 'heart';
export var SYSTEM_ICON_HELP_ANSWER = 'help-answer';
export var SYSTEM_ICON_HELP_CIRCLE = 'help-circle';
export var SYSTEM_ICON_HELP_FAQ = 'help-faq';
export var SYSTEM_ICON_HIDE_PASSWORD = 'hide-password';
export var SYSTEM_ICON_HOME_ALARM = 'home-alarm';
export var SYSTEM_ICON_HOME_GARDEN = 'home-garden';
export var SYSTEM_ICON_HOME_MONITOR = 'home-monitor';
export var SYSTEM_ICON_HOME = 'home';
export var SYSTEM_ICON_IDEA = 'idea';
export var SYSTEM_ICON_IMPACT_ALERT = 'impact-alert';
export var SYSTEM_ICON_INCOMING_CALL = 'incoming-call';
export var SYSTEM_ICON_INDUSTRY = 'industry';
export var SYSTEM_ICON_INFO_CIRCLE = 'info-circle';
export var SYSTEM_ICON_INSERT_CODE = 'insert-code';
export var SYSTEM_ICON_INSURANCE = 'insurance';
export var SYSTEM_ICON_INTELLIGENCE_SCAN = 'intelligence-scan';
export var SYSTEM_ICON_INTERNATIONAL = 'international';
export var SYSTEM_ICON_INTERNET_MOVING_SERVICE = 'internet-moving-service';
export var SYSTEM_ICON_INVENTORY_MONITORING = 'inventory-monitoring';
export var SYSTEM_ICON_IOT = 'iot';
export var SYSTEM_ICON_IRIS_SCANNER = 'iris-scanner';
export var SYSTEM_ICON_ITALIC = 'italic';
export var SYSTEM_ICON_KIDS_WATCH = 'kids-watch';
export var SYSTEM_ICON_KIPPY_DEVICE = 'kippy-device';
export var SYSTEM_ICON_KIPPY_LIGHT = 'kippy-light';
export var SYSTEM_ICON_LANDLINE_INTERNET = 'landline-internet';
export var SYSTEM_ICON_LAPTOP = 'laptop';
export var SYSTEM_ICON_LATERAL_MOVEMENT = 'lateral-movement';
export var SYSTEM_ICON_LAYERS = 'layers';
export var SYSTEM_ICON_LIKE = 'like';
export var SYSTEM_ICON_LINK_INSTER_EDIT = 'link-inster-edit';
export var SYSTEM_ICON_LIST_VIEW = 'list-view';
export var SYSTEM_ICON_LOAN = 'loan';
export var SYSTEM_ICON_LOCATE_ME = 'locate-me';
export var SYSTEM_ICON_LOCATION_ALERT = 'location-alert';
export var SYSTEM_ICON_LOCATION_PIN = 'location-pin';
export var SYSTEM_ICON_LOCATION = 'location';
export var SYSTEM_ICON_LOG_IN = 'log-in';
export var SYSTEM_ICON_LOG_OUT = 'log-out';
export var SYSTEM_ICON_MAIL_FORMS = 'mail-forms';
export var SYSTEM_ICON_MAIL_READ = 'mail-read';
export var SYSTEM_ICON_MAIL = 'mail';
export var SYSTEM_ICON_MAKE_PAYMENT = 'make-payment';
export var SYSTEM_ICON_MAP_GPS = 'map-gps';
export var SYSTEM_ICON_MAP = 'map';
export var SYSTEM_ICON_MASTER = 'master';
export var SYSTEM_ICON_MAXIMIZE_IMAGE = 'maximize-image';
export var SYSTEM_ICON_MEDIA = 'media';
export var SYSTEM_ICON_MENU = 'menu';
export var SYSTEM_ICON_MERGE_CALL = 'merge-call';
export var SYSTEM_ICON_MESH_WIFI_AND_REPEATER = 'mesh-wifi-and-repeater';
export var SYSTEM_ICON_MICROPHONE = 'microphone';
export var SYSTEM_ICON_MINIMIZE_IMAGE = 'minimize-image';
export var SYSTEM_ICON_MINUS_CIRCLE = 'minus-circle';
export var SYSTEM_ICON_MINUS = 'minus';
export var SYSTEM_ICON_MINUTES_SMS = 'minutes-sms';
export var SYSTEM_ICON_MOBILE_INFO = 'mobile-info';
export var SYSTEM_ICON_MOBILE_TRADE_IN = 'mobile-trade-in';
export var SYSTEM_ICON_MOBILE = 'mobile';
export var SYSTEM_ICON_MOBILES = 'mobiles';
export var SYSTEM_ICON_MONITOR_BRIGHTNESS_CONTRAST = 'monitor-brightness-contrast';
export var SYSTEM_ICON_MORE_ANDROID = 'more-android';
export var SYSTEM_ICON_MORE = 'more';
export var SYSTEM_ICON_MOTION_ALERT = 'motion-alert';
export var SYSTEM_ICON_MOTION_SENSOR = 'motion-sensor';
export var SYSTEM_ICON_MOTION = 'motion';
export var SYSTEM_ICON_MOUNTAIN = 'mountain';
export var SYSTEM_ICON_MOUSE = 'mouse';
export var SYSTEM_ICON_MOVE_MONEY = 'move-money';
export var SYSTEM_ICON_MULTIMEDIA_PORT = 'multimedia-port';
export var SYSTEM_ICON_MULTISCREEN = 'multiscreen';
export var SYSTEM_ICON_MUSIC_AND_MOVIES = 'music-and-movies';
export var SYSTEM_ICON_MUTE = 'mute';
export var SYSTEM_ICON_MY_TRIPS = 'my-trips';
export var SYSTEM_ICON_NEED_HELP = 'need-help';
export var SYSTEM_ICON_NETWORK_PATCH_PANEL = 'network-patch-panel';
export var SYSTEM_ICON_NETWORK_TOWER = 'network-tower';
export var SYSTEM_ICON_NETWORKER = 'networker';
export var SYSTEM_ICON_NEW = 'new';
export var SYSTEM_ICON_NEWS = 'news';
export var SYSTEM_ICON_NFC = 'nfc';
export var SYSTEM_ICON_NIGHT_VISION = 'night-vision';
export var SYSTEM_ICON_NO_WIFI = 'no-wifi';
export var SYSTEM_ICON_NOTIFICATION_ERROR = 'notification-error';
export var SYSTEM_ICON_NOTIFICATION_INFO = 'notification-info';
export var SYSTEM_ICON_NOTIFICATION_MUTE = 'notification-mute';
export var SYSTEM_ICON_NOTIFICATION_TICK_THICK = 'notification-tick-thick';
export var SYSTEM_ICON_NOTIFICATION_TICK = 'notification-tick';
export var SYSTEM_ICON_NOTIFICATION = 'notification';
export var SYSTEM_ICON_NUMBER_0 = 'number-0';
export var SYSTEM_ICON_NUMBER_1 = 'number-1';
export var SYSTEM_ICON_NUMBER_2 = 'number-2';
export var SYSTEM_ICON_NUMBER_3 = 'number-3';
export var SYSTEM_ICON_NUMBER_4 = 'number-4';
export var SYSTEM_ICON_NUMBER_5 = 'number-5';
export var SYSTEM_ICON_NUMBER_6 = 'number-6';
export var SYSTEM_ICON_NUMBER_7 = 'number-7';
export var SYSTEM_ICON_NUMBER_8 = 'number-8';
export var SYSTEM_ICON_NUMBER_9 = 'number-9';
export var SYSTEM_ICON_NUMBERED_VIEW = 'numbered-view';
export var SYSTEM_ICON_OFFER_BADGE_PRECENT = 'offer-badge-precent';
export var SYSTEM_ICON_OFFER_BADGE = 'offer-badge';
export var SYSTEM_ICON_ONE_NUMBER = 'one-number';
export var SYSTEM_ICON_ONLINE_FORM = 'online-form';
export var SYSTEM_ICON_ONLINE = 'online';
export var SYSTEM_ICON_OUTGOING_CALL = 'outgoing-call';
export var SYSTEM_ICON_PADLOCK_CLOSE = 'padlock-close';
export var SYSTEM_ICON_PADLOCK_OPEN = 'padlock-open';
export var SYSTEM_ICON_PARENTAL_CONTROL = 'parental-control';
export var SYSTEM_ICON_PARKING_TICKET = 'parking-ticket';
export var SYSTEM_ICON_PASSWORD_KEY = 'password-key';
export var SYSTEM_ICON_PATROL_STATION = 'patrol-station';
export var SYSTEM_ICON_PAUSE_CIRCLE = 'pause-circle';
export var SYSTEM_ICON_PAUSE = 'pause';
export var SYSTEM_ICON_PAYG = 'payg';
export var SYSTEM_ICON_PAYMENT_CVV = 'payment-cvv';
export var SYSTEM_ICON_PAYMENT = 'payment';
export var SYSTEM_ICON_PET_COLLAR = 'pet-collar';
export var SYSTEM_ICON_PETROL_STATION = 'petrol-station';
export var SYSTEM_ICON_PHOTOS = 'photos';
export var SYSTEM_ICON_PIE_CHART = 'pie-chart';
export var SYSTEM_ICON_PIN = 'pin';
export var SYSTEM_ICON_PING = 'ping';
export var SYSTEM_ICON_PLACEHOLDER = 'placeholder';
export var SYSTEM_ICON_PLAY_ARROW = 'play-arrow';
export var SYSTEM_ICON_PLAY_CIRCLE = 'play-circle';
export var SYSTEM_ICON_PLUS = 'plus';
export var SYSTEM_ICON_POP_IN = 'pop-in';
export var SYSTEM_ICON_POP_OUT = 'pop-out';
export var SYSTEM_ICON_PORTALS_FOR_VIDEOS = 'portals-for-videos';
export var SYSTEM_ICON_POWER = 'power';
export var SYSTEM_ICON_PREPAID = 'prepaid';
export var SYSTEM_ICON_PREPARE = 'prepare';
export var SYSTEM_ICON_PRICE_PROMISE = 'price-promise';
export var SYSTEM_ICON_PRICE_TAG = 'price-tag';
export var SYSTEM_ICON_PRINT = 'print';
export var SYSTEM_ICON_PRIVACY = 'privacy';
export var SYSTEM_ICON_PRO = 'pro';
export var SYSTEM_ICON_PROFILE = 'profile';
export var SYSTEM_ICON_PUBLIC_SECTOR = 'public-sector';
export var SYSTEM_ICON_PUBLIC_WIFI = 'public-wifi';
export var SYSTEM_ICON_PUZZLE = 'puzzle';
export var SYSTEM_ICON_QR_CODE = 'qr-code';
export var SYSTEM_ICON_RAFFLES = 'raffles';
export var SYSTEM_ICON_RANK_PROFESSOR = 'rank-professor';
export var SYSTEM_ICON_RANK_SCOUT = 'rank-scout';
export var SYSTEM_ICON_REARRANGE = 'rearrange';
export var SYSTEM_ICON_RECORD_TV = 'record-tv';
export var SYSTEM_ICON_REFRESH = 'refresh';
export var SYSTEM_ICON_REMAINING_TIME = 'remaining-time';
export var SYSTEM_ICON_RENEWABLE_ENERGY = 'renewable-energy';
export var SYSTEM_ICON_REPLY = 'reply';
export var SYSTEM_ICON_RESEARCH = 'research';
export var SYSTEM_ICON_RETAKE_PHOTO = 'retake-photo';
export var SYSTEM_ICON_RETURN_PRODUCT = 'return-product';
export var SYSTEM_ICON_ROAMING = 'roaming';
export var SYSTEM_ICON_ROBOT = 'robot';
export var SYSTEM_ICON_ROCKET = 'rocket';
export var SYSTEM_ICON_ROUTER = 'router';
export var SYSTEM_ICON_ROUTINES = 'routines';
export var SYSTEM_ICON_SAFE_ZONE = 'safe-zone';
export var SYSTEM_ICON_SATISFACTION_GUARANTEE = 'satisfaction-guarantee';
export var SYSTEM_ICON_SAVE_TEMPORARILY = 'save-temporarily';
export var SYSTEM_ICON_SAVE = 'save';
export var SYSTEM_ICON_SAVINGS = 'savings';
export var SYSTEM_ICON_SCAN_CREDIT_CARD = 'scan-credit-card';
export var SYSTEM_ICON_SCREEN_REPAIR = 'screen-repair';
export var SYSTEM_ICON_SCREEN_SIZE = 'screen-size';
export var SYSTEM_ICON_SEARCH_DEVICE = 'search-device';
export var SYSTEM_ICON_SEARCH = 'search';
export var SYSTEM_ICON_SECURE_GRIP = 'secure-grip';
export var SYSTEM_ICON_SECURITY_CAMERA = 'security-camera';
export var SYSTEM_ICON_SEND_TOBI = 'send-tobi';
export var SYSTEM_ICON_SENIOR = 'senior';
export var SYSTEM_ICON_SETTINGS = 'settings';
export var SYSTEM_ICON_SETUP = 'setup';
export var SYSTEM_ICON_SHAKE = 'shake';
export var SYSTEM_ICON_SHARE_YOUR_MINS = 'share-your-mins';
export var SYSTEM_ICON_SHARE = 'share';
export var SYSTEM_ICON_SHOPPING_CHECKOUT = 'shopping-checkout';
export var SYSTEM_ICON_SHOPPING_TROLLEY = 'shopping-trolley';
export var SYSTEM_ICON_SHOW_PASSWORD = 'show-password';
export var SYSTEM_ICON_SIGN_LANGUAGE = 'sign-language';
export var SYSTEM_ICON_SIGNED_IN = 'signed-in';
export var SYSTEM_ICON_SIM_SWAP = 'sim-swap';
export var SYSTEM_ICON_SIM = 'sim';
export var SYSTEM_ICON_SINGLE_PALLET = 'single-pallet';
export var SYSTEM_ICON_SMART_CAMERA = 'smart-camera';
export var SYSTEM_ICON_SMART_HOME = 'smart-home';
export var SYSTEM_ICON_SMART_PLUG = 'smart-plug';
export var SYSTEM_ICON_SME = 'sme';
export var SYSTEM_ICON_SMS = 'sms';
export var SYSTEM_ICON_SNOW = 'snow';
export var SYSTEM_ICON_SOCIAL_FACEBOOK = 'social-facebook';
export var SYSTEM_ICON_SOCIAL_INSTA = 'social-insta';
export var SYSTEM_ICON_SOCIAL_LINKEDIN = 'social-linkedin';
export var SYSTEM_ICON_SOCIAL_PAYPAL = 'social-paypal';
export var SYSTEM_ICON_SOCIAL_PINTEREST = 'social-pinterest';
export var SYSTEM_ICON_SOCIAL_TWITTER = 'social-twitter';
export var SYSTEM_ICON_SOCIAL_YOUTUBE = 'social-youtube';
export var SYSTEM_ICON_SOHO = 'soho';
export var SYSTEM_ICON_SORT = 'sort';
export var SYSTEM_ICON_SOS_CREDIT = 'sos-credit';
export var SYSTEM_ICON_SOS_EMERGENCY = 'sos-emergency';
export var SYSTEM_ICON_SOS = 'sos';
export var SYSTEM_ICON_SOUND_ACTIVE = 'sound-active';
export var SYSTEM_ICON_SOUND_OFF = 'sound-off';
export var SYSTEM_ICON_SOUND = 'sound';
export var SYSTEM_ICON_SPEEDING = 'speeding';
export var SYSTEM_ICON_SPELL_CHECK = 'spell-check';
export var SYSTEM_ICON_STEERING_WHEEL = 'steering-wheel';
export var SYSTEM_ICON_STOP_CIRCLE = 'stop-circle';
export var SYSTEM_ICON_STOP = 'stop';
export var SYSTEM_ICON_STORE_OFFER = 'store-offer';
export var SYSTEM_ICON_STRIKE_THROUGH = 'strike-through';
export var SYSTEM_ICON_STUDENT = 'student';
export var SYSTEM_ICON_SUPERSTAR = 'superstar';
export var SYSTEM_ICON_SUPERWIFI = 'superwifi';
export var SYSTEM_ICON_SWITCH_CALL = 'switch-call';
export var SYSTEM_ICON_SWITCH_CAMERA = 'switch-camera';
export var SYSTEM_ICON_SYNC = 'sync';
export var SYSTEM_ICON_TABLET_MOBILE = 'tablet-mobile';
export var SYSTEM_ICON_TABLET = 'tablet';
export var SYSTEM_ICON_TALK = 'talk';
export var SYSTEM_ICON_TECHNIC = 'technic';
export var SYSTEM_ICON_TETHERING = 'tethering';
export var SYSTEM_ICON_THEFT = 'theft';
export var SYSTEM_ICON_THICK_TICK = 'thick-tick';
export var SYSTEM_ICON_TICK_CIRCLE = 'tick-circle';
export var SYSTEM_ICON_TICK_OUTLINE = 'tick-outline';
export var SYSTEM_ICON_TICK = 'tick';
export var SYSTEM_ICON_TICKETS = 'tickets';
export var SYSTEM_ICON_TO_DO_LIST = 'to-do-list';
export var SYSTEM_ICON_TOGGLE = 'toggle';
export var SYSTEM_ICON_TOP_UP = 'top-up';
export var SYSTEM_ICON_TOPICS = 'topics';
export var SYSTEM_ICON_TOUCH_SCREEN = 'touch-screen';
export var SYSTEM_ICON_TRACKISAFE = 'trackisafe';
export var SYSTEM_ICON_TRADE_IN = 'trade-in';
export var SYSTEM_ICON_TRAINERS = 'trainers';
export var SYSTEM_ICON_TRANSFER_PROJECT = 'transfer-project';
export var SYSTEM_ICON_TRANSLATE = 'translate';
export var SYSTEM_ICON_TREE = 'tree';
export var SYSTEM_ICON_TROPHY = 'trophy';
export var SYSTEM_ICON_TV_ANYTIME = 'tv-anytime';
export var SYSTEM_ICON_TV_BLUETOOTH_AND_REMOTE = 'tv-bluetooth-and-remote';
export var SYSTEM_ICON_TV_CHANNEL_VARIETY = 'tv-channel-variety';
export var SYSTEM_ICON_TV_DISCOVERY = 'tv-discovery';
export var SYSTEM_ICON_TV_ENTERTAINMENT = 'tv-entertainment';
export var SYSTEM_ICON_TV_EPG = 'tv-epg';
export var SYSTEM_ICON_TV_EVERYWHERE_HOME = 'tv-everywhere-home';
export var SYSTEM_ICON_TV_EVERYWHERE = 'tv-everywhere';
export var SYSTEM_ICON_TV_HD = 'tv-hd';
export var SYSTEM_ICON_TV_KIDS = 'tv-kids';
export var SYSTEM_ICON_TV_PRIMETIME = 'tv-primetime';
export var SYSTEM_ICON_TV_REMOTE = 'tv-remote';
export var SYSTEM_ICON_TV_TIME_FLEXIBLE = 'tv-time-flexible';
export var SYSTEM_ICON_TV_TV = 'tv-tv';
export var SYSTEM_ICON_TV_UHD = 'tv-uhd';
export var SYSTEM_ICON_TV = 'tv';
export var SYSTEM_ICON_UNDERLINED = 'underlined';
export var SYSTEM_ICON_UNIFIED_COMMUNICATIONS = 'unified-communications';
export var SYSTEM_ICON_UNLIMITED_MOBILE = 'unlimited-mobile';
export var SYSTEM_ICON_UNLIMITED = 'unlimited';
export var SYSTEM_ICON_UNLOCKED_REWARDS = 'unlocked-rewards';
export var SYSTEM_ICON_UPCOMING = 'upcoming';
export var SYSTEM_ICON_UPGRADE_MOBILES = 'upgrade-mobiles';
export var SYSTEM_ICON_UPGRADE = 'upgrade';
export var SYSTEM_ICON_UPLOAD = 'upload';
export var SYSTEM_ICON_USB_MODEM = 'usb-modem';
export var SYSTEM_ICON_USER_LOG_IN = 'user-log-in';
export var SYSTEM_ICON_USER_SCAN = 'user-scan';
export var SYSTEM_ICON_USERS = 'users';
export var SYSTEM_ICON_V_CAR_DONGLE = 'v-car-dongle';
export var SYSTEM_ICON_V_CAR = 'v-car';
export var SYSTEM_ICON_V_KIDS = 'v-kids';
export var SYSTEM_ICON_V_PET = 'v-pet';
export var SYSTEM_ICON_VIDEO_FRAME = 'video-frame';
export var SYSTEM_ICON_VIDEO = 'video';
export var SYSTEM_ICON_VIRTUOSO = 'virtuoso';
export var SYSTEM_ICON_VIRUS_PROTECTION = 'virus-protection';
export var SYSTEM_ICON_VODAFONE_STORE = 'vodafone-store';
export var SYSTEM_ICON_VODAFONE_TV = 'vodafone-tv';
export var SYSTEM_ICON_VOICEMAIL = 'voicemail';
export var SYSTEM_ICON_VR_HEADSET = 'vr-headset';
export var SYSTEM_ICON_WARNING = 'warning';
export var SYSTEM_ICON_WATCH = 'watch';
export var SYSTEM_ICON_WATERPROOF = 'waterproof';
export var SYSTEM_ICON_WEB_PROTECTION = 'web-protection';
export var SYSTEM_ICON_WEBSITE = 'website';
export var SYSTEM_ICON_WEIGHT = 'weight';
export var SYSTEM_ICON_WIFI_ALWAYS_ON = 'wifi-always-on';
export var SYSTEM_ICON_WIFI_GOOD = 'wifi-good';
export var SYSTEM_ICON_WITHDRAW_CASH = 'withdraw-cash';
export var SYSTEM_ICON_WIZARD = 'wizard';
export var SYSTEM_ICON_ZOOM_IN = 'zoom-in';
export var SYSTEM_ICON_ZOOM_OUT = 'zoom-out';
// endregion
// #endregion
// #region System Icon Array (VSCode)
// region System Icon Array (Webstorm)
export var SYSTEM_ICONS = [
    SYSTEM_ICON_2G,
    SYSTEM_ICON_360_VIEW,
    SYSTEM_ICON_3G,
    SYSTEM_ICON_4G_PLUS_PLUS,
    SYSTEM_ICON_4G_PLUS,
    SYSTEM_ICON_4G,
    SYSTEM_ICON_5G_PLUS,
    SYSTEM_ICON_5G,
    SYSTEM_ICON_AB_TEST,
    SYSTEM_ICON_ABRUPT_CORNERING,
    SYSTEM_ICON_ACCELERATION_HIGH,
    SYSTEM_ICON_ACCELERATION_LOW,
    SYSTEM_ICON_ACCELERATION_MIDDLE,
    SYSTEM_ICON_ACCELEROMETER_HIGH,
    SYSTEM_ICON_ACCELEROMETER_MIDDLE,
    SYSTEM_ICON_ACCESSORIES,
    SYSTEM_ICON_ACCOUNTABILITY,
    SYSTEM_ICON_ACCOUNTS_REUNITE,
    SYSTEM_ICON_ACE,
    SYSTEM_ICON_ACTIVITY,
    SYSTEM_ICON_ADD_BUSINESS_USER,
    SYSTEM_ICON_ADD_FRIEND,
    SYSTEM_ICON_ADD_ONS,
    SYSTEM_ICON_ADD_PLUS,
    SYSTEM_ICON_ADD_SIM,
    SYSTEM_ICON_ADMIN,
    SYSTEM_ICON_ADULT,
    SYSTEM_ICON_AGB,
    SYSTEM_ICON_ALARM_ASSISTANT_FIRE,
    SYSTEM_ICON_ALARM_ASSISTANT_LEAKAGE,
    SYSTEM_ICON_ALARM_ASSISTANT,
    SYSTEM_ICON_ALARM,
    SYSTEM_ICON_ALL_REWARDS,
    SYSTEM_ICON_AMBULANCE,
    SYSTEM_ICON_ANDROID,
    SYSTEM_ICON_ANTI_SHOCK,
    SYSTEM_ICON_APP_ANYTIME,
    SYSTEM_ICON_APP_CHANNEL_MANAGEMENT,
    SYSTEM_ICON_APP_CHANNEL_VARIETY,
    SYSTEM_ICON_APP_CLOUD_DVR,
    SYSTEM_ICON_APP_DISCOVERY,
    SYSTEM_ICON_APP_EPG_BACKWARDS,
    SYSTEM_ICON_APP_EPG,
    SYSTEM_ICON_APP_HD,
    SYSTEM_ICON_APP_KIDS,
    SYSTEM_ICON_APP_MEDIATHEKEN,
    SYSTEM_ICON_APP_MOBILE_ENTERTAINMENT,
    SYSTEM_ICON_APP_MULTISCREEN,
    SYSTEM_ICON_APP_RESTART,
    SYSTEM_ICON_APP_SIDELOAD,
    SYSTEM_ICON_APP_SWIPE_TO_TV,
    SYSTEM_ICON_APP_TV_FOR_YOU,
    SYSTEM_ICON_APP_TV,
    SYSTEM_ICON_APP_UHD,
    SYSTEM_ICON_APP_VIDEOSTORE,
    SYSTEM_ICON_APP_VOD,
    SYSTEM_ICON_APPS,
    SYSTEM_ICON_AR,
    SYSTEM_ICON_ARROW_DOWN,
    SYSTEM_ICON_ARROW_LEFT,
    SYSTEM_ICON_ARROW_RIGHT,
    SYSTEM_ICON_ARROW_UP,
    SYSTEM_ICON_ATTACHMENT,
    SYSTEM_ICON_AUTO_TOPUP,
    SYSTEM_ICON_BACKSPACE,
    SYSTEM_ICON_BAD_SMILEY,
    SYSTEM_ICON_BAG,
    SYSTEM_ICON_BARCODE,
    SYSTEM_ICON_BATTERY_EMPTY,
    SYSTEM_ICON_BATTERY_LOW,
    SYSTEM_ICON_BATTERY_ON_CHARGE,
    SYSTEM_ICON_BATTERY_REALLY_LOW,
    SYSTEM_ICON_BATTERY,
    SYSTEM_ICON_BIKE,
    SYSTEM_ICON_BILL,
    SYSTEM_ICON_BIOMETRIC_AUTHENTICATION,
    SYSTEM_ICON_BIRTHDAY_GREETING,
    SYSTEM_ICON_BLOCK,
    SYSTEM_ICON_BLOG,
    SYSTEM_ICON_BLUETOOTH,
    SYSTEM_ICON_BOLD,
    SYSTEM_ICON_BOLT,
    SYSTEM_ICON_BOOK,
    SYSTEM_ICON_BOOKMARK,
    SYSTEM_ICON_BOOSTING_PAUSE,
    SYSTEM_ICON_BOOSTING,
    SYSTEM_ICON_BOX,
    SYSTEM_ICON_BRACELET_ACTIVE,
    SYSTEM_ICON_BRACELET_DISABLED,
    SYSTEM_ICON_BRACELET_GENERIC,
    SYSTEM_ICON_BRACELET_NOT_FOUND,
    SYSTEM_ICON_BRACELET_OFF,
    SYSTEM_ICON_BRACELET_ON,
    SYSTEM_ICON_BRACELET_PUT_ON,
    SYSTEM_ICON_BRACELET_REMOVE,
    SYSTEM_ICON_BRAKING,
    SYSTEM_ICON_BRIEFCASE,
    SYSTEM_ICON_BROADBAND,
    SYSTEM_ICON_BULLET_LIST,
    SYSTEM_ICON_BUNDLES,
    SYSTEM_ICON_BUSINESS_PHONE_FIXED_LINE,
    SYSTEM_ICON_BUSINESS,
    SYSTEM_ICON_CABLE_ERROR,
    SYSTEM_ICON_CABLE_FIBRE,
    SYSTEM_ICON_CALCULATOR,
    SYSTEM_ICON_CALENDAR_12,
    SYSTEM_ICON_CALENDAR_14,
    SYSTEM_ICON_CALENDAR_3,
    SYSTEM_ICON_CALENDAR_30,
    SYSTEM_ICON_CALENDAR_ITEM,
    SYSTEM_ICON_CALENDAR_MONTH,
    SYSTEM_ICON_CALENDAR,
    SYSTEM_ICON_CALL_LOG,
    SYSTEM_ICON_CALL,
    SYSTEM_ICON_CAMERA_INACTIVE,
    SYSTEM_ICON_CAMERA,
    SYSTEM_ICON_CANCEL_CONTRACT,
    SYSTEM_ICON_CAR_KEY,
    SYSTEM_ICON_CARER,
    SYSTEM_ICON_CASE_STUDY,
    SYSTEM_ICON_CELEBRATE,
    SYSTEM_ICON_CHANGE_CONTRACT,
    SYSTEM_ICON_CHARGE_TO_BILL,
    SYSTEM_ICON_CHARITY_GIVING,
    SYSTEM_ICON_CHART_BAR,
    SYSTEM_ICON_CHART_LINE,
    SYSTEM_ICON_CHAT,
    SYSTEM_ICON_CHECK_CONTRACT,
    SYSTEM_ICON_CHERRY,
    SYSTEM_ICON_CHEVRON_DOWN,
    SYSTEM_ICON_CHEVRON_LEFT,
    SYSTEM_ICON_CHEVRON_RIGHT,
    SYSTEM_ICON_CHEVRON_UP,
    SYSTEM_ICON_CHILD_TRACKER,
    SYSTEM_ICON_CHROMECAST,
    SYSTEM_ICON_CLAPPING,
    SYSTEM_ICON_CLOCK,
    SYSTEM_ICON_CLOSE_PROJECT_WITH_COMMENT,
    SYSTEM_ICON_CLOSE,
    SYSTEM_ICON_CLOUD_DOWNLOAD,
    SYSTEM_ICON_CLOUD_UPLOAD,
    SYSTEM_ICON_CLUSTER_OF_EVENTS,
    SYSTEM_ICON_COAXIALCABLE,
    SYSTEM_ICON_COFFEE,
    SYSTEM_ICON_COLOUR_PALETTE,
    SYSTEM_ICON_COMMENT_CLOSED_PROJECT,
    SYSTEM_ICON_COMMENT,
    SYSTEM_ICON_COMMENTS,
    SYSTEM_ICON_COMMUNITY,
    SYSTEM_ICON_COMPASS,
    SYSTEM_ICON_COMPLAINTS,
    SYSTEM_ICON_COMPLETED,
    SYSTEM_ICON_CONFIDENTIAL,
    SYSTEM_ICON_CONNECTED_CAR,
    SYSTEM_ICON_CONNECTED_DEVICES,
    SYSTEM_ICON_CONNECTED,
    SYSTEM_ICON_CONNECTIVITY,
    SYSTEM_ICON_CONSULTANT,
    SYSTEM_ICON_CONTACTLESS_PAYMENT,
    SYSTEM_ICON_CONTACTS,
    SYSTEM_ICON_CONTROL_PANEL,
    SYSTEM_ICON_CONVERGED_PROPOSITION,
    SYSTEM_ICON_CONVERT_CURRENCY,
    SYSTEM_ICON_COPY,
    SYSTEM_ICON_CPU,
    SYSTEM_ICON_DARK_MODE,
    SYSTEM_ICON_DASHBOARD,
    SYSTEM_ICON_DATA,
    SYSTEM_ICON_DEALS,
    SYSTEM_ICON_DELETE,
    SYSTEM_ICON_DELIVERY,
    SYSTEM_ICON_DESIGN_SPRINT,
    SYSTEM_ICON_DEVICE_DIAGNOSTICS,
    SYSTEM_ICON_DEVICE_GUIDE,
    SYSTEM_ICON_DEVICE_OVERVIEW,
    SYSTEM_ICON_DEVICE_THICKNESS,
    SYSTEM_ICON_DIAGNOSTICS,
    SYSTEM_ICON_DIAL_PAD,
    SYSTEM_ICON_DISCONNECT,
    SYSTEM_ICON_DISH,
    SYSTEM_ICON_DISLIKE,
    SYSTEM_ICON_DOCUMENT_DOC,
    SYSTEM_ICON_DOCUMENT_JPG,
    SYSTEM_ICON_DOCUMENT_PDF,
    SYSTEM_ICON_DOCUMENT_PNG,
    SYSTEM_ICON_DOCUMENT_PPT,
    SYSTEM_ICON_DOCUMENT_RTF,
    SYSTEM_ICON_DOCUMENT_SVG,
    SYSTEM_ICON_DOCUMENT_XLS,
    SYSTEM_ICON_DOCUMENT_ZIP,
    SYSTEM_ICON_DOCUMENT,
    SYSTEM_ICON_DOORBELL_CAMERA,
    SYSTEM_ICON_DOUBLE_STACKED_PALLET,
    SYSTEM_ICON_DOWNLOAD,
    SYSTEM_ICON_DRINKS,
    SYSTEM_ICON_DRONE,
    SYSTEM_ICON_DSL,
    SYSTEM_ICON_DWDM,
    SYSTEM_ICON_E_SIM,
    SYSTEM_ICON_EDIT,
    SYSTEM_ICON_END_CALL,
    SYSTEM_ICON_ENGINEER,
    SYSTEM_ICON_ENTERPRISE,
    SYSTEM_ICON_ENTERTAINMENT,
    SYSTEM_ICON_ENVIRONMENT,
    SYSTEM_ICON_ERROR_CIRCLE,
    SYSTEM_ICON_ERROR,
    SYSTEM_ICON_ESIM_LOGO,
    SYSTEM_ICON_ESSENTIALS_BROADBAND,
    SYSTEM_ICON_ETHERNET,
    SYSTEM_ICON_EURO_CURRENCY,
    SYSTEM_ICON_EXTEND_CONTRACT,
    SYSTEM_ICON_EXTENDER,
    SYSTEM_ICON_FACE_RECOGNITION,
    SYSTEM_ICON_FAILED,
    SYSTEM_ICON_FALL_DETECTION,
    SYSTEM_ICON_FAMILY,
    SYSTEM_ICON_FASHION,
    SYSTEM_ICON_FAVORITE,
    SYSTEM_ICON_FEATHER,
    SYSTEM_ICON_FILTER,
    SYSTEM_ICON_FIND_AND_RING_MY_DEVICE,
    SYSTEM_ICON_FIND_MY_CAR,
    SYSTEM_ICON_FIRMWARE_UPDATE,
    SYSTEM_ICON_FITNESS_HEALTH,
    SYSTEM_ICON_FITNESS_TRACKER,
    SYSTEM_ICON_FOCUS,
    SYSTEM_ICON_FOLDER,
    SYSTEM_ICON_FORWARD,
    SYSTEM_ICON_FOX,
    SYSTEM_ICON_GAME_CONSOLE,
    SYSTEM_ICON_GENERIC_DEVICE_IOT,
    SYSTEM_ICON_GENIUS,
    SYSTEM_ICON_GIGA_DEPOT,
    SYSTEM_ICON_GIGA_TV_A,
    SYSTEM_ICON_GIGA_TV_B,
    SYSTEM_ICON_GIGACUBE,
    SYSTEM_ICON_GPS_COVERAGE,
    SYSTEM_ICON_GRAVITY,
    SYSTEM_ICON_GRID,
    SYSTEM_ICON_GUEST_WIFI,
    SYSTEM_ICON_GURU,
    SYSTEM_ICON_HAPPY_SMILEY,
    SYSTEM_ICON_HEART,
    SYSTEM_ICON_HELP_ANSWER,
    SYSTEM_ICON_HELP_CIRCLE,
    SYSTEM_ICON_HELP_FAQ,
    SYSTEM_ICON_HIDE_PASSWORD,
    SYSTEM_ICON_HOME_ALARM,
    SYSTEM_ICON_HOME_GARDEN,
    SYSTEM_ICON_HOME_MONITOR,
    SYSTEM_ICON_HOME,
    SYSTEM_ICON_IDEA,
    SYSTEM_ICON_IMPACT_ALERT,
    SYSTEM_ICON_INCOMING_CALL,
    SYSTEM_ICON_INDUSTRY,
    SYSTEM_ICON_INFO_CIRCLE,
    SYSTEM_ICON_INSERT_CODE,
    SYSTEM_ICON_INSURANCE,
    SYSTEM_ICON_INTELLIGENCE_SCAN,
    SYSTEM_ICON_INTERNATIONAL,
    SYSTEM_ICON_INTERNET_MOVING_SERVICE,
    SYSTEM_ICON_INVENTORY_MONITORING,
    SYSTEM_ICON_IOT,
    SYSTEM_ICON_IRIS_SCANNER,
    SYSTEM_ICON_ITALIC,
    SYSTEM_ICON_KIDS_WATCH,
    SYSTEM_ICON_KIPPY_DEVICE,
    SYSTEM_ICON_KIPPY_LIGHT,
    SYSTEM_ICON_LANDLINE_INTERNET,
    SYSTEM_ICON_LAPTOP,
    SYSTEM_ICON_LATERAL_MOVEMENT,
    SYSTEM_ICON_LAYERS,
    SYSTEM_ICON_LIKE,
    SYSTEM_ICON_LINK_INSTER_EDIT,
    SYSTEM_ICON_LIST_VIEW,
    SYSTEM_ICON_LOAN,
    SYSTEM_ICON_LOCATE_ME,
    SYSTEM_ICON_LOCATION_ALERT,
    SYSTEM_ICON_LOCATION_PIN,
    SYSTEM_ICON_LOCATION,
    SYSTEM_ICON_LOG_IN,
    SYSTEM_ICON_LOG_OUT,
    SYSTEM_ICON_MAIL_FORMS,
    SYSTEM_ICON_MAIL_READ,
    SYSTEM_ICON_MAIL,
    SYSTEM_ICON_MAKE_PAYMENT,
    SYSTEM_ICON_MAP_GPS,
    SYSTEM_ICON_MAP,
    SYSTEM_ICON_MASTER,
    SYSTEM_ICON_MAXIMIZE_IMAGE,
    SYSTEM_ICON_MEDIA,
    SYSTEM_ICON_MENU,
    SYSTEM_ICON_MERGE_CALL,
    SYSTEM_ICON_MESH_WIFI_AND_REPEATER,
    SYSTEM_ICON_MICROPHONE,
    SYSTEM_ICON_MINIMIZE_IMAGE,
    SYSTEM_ICON_MINUS_CIRCLE,
    SYSTEM_ICON_MINUS,
    SYSTEM_ICON_MINUTES_SMS,
    SYSTEM_ICON_MOBILE_INFO,
    SYSTEM_ICON_MOBILE_TRADE_IN,
    SYSTEM_ICON_MOBILE,
    SYSTEM_ICON_MOBILES,
    SYSTEM_ICON_MONITOR_BRIGHTNESS_CONTRAST,
    SYSTEM_ICON_MORE_ANDROID,
    SYSTEM_ICON_MORE,
    SYSTEM_ICON_MOTION_ALERT,
    SYSTEM_ICON_MOTION_SENSOR,
    SYSTEM_ICON_MOTION,
    SYSTEM_ICON_MOUNTAIN,
    SYSTEM_ICON_MOUSE,
    SYSTEM_ICON_MOVE_MONEY,
    SYSTEM_ICON_MULTIMEDIA_PORT,
    SYSTEM_ICON_MULTISCREEN,
    SYSTEM_ICON_MUSIC_AND_MOVIES,
    SYSTEM_ICON_MUTE,
    SYSTEM_ICON_MY_TRIPS,
    SYSTEM_ICON_NEED_HELP,
    SYSTEM_ICON_NETWORK_PATCH_PANEL,
    SYSTEM_ICON_NETWORK_TOWER,
    SYSTEM_ICON_NETWORKER,
    SYSTEM_ICON_NEW,
    SYSTEM_ICON_NEWS,
    SYSTEM_ICON_NFC,
    SYSTEM_ICON_NIGHT_VISION,
    SYSTEM_ICON_NO_WIFI,
    SYSTEM_ICON_NOTIFICATION_ERROR,
    SYSTEM_ICON_NOTIFICATION_INFO,
    SYSTEM_ICON_NOTIFICATION_MUTE,
    SYSTEM_ICON_NOTIFICATION_TICK_THICK,
    SYSTEM_ICON_NOTIFICATION_TICK,
    SYSTEM_ICON_NOTIFICATION,
    SYSTEM_ICON_NUMBER_0,
    SYSTEM_ICON_NUMBER_1,
    SYSTEM_ICON_NUMBER_2,
    SYSTEM_ICON_NUMBER_3,
    SYSTEM_ICON_NUMBER_4,
    SYSTEM_ICON_NUMBER_5,
    SYSTEM_ICON_NUMBER_6,
    SYSTEM_ICON_NUMBER_7,
    SYSTEM_ICON_NUMBER_8,
    SYSTEM_ICON_NUMBER_9,
    SYSTEM_ICON_NUMBERED_VIEW,
    SYSTEM_ICON_OFFER_BADGE_PRECENT,
    SYSTEM_ICON_OFFER_BADGE,
    SYSTEM_ICON_ONE_NUMBER,
    SYSTEM_ICON_ONLINE_FORM,
    SYSTEM_ICON_ONLINE,
    SYSTEM_ICON_OUTGOING_CALL,
    SYSTEM_ICON_PADLOCK_CLOSE,
    SYSTEM_ICON_PADLOCK_OPEN,
    SYSTEM_ICON_PARENTAL_CONTROL,
    SYSTEM_ICON_PARKING_TICKET,
    SYSTEM_ICON_PASSWORD_KEY,
    SYSTEM_ICON_PATROL_STATION,
    SYSTEM_ICON_PAUSE_CIRCLE,
    SYSTEM_ICON_PAUSE,
    SYSTEM_ICON_PAYG,
    SYSTEM_ICON_PAYMENT_CVV,
    SYSTEM_ICON_PAYMENT,
    SYSTEM_ICON_PET_COLLAR,
    SYSTEM_ICON_PETROL_STATION,
    SYSTEM_ICON_PHOTOS,
    SYSTEM_ICON_PIE_CHART,
    SYSTEM_ICON_PIN,
    SYSTEM_ICON_PING,
    SYSTEM_ICON_PLACEHOLDER,
    SYSTEM_ICON_PLAY_ARROW,
    SYSTEM_ICON_PLAY_CIRCLE,
    SYSTEM_ICON_PLUS,
    SYSTEM_ICON_POP_IN,
    SYSTEM_ICON_POP_OUT,
    SYSTEM_ICON_PORTALS_FOR_VIDEOS,
    SYSTEM_ICON_POWER,
    SYSTEM_ICON_PREPAID,
    SYSTEM_ICON_PREPARE,
    SYSTEM_ICON_PRICE_PROMISE,
    SYSTEM_ICON_PRICE_TAG,
    SYSTEM_ICON_PRINT,
    SYSTEM_ICON_PRIVACY,
    SYSTEM_ICON_PRO,
    SYSTEM_ICON_PROFILE,
    SYSTEM_ICON_PUBLIC_SECTOR,
    SYSTEM_ICON_PUBLIC_WIFI,
    SYSTEM_ICON_PUZZLE,
    SYSTEM_ICON_QR_CODE,
    SYSTEM_ICON_RAFFLES,
    SYSTEM_ICON_RANK_PROFESSOR,
    SYSTEM_ICON_RANK_SCOUT,
    SYSTEM_ICON_REARRANGE,
    SYSTEM_ICON_RECORD_TV,
    SYSTEM_ICON_REFRESH,
    SYSTEM_ICON_REMAINING_TIME,
    SYSTEM_ICON_RENEWABLE_ENERGY,
    SYSTEM_ICON_REPLY,
    SYSTEM_ICON_RESEARCH,
    SYSTEM_ICON_RETAKE_PHOTO,
    SYSTEM_ICON_RETURN_PRODUCT,
    SYSTEM_ICON_ROAMING,
    SYSTEM_ICON_ROBOT,
    SYSTEM_ICON_ROCKET,
    SYSTEM_ICON_ROUTER,
    SYSTEM_ICON_ROUTINES,
    SYSTEM_ICON_SAFE_ZONE,
    SYSTEM_ICON_SATISFACTION_GUARANTEE,
    SYSTEM_ICON_SAVE_TEMPORARILY,
    SYSTEM_ICON_SAVE,
    SYSTEM_ICON_SAVINGS,
    SYSTEM_ICON_SCAN_CREDIT_CARD,
    SYSTEM_ICON_SCREEN_REPAIR,
    SYSTEM_ICON_SCREEN_SIZE,
    SYSTEM_ICON_SEARCH_DEVICE,
    SYSTEM_ICON_SEARCH,
    SYSTEM_ICON_SECURE_GRIP,
    SYSTEM_ICON_SECURITY_CAMERA,
    SYSTEM_ICON_SEND_TOBI,
    SYSTEM_ICON_SENIOR,
    SYSTEM_ICON_SETTINGS,
    SYSTEM_ICON_SETUP,
    SYSTEM_ICON_SHAKE,
    SYSTEM_ICON_SHARE_YOUR_MINS,
    SYSTEM_ICON_SHARE,
    SYSTEM_ICON_SHOPPING_CHECKOUT,
    SYSTEM_ICON_SHOPPING_TROLLEY,
    SYSTEM_ICON_SHOW_PASSWORD,
    SYSTEM_ICON_SIGN_LANGUAGE,
    SYSTEM_ICON_SIGNED_IN,
    SYSTEM_ICON_SIM_SWAP,
    SYSTEM_ICON_SIM,
    SYSTEM_ICON_SINGLE_PALLET,
    SYSTEM_ICON_SMART_CAMERA,
    SYSTEM_ICON_SMART_HOME,
    SYSTEM_ICON_SMART_PLUG,
    SYSTEM_ICON_SME,
    SYSTEM_ICON_SMS,
    SYSTEM_ICON_SNOW,
    SYSTEM_ICON_SOCIAL_FACEBOOK,
    SYSTEM_ICON_SOCIAL_INSTA,
    SYSTEM_ICON_SOCIAL_LINKEDIN,
    SYSTEM_ICON_SOCIAL_PAYPAL,
    SYSTEM_ICON_SOCIAL_PINTEREST,
    SYSTEM_ICON_SOCIAL_TWITTER,
    SYSTEM_ICON_SOCIAL_YOUTUBE,
    SYSTEM_ICON_SOHO,
    SYSTEM_ICON_SORT,
    SYSTEM_ICON_SOS_CREDIT,
    SYSTEM_ICON_SOS_EMERGENCY,
    SYSTEM_ICON_SOS,
    SYSTEM_ICON_SOUND_ACTIVE,
    SYSTEM_ICON_SOUND_OFF,
    SYSTEM_ICON_SOUND,
    SYSTEM_ICON_SPEEDING,
    SYSTEM_ICON_SPELL_CHECK,
    SYSTEM_ICON_STEERING_WHEEL,
    SYSTEM_ICON_STOP_CIRCLE,
    SYSTEM_ICON_STOP,
    SYSTEM_ICON_STORE_OFFER,
    SYSTEM_ICON_STRIKE_THROUGH,
    SYSTEM_ICON_STUDENT,
    SYSTEM_ICON_SUPERSTAR,
    SYSTEM_ICON_SUPERWIFI,
    SYSTEM_ICON_SWITCH_CALL,
    SYSTEM_ICON_SWITCH_CAMERA,
    SYSTEM_ICON_SYNC,
    SYSTEM_ICON_TABLET_MOBILE,
    SYSTEM_ICON_TABLET,
    SYSTEM_ICON_TALK,
    SYSTEM_ICON_TECHNIC,
    SYSTEM_ICON_TETHERING,
    SYSTEM_ICON_THEFT,
    SYSTEM_ICON_THICK_TICK,
    SYSTEM_ICON_TICK_CIRCLE,
    SYSTEM_ICON_TICK_OUTLINE,
    SYSTEM_ICON_TICK,
    SYSTEM_ICON_TICKETS,
    SYSTEM_ICON_TO_DO_LIST,
    SYSTEM_ICON_TOGGLE,
    SYSTEM_ICON_TOP_UP,
    SYSTEM_ICON_TOPICS,
    SYSTEM_ICON_TOUCH_SCREEN,
    SYSTEM_ICON_TRACKISAFE,
    SYSTEM_ICON_TRADE_IN,
    SYSTEM_ICON_TRAINERS,
    SYSTEM_ICON_TRANSFER_PROJECT,
    SYSTEM_ICON_TRANSLATE,
    SYSTEM_ICON_TREE,
    SYSTEM_ICON_TROPHY,
    SYSTEM_ICON_TV_ANYTIME,
    SYSTEM_ICON_TV_BLUETOOTH_AND_REMOTE,
    SYSTEM_ICON_TV_CHANNEL_VARIETY,
    SYSTEM_ICON_TV_DISCOVERY,
    SYSTEM_ICON_TV_ENTERTAINMENT,
    SYSTEM_ICON_TV_EPG,
    SYSTEM_ICON_TV_EVERYWHERE_HOME,
    SYSTEM_ICON_TV_EVERYWHERE,
    SYSTEM_ICON_TV_HD,
    SYSTEM_ICON_TV_KIDS,
    SYSTEM_ICON_TV_PRIMETIME,
    SYSTEM_ICON_TV_REMOTE,
    SYSTEM_ICON_TV_TIME_FLEXIBLE,
    SYSTEM_ICON_TV_TV,
    SYSTEM_ICON_TV_UHD,
    SYSTEM_ICON_TV,
    SYSTEM_ICON_UNDERLINED,
    SYSTEM_ICON_UNIFIED_COMMUNICATIONS,
    SYSTEM_ICON_UNLIMITED_MOBILE,
    SYSTEM_ICON_UNLIMITED,
    SYSTEM_ICON_UNLOCKED_REWARDS,
    SYSTEM_ICON_UPCOMING,
    SYSTEM_ICON_UPGRADE_MOBILES,
    SYSTEM_ICON_UPGRADE,
    SYSTEM_ICON_UPLOAD,
    SYSTEM_ICON_USB_MODEM,
    SYSTEM_ICON_USER_LOG_IN,
    SYSTEM_ICON_USER_SCAN,
    SYSTEM_ICON_USERS,
    SYSTEM_ICON_V_CAR_DONGLE,
    SYSTEM_ICON_V_CAR,
    SYSTEM_ICON_V_KIDS,
    SYSTEM_ICON_V_PET,
    SYSTEM_ICON_VIDEO_FRAME,
    SYSTEM_ICON_VIDEO,
    SYSTEM_ICON_VIRTUOSO,
    SYSTEM_ICON_VIRUS_PROTECTION,
    SYSTEM_ICON_VODAFONE_STORE,
    SYSTEM_ICON_VODAFONE_TV,
    SYSTEM_ICON_VOICEMAIL,
    SYSTEM_ICON_VR_HEADSET,
    SYSTEM_ICON_WARNING,
    SYSTEM_ICON_WATCH,
    SYSTEM_ICON_WATERPROOF,
    SYSTEM_ICON_WEB_PROTECTION,
    SYSTEM_ICON_WEBSITE,
    SYSTEM_ICON_WEIGHT,
    SYSTEM_ICON_WIFI_ALWAYS_ON,
    SYSTEM_ICON_WIFI_GOOD,
    SYSTEM_ICON_WITHDRAW_CASH,
    SYSTEM_ICON_WIZARD,
    SYSTEM_ICON_ZOOM_IN,
    SYSTEM_ICON_ZOOM_OUT,
];
// endregion
// #endregion
// endregion
// #endregion
