var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './accordion.scss';
import renderTemplate from './_accordion.hbs';
import { Pattern, getPropertiesFromClassName, onLoad, removeUndefinedFieldsFromObject, stripHtml, } from '@vfde-brix/ws10/core';
import { ACCORDION_BASE_CLASSNAME, ACCORDION_ITEM_HEADLINE_CLASSNAME, AccordionVariant, ACCORDION_TIMEOUT_SCROLL_INTO_VIEW, ACCORDION_ITEM_CONTENT_CLASSNAME, ACCORDION_ITEM_ICON_CLASSNAME, ACCORDION_ITEM_DETAILS_CLASSNAME, ACCORDION_ITEM_DETAILS_OPEN, ACCORDION_ITEM_CONTENT_ID_PREFIX, ACCORDION_ITEM_SUMMARY_CLASSNAME, ACCORDION_ANIMATION_OPTIONS, ACCORDION_DEEPLINK_CLASSNAME, ACCORDION_OPEN_INDIVIDUALLY_CLASSNAME, ACCORDION_SCROLL_TO_OPENED_ITEM_CLASSNAME, ACCORDION_ITEM_CONTENT_INNER_CLASSNAME, ACCORDION_ITEM_DETAILS_ID_PREFIX, } from './Constants';
import AccordionService from './AccordionService';
import { getSystemIconName } from '../system-icon/utils/getSystemIconName';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
/**
 * Accordion
 */
var Accordion = /** @class */ (function (_super) {
    __extends(Accordion, _super);
    /**
     * Accordion constructor
     */
    function Accordion(containerElement, businessLogicOrProperties) {
        var _this = _super.call(this, containerElement, businessLogicOrProperties) || this;
        /**
         * Toggle item by ID
         * @param id The ID of the item
         * @param force Optional boolean to force opening or closing. By default the item is toggled
         * @param shouldScroll Optional boolean to enable scroll behavior after the item was opened (default=`true`)
         */
        _this.toggleItemById = function (id, force, shouldScroll) {
            if (shouldScroll === void 0) { shouldScroll = true; }
            var index = _this.properties.items.findIndex(function (item) { return item.stdId === id; });
            if (index === -1) {
                throw new Error("Accordion item with id '".concat(id, "' does not exist"));
            }
            _this.toggleItemByIndex(index, force, shouldScroll);
        };
        _this.accordionService = new AccordionService();
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    Accordion.prototype.afterInit = function () {
        var e_1, _a;
        var _this = this;
        var properties = this.getProperties();
        if (properties && properties.optDeepLink) {
            var accordionItemsDetails_1 = this.containerElement.getElementsByClassName(ACCORDION_ITEM_DETAILS_CLASSNAME);
            var accordionItemIndexesFromUrl_2 = this.accordionService.getAccordionItemIndexByUrlParam(properties);
            if (accordionItemIndexesFromUrl_2.length) {
                try {
                    for (var accordionItemIndexesFromUrl_1 = __values(accordionItemIndexesFromUrl_2), accordionItemIndexesFromUrl_1_1 = accordionItemIndexesFromUrl_1.next(); !accordionItemIndexesFromUrl_1_1.done; accordionItemIndexesFromUrl_1_1 = accordionItemIndexesFromUrl_1.next()) {
                        var index = accordionItemIndexesFromUrl_1_1.value;
                        this.toggleItemByIndex(index, true, false);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (accordionItemIndexesFromUrl_1_1 && !accordionItemIndexesFromUrl_1_1.done && (_a = accordionItemIndexesFromUrl_1.return)) _a.call(accordionItemIndexesFromUrl_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                onLoad(function () {
                    // scroll to first restored accordion item
                    _this.scrollToAccordionItem(accordionItemsDetails_1[accordionItemIndexesFromUrl_2[0]]);
                });
            }
        }
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Accordion.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    Accordion.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * getting properties from DOM
     */
    Accordion.prototype.readDom = function (accordionBusinessLogic) {
        var e_2, _a;
        var accordionElement = this.containerElement.getElementsByClassName(ACCORDION_BASE_CLASSNAME)[0];
        var accordionItemsDetails = __spreadArray([], __read(this.containerElement.getElementsByClassName(ACCORDION_ITEM_DETAILS_CLASSNAME)), false);
        var optOpenIndividually = accordionElement.classList.contains(ACCORDION_OPEN_INDIVIDUALLY_CLASSNAME);
        var optScrollToOpenedItem = accordionElement.classList.contains(ACCORDION_SCROLL_TO_OPENED_ITEM_CLASSNAME);
        var optDeepLink = accordionElement.classList.contains(ACCORDION_DEEPLINK_CLASSNAME);
        var optVariant = getPropertiesFromClassName(accordionElement.className, {
            optVariant: [AccordionVariant.Flat, AccordionVariant.Card],
        }).optVariant;
        var items = [];
        try {
            for (var accordionItemsDetails_2 = __values(accordionItemsDetails), accordionItemsDetails_2_1 = accordionItemsDetails_2.next(); !accordionItemsDetails_2_1.done; accordionItemsDetails_2_1 = accordionItemsDetails_2.next()) {
                var accordionItemDetails = accordionItemsDetails_2_1.value;
                var stdId = accordionItemDetails.id.replace(ACCORDION_ITEM_DETAILS_ID_PREFIX, '');
                var stdHeadline = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_HEADLINE_CLASSNAME)[0].innerHTML.trim();
                var optOpen = accordionItemDetails.open;
                var optHidden = accordionItemDetails.classList.contains(CLASSNAME_HIDDEN);
                var iconContainer = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_ICON_CLASSNAME)[0];
                var stdIconLeft = iconContainer ? getSystemIconName(iconContainer) : undefined;
                var containerAnyComponent = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_CONTENT_INNER_CLASSNAME)[0].innerHTML.trim();
                var item = {
                    stdId: stdId,
                    stdHeadline: stdHeadline,
                    containerAnyComponent: containerAnyComponent,
                    stdIconLeft: stdIconLeft,
                    optOpen: optOpen,
                    optHidden: optHidden,
                };
                items.push(removeUndefinedFieldsFromObject(item));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (accordionItemsDetails_2_1 && !accordionItemsDetails_2_1.done && (_a = accordionItemsDetails_2.return)) _a.call(accordionItemsDetails_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return removeUndefinedFieldsFromObject({
            items: items,
            optOpenIndividually: optOpenIndividually,
            optScrollToOpenedItem: optScrollToOpenedItem,
            optDeepLink: optDeepLink,
            optVariant: optVariant,
            business: accordionBusinessLogic,
        });
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    Accordion.prototype.initEvents = function () {
        var _this = this;
        var accordionItemsDetails = Array.from(this.containerElement.getElementsByClassName(ACCORDION_ITEM_DETAILS_CLASSNAME));
        accordionItemsDetails.forEach(function (accordionItemDetails, index) {
            _this.addAccordionItemEventListeners(accordionItemDetails, index);
        });
    };
    /**
     * Adds the relevant event listeners to each accordion item
     */
    Accordion.prototype.addAccordionItemEventListeners = function (accordionItemDetails, index) {
        var _this = this;
        var accordionItemSummary = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_SUMMARY_CLASSNAME)[0];
        accordionItemSummary.addEventListener('click', function (event) {
            var _a, _b;
            event.preventDefault();
            _this.toggleItemByIndex(index);
            (_b = (_a = _this.properties.business).onClick) === null || _b === void 0 ? void 0 : _b.call(_a, _this.properties.items[index]);
        });
    };
    /**
     * Toggle item by index
     * @param index The index of the item
     * @param force Optional boolean to force opening or closing. By default the item is toggled
     * @param shouldScroll Optional boolean to enable scroll behavior after the item was opened (default=`true`)
     */
    Accordion.prototype.toggleItemByIndex = function (index, force, shouldScroll) {
        var _this = this;
        if (shouldScroll === void 0) { shouldScroll = true; }
        var accordionItemsDetails = this.containerElement.getElementsByClassName(ACCORDION_ITEM_DETAILS_CLASSNAME);
        var _a = this.properties, items = _a.items, optOpenIndividually = _a.optOpenIndividually, business = _a.business, optDeepLink = _a.optDeepLink, optScrollToOpenedItem = _a.optScrollToOpenedItem;
        var item = items[index];
        if (!item) {
            throw new Error("Accordion item at index '".concat(index, "' does not exist"));
        }
        var newIsOpen = typeof force === 'boolean' ? force : !item.optOpen;
        if (items[index].optOpen === newIsOpen) {
            // if optOpen value has not changed, do nothing
            return;
        }
        var shouldRepairScrollPosition = false;
        var precedingContentHeight = 0;
        if (optOpenIndividually) {
            var oldOpenItemIndex = this.getOpenItemIndex();
            if (oldOpenItemIndex !== -1) {
                // In case the user opened a following accordion item after he opened a preceding item already
                // (e. g. first he opens item 1, then item 2).
                // This will make item1 close and item2 open, but if item1 has a larger content than item2,
                // this will quickly shrink the height of the page which catapults the user to the area below the accordion.
                // To avoid this behavior, we scroll the user to the opened item.
                // We don't need to do this, if the user opened a preceding item,
                // because in that case the page will just shrink,
                // until the point where it reaches the closed item (below the opened item).
                shouldRepairScrollPosition = index > oldOpenItemIndex;
                if (shouldRepairScrollPosition) {
                    precedingContentHeight = this.getContentHeightByIndex(oldOpenItemIndex);
                }
            }
            // open/close one item, close the others
            Array.from(accordionItemsDetails).forEach(function (accordionItemDetails, i) {
                var isOpenedItemIndex = i === index;
                var newOptOpen = isOpenedItemIndex ? newIsOpen : false;
                if (items[i].optOpen !== newOptOpen) {
                    items[i].optOpen = newOptOpen;
                    _this.animateContent(accordionItemDetails, newOptOpen);
                }
            });
        }
        else {
            // open/close one item only
            item.optOpen = newIsOpen;
            this.animateContent(accordionItemsDetails[index], newIsOpen);
        }
        if (business.onToggle) {
            business.onToggle(item);
        }
        if (optDeepLink) {
            this.accordionService.updateUrl(item.stdId, newIsOpen, optOpenIndividually);
        }
        if (shouldScroll &&
            ((item.optOpen && optScrollToOpenedItem)
                || (shouldRepairScrollPosition && precedingContentHeight > this.getContentHeightByIndex(index)))) {
            this.scrollToAccordionItem(accordionItemsDetails[index]);
        }
    };
    /**
     * Strip HTML from headline for tracking
     */
    Accordion.getTrackingHeadline = function (item) {
        return stripHtml(item.stdHeadline);
    };
    /**
     * Starts the content animation and updates the aria attributes afterwards
     */
    Accordion.prototype.animateContent = function (accordionItemDetails, isOpen) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.animateContentHeight(accordionItemDetails, isOpen)];
                    case 1:
                        _a.sent();
                        this.updateAriaAttributes(accordionItemDetails, isOpen);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Performs the content animation and returns a Promise which is fulfilled when the animation is finished.
     */
    Accordion.prototype.animateContentHeight = function (accordionItemDetails, isOpen) {
        return new Promise(function (resolve) {
            var accordionItemContent = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_CONTENT_CLASSNAME)[0];
            var existingAnimations = accordionItemContent.getAnimations();
            var currentContentHeight = getComputedStyle(accordionItemContent).height;
            // cancel the existing animations so always only 1 animation runs at the same time
            existingAnimations.forEach(function (anim) { return anim.cancel(); });
            var onAnimationStart = function () {
                if (isOpen) {
                    accordionItemDetails.open = true;
                    return "".concat(accordionItemContent.scrollHeight, "px");
                }
                // remove 'overflow: visible' before closing the accordion so the overflow becomes hidden again
                accordionItemContent.style.removeProperty('overflow');
                return '0';
            };
            var onAnimationFinish = function () {
                if (isOpen) {
                    // after the item was opened set height to auto and overflow to visible so focus outlines are not cut off
                    accordionItemContent.style.cssText = 'height: auto; overflow: visible;';
                }
                else {
                    // after item was closed, set the open attribute to false and height to zero
                    accordionItemDetails.open = false;
                    accordionItemContent.style.height = '0';
                }
                resolve();
            };
            accordionItemDetails.classList.toggle(ACCORDION_ITEM_DETAILS_OPEN, isOpen);
            var targetContentHeight = onAnimationStart();
            var animation = accordionItemContent.animate([
                { height: currentContentHeight },
                { height: targetContentHeight },
            ], ACCORDION_ANIMATION_OPTIONS);
            animation.addEventListener('finish', function () {
                onAnimationFinish();
            });
        });
    };
    /**
     * Update the aria-attributes based on the new open state
     */
    Accordion.prototype.updateAriaAttributes = function (accordionItemDetails, isOpen) {
        var accordionItemSummary = accordionItemDetails.getElementsByClassName(ACCORDION_ITEM_SUMMARY_CLASSNAME)[0];
        accordionItemSummary.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
    };
    /**
     * Scrolls to an accordion item with a delay
     */
    Accordion.prototype.scrollToAccordionItem = function (accordionItemDetails) {
        setTimeout(function () {
            accordionItemDetails.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, ACCORDION_TIMEOUT_SCROLL_INTO_VIEW);
    };
    /**
     * Returns the index of the currently open item or `-1` if all items are closed
     */
    Accordion.prototype.getOpenItemIndex = function () {
        return this.properties.items.findIndex(function (item) { return item.optOpen; });
    };
    /**
     * Returns the currently open item
     */
    Accordion.prototype.getOpenItem = function () {
        return this.properties.items.find(function (item) { return item.optOpen; });
    };
    /**
     * Returns the height of the content for the item at the given index
     */
    Accordion.prototype.getContentHeightByIndex = function (index) {
        var itemId = this.properties.items[index].stdId;
        var content = this.containerElement.querySelector("#".concat(ACCORDION_ITEM_CONTENT_ID_PREFIX).concat(itemId));
        return content.scrollHeight || 0;
    };
    return Accordion;
}(Pattern));
export { Accordion };
/**
 * This function returns an instance of Accordion
 */
export var createAccordion = function (containerElement, businessLogicOrProperties) {
    var accordion = new Accordion(containerElement, businessLogicOrProperties);
    accordion.init();
    return accordion;
};
