/**
 * Item state disabled
 */
export var ITEM_STATE_DISABLED = 'disabled';
/**
 * Item state checked
 */
export var ITEM_STATE_CHECKED = 'checked';
/**
 * Base class name
 */
export var FORM_SELECTION_CONTROL_BASE_CLASSNAME = 'ws10-form-selection-control';
/**
 * ClassName item
 */
export var FORM_SELECTION_CONTROL_ITEM_CLASSNAME = "".concat(FORM_SELECTION_CONTROL_BASE_CLASSNAME, "__item");
/**
 * ClassName item label
 */
export var FORM_SELECTION_CONTROL_TEXT_CLASSNAME = "".concat(FORM_SELECTION_CONTROL_BASE_CLASSNAME, "__text");
/**
 * ClassName item input
 */
export var FORM_SELECTION_CONTROL_INPUT_CLASSNAME = "".concat(FORM_SELECTION_CONTROL_BASE_CLASSNAME, "__input");
/**
 * ClassName item input
 */
export var FORM_SELECTION_CONTROL_ERROR_MESSAGE_CLASSNAME = "".concat(FORM_SELECTION_CONTROL_BASE_CLASSNAME, "__error-message");
/**
 * ClassName switch variant
 */
var SWITCH_VARIANT_DEFAULT = 'switch';
/**
 * Switch variants
 */
export var SWITCH_VARIANTS = [
    SWITCH_VARIANT_DEFAULT,
];
/**
 *  Type Radio
 */
export var FORM_SELECTION_CONTROL_TYPE_RADIO = 'radio';
/**
 * Type Checkbox
 */
export var FORM_SELECTION_CONTROL_TYPE_CHECKBOX = 'checkbox';
/**
 * Control Types
 */
export var FORM_SELECTION_CONTROL_TYPES = [
    FORM_SELECTION_CONTROL_TYPE_RADIO,
    FORM_SELECTION_CONTROL_TYPE_CHECKBOX,
];
/**
 * class name on outer div when error is set to toggle errorMessage and helperText
 */
export var CLASSNAME_IN_ERROR_CASE = "".concat(FORM_SELECTION_CONTROL_BASE_CLASSNAME, "__error");
