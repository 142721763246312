/**
 * Base class for form label
 */
export var FORM_LABEL_CLASSNAME = 'ws10-form-label';
/**
 * Form label disabled state
 */
export var FORM_LABEL_CLASSNAME_DISABLED = "".concat(FORM_LABEL_CLASSNAME, "--disabled");
/**
 * Form label error state
 */
export var FORM_LABEL_CLASSNAME_ERROR = "".concat(FORM_LABEL_CLASSNAME, "--error");
/**
 * Form label warn state
 */
export var FORM_LABEL_CLASSNAME_WARN = "".concat(FORM_LABEL_CLASSNAME, "--warn");
/**
 * Form label success state
 */
export var FORM_LABEL_CLASSNAME_SUCCESS = "".concat(FORM_LABEL_CLASSNAME, "--success");
