/**
 * Any constants you need belong in this file. These are normally used for selectors.
 * The advantage of this is that you can use these same constant in your validator, tests
 * and main ts file.
 */
/**
 * Base class name
 */
export var COLLAPSABLE_CARD_BASE_CLASSNAME = 'ws10-collapsable-card';
export var COLLAPSABLE_CARD_BG_COLOR_WHITE = 'white';
export var COLLAPSABLE_CARD_BG_COLOR_GREY = 'dark-grey';
/**
 * All valid colors for card
 */
export var COLLAPSABLE_CARD_COLORS = [
    COLLAPSABLE_CARD_BG_COLOR_WHITE,
    COLLAPSABLE_CARD_BG_COLOR_GREY,
];
/**
 * ClassName of card content
 */
export var COLLAPSABLE_CARD_CONTENT = "".concat(COLLAPSABLE_CARD_BASE_CLASSNAME, "__content");
/**
 * ClassName of button-link wrapper
 */
export var COLLAPSABLE_CARD_TOGGLE = "".concat(COLLAPSABLE_CARD_BASE_CLASSNAME, "__toggle");
/**
 * ClassName to indicate that card is collapsed
 */
export var CARD_COLLAPSED_CLASS = "".concat(COLLAPSABLE_CARD_BASE_CLASSNAME, "--collapsed");
/**
 * classname for inverse variant
 */
export var COLLAPSABLE_CARD_INVERSE_CLASS_NAME = "".concat(COLLAPSABLE_CARD_BASE_CLASSNAME, "--inverse");
