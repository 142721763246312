/**
 * Base class name
 */
export var LOADING_SPINNER_BASE_CLASSNAME = 'ws10-loading-spinner';
/**
 * Classname to check if color is inverse
 */
export var LOADING_SPINNER_CLASSNAME_INVERSE = '-inverse';
/**
 * Classname to check if spinner is nested
 */
export var LOADING_SPINNER_CLASSNAME_NESTED = '-nested';
/**
 * Classname to hide element
 */
export var LOADING_SPINNER_CLASSNAME_HIDDEN = 'ws10-is-hidden';
/**
 * Loading spinner color variation: primary
 */
export var LOADING_SPINNER_COLOR_PRIMARY = 'primary';
/**
 * Loading spinner color variation: secondary
 */
export var LOADING_SPINNER_COLOR_SECONDARY = 'secondary';
/**
 * Color Variations
 */
export var LOADING_SPINNER_COLORS = [
    LOADING_SPINNER_COLOR_PRIMARY,
    LOADING_SPINNER_COLOR_SECONDARY,
];
/**
 * Loading spinner size variation: large
 */
export var LOADING_SPINNER_SIZE_LARGE = 'large';
/**
 * Loading spinner size variation: medium
 */
export var LOADING_SPINNER_SIZE_MEDIUM = 'medium';
/**
 * Loading spinner size variation: small
 */
export var LOADING_SPINNER_SIZE_SMALL = 'small';
/**
 * Size Variations
 */
export var LOADING_SPINNER_SIZES = [
    LOADING_SPINNER_SIZE_LARGE,
    LOADING_SPINNER_SIZE_MEDIUM,
    LOADING_SPINNER_SIZE_SMALL,
];
/**
 * Show the loading-spinner only after waiting X ms
 */
export var LOADING_SPINNER_DEFAULT_SHOW_DELAY = 250;
/**
 * Hide the loading-spinner only after showing it for at least X ms
 */
export var LOADING_SPINNER_DEFAULT_HIDE_DELAY = 200;
