/**
 * Base class name
 */
export var SEARCH_INPUT_BASE_CLASSNAME = 'ws10-search-input-wrapper';
/**
 * Container for form text input
 */
export var FORM_TEXT_INPUT_CONTAINER = 'ws10-form-text-input-container';
/**
 * Container for button icon only
 */
export var BUTTON_ICON_ONLY_CONTAINER = 'ws10-button-icon-only-container';
/**
 * Classname for inverse variant
 */
export var SEARCH_INPUT_INVERSE_CLASS_NAME = "".concat(SEARCH_INPUT_BASE_CLASSNAME, "--inverse");
/**
 * Classname for disabled variant
 */
export var SEARCH_INPUT_DISABLED_CLASS_NAME = "".concat(SEARCH_INPUT_BASE_CLASSNAME, "--disabled");
