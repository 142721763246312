var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-icon-text--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAlignment") || (depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAlignment","hash":{},"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":79}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-icon-text--mid-render-icon";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-icon-text--inverse";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-icon-text"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":86}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"imgMidRenderIconPath") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":86},"end":{"line":1,"column":153}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":153},"end":{"line":1,"column":202}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"ws10-icon-text__icon-container\">\n"
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"optSystemIconColor":(depth0 != null ? lookupProperty(depth0,"optSystemIconColor") : depth0),"stdSystemIconName":(depth0 != null ? lookupProperty(depth0,"stdSystemIconName") : depth0),"imgMidRenderIconPath":(depth0 != null ? lookupProperty(depth0,"imgMidRenderIconPath") : depth0),"optSize":(depth0 != null ? lookupProperty(depth0,"optIconSize") : depth0)},"data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"ws10-icon-text__text\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});