/**
 * When using Safari on IOS several bugs could occur because of safari's "features"
 * To have a general solution we can use this helper to identify if we are in that environment
 */
export var isSafariOniOS = function () {
    var userAgent = window.navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    var isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    return isSafari && isiOS;
};
/**
 * Fix: Safari IOS environment keyboard pushes up content and messes with the scrolling
 */
export var fixScrollingAfterIOSKeyboard = function () {
    setTimeout(function () {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, 300);
};
