export var IMAGE_GALLERY_BASE_CLASSNAME = 'ws10-image-gallery';
export var IMAGE_GALLERY_IMAGES = "".concat(IMAGE_GALLERY_BASE_CLASSNAME, "-images");
export var IMAGE_GALLERY_IMAGES_IMAGE = "".concat(IMAGE_GALLERY_IMAGES, "__image");
export var IMAGE_GALLERY_SLIDER = "".concat(IMAGE_GALLERY_BASE_CLASSNAME, "-slider");
export var IMAGE_GALLERY_SLIDER_SLIDE = "".concat(IMAGE_GALLERY_SLIDER, "__slide");
export var IMAGE_GALLERY_SLIDER_IMAGE = "".concat(IMAGE_GALLERY_SLIDER, "__image");
export var IMAGE_GALLERY_IMAGE_ITEM = "".concat(IMAGE_GALLERY_BASE_CLASSNAME, "-slider__image");
export var IMAGE_GALLERY_ICON = "".concat(IMAGE_GALLERY_BASE_CLASSNAME, "-icon");
export var IMAGE_GALLERY_MIN_ITEM_COUNT = 2;
export var IMAGE_GALLERY_MAX_ITEM_COUNT = 3;
