var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-auto-suggestion-item__color-swatch-container\">\n"
    + ((stack1 = container.invokePartial(require("../color-swatch/_color-swatch.hbs"),(depth0 != null ? lookupProperty(depth0,"containerColorSwatch") : depth0),{"name":"_color-swatch","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-auto-suggestion-item__promo-price-container\">\n"
    + ((stack1 = container.invokePartial(require("../promo-price/_promo-price.hbs"),(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"_promo-price","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-auto-suggestion-item\">\n    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkRef") || (depth0 != null ? lookupProperty(depth0,"linkRef") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkRef","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":24}}}) : helper)))
    + "\" class=\"ws10-auto-suggestion-item__wrapper ws10-auto-suggestion-item__link ws10-spacing-0\" tabindex=\"0\">\n        <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgImage") || (depth0 != null ? lookupProperty(depth0,"imgImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgImage","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":30}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdAltTag") || (depth0 != null ? lookupProperty(depth0,"stdAltTag") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdAltTag","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":50}}}) : helper)))
    + "\" class=\"ws10-auto-suggestion-item__image\">\n        <div class=\"ws10-auto-suggestion-item__text-wrapper\">\n            <div class=\"ws10-auto-suggestion-item__text\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtText") || (depth0 != null ? lookupProperty(depth0,"txtText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtText","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerColorSwatch") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </a>\n</div>\n";
},"usePartial":true,"useData":true});