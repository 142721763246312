/**
 * Attempts to call the given callback function.
 * If the call leads to an error being thrown,
 * a default value is returned.
 * @param callback The function to be called
 * @param defaultReturnValue The default return value which is used
 * if `callback` threw an error (optional).
 * @param errorHandler A function to handle the error (optional).
 * It is called with the error object if the callback threw an error.
 * If this function returns a value other than `undefined`,
 * this value will be used instad of `defaultReturnValue`.
 */
export var attempt = function (callback, defaultReturnValue, errorHandler) {
    var returnValue;
    try {
        returnValue = callback();
    }
    catch (error) {
        if (typeof errorHandler === 'function') {
            returnValue = errorHandler(error);
        }
        if (returnValue === undefined) {
            returnValue = defaultReturnValue;
        }
    }
    return returnValue;
};
