var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-discovery-card--full-width";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"ws10-discovery-card__item ws10-discovery-card__item--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optOrientation") || (depth0 != null ? lookupProperty(depth0,"optOrientation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optOrientation","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":87}}}) : helper)))
    + " ws10-discovery-card__item--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentAlignment") || (depth0 != null ? lookupProperty(depth0,"optContentAlignment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentAlignment","hash":{},"data":data,"loc":{"start":{"line":3,"column":115},"end":{"line":3,"column":138}}}) : helper)))
    + "\">\n        <div class=\"ws10-discovery-card__image-container\">\n            <picture class=\"ws10-discovery-card__picture\">\n                <source srcset=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSrcMobile") || (depth0 != null ? lookupProperty(depth0,"imgSrcMobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSrcMobile","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":50}}}) : helper)))
    + "\" media=\"(max-width: 599px)\">\n                <img class=\"ws10-discovery-card__image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSrcDesktop") || (depth0 != null ? lookupProperty(depth0,"imgSrcDesktop") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSrcDesktop","hash":{},"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":80}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdImgAlt") || (depth0 != null ? lookupProperty(depth0,"stdImgAlt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdImgAlt","hash":{},"data":data,"loc":{"start":{"line":7,"column":87},"end":{"line":7,"column":102}}}) : helper)))
    + "\"/>\n            </picture>\n        </div>\n        <div class=\"ws10-discovery-card__content-container\">\n            <div class=\"ws10-discovery-card__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contentHighlightBadge") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "                <h3 class=\"ws10-h3 ws10-discovery-card__headline\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":37}}}) : helper)))
    + "\n                </h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"txtContent") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":29,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"ws10-discovery-card__actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"buttonLinkItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-discovery-card__highlight-badge\">\n"
    + ((stack1 = container.invokePartial(require("../highlight-badge/_highlight-badge.hbs"),(depth0 != null ? lookupProperty(depth0,"contentHighlightBadge") : depth0),{"name":"_highlight-badge","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-text ws10-discovery-card__txt-content\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-discovery-card__promo-price\">\n"
    + ((stack1 = container.invokePartial(require("../promo-price/_promo-price.hbs"),(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"_promo-price","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonLinkItems") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":37,"column":25}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"ws10-discovery-card__action-button\">\n"
    + ((stack1 = container.invokePartial(require("../button-link/_button-link.hbs"),depth0,{"name":"_button-link","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-discovery-card"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optFullWidth") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":90}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"discoveryCardItems") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});