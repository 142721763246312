var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
/**
 * Toggles the `CLASSNAME_HIDDEN` class on the given elements
 * @param elements - Array of elements
 * @param shouldShow - Optional boolean to force show or hide the elements
 */
export var toggleElementsVisibility = function (elements, shouldShow) {
    var e_1, _a;
    try {
        for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
            var element = elements_1_1.value;
            toggleElementVisibility(element, shouldShow);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
/**
 * Toggles the `CLASSNAME_HIDDEN` class on the given element
 * @param element - The element
 * @param shouldShow - Optional boolean to force show or hide the elements
 */
export var toggleElementVisibility = function (element, shouldShow) {
    element.classList.toggle(CLASSNAME_HIDDEN, typeof shouldShow === 'boolean' ? !shouldShow : undefined);
};
/**
 * Toggles the `CLASSNAME_HIDDEN` class on the elements in the DOM with the given IDs
 * @param ids - Array of element IDs
 * @param shouldShow - Optional boolean to force show or hide the elements
 */
export var toggleElementsVisibilityById = function (ids, shouldShow) {
    var e_2, _a;
    try {
        for (var ids_1 = __values(ids), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
            var id = ids_1_1.value;
            toggleElementVisibilityById(id, shouldShow);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (ids_1_1 && !ids_1_1.done && (_a = ids_1.return)) _a.call(ids_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
};
/**
 * Toggles the `CLASSNAME_HIDDEN` class on the element in the DOM with the given ID
 * @param id - The ID
 * @param shouldShow - Optional boolean to force show or hide the elements
 */
export var toggleElementVisibilityById = function (id, shouldShow) {
    var element = document.getElementById(id);
    element && toggleElementVisibility(element, shouldShow);
};
