/** Base classname */
export var ITEM_SUMMARY_CARD_BASE_CLASSNAME = 'ws10-item-summary-card';
/** Item classname */
export var ITEM_SUMMARY_CARD_ITEM_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__item");
/** Items container classname */
export var ITEM_SUMMARY_CARD_ITEMS_CONTAINER_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__items-container");
/** System icon classname */
export var ITEM_SUMMARY_CARD_SYSTEM_ICON_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__system-icon");
/** Headline classname */
export var ITEM_SUMMARY_CARD_HEADLINE_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__headline");
/** Subline classname */
export var ITEM_SUMMARY_CARD_SUBLINE_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__subline");
/** Copytext classname */
export var ITEM_SUMMARY_CARD_COPYTEXT_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__copytext");
/** Textlink classname */
export var ITEM_SUMMARY_CARD_TEXTLINK_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__text-link");
/** Buttonlink classname */
export var ITEM_SUMMARY_CARD_BUTTONLINK_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__button-link");
/** Icon Button classname */
export var ITEM_SUMMARY_CARD_ICONBUTTON_CLASSNAME = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__icon-button");
/** Tooltip classname */
export var ITEM_SUMMARY_CARD_TOOLTIP = "".concat(ITEM_SUMMARY_CARD_BASE_CLASSNAME, "__tooltip");
/** Enum to define the variant */
export var OptVariant;
(function (OptVariant) {
    OptVariant["Standalone"] = "standalone";
    OptVariant["Nested"] = "nested";
    OptVariant["Transparent"] = "transparent";
})(OptVariant || (OptVariant = {}));
/**
 * Array of all variants
 */
export var ITEM_SUMMARY_CARD_VARIANTS = [
    OptVariant.Standalone,
    OptVariant.Nested,
    OptVariant.Transparent,
];
