var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
export var ifCond2Helper = function () {
    /**
     * Attention
     * Brix, jest and rollup expect slightly different exports
     * Therefor this helper exists three times:
     *
     * brix/helpers/ifCond.js
     * handlebars-helper/rollup/ifCond.js
     * handlebars-helper/jest/ifCond.js
     *
     * Make sure to do every change in every of these files
     *
     * Or do the right thing and remove duplicated code
     * https://jira.wf-de.vodafone.com/browse/BRIX-42
     */
    var e_1, _a, e_2, _b;
    /*eslint-disable */
    /**
     * Allow handlebar if conditions
     * https://stackoverflow.com/questions/8853396/logical-operator-in-a-handlebars-js-if-conditional
     */
    var args = Array.from(arguments);
    // Last element is always options
    var options = args.pop();
    // The number of arguments has to be uneven, otherwise there is no valid combination
    if (!(args.length % 2)) {
        throw new Error('The number of arguments is wrong.');
    }
    var comparisonOperators = [
        '===',
        '!==',
        '<',
        '<=',
        '>',
        '>=',
    ];
    var logicalOperators = [
        '&&',
        '||',
        'NOR',
    ];
    var operators = __spreadArray(__spreadArray([], __read(comparisonOperators), false), __read(logicalOperators), false);
    var compare = {};
    try {
        for (var _c = __values(args.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
            var _e = __read(_d.value, 2), index = _e[0], arg = _e[1];
            if (-1 !== operators.indexOf(arg)) {
                if (!compare[arg]) {
                    compare[arg] = [
                        args[index - 1],
                    ];
                }
                compare[arg].push(args[index + 1]);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (Object.keys(compare).length > 1) {
        throw new Error('The combination of different operators is not possible yet.');
    }
    if (operators.indexOf(Object.keys(compare)[0]) === -1) {
        throw new Error('This operator is not valid.');
    }
    try {
        for (var _f = __values(Object.entries(compare)), _g = _f.next(); !_g.done; _g = _f.next()) {
            var _h = __read(_g.value, 2), operator = _h[0], items = _h[1];
            if (comparisonOperators.indexOf(operator) !== -1 && items.length > 2) {
                throw new Error('You cannot compare more than two operators');
            }
            var _j = __read(items, 2), v1 = _j[0], v2 = _j[1];
            switch (operator) {
                case '===':
                    return (v1 === v2) ? options.fn(this) : options.inverse(this);
                case '!==':
                    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case '&&':
                    var filter1 = items.filter(function (item) { return !!item; });
                    return filter1.length === items.length ? options.fn(this) : options.inverse(this);
                case '||':
                    var filter2 = items.filter(function (item) { return checkVariable(item); });
                    return filter2.length >= 1 ? options.fn(this) : options.inverse(this);
                case 'NOR':
                    var filter3 = items.filter(function (item) { return checkVariable(item); });
                    return filter3.length === 0 ? options.fn(this) : options.inverse(this);
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
        }
        finally { if (e_2) throw e_2.error; }
    }
    /* eslint-enable */
    /* istanbul ignore next */
    return options.inverse(this);
};
var registerIfCond2Helper = function (Handlebars) {
    Handlebars.registerHelper('ifCond2', ifCond2Helper);
};
var checkVariable = function (value) {
    if (value !== null && typeof value === 'object') {
        return Object.keys(value).length > 0;
    }
    return !!value;
};
export default registerIfCond2Helper;
