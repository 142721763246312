/**
 * Base class name
 */
export var HYBRID_TABLE_BASE_CLASSNAME = 'ws10-hybrid-table';
/**
 * TABLE_TOOLTIP_CLASSNAME
 */
export var HYBRID_TABLE_TOOLTIP = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__tooltip");
/** text */
export var HYBRID_TABLE_TEXT = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__text");
/** icon */
export var HYBRID_TABLE_ICON = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__icon");
/** sort icon */
export var HYBRID_TABLE_SORT = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__sorting");
/** ascending modifier */
export var HYBRID_TABLE_SORT_ASCENDING_MODIFIER = "".concat(HYBRID_TABLE_SORT, "--ascending");
/** descending modifier */
export var HYBRID_TABLE_SORT_DESCENDING_MODIFIER = "".concat(HYBRID_TABLE_SORT, "--descending");
/** desktop row */
export var HYBRID_TABLE_DESKTOP_ROW = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__row-desktop");
/** mobile row */
export var HYBRID_TABLE_MOBILE_ROW = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__row-mobile");
/** accordions */
export var HYBRID_TABLE_ACCORDION = 'ws10-accordion-wrapper';
/** Paginate */
export var HYBRID_TABLE_PAGINATE = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__paginate");
/** Paginate show more */
export var HYBRID_TABLE_PAGINATE_SHOW_MORE = "".concat(HYBRID_TABLE_PAGINATE, "--show-more");
/** Paginate show less */
export var HYBRID_PAGINATE_SHOW_LESS = "".concat(HYBRID_TABLE_PAGINATE, "--show-less");
/** Paginate chunks */
export var HYBRID_TABLE_PAGINATE_CHUNKS = 12;
/** hidden */
export var HYBRID_TABLE_HIDDEN = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__hidden");
/** sorting options asc */
export var HYBRID_TABLE_SORT_ASC = 'ascending';
/** sorting desc */
export var HYBRID_TABLE_SORT_DESC = 'descending';
/** icon wrapper base class  */
export var HYBRID_TABLE_ICON_WRAPPER = "".concat(HYBRID_TABLE_BASE_CLASSNAME, "__m-100");
