var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdTooltipId") || (depth0 != null ? lookupProperty(depth0,"stdTooltipId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdTooltipId","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":26}}}) : helper)))
    + "\" class=\"ws10-tooltip\"\n    data-headline=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":2,"column":19},"end":{"line":2,"column":34}}}) : helper)))
    + "\"\n    data-content=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\"\n    data-alignement=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optAlignment") || (depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optAlignment","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":37}}}) : helper)))
    + "\"\n    data-size-variant=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdSizeVariant") || (depth0 != null ? lookupProperty(depth0,"stdSizeVariant") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSizeVariant","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":41}}}) : helper)))
    + "\"\n    >\n</span>\n";
},"useData":true});