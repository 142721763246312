import { BackgroundColors } from './IconTextBannerInterface';
/**
 * Base class name
 */
export var BASE_CLASSNAME = 'ws10-icon-text-banner';
/**
 * BackgroundColors: DarkRed
 */
export var BACKGROUND_COLOR_DARKRED = BackgroundColors.DarkRed;
/**
 * BackgroundColors: RedGradiant
 */
export var BACKGROUND_COLOR_REDGREDIANT = BackgroundColors.RedGradiant;
/**
 * BackgroundColors: LightGrey
 */
export var BACKGROUND_COLOR_LIGHTGRAY = BackgroundColors.LightGrey;
/**
 * BackgroundColors: white
 */
export var BACKGROUND_COLOR_WHITE = BackgroundColors.White;
/**
 * Color Variations
 */
export var BACKGROUND_COLOR = [
    BACKGROUND_COLOR_DARKRED,
    BACKGROUND_COLOR_REDGREDIANT,
    BACKGROUND_COLOR_LIGHTGRAY,
    BACKGROUND_COLOR_WHITE,
];
/**
 * ICON TEXT
 */
export var ICON_TEXT = "".concat(BASE_CLASSNAME, "__icon-text");
export var ICON_TEXT_BASIC = 'ws10-icon-text';
export var ICON_TEXT_INVERSE = 'ws10-icon-text--inverse';
/**
 * BUTTONS
 */
export var BUTTONS = "".concat(BASE_CLASSNAME, "__buttons");
/**
 * ICON BANNER CONTAINER
 */
export var ICON_BANNER_CONTAINER = "".concat(BASE_CLASSNAME, "__container");
/**
 * DARKRED BACKGROUND
 */
export var DARKRED_BACKGROUND = "".concat(BASE_CLASSNAME, "--darkRed");
/**
 * RED GREDIANT BACKGROUND
 */
export var RED_GREDIANT_BACKGROUND = "".concat(BASE_CLASSNAME, "--redGradiant");
/**
 * WHITE BACKGROUND
 */
export var WHITE_BACKGROUND = "".concat(BASE_CLASSNAME, "--white");
/**
 * ClassName for headline
 */
export var HEADLINE = "".concat(BASE_CLASSNAME, "__headline");
/**
 * ClassName for sub headline
 */
export var SUB_HEADLINE = "".concat(BASE_CLASSNAME, "__sub-headline");
/**
 * standard button
 */
export var BUTTON_SIZE_STANDARD = 'standard';
/**
 * button width
 */
export var BUTTON_WIDTH = 'hybrid';
/**
 * icon size
 */
export var ICON_SIZE_MEDIUM = 'medium';
/**
 * alignment left
 */
export var ICON_ALGNMENT_LEFT = 'left';
/**
 * RED GREDIANT BACKGROUND
 */
export var RED_GREDIANT = 'redGradiant';
/**
 * RED  BACKGROUND
 */
export var DARK_RED = 'darkRed';
