/**
 * Base class name
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME = 'ws10-form-element-block';
/**
 * Error classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_ERROR = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--error");
/**
 * Warning classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_WARN = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--warn");
/**
 * Success classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_SUCCESS = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--success");
/**
 * Label classname
 */
export var FORM_ELEMENT_BLOCK_LABEL_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__label-container");
/**
 * Input container classname
 */
export var FORM_ELEMENT_BLOCK_INPUT_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__input-container");
/**
 * Loading anmation container classname
 */
export var FORM_ELEMENT_BLOCK_LOADING_ANIMATION_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__loading-animation");
/**
 * Helper text classname
 */
export var FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__helper-text");
/**
 * Error message classname
 */
export var FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__error-message");
/**
 * In case optWarning is true we apply this class
 */
export var FORM_ELEMENT_BLOCK_SHOW_WARNING = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--show-warning");
/**
 * Type variations
 */
export var FORM_ELEMENT_BLOCK_TYPES = [
    'text-input', 'textarea', 'select',
];
