var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './linear-stepper.scss';
import renderTemplate from './_linear-stepper.hbs';
import { Pattern, isMobileViewport, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { LINEAR_STEPPER_ACTIVE_STEP_CLASSNAME, LINEAR_STEPPER_BASE_CLASSNAME, LINEAR_STEPPER_CENTERED_CLASSNAME, LINEAR_STEPPER_CONTAINER_CLASSNAME, LINEAR_STEPPER_CONTENT_COLUMNS_ATTR, LINEAR_STEPPER_INVERSE_CLASSNAME, LINEAR_STEPPER_STEPS_LENGTH_CLASSNAME, LINEAR_STEPPER_STEP_CLASSNAME, LINEAR_STEPPER_STEP_NUMBER_CLASSNAME, LINEAR_STEPPER_STEPS_CLASSNAME, MOBILE_THRESHOLD, LINEAR_STEPPER_FINISHED_STEP_CLASSNAME, LINEAR_STEPPER_ACTIVE_STEP_CIRCLE_CLASSNAME, LINEAR_STEPPER_ACTIVE_STEP_ATTR, LINEAR_STEPPER_STEP_BUTTON_ITEM_CLASSNAME, LINEAR_STEPPER_DYNAMIC_NEXT_BTN_DEFAULT_TEXT, LINEAR_STEPPER_DYNAMIC_PREV_BTN_DEFAULT_TEXT, LINEAR_STEPPER_DYNAMIC_PREV_BUTTON_CLASSNAME, LINEAR_STEPPER_DYNAMIC_NEXT_BUTTON_CLASSNAME, LINEAR_STEPPER_DYNAMIC_CONTENT_CLASSNAME, STEPS_CONTENT_ID_PREFIX, LINEAR_STEPPER_STATIC_STEP_LINK_HREF_ATTR, LINEAR_STEPPER_DYNAMIC_NEXT_BTN_TEXT_ATTR, LINEAR_STEPPER_DYNAMIC_PREV_BTN_TEXT_ATTR, STATIC_KEYS, LINEAR_STEPPER_STEP_STATIC_CLASSNAME, LINEAR_STEPPER_STEP_DYNAMIC_CLASSNAME, LINEAR_STEPPER_DYNAMIC_CONTENT_CONTAINER_CLASSNAME, LINEAR_STEPPER_FOOTNOTE_STEP_TEXT_CLASSNAME, LINEAR_STEPPER_FOOTNOTE_STEP_CLASSNAME, LINEAR_STEPPER_FOOTNOTE_TOTAL_CLASSNAME, LINEAR_STEPPER_FOOTNOTE_TOTAL_TEXT_CLASSNAME, LINEAR_STEPPER_DYNAMIC_BUTTONS_CLASSNAME, LINEAR_STEPPER_DYNAMIC_BTNS_WITH_PREV_CLASSNAME, } from './Constants';
import { BUTTON_COLOR_PRIMARY, BUTTON_COLOR_TERTIARY, BUTTON_SIZE_STANDARD, BUTTON_WIDTH_VARIATION_HYBRID, createButton, } from '../button';
/**
 * LinearStepper
 */
var LinearStepper = /** @class */ (function (_super) {
    __extends(LinearStepper, _super);
    function LinearStepper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Init Dom Elements to be used across the component
     */
    LinearStepper.prototype.initDomElements = function () {
        var _a;
        this.stepButtons =
            __spreadArray([], __read(this.containerElement.getElementsByClassName(LINEAR_STEPPER_STEP_BUTTON_ITEM_CLASSNAME)), false);
        this.steps = __spreadArray([], __read(this.containerElement.getElementsByClassName(LINEAR_STEPPER_STEP_CLASSNAME)), false);
        this.stepperElement = this.containerElement.getElementsByClassName(LINEAR_STEPPER_BASE_CLASSNAME)[0];
        this.stepperContainer = this.containerElement.getElementsByClassName(LINEAR_STEPPER_STEPS_CLASSNAME)[0];
        this.currentStepNubmer =
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_STEP_NUMBER_CLASSNAME)[0];
        this.stepsTotalLength =
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_STEPS_LENGTH_CLASSNAME)[0];
        // init Dom elements for dynamic stepper
        this.dynamicStepsContent =
            __spreadArray([], __read(this.containerElement.getElementsByClassName(LINEAR_STEPPER_DYNAMIC_CONTENT_CLASSNAME)), false);
        this.dynamicButtonsContainer =
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_DYNAMIC_BUTTONS_CLASSNAME)[0];
        var hasDynamicPrevButton = (_a = this.dynamicButtonsContainer) === null || _a === void 0 ? void 0 : _a.classList.contains(LINEAR_STEPPER_DYNAMIC_BTNS_WITH_PREV_CLASSNAME);
        this.dynamicNextButtonContainer =
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_DYNAMIC_NEXT_BUTTON_CLASSNAME)[0];
        if (this.dynamicNextButtonContainer) {
            var stdDynamicStepperNextBtnText = this.dynamicNextButtonContainer.getAttribute(LINEAR_STEPPER_DYNAMIC_NEXT_BTN_TEXT_ATTR);
            this.dynamicNextButton = createButton(this.dynamicNextButtonContainer, this.getDynamicNextButtonProperties(stdDynamicStepperNextBtnText));
        }
        hasDynamicPrevButton && this.insertDynamicPrevButton();
        this.initActiveStep();
    };
    /**
     * Set active as sent in interface in property optActiveStep if exist.
     * If not exist init first step as default selected step
     */
    LinearStepper.prototype.initActiveStep = function () {
        var activeStep = parseInt(this.stepperContainer.getAttribute(LINEAR_STEPPER_ACTIVE_STEP_ATTR), 10);
        if (this.steps.length > 0) {
            this.currentStep = (activeStep && activeStep >= 1 && activeStep <= this.steps.length) ? activeStep : 1;
            this.updateStep();
            this.currentStepNubmer ? this.currentStepNubmer.textContent = this.currentStep.toString() : null;
            this.stepsTotalLength ? this.stepsTotalLength.textContent = this.steps.length.toString() : null;
        }
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    LinearStepper.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    LinearStepper.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * Add event listeners to DOM elements
     */
    LinearStepper.prototype.initEvents = function () {
        var _this = this;
        this.stepButtons.forEach(function (stepBtn, index) {
            stepBtn.addEventListener('click', function (event) {
                if (index < _this.currentStep - 1) {
                    _this.activateStep(index, event);
                }
            });
            stepBtn.addEventListener('keydown', function (event) {
                if (index < _this.currentStep - 1) {
                    if (event && event.key === STATIC_KEYS.enter) {
                        _this.activateStep(index, event);
                    }
                }
            });
        });
        window.addEventListener('resize', function () {
            var _a;
            var hasDynamicPrevButton = (_a = _this.dynamicButtonsContainer) === null || _a === void 0 ? void 0 : _a.classList.contains(LINEAR_STEPPER_DYNAMIC_BTNS_WITH_PREV_CLASSNAME);
            hasDynamicPrevButton && _this.insertDynamicPrevButton();
            _this.showPrevAndNextStepOfActiveStep(_this.stepButtons[_this.currentStep - 1]);
        });
    };
    /**
     * Insert dynamic previous button before next button in desktop view
     * Insert dynamic previous button after next button in mobile view
     * done it this way to make the tab take the same order like order the buttons displayed in screen
     * so previous button is tabbed first in desktop view and tabbed second in mobile view
     */
    LinearStepper.prototype.insertDynamicPrevButton = function () {
        // Check if the prev button already exists in the buttonscontainer
        if (!this.dynamicPrevButtonContainer) {
            this.dynamicPrevButtonContainer = document.createElement('div');
            this.dynamicPrevButtonContainer.classList.add(LINEAR_STEPPER_DYNAMIC_PREV_BUTTON_CLASSNAME);
            var stdDynamicStepperPrevBtnText = this.dynamicButtonsContainer.getAttribute(LINEAR_STEPPER_DYNAMIC_PREV_BTN_TEXT_ATTR);
            createButton(this.dynamicPrevButtonContainer, this.getDynamicPrevButtonProperties(stdDynamicStepperPrevBtnText));
        }
        if (!isMobileViewport()) {
            // Desktop view: Insert prev button before the next button if not already inserted
            if (!this.dynamicNextButtonContainer.previousElementSibling ||
                this.dynamicNextButtonContainer.previousElementSibling !== this.dynamicPrevButtonContainer) {
                this.dynamicButtonsContainer.insertBefore(this.dynamicPrevButtonContainer, this.dynamicNextButtonContainer);
            }
        }
        // Mobile view: Insert prev button after the next button if not already inserted
        else if (isMobileViewport() &&
            (!this.dynamicNextButtonContainer.nextElementSibling ||
                this.dynamicNextButtonContainer.nextElementSibling !== this.dynamicPrevButtonContainer)) {
            this.dynamicNextButtonContainer.insertAdjacentElement('afterend', this.dynamicPrevButtonContainer);
        }
    };
    /**
     * Activate current step and execute onclick event sent from the business logic
     */
    LinearStepper.prototype.activateStep = function (index, event) {
        var _a, _b;
        this.currentStep = index + 1;
        this.updateStep();
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onStepClick) {
            this.properties.business.onStepClick(event, this.currentStep);
        }
    };
    /**
     * Handle Click with href and index for static stepper
     */
    LinearStepper.prototype.handleClick = function (element, index) {
        var _this = this;
        var linkHref = this.steps.map(function (step) { return step.getAttribute(LINEAR_STEPPER_STATIC_STEP_LINK_HREF_ATTR); });
        element.onclick = function () { return _this.openLinkHref(linkHref[index]); };
        element.onkeydown = function (event) {
            if (event.key === STATIC_KEYS.enter) {
                _this.openLinkHref(linkHref[index]);
            }
        };
    };
    /**
     * Open link in the same tab when click or press enter when navigate through tab
     * on the step that has link in static stepper
     */
    LinearStepper.prototype.openLinkHref = function (linkHref) {
        var link = document.createElement('a');
        link.href = linkHref;
        link.click();
    };
    /**
     * Add Link To Finished steps in case of static linear stepper
     */
    LinearStepper.prototype.addLinkToButton = function () {
        var _this = this;
        var finishedButtonsContainer = __spreadArray([], __read(this.containerElement.getElementsByClassName(LINEAR_STEPPER_FINISHED_STEP_CLASSNAME)), false);
        var finishedButtons = finishedButtonsContainer.map(function (ele) {
            return ele.getElementsByClassName(LINEAR_STEPPER_STEP_BUTTON_ITEM_CLASSNAME)[0];
        });
        var linkHref = this.steps.map(function (step) { return step.getAttribute(LINEAR_STEPPER_STATIC_STEP_LINK_HREF_ATTR); });
        if (finishedButtons) {
            finishedButtons.map(function (element, index) {
                if (linkHref[index]) {
                    _this.handleClick(element, index);
                }
                else {
                    element.disabled = true;
                }
            });
        }
    };
    /**
     * Move stepper to next step
     * Return current step number so we can use it to implement any logic we need
     */
    LinearStepper.prototype.nextStep = function () {
        var _a, _b;
        if (this.currentStep < this.steps.length) {
            this.currentStep++;
            this.updateStep();
            if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onStepNext) {
                this.properties.business.onStepNext(this.currentStep);
            }
            return this.currentStep;
        }
    };
    /**
     * Move stepper to prev step
     * Return current step number so we can use it to implement any logic we need
     */
    LinearStepper.prototype.prevStep = function () {
        var _a, _b;
        if (this.currentStep > 1) {
            this.currentStep--;
            this.updateStep();
            if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onStepPrev) {
                this.properties.business.onStepPrev(this.currentStep);
            }
            return this.currentStep;
        }
    };
    /**
     * Scroll into view for prev , next and active step
     * Always show active step and one prev and one next to it
     * Check if we are in x small to small screens as overflow happens only in them
     */
    LinearStepper.prototype.showPrevAndNextStepOfActiveStep = function (step) {
        var mobileThreshold = MOBILE_THRESHOLD;
        var currentWidth = window.innerWidth;
        if (currentWidth < mobileThreshold) {
            var stepperRect = this.stepperContainer.getBoundingClientRect();
            var activeStepRect = step.getBoundingClientRect();
            // Calculate the left and right boundaries for the visible area
            var leftBoundary = stepperRect.left + activeStepRect.width;
            var rightBoundary = stepperRect.right - activeStepRect.width;
            // Scroll the stepper container if the active step is not fully visible
            if (activeStepRect.left < leftBoundary) {
                this.stepperContainer.scrollLeft += activeStepRect.left - leftBoundary;
            }
            else if (activeStepRect.right > rightBoundary) {
                this.stepperContainer.scrollLeft += activeStepRect.right - rightBoundary;
            }
        }
    };
    /**
     * Update each step when click on it to update (add or remove) active, finished, active circle classes
     * Update step number in footnote if exists
     * Update step button to be disabled for current and upcoming steps (only done steps should be clickable and tabbable)
     */
    LinearStepper.prototype.updateStep = function () {
        var _this = this;
        this.steps.forEach(function (step, index) {
            step.classList["".concat((index + 1) === _this.currentStep ? STATIC_KEYS.add : STATIC_KEYS.remove)](LINEAR_STEPPER_ACTIVE_STEP_CIRCLE_CLASSNAME);
            step.classList["".concat((index + 1) < _this.currentStep ? STATIC_KEYS.add : STATIC_KEYS.remove)](LINEAR_STEPPER_FINISHED_STEP_CLASSNAME);
            step.classList["".concat(index < _this.currentStep ? STATIC_KEYS.add : STATIC_KEYS.remove)](LINEAR_STEPPER_ACTIVE_STEP_CLASSNAME);
        });
        this.currentStepNubmer ? this.currentStepNubmer.textContent = this.currentStep.toString() : null;
        this.stepButtons.forEach(function (stepBtn, index) {
            stepBtn.disabled = index + 1 >= _this.currentStep;
        });
        this.showPrevAndNextStepOfActiveStep(this.stepButtons[this.currentStep - 1]);
        var dynamicSteps = this.stepperContainer.classList.contains(LINEAR_STEPPER_STEP_DYNAMIC_CLASSNAME);
        if (dynamicSteps) {
            this.handleStepContentForDynamicStepper();
            this.hideShowPrevNextBtnsForDynamicStepper();
        }
        else {
            this.addLinkToButton();
        }
    };
    /**
     * Hide prev button in dynamic stepper in first step
     * Hide next button in dynamic stepper in last step
     */
    LinearStepper.prototype.hideShowPrevNextBtnsForDynamicStepper = function () {
        if (this.dynamicNextButtonContainer) {
            this.dynamicNextButtonContainer.style.display = (this.currentStep === this.steps.length) ? STATIC_KEYS.none : STATIC_KEYS.block;
            this.dynamicNextButtonContainer.style.marginBottom =
                (this.currentStep === 1 || !this.dynamicPrevButtonContainer) ? '0' : STATIC_KEYS.space100;
        }
        if (this.dynamicPrevButtonContainer) {
            this.dynamicPrevButtonContainer.style.display = (this.currentStep === 1) ? STATIC_KEYS.none : STATIC_KEYS.block;
            this.dynamicPrevButtonContainer.parentElement.style.justifyContent
                = (this.currentStep === 1) ? STATIC_KEYS.flexEnd : STATIC_KEYS.flexSpaceBetween;
        }
    };
    /**
     * Get next button properties for dynamic linear stepper
     */
    LinearStepper.prototype.getDynamicNextButtonProperties = function (stdDynamicStepperNextBtnText) {
        return this.getDynamicButtonsProperties(this.nextStep.bind(this), stdDynamicStepperNextBtnText ? stdDynamicStepperNextBtnText
            : LINEAR_STEPPER_DYNAMIC_NEXT_BTN_DEFAULT_TEXT, BUTTON_COLOR_PRIMARY);
    };
    /**
     * Get previous button properties for dynamic linear stepper
     */
    LinearStepper.prototype.getDynamicPrevButtonProperties = function (stdDynamicStepperPrevBtnText) {
        return this.getDynamicButtonsProperties(this.prevStep.bind(this), stdDynamicStepperPrevBtnText ? stdDynamicStepperPrevBtnText :
            LINEAR_STEPPER_DYNAMIC_PREV_BTN_DEFAULT_TEXT, BUTTON_COLOR_TERTIARY);
    };
    /**
     * Get previous and next buttons properties for dynamic linear stepper
     */
    LinearStepper.prototype.getDynamicButtonsProperties = function (onClickFun, stdContent, optColor) {
        var business = {
            onClick: function () { return onClickFun(); },
        };
        var buttonProps = {
            stdContent: stdContent,
            optColor: optColor,
            optSize: BUTTON_SIZE_STANDARD,
            optWidthVariation: BUTTON_WIDTH_VARIATION_HYBRID,
            business: business,
        };
        return buttonProps;
    };
    /**
     * Handle each step content for dynamic stepper to show content related to each step and hide other content
     */
    LinearStepper.prototype.handleStepContentForDynamicStepper = function () {
        var stepId = this.steps[this.currentStep - 1].id;
        this.dynamicStepsContent.forEach(function (content) {
            if (content) {
                content.style.display = content.id === (STEPS_CONTENT_ID_PREFIX + stepId) ? STATIC_KEYS.block : STATIC_KEYS.none;
            }
        });
    };
    /**
     * Function to remove empty linkHref from staticStepperSteps item that doesnot have a link
     * @param staticStepperSteps {IStaticStepItem[]}
     * @returns static stepperSteps
     */
    LinearStepper.prototype.filterLinkHref = function (staticStepperSteps) {
        return staticStepperSteps.map(function (step) {
            if (!step.linkHref) {
                delete step.linkHref;
            }
            return step;
        });
    };
    /**
     * Disable dynamic stepper next button
     */
    LinearStepper.prototype.disableNextButton = function (disable) {
        this.dynamicNextButton.update({ optDisabled: disable });
    };
    /**
     * Read dom and initialize linear stepper properties.
     */
    LinearStepper.prototype.readDom = function (linearStepperBusinessLogic) {
        var _a, _b;
        this.initDomElements();
        var optActiveStep = parseInt(this.stepperContainer.getAttribute(LINEAR_STEPPER_ACTIVE_STEP_ATTR), 10) || undefined;
        var optFootnote = !!this.currentStepNubmer;
        var optInverse = this.stepperElement.classList.contains(LINEAR_STEPPER_INVERSE_CLASSNAME);
        var optCentered = this.stepperElement.classList.contains(LINEAR_STEPPER_CENTERED_CLASSNAME);
        var contentContainerElement = this.stepperElement.getElementsByClassName(LINEAR_STEPPER_CONTAINER_CLASSNAME)[0];
        var optContentColumns = parseInt(contentContainerElement.getAttribute(LINEAR_STEPPER_CONTENT_COLUMNS_ATTR), 10) || undefined;
        var hasFootnoteStepTextProperty = (_a = this.containerElement.getElementsByClassName(LINEAR_STEPPER_FOOTNOTE_STEP_CLASSNAME)[0]) === null || _a === void 0 ? void 0 : _a.classList.contains(LINEAR_STEPPER_FOOTNOTE_STEP_TEXT_CLASSNAME);
        var hasFootnoteStepTotalProperty = (_b = this.containerElement.getElementsByClassName(LINEAR_STEPPER_FOOTNOTE_TOTAL_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.classList.contains(LINEAR_STEPPER_FOOTNOTE_TOTAL_TEXT_CLASSNAME);
        var stdFootnoteStepText = hasFootnoteStepTextProperty ?
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_FOOTNOTE_STEP_CLASSNAME)[0].textContent.trim() : undefined;
        var stdFootnoteStepTotalText = hasFootnoteStepTotalProperty ?
            this.containerElement.getElementsByClassName(LINEAR_STEPPER_FOOTNOTE_TOTAL_CLASSNAME)[0].textContent.trim() : undefined;
        var dynamicNextButtonContainer = this.containerElement.getElementsByClassName(LINEAR_STEPPER_DYNAMIC_NEXT_BUTTON_CLASSNAME)[0];
        var dynamicPrevButtonContainer = this.containerElement.getElementsByClassName(LINEAR_STEPPER_DYNAMIC_BUTTONS_CLASSNAME)[0];
        var stdDynamicStepperNextBtnText = (dynamicNextButtonContainer === null || dynamicNextButtonContainer === void 0 ? void 0 : dynamicNextButtonContainer.getAttribute(LINEAR_STEPPER_DYNAMIC_NEXT_BTN_TEXT_ATTR)) || undefined;
        var stdDynamicStepperPrevBtnText = (dynamicPrevButtonContainer === null || dynamicPrevButtonContainer === void 0 ? void 0 : dynamicPrevButtonContainer.getAttribute(LINEAR_STEPPER_DYNAMIC_PREV_BTN_TEXT_ATTR)) || undefined;
        var optDynamicPrevButton = dynamicPrevButtonContainer === null || dynamicPrevButtonContainer === void 0 ? void 0 : dynamicPrevButtonContainer.classList.contains(LINEAR_STEPPER_DYNAMIC_BTNS_WITH_PREV_CLASSNAME);
        var staticSteps = this.stepperContainer.classList.contains(LINEAR_STEPPER_STEP_STATIC_CLASSNAME);
        var dynamicSteps = this.stepperContainer.classList.contains(LINEAR_STEPPER_STEP_DYNAMIC_CLASSNAME);
        var dynamicContentsContainer = this.containerElement
            .getElementsByClassName(LINEAR_STEPPER_DYNAMIC_CONTENT_CONTAINER_CLASSNAME)[0];
        var stepperSteps;
        if (staticSteps) {
            var staticStepperStepsItems = this.steps
                .map(function (stepContainer) { return ({
                stdTextLabel: stepContainer.getElementsByTagName('p')[0].innerHTML.trim(),
                linkHref: stepContainer.getAttribute(LINEAR_STEPPER_STATIC_STEP_LINK_HREF_ATTR),
                stdId: stepContainer.id,
            }); });
            stepperSteps = this.filterLinkHref(staticStepperStepsItems);
        }
        if (dynamicSteps) {
            var dynamicStepperSteps = this.steps
                .map(function (stepContainer) { return ({
                stdTextLabel: stepContainer.getElementsByTagName('p')[0].innerHTML.trim(),
                stdId: stepContainer.id,
                containerAnyComponent: dynamicContentsContainer
                    .querySelector("[data-for=\"".concat(stepContainer.id, "\"]")).innerHTML.replace(/\s+/g, ' ')
                    .trim(),
            }); });
            stepperSteps = dynamicStepperSteps;
        }
        var properties = removeUndefinedFieldsFromObject({
            stepperSteps: stepperSteps,
            optDynamicStepperSteps: dynamicSteps,
            optFootnote: optFootnote,
            optInverse: optInverse,
            optCentered: optCentered,
            optActiveStep: optActiveStep,
            optContentColumns: optContentColumns,
            optDynamicPrevButton: optDynamicPrevButton,
            stdDynamicStepperNextBtnText: stdDynamicStepperNextBtnText,
            stdDynamicStepperPrevBtnText: stdDynamicStepperPrevBtnText,
            stdFootnoteStepText: stdFootnoteStepText,
            stdFootnoteStepTotalText: stdFootnoteStepTotalText,
            business: linearStepperBusinessLogic,
        });
        return properties;
    };
    return LinearStepper;
}(Pattern));
export { LinearStepper };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of LinearStepper
 */
export var createLinearStepper = function (containerElement, businessLogicOrProperties) {
    var linearStepper = new LinearStepper(containerElement, businessLogicOrProperties);
    linearStepper.init();
    return linearStepper;
};
