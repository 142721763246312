var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './notification-icon.scss';
import renderTemplate from './_notification-icon.hbs';
import { Pattern, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { NOTIFICATION_ICON_STATES } from './Constants';
/**
 * WS10 SystemIcon
 */
var NotificationIcon = /** @class */ (function (_super) {
    __extends(NotificationIcon, _super);
    function NotificationIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    NotificationIcon.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    NotificationIcon.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    NotificationIcon.prototype.readDom = function (business) {
        var svg = this.containerElement.getElementsByTagName('svg')[0];
        var className = __spreadArray([], __read(svg.classList), false).join(' ');
        var map = {
            optState: NOTIFICATION_ICON_STATES,
        };
        var optState = getPropertiesFromClassName(className, map).optState;
        return {
            optState: optState,
            business: business,
        };
    };
    return NotificationIcon;
}(Pattern));
export { NotificationIcon };
/**
 * This function returns an instance of Icon
 */
export var createNotificationIcon = function (containerElement, properties) {
    var notificationIcon = new NotificationIcon(containerElement, properties);
    notificationIcon.init();
    return notificationIcon;
};
