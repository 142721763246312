import { SPACING_VARIANTS } from '@vfde-brix/ws10/styles';
/**
 * spacing css class prefix
 */
export var SPACING_PREFIX = 'ws10-spacing-';
/**
 * spacingSearch RegEx
 */
export var spacingSearch = function () { return new RegExp("".concat(SPACING_PREFIX, "(\\S*)"), 'gm'); };
/**
 * Get Spacing from classname
 */
export var getContainerSpacing = function (className) {
    var match = className.match(spacingSearch());
    return match ? getFirstValidSpacing(match) : undefined;
};
var getFirstValidSpacing = function (spacings) {
    var i = spacings.length - 1;
    for (; i >= 0; i--) {
        var spacing = spacings[i].replace(SPACING_PREFIX, '');
        if (SPACING_VARIANTS.indexOf(spacing) !== -1) {
            return spacing;
        }
    }
    return undefined;
};
