export var leadingZeroHelper = function (value) {
    /**
     * Attention
     * Brix, jest and rollup expect slightly different exports
     * Therefore this helper exists three times:
     *
     * brix/helpers/leadingZero.js
     * handlebars-helper/rollup/leadingZero.js
     * handlebars-helper/jest/leadingZero.js
     *
     * Make sure to do every change in every of these files
     *
     * Or do the right thing and remove duplicated code
     * https://jira.wf-de.vodafone.com/browse/BRIX-42
     */
    /*eslint-disable */
    /**
     * Allow handlebar limit to each
     */
    value = parseInt(value, 10);
    return (10 <= value ? "".concat(value) : "0".concat(value));
};
var registerLeadingZeroHelper = function (Handlebars) {
    Handlebars.registerHelper('leadingZero', leadingZeroHelper);
};
export default registerLeadingZeroHelper;
