var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-select__option--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":116}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":1,"column":195},"end":{"line":1,"column":207}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<option class=\"ws10-form-select__option js-form-select-option"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":123}}})) != null ? stack1 : "")
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdOptionValue") || (depth0 != null ? lookupProperty(depth0,"stdOptionValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdOptionValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":132},"end":{"line":1,"column":150}}}) : helper)))
    + "\" label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdOptionLabel") || (depth0 != null ? lookupProperty(depth0,"stdOptionLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdOptionLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":159},"end":{"line":1,"column":177}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":178},"end":{"line":1,"column":214}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdOptionLabel") || (depth0 != null ? lookupProperty(depth0,"stdOptionLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdOptionLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":215},"end":{"line":1,"column":233}}}) : helper)))
    + "</option>\n\n";
},"useData":true});