var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import renderTemplate from './_interaction-list-item.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
import { ACTION_ICON_CLASSNAME, INTERACTION_LIST_ITEM_CLASSNAME, LABEL_CLASSNAME, LABEL_ICON_CLASSNAME, } from './Constants';
/**
 * Interaction List Item component.
 * This component can work as a stand-alone or be part of the Interaction List Block.
 */
var InteractionListItem = /** @class */ (function (_super) {
    __extends(InteractionListItem, _super);
    function InteractionListItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    InteractionListItem.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     */
    InteractionListItem.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    InteractionListItem.prototype.initEvents = function () {
        var _this = this;
        var interactionItem = this.containerElement.getElementsByClassName(INTERACTION_LIST_ITEM_CLASSNAME)[0];
        interactionItem.addEventListener('click', function () { return _this.properties.business.onClick; });
    };
    /**
     * getting properties from DOM
     */
    InteractionListItem.prototype.readDom = function (interactionListItemBusinessLogic) {
        var itemElement = this.containerElement.getElementsByClassName(INTERACTION_LIST_ITEM_CLASSNAME)[0];
        var labelIcon = itemElement.getElementsByClassName(LABEL_ICON_CLASSNAME)[0];
        var stdLabelIcon;
        /* No need for else branch test*/
        /* istanbul ignore else */
        if (labelIcon) {
            stdLabelIcon = getSystemIconName(labelIcon);
        }
        var stdLabel = itemElement.getElementsByClassName(LABEL_CLASSNAME)[0].innerHTML.trim();
        var linkHref = itemElement.getAttribute('href');
        var optTarget = itemElement.getAttribute('target');
        var optActionIcon = getSystemIconName(itemElement.getElementsByClassName(ACTION_ICON_CLASSNAME)[0]);
        return removeUndefinedFieldsFromObject({
            business: interactionListItemBusinessLogic,
            stdLabelIcon: stdLabelIcon,
            stdLabel: stdLabel,
            linkHref: linkHref,
            optTarget: optTarget,
            optActionIcon: optActionIcon,
        });
    };
    return InteractionListItem;
}(Pattern));
export { InteractionListItem };
/**
 * This function returns an instance of InteractionListItem
 */
export var createInteractionListItem = function (containerElement, businessLogicOrProperties) {
    var interactionListItem = new InteractionListItem(containerElement, businessLogicOrProperties);
    interactionListItem.init();
    return interactionListItem;
};
