var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './video.scss';
import renderTemplate from './_video.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
/**
 * For the iPhone Launch we needed a VideoText
 *
 * It is prohibited to use this pattern unless there is a spec.
 * Only exception is in Apple Context.
 *
 */
var Video = /** @class */ (function (_super) {
    __extends(Video, _super);
    function Video() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Video.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    Video.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Get Properties from DOM
     */
    Video.prototype.readDom = function (business) {
        var videoElement = this.containerElement.getElementsByTagName('video')[0];
        var imgPreviewSrc = videoElement.getAttribute('poster') || undefined;
        var sourceElement = this.containerElement.getElementsByTagName('source')[0];
        var mediaSrc = sourceElement.getAttribute('src');
        var optPreload = videoElement.getAttribute('preload');
        var properties = {
            mediaType: 'video',
            mediaSrc: mediaSrc,
            imgPreviewSrc: imgPreviewSrc,
            optPreload: optPreload,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return Video;
}(Pattern));
export { Video };
/**
 * This function returns an instance of VideoText
 */
export var createVideo = function (containerElement, businessLogicOrProperties) {
    var video = new Video(containerElement, businessLogicOrProperties);
    video.init();
    return video;
};
