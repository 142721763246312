/**
 * At least form-textarea, form-input-text and form-select share the very same handlers
 */
export var addFormElementEventHandlers = function (properties, formElement) {
    var getValue = function () {
        var stdValue = formElement.value.trim();
        properties.stdValue = stdValue;
    };
    if (properties.business.onBlur) {
        formElement.addEventListener('blur', function (event) {
            getValue();
            properties.business.onBlur(event, properties.stdValue);
        });
    }
    if (properties.business.onFocus) {
        formElement.addEventListener('focus', function (event) {
            getValue();
            properties.business.onFocus(event, properties.stdValue);
        });
    }
    if (properties.business.onInput) {
        formElement.addEventListener('input', function (event) {
            getValue();
            properties.business.onInput(event, properties.stdValue);
        });
    }
    if (properties.business.onKeyPress) {
        formElement.addEventListener('keypress', function (event) {
            getValue();
            properties.business.onKeyPress(event, properties.stdValue);
        });
    }
    if (properties.business.onKeyDown) {
        formElement.addEventListener('keydown', function (event) {
            getValue();
            properties.business.onKeyDown(event, properties.stdValue);
        });
    }
    // when input is blurred, our business should know
    formElement.addEventListener('change', function (event) {
        getValue();
        properties.business.onChange && properties.business.onChange(event, properties.stdValue);
    });
};
/**
 * At least form-textarea and form-input-text share this very same behavior
 */
export var elementRemoveStateOnDownEventHandler = function (properties, element, formElementClassName) {
    /* istanbul ignore else */
    if (properties.optState) {
        element.classList.remove("".concat(formElementClassName, "--error"));
        element.classList.remove("".concat(formElementClassName, "--warn"));
        element.classList.remove("".concat(formElementClassName, "--success"));
        properties.optState = undefined;
    }
};
