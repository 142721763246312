var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ACCORDION_PARAM_NAME, ACCORDION_PARAM_VALUE_SEPARATOR, } from './Constants';
/**
 * AccordionParamService
 */
var AccordionService = /** @class */ (function () {
    function AccordionService() {
    }
    /**
     * Returns the indexes of the accordionItem whose IDs are set as value of the URL param.
     */
    AccordionService.prototype.getAccordionItemIndexByUrlParam = function (properties) {
        var e_1, _a;
        var items = properties.items;
        var accordionItemIdsFromUrl = this.getAccordionItemIdsFromUrl();
        var indexes = [];
        try {
            for (var _b = __values(items.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), index = _d[0], item = _d[1];
                if (accordionItemIdsFromUrl.includes(item.stdId)) {
                    indexes.push(index);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return indexes;
    };
    /**
     * Toggles the 'accordion' URL param in the url according to  'open' state of the the toggled item
     */
    AccordionService.prototype.updateUrl = function (accordionItemId, isOpen, openIndividually) {
        var accordionItemIdsFromUrl = openIndividually ? [] : this.getAccordionItemIdsFromUrl();
        var idIndex = accordionItemIdsFromUrl.indexOf(accordionItemId);
        if (isOpen) {
            if (idIndex === -1) {
                // add ID to param value
                accordionItemIdsFromUrl.push(accordionItemId);
            }
        }
        else if (idIndex !== -1) {
            // remove ID from param value
            accordionItemIdsFromUrl.splice(idIndex, 1);
        }
        // update search params with new param value
        var urlSearchParams = new URLSearchParams(window.location.search);
        if (accordionItemIdsFromUrl.length) {
            urlSearchParams.set(ACCORDION_PARAM_NAME, accordionItemIdsFromUrl.join(ACCORDION_PARAM_VALUE_SEPARATOR));
        }
        else {
            urlSearchParams.delete(ACCORDION_PARAM_NAME);
        }
        var queryString = urlSearchParams.toString();
        // construct the new URL with pathname and queryString if there is one
        var newUrl = "".concat(window.location.pathname).concat(queryString ? "?".concat(queryString) : '');
        // update browser URL
        window.history.replaceState({}, '', newUrl);
    };
    /**
     * Returns all item IDs which are set as URL params as an array.
     */
    AccordionService.prototype.getAccordionItemIdsFromUrl = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var paramValue = urlParams.get(ACCORDION_PARAM_NAME);
        if (!paramValue) {
            return [];
        }
        return paramValue.split(ACCORDION_PARAM_VALUE_SEPARATOR);
    };
    return AccordionService;
}());
export default AccordionService;
