var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_table.hbs';
import './table.scss';
import { Pattern, } from '@vfde-brix/ws10/core';
import { TABLE_CAPTION_CLASSNAME, TABLE_CELL_CLASSNAME, TABLE_HEADER_CLASSNAME, TABLE_ROW_CLASSNAME, } from './Constants';
/**
 * Table pattern
 */
var Table = /** @class */ (function (_super) {
    __extends(Table, _super);
    function Table() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Table.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * ll rendering gets done with this function.
     */
    Table.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * this gets the properties from the DOM elements
     */
    Table.prototype.readDom = function (tableBusinessLogic) {
        var e_1, _a;
        var captionElement = this.containerElement.getElementsByClassName(TABLE_CAPTION_CLASSNAME)[0];
        var stdCaption = captionElement === null || captionElement === void 0 ? void 0 : captionElement.innerHTML.trim();
        var properties = {
            stdCaption: stdCaption,
            listRow: [],
            business: tableBusinessLogic,
        };
        var tableRowElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(TABLE_ROW_CLASSNAME)), false);
        try {
            for (var tableRowElements_1 = __values(tableRowElements), tableRowElements_1_1 = tableRowElements_1.next(); !tableRowElements_1_1.done; tableRowElements_1_1 = tableRowElements_1.next()) {
                var tableRowElement = tableRowElements_1_1.value;
                var stdLabel = tableRowElement.getElementsByClassName(TABLE_HEADER_CLASSNAME)[0].textContent.trim();
                var stdText = tableRowElement.getElementsByClassName(TABLE_CELL_CLASSNAME)[1].textContent.trim();
                properties.listRow.push({
                    stdLabel: stdLabel,
                    stdText: stdText,
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tableRowElements_1_1 && !tableRowElements_1_1.done && (_a = tableRowElements_1.return)) _a.call(tableRowElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    return Table;
}(Pattern));
export { Table };
/**
 * This function returns an instance of Table
 */
export var createTable = function (containerElement, businessLogicOrProperties) {
    var table = new Table(containerElement, businessLogicOrProperties);
    table.init();
    return table;
};
