var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './button-icon-only.scss';
import renderTemplate from './_button-icon-only.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
import { BUTTON_ICON_ONLY_COLORS, BUTTON_ICON_ONLY_SHAPES, BUTTON_ICON_ONLY_BASE_CLASSNAME, BUTTON_ICON_ONLY_SIZES, BUTTON_ICON_ONLY_DISABLED, } from './Constants';
/**
 * All components should inherit Component
 */
var ButtonIconOnly = /** @class */ (function (_super) {
    __extends(ButtonIconOnly, _super);
    function ButtonIconOnly() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    ButtonIconOnly.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    ButtonIconOnly.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    ButtonIconOnly.prototype.initEvents = function () {
        var buttonElement = this.containerElement.firstElementChild;
        var disabled = buttonElement.classList.contains(BUTTON_ICON_ONLY_DISABLED);
        // only add eventListeners if the button's not disabled
        if (!disabled) {
            var onClick = this.properties.business.onClick;
            this.containerElement.addEventListener('click', onClick);
        }
    };
    /**
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     */
    ButtonIconOnly.prototype.readDom = function (business) {
        var buttonElement = this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_BASE_CLASSNAME)[0];
        var iconElement = buttonElement.getElementsByTagName('svg')[0];
        var stdHref = buttonElement.getAttribute('href');
        var stdAriaLabel = buttonElement.getAttribute('aria-label');
        var optDisabled = buttonElement.classList.contains(BUTTON_ICON_ONLY_DISABLED);
        // arrays saved in the contants can be used to look for all variation values
        // using brix core getPropsFromClass fn
        var _a = getPropertiesFromClassName(buttonElement.className, {
            color: BUTTON_ICON_ONLY_COLORS,
            shape: BUTTON_ICON_ONLY_SHAPES,
            size: BUTTON_ICON_ONLY_SIZES,
        }), color = _a.color, shape = _a.shape, size = _a.size;
        var stdIconName = getSystemIconName(iconElement);
        var properties = {
            optColor: color,
            optShape: shape,
            optSize: size,
            optDisabled: optDisabled,
            stdIconName: stdIconName,
            stdAriaLabel: stdAriaLabel,
            business: business,
        };
        // These attributes need to be checked seperately as they can be null not just undefined
        stdHref && (properties.stdHref = stdHref);
        return removeUndefinedFieldsFromObject(properties);
    };
    return ButtonIconOnly;
}(Pattern));
export { ButtonIconOnly };
/**
 * This function returns an instance of ButtonIconOnly
 */
export var createButtonIconOnly = function (containerElement, businessLogicOrProperties) {
    var buttonIconOnly = new ButtonIconOnly(containerElement, businessLogicOrProperties);
    buttonIconOnly.init();
    return buttonIconOnly;
};
