/**
 * Constants file, please add all constants as exported variables/functions,
 * so that we do not need to take the whole file for 1 constant
 */
/**
 * Min Phablet/Tablet
 * @todo Why is this unused
 */
export var SMALL_MIN_WIDTH = 600;
/**
 * Mobile max-width
 */
export var MOBILE_MAX_WIDTH = SMALL_MIN_WIDTH - 1;
/**
 * Min Landscape tablet
 */
export var MEDIUM_MIN_WIDTH = 768;
/**
 * Min Desktop
 */
export var LARGE_MIN_WIDTH = 1024;
/**
 * X large min width
 */
export var XLARGE_MIN_WIDTH = 1280;
/**
 * Breakpoint names
 */
export var MOBILE_ONLY = 'mobile-only';
/**
 * Small tablet up
 */
export var BREAKPOINT_SMALL = 'small';
/**
 * Tablet up
 */
export var BREAKPOINT_MEDIUM = 'medium';
/**
 * Desktop up
 */
export var BREAKPOINT_LARGE = 'large';
/**
 * X-Large up
 */
export var BREAKPOINT_XLARGE = 'x-large';
