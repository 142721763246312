var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './youtube-video.scss';
import renderTemplate from './_youtube-video.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
/**
 * YoutubeVideo class
 */
var YoutubeVideo = /** @class */ (function (_super) {
    __extends(YoutubeVideo, _super);
    function YoutubeVideo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    YoutubeVideo.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    YoutubeVideo.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Read properties from DOM.
     */
    YoutubeVideo.prototype.readDom = function (business) {
        var iframeElement = this.containerElement.getElementsByTagName('iframe')[0];
        var iframeUrl = iframeElement.getAttribute('src');
        var stdTitle = iframeElement.getAttribute('title');
        var youtubeIdRegEx = /embed\/(.*?)$/;
        var matches = iframeUrl.match(youtubeIdRegEx);
        // no sage guard if, because by the hbs we know that "embed/" will match
        var stdYoutubeVideoID = matches[1];
        var properties = {
            mediaType: 'youtube-video',
            stdYoutubeVideoID: stdYoutubeVideoID,
            stdTitle: stdTitle,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return YoutubeVideo;
}(Pattern));
export { YoutubeVideo };
/**
 * This function returns an instance of YoutubeVideo
 */
export var createYoutubeVideo = function (containerElement, businessLogicOrProperties) {
    var youtubeVideo = new YoutubeVideo(containerElement, businessLogicOrProperties);
    youtubeVideo.init();
    return youtubeVideo;
};
