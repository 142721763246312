var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../image/_image.hbs"),(depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0),{"name":"_image","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../video/_video.hbs"),(depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0),{"name":"_video","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../youtube-video/_youtube-video.hbs"),(depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0),{"name":"_youtube-video","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-media-text__headline\">\n"
    + ((stack1 = container.invokePartial(require("../headline/_headline.hbs"),(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"_headline","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-media-text__buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"containerButtons") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":34,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"ws10-media-text__button-container\">\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),depth0,{"name":"_button","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-media-text ws10-media-text--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optMediaPosition") || (depth0 != null ? lookupProperty(depth0,"optMediaPosition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optMediaPosition","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":65}}}) : helper)))
    + " ws10-media-text-column ws10-media-text-column--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optColumnWidth") || (depth0 != null ? lookupProperty(depth0,"optColumnWidth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optColumnWidth","hash":{},"data":data,"loc":{"start":{"line":1,"column":113},"end":{"line":1,"column":131}}}) : helper)))
    + "\">\n\n    <div class=\"ws10-media-text__media\">\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0)) != null ? lookupProperty(stack1,"mediaType") : stack1),"===","image",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0)) != null ? lookupProperty(stack1,"mediaType") : stack1),"===","video",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerMedia") : depth0)) != null ? lookupProperty(stack1,"mediaType") : stack1),"===","youtube-video",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"ws10-media-text__segment\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"ws10-media-text__copytext--container\">\n            <div class=\"ws10-media-text__copytext ws10-text-style\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"txtCopytext") || (depth0 != null ? lookupProperty(depth0,"txtCopytext") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtCopytext","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":31}}}) : helper)))
    + "\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerButtons") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n</div>\n";
},"usePartial":true,"useData":true});