var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import style
import './image-header.scss';
import renderTemplate from './_image-header.hbs';
import { Pattern, NO_PATTERN_BUSINESS_LOGIC, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { IMAGE_HEADER_BASE_CLASSNAME, IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME, IMAGE_HEADER_CONTENT_CONTAINER_CLASSNAME, IMAGE_HEADER_HEADLINE_CLASSNAME, IMAGE_HEADER_HIGHLIGHT_BADGE_CLASSNAME, IMAGE_HEADER_IMG_CLASSNAME, IMAGE_HEADER_SUBLINE_CLASSNAME, IMAGE_HEADER_SIZE_LARGE, IMAGE_HEADER_SIZE_MEDIUM, IMAGE_HEADER_ALIGNMENT_RIGHT, IMAGE_HEADER_ALIGNMENT_LEFT, IMAGE_HEADER_BLACK_HEADLINE_CLASSNAME, IMAGE_HEADER_PRICE_PROMO_CLASSNAME, IMAGE_HEADER_CONTENT_CONTAINER_SOLID_CLASSNAME, IMAGE_HEADER_BUTTON_CONTAINER_NO_MARGIN_CLASSNAME, IMAGE_HEADER_MOBILE_SIZE, } from './Constants';
import { IMAGE_HEADER_IMG_SMALL_CLASSNAME, IMAGE_HEADER_SPLIT_CLASSNAME, } from './Constants';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { createHighlightBadge } from '@vfde-brix/ws10/highlight-badge';
import { createHeadline } from '@vfde-brix/ws10/headline';
import { createJustText } from '@vfde-brix/ws10/just-text';
import { createPromoPrice, PromoPriceVariant, } from '@vfde-brix/ws10/promo-price';
import { cloneDeep } from 'lodash';
/**
 * ImageHeader component
 */
var ImageHeader = /** @class */ (function (_super) {
    __extends(ImageHeader, _super);
    function ImageHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    ImageHeader.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     * If the component contains another component, you would then render the child component in this function.
     */
    ImageHeader.prototype.writeDom = function () {
        if (!this.updates) {
            this.updates = 0;
        }
        if (this.updates === 0) {
            this.originalProperties = cloneDeep(this.properties);
            this.updates++;
        }
        this.containerElement.innerHTML = renderTemplate(this.properties);
        var imageHeaderElement = this.containerElement.getElementsByClassName(IMAGE_HEADER_BASE_CLASSNAME)[0];
        var buttonElements = imageHeaderElement.getElementsByClassName(BUTTON_BASE_CLASSNAME);
        if (buttonElements.length === 1
            && !this.properties.containerHeadline
            && !this.properties.containerSubline
            && !this.properties.containerPromoPrice) {
            imageHeaderElement
                .getElementsByClassName(IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME)[0]
                .classList.add(IMAGE_HEADER_BUTTON_CONTAINER_NO_MARGIN_CLASSNAME);
        }
        // price Promo
        var promoPriceContainerElement = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_PRICE_PROMO_CLASSNAME)[0];
        if (promoPriceContainerElement) {
            this.checkScreenSizeUpdate();
        }
    };
    /** check screen size required updates */
    ImageHeader.prototype.checkScreenSizeUpdate = function () {
        if (window.innerWidth > IMAGE_HEADER_MOBILE_SIZE) {
            this.updatePromoPriceProperties(PromoPriceVariant.Medium, this.originalProperties.containerPromoPrice.optInverse);
        }
        else {
            this.updatePromoPriceProperties(PromoPriceVariant.Small, true);
        }
    };
    /**
     * Abstract pattern class enforces an implementation initEvents
     */
    ImageHeader.prototype.initEvents = function () {
        var _this = this;
        window.addEventListener('resize', function () {
            var _a;
            if ((_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerPromoPrice) {
                _this.checkScreenSizeUpdate();
            }
        });
    };
    /**
     * get promo price properties
     */
    ImageHeader.prototype.updatePromoPriceProperties = function (size, optInverse) {
        if (this.properties.containerPromoPrice.optSizeVariation === size) {
            return;
        }
        var newPromoPrice = __assign(__assign({}, this.properties.containerPromoPrice), { optSizeVariation: size, optInverse: optInverse !== undefined ? optInverse : this.properties.containerPromoPrice.optInverse });
        var newProperties = __assign(__assign({}, this.properties), { containerPromoPrice: newPromoPrice });
        this.update(newProperties);
    };
    /**
     * get properties from dom
     */
    ImageHeader.prototype.readDom = function (imageHeaderBusinessLogic) {
        var imageHeaderElement = this.containerElement.getElementsByClassName(IMAGE_HEADER_BASE_CLASSNAME)[0];
        var propertiesFromClassName = getPropertiesFromClassName(imageHeaderElement.className, {
            optSize: [IMAGE_HEADER_SIZE_LARGE, IMAGE_HEADER_SIZE_MEDIUM],
            optContentAlignment: [
                IMAGE_HEADER_ALIGNMENT_RIGHT,
                IMAGE_HEADER_ALIGNMENT_LEFT,
            ],
        });
        var imgDesktop = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_IMG_CLASSNAME)[0];
        var imgMobile = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_IMG_SMALL_CLASSNAME)[0];
        var contentContainerElement = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_CONTENT_CONTAINER_CLASSNAME)[0];
        var optContentColumns = contentContainerElement.getAttribute('data-image-header-contentColumns');
        var properties = {
            optSize: propertiesFromClassName.optSize,
            stdImageAltText: imgDesktop.alt,
            stdImgSrcDesktop: imgDesktop.src,
            stdImgSrcMobile: imgMobile.src,
            business: imageHeaderBusinessLogic,
            optContentAlignment: propertiesFromClassName.optContentAlignment,
            optContentColumns: +optContentColumns,
        };
        // headline
        var headline = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_HEADLINE_CLASSNAME)[0];
        if (headline) {
            var headlineComponent = createHeadline(headline, NO_PATTERN_BUSINESS_LOGIC);
            var headlineContainer = headlineComponent.getProperties();
            properties.containerHeadline = headlineContainer;
            var optBlackText = headline.classList.contains(IMAGE_HEADER_BLACK_HEADLINE_CLASSNAME);
            if (optBlackText) {
                properties.optBlackText = optBlackText;
            }
        }
        // price Promo
        var promoPriceContainerElement = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_PRICE_PROMO_CLASSNAME)[0];
        var promoPriceComponent;
        if (promoPriceContainerElement) {
            promoPriceComponent = createPromoPrice(promoPriceContainerElement, NO_PATTERN_BUSINESS_LOGIC);
        }
        var containerPromoPrice;
        if (promoPriceComponent) {
            containerPromoPrice = promoPriceComponent.getProperties();
            if (window.innerWidth > IMAGE_HEADER_MOBILE_SIZE) {
                containerPromoPrice.optSizeVariation = PromoPriceVariant.Medium;
            }
            else {
                containerPromoPrice.optSizeVariation = PromoPriceVariant.Small;
            }
            promoPriceComponent.update(containerPromoPrice);
            properties.containerPromoPrice = containerPromoPrice;
        }
        // highlight badge
        var highlightBadgeContainerElement = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_HIGHLIGHT_BADGE_CLASSNAME)[0];
        if (highlightBadgeContainerElement) {
            var highlightBadge = createHighlightBadge(highlightBadgeContainerElement, NO_PATTERN_BUSINESS_LOGIC);
            properties.containerHighlightBadge = highlightBadge.getProperties();
        }
        // subline
        var subline = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_SUBLINE_CLASSNAME)[0];
        if (subline) {
            if (subline.getElementsByClassName(IMAGE_HEADER_SUBLINE_CLASSNAME)[0]) {
                var sublineComponent = createHeadline(subline, NO_PATTERN_BUSINESS_LOGIC);
                properties.containerSubline = sublineComponent.getProperties();
            }
            else {
                var sublineChildComponent = subline.firstElementChild;
                var sublineComponent = createJustText(sublineChildComponent, NO_PATTERN_BUSINESS_LOGIC);
                properties.containerSubline = sublineComponent.getProperties();
            }
        }
        // buttons
        var buttonsContainerElement = imageHeaderElement.getElementsByClassName(IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME);
        if (buttonsContainerElement === null || buttonsContainerElement === void 0 ? void 0 : buttonsContainerElement.length) {
            var containerButtons = [];
            var buttonElements = imageHeaderElement.getElementsByClassName(BUTTON_BASE_CLASSNAME);
            if (buttonElements.length === 1 && !headline && !subline && !promoPriceComponent) {
                imageHeaderElement
                    .getElementsByClassName(IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME)[0]
                    .classList.add(IMAGE_HEADER_BUTTON_CONTAINER_NO_MARGIN_CLASSNAME);
            }
            for (var i = 0, x = buttonElements.length; i < x; i += 1) {
                var buttonContainer = buttonElements[i].parentNode;
                var button = createButton(buttonContainer, NO_PATTERN_BUSINESS_LOGIC);
                containerButtons.push(button.getProperties());
            }
            properties.containerButtons = containerButtons;
        }
        var optSplit = imageHeaderElement.classList.contains(IMAGE_HEADER_SPLIT_CLASSNAME);
        if (optSplit) {
            properties.optSplit = optSplit;
        }
        var optSolidBackground = imageHeaderElement.classList.contains(IMAGE_HEADER_CONTENT_CONTAINER_SOLID_CLASSNAME);
        if (optSolidBackground) {
            properties.optSolidBackground = optSolidBackground;
        }
        if (!this.updates) {
            this.updates = 0;
        }
        if (this.updates === 0) {
            this.originalProperties = cloneDeep(properties);
            this.updates++;
        }
        return properties;
    };
    return ImageHeader;
}(Pattern));
export { ImageHeader };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of ImageHeader
 */
export var createImageHeader = function (containerElement, properties) {
    var imageHeader = new ImageHeader(containerElement, properties);
    imageHeader.init();
    return imageHeader;
};
