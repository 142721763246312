var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optBackground") || (depth0 != null ? lookupProperty(depth0,"optBackground") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optBackground","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":66}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "monochrome-100";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optVerticalPadding") || (depth0 != null ? lookupProperty(depth0,"optVerticalPadding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optVerticalPadding","hash":{},"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":81}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "medium";
},"9":function(container,depth0,helpers,partials,data) {
    return " ws10-belt-container--border-bottom";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-belt-container\n    ws10-belt-container--bg-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optBackground") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":95}}})) != null ? stack1 : "")
    + "\n    ws10-belt-container--padding-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optVerticalPadding") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":102}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optBorderBottom") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":69}}})) != null ? stack1 : "")
    + "\n\">\n    <div class=\"ws10-belt-container__belt\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"containerAnyComponent") || (depth0 != null ? lookupProperty(depth0,"containerAnyComponent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"containerAnyComponent","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});