var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-suggest-input--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":58}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-form-suggest-input--disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":10,"column":64},"end":{"line":10,"column":73}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-label=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdResultListAriaLabel") || (depth0 != null ? lookupProperty(depth0,"stdResultListAriaLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdResultListAriaLabel","hash":{},"data":data,"loc":{"start":{"line":10,"column":179},"end":{"line":10,"column":205}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./_form-suggest-input-item.hbs"),depth0,{"name":"_form-suggest-input-item","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-form-suggest-input\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":65}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":64}}})) != null ? stack1 : "")
    + "\"\n>\n    <div class=\"ws10-form-suggest-input__container\">\n        <div class=\"ws10-form-suggest-input__input\">\n"
    + ((stack1 = container.invokePartial(require("../form-element/_form-element.hbs"),depth0,{"name":"_form-element","hash":{"optLoadingAnimationSize":(depth0 != null ? lookupProperty(depth0,"optLoadingAnimationSize") : depth0),"optWithLoadingAnimation":(depth0 != null ? lookupProperty(depth0,"optWithLoadingAnimation") : depth0),"optAutoCompleteOff":true,"stdAriaAutocomplete":(depth0 != null ? lookupProperty(depth0,"stdAriaAutocomplete") : depth0),"stdAriaControls":(depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0),"optRole":(depth0 != null ? lookupProperty(depth0,"optRole") : depth0),"optWarnForValidation":(depth0 != null ? lookupProperty(depth0,"optWarnForValidation") : depth0),"stdErrorKey":(depth0 != null ? lookupProperty(depth0,"stdErrorKey") : depth0),"stdErrorMessage":(depth0 != null ? lookupProperty(depth0,"stdErrorMessage") : depth0),"stdHelperText":(depth0 != null ? lookupProperty(depth0,"stdHelperText") : depth0),"stdLabel":(depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0),"containerLabel":(depth0 != null ? lookupProperty(depth0,"containerLabel") : depth0),"containerInput":(depth0 != null ? lookupProperty(depth0,"containerInput") : depth0),"optType":"text","optInputFieldType":"text-input"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"ws10-form-suggest-input__result-wrapper\">\n            <ul id=\"ws10-form-suggest-input-result"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdId") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":80}}})) != null ? stack1 : "")
    + "\" class=\"ws10-form-suggest-input__result\" role=\"listbox\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdResultListAriaLabel") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":136},"end":{"line":10,"column":213}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});