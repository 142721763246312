var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import styles
import './image.scss';
import renderTemplate from './_image.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { IMAGE_BASE_CLASSNAME, IMAGE_OBJECT_FIT_VARIATIONS, IMAGE_WIDTH, IMAGE_WIDTH_FULL, } from './Constants';
import { MOBILE_MAX_WIDTH } from '@vfde-brix/ws10/styles';
/**
 * An image
 */
var Image = /** @class */ (function (_super) {
    __extends(Image, _super);
    function Image() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Image.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optWidth === undefined) {
            newProperties.optWidth = IMAGE_WIDTH_FULL;
        }
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    Image.prototype.writeDom = function () {
        // This passes mobileMaxWidth to template but break typing
        // @TODO find a better solution and allow other hbs to access constants
        this.containerElement.innerHTML = renderTemplate(__assign(__assign({}, this.properties), { mobileMaxWidth: MOBILE_MAX_WIDTH }));
    };
    /**
     * Get Properties from DOM
     */
    Image.prototype.readDom = function (business) {
        var pictureElement = this.containerElement.getElementsByClassName(IMAGE_BASE_CLASSNAME)[0];
        var sourceElement = this.containerElement.getElementsByTagName('source')[0];
        var imgSrcMobile = sourceElement.getAttribute('srcset');
        var imgElement = this.containerElement.getElementsByTagName('img')[0];
        var imgSrcDesktop = imgElement.getAttribute('src');
        var stdAltText = imgElement.getAttribute('alt');
        var stdTitle = imgElement.getAttribute('title');
        var objectFit = getPropertiesFromClassName(imgElement.className, {
            objectFit: IMAGE_OBJECT_FIT_VARIATIONS,
        }).objectFit;
        var optWidth = getPropertiesFromClassName(pictureElement.className, {
            optWidth: IMAGE_WIDTH.map(function (item) { return "width-".concat(item); }),
        }).optWidth;
        var anchorElement = this.containerElement.getElementsByTagName('a')[0];
        var linkHref = anchorElement === null || anchorElement === void 0 ? void 0 : anchorElement.getAttribute('href');
        var optTarget = anchorElement === null || anchorElement === void 0 ? void 0 : anchorElement.getAttribute('target');
        var properties = {
            mediaType: 'image',
            imgSrcDesktop: imgSrcDesktop,
            imgSrcMobile: imgSrcMobile,
            stdAltText: stdAltText,
            stdTitle: stdTitle,
            linkHref: linkHref,
            optTarget: optTarget,
            optWidth: (optWidth === null || optWidth === void 0 ? void 0 : optWidth.replace('width-', '')) || IMAGE_WIDTH_FULL,
            objectFit: objectFit,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return Image;
}(Pattern));
export { Image };
/**
 * This function returns an instance of ImageText
 */
export var createImage = function (containerElement, businessLogicOrProperties) {
    var image = new Image(containerElement, businessLogicOrProperties);
    image.init();
    return image;
};
