var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import renderTemplate from './_icon-text-banner.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { BACKGROUND_COLOR, BUTTONS, DARK_RED, HEADLINE, ICON_BANNER_CONTAINER, ICON_TEXT, ICON_TEXT_BASIC, ICON_TEXT_INVERSE, RED_GREDIANT, SUB_HEADLINE, } from './Constants';
import { createButton, } from '../button';
import { createIconText } from '@vfde-brix/ws10/icon-text';
/**
 * import style
 */
import './icon-text-banner.scss';
/**
 * Icon Text Banner
 */
var IconTextBanner = /** @class */ (function (_super) {
    __extends(IconTextBanner, _super);
    function IconTextBanner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    IconTextBanner.prototype.getDefaultProperties = function (newProperties) {
        var optBackgroundColor = newProperties.optBackgroundColor, buttons = newProperties.buttons, iconTextItems = newProperties.iconTextItems;
        var isDarkBackground = optBackgroundColor === DARK_RED || optBackgroundColor === RED_GREDIANT;
        if (buttons) {
            buttons.forEach(function (button) {
                button.optInverse = isDarkBackground;
            });
        }
        if (iconTextItems) {
            iconTextItems.forEach(function (item) {
                item.optInverse = isDarkBackground;
            });
        }
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    IconTextBanner.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.setColumnsCount();
        this.initDomElements();
    };
    /**
     * This helper function to initializing IconComponent an loadingAnimationComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    IconTextBanner.prototype.initDomElements = function () {
        var e_1, _a, e_2, _b;
        var iconTextItems = [];
        var buttonsItems = [];
        var listItemContainers = this.containerElement.getElementsByClassName(ICON_TEXT);
        var buttonsContainers = this.containerElement.getElementsByClassName(BUTTONS);
        try {
            for (var listItemContainers_1 = __values(listItemContainers), listItemContainers_1_1 = listItemContainers_1.next(); !listItemContainers_1_1.done; listItemContainers_1_1 = listItemContainers_1.next()) {
                var itemContainer = listItemContainers_1_1.value;
                var item = createIconText(itemContainer, NO_PATTERN_BUSINESS_LOGIC);
                iconTextItems.push(item.getProperties());
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (listItemContainers_1_1 && !listItemContainers_1_1.done && (_a = listItemContainers_1.return)) _a.call(listItemContainers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var buttonsContainers_1 = __values(buttonsContainers), buttonsContainers_1_1 = buttonsContainers_1.next(); !buttonsContainers_1_1.done; buttonsContainers_1_1 = buttonsContainers_1.next()) {
                var itemContainer = buttonsContainers_1_1.value;
                var item = createButton(itemContainer, NO_PATTERN_BUSINESS_LOGIC);
                buttonsItems.push(item.getProperties());
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (buttonsContainers_1_1 && !buttonsContainers_1_1.done && (_b = buttonsContainers_1.return)) _b.call(buttonsContainers_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return { buttonsItems: buttonsItems, iconTextItems: iconTextItems };
    };
    /**
     * This is an abstract function so you have to implement it.
     */
    IconTextBanner.prototype.readDom = function (iconTextBannerBusinessLogic) {
        var initDom = this.initDomElements();
        this.setColumnsCount();
        var background = this.containerElement.getElementsByClassName(ICON_BANNER_CONTAINER)[0];
        var stdHeadline = this.containerElement.getElementsByClassName(HEADLINE)[0].textContent.trim();
        var stdSubHeadline = this.containerElement.getElementsByClassName(SUB_HEADLINE)[0].textContent.trim();
        var propertiesFromClassName = getPropertiesFromClassName(background.className, {
            color: BACKGROUND_COLOR,
        });
        var optColor = propertiesFromClassName.color;
        if (optColor === DARK_RED || optColor === RED_GREDIANT) {
            var iconTexts = __spreadArray([], __read(this.containerElement.getElementsByClassName(ICON_TEXT_BASIC)), false);
            iconTexts.forEach(function (element) {
                element.classList.add(ICON_TEXT_INVERSE);
            });
        }
        var properties = {
            stdHeadline: stdHeadline,
            iconTextItems: initDom.iconTextItems,
            stdSubHeadline: stdSubHeadline,
            buttons: initDom.buttonsItems,
            optBackgroundColor: optColor,
            business: iconTextBannerBusinessLogic,
        };
        return properties;
    };
    /**
     * To set columns count
     */
    IconTextBanner.prototype.setColumnsCount = function () {
        var e_3, _a;
        var iconTextItemContainers = this.containerElement.getElementsByClassName(ICON_TEXT);
        try {
            for (var iconTextItemContainers_1 = __values(iconTextItemContainers), iconTextItemContainers_1_1 = iconTextItemContainers_1.next(); !iconTextItemContainers_1_1.done; iconTextItemContainers_1_1 = iconTextItemContainers_1.next()) {
                var itemContainer = iconTextItemContainers_1_1.value;
                if ((iconTextItemContainers === null || iconTextItemContainers === void 0 ? void 0 : iconTextItemContainers.length) === 3 || (iconTextItemContainers === null || iconTextItemContainers === void 0 ? void 0 : iconTextItemContainers.length) > 4) {
                    itemContainer.className +=
                        ' ws10-column  ws10-column--x-large-4 ' + ' ws10-column  ws10-column--large-4 ' +
                            ' ws10-column  ws10-column--medium-4 ' + ' ws10-column  ws10-column--small-6 ';
                }
                if ((iconTextItemContainers === null || iconTextItemContainers === void 0 ? void 0 : iconTextItemContainers.length) === 4) {
                    itemContainer.className +=
                        ' ws10-column  ws10-column--x-large-3 ' + ' ws10-column  ws10-column--large-3 ' +
                            ' ws10-column  ws10-column--medium-3 ' + ' ws10-column  ws10-column--small-6 ';
                }
                if ((iconTextItemContainers === null || iconTextItemContainers === void 0 ? void 0 : iconTextItemContainers.length) < 3) {
                    itemContainer.className +=
                        ' ws10-column  ws10-column--x-large-6 ' + ' ws10-column  ws10-column--large-6 ' +
                            ' ws10-column  ws10-column--medium-6 ' + ' ws10-column  ws10-column--small-6 ';
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (iconTextItemContainers_1_1 && !iconTextItemContainers_1_1.done && (_a = iconTextItemContainers_1.return)) _a.call(iconTextItemContainers_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    return IconTextBanner;
}(Pattern));
export { IconTextBanner };
/**
 * This function returns an instance of Icon Text Banner
 */
export var createIconTextBannerComponent = function (containerElement, businessLogicOrProperties) {
    var iconText = new IconTextBanner(containerElement, businessLogicOrProperties);
    iconText.init();
    return iconText;
};
