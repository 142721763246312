/** Base class name */
export var TARIFF_CARD_BASE_CLASSNAME = 'ws10-tariff-card';
/** Optional wrapper classname */
export var TARIFF_CARD_WRAPPER_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__wrapper");
/** Tariff Card Item classname */
export var TARIFF_CARD_ITEM_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__item");
/** Flag badge class name */
export var TARIFF_CARD_FLAG_BADGE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__flag-badge");
/** Without Flag badge class name */
export var TARIFF_CARD_WITHOUT_FLAG_BADGE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__without-flag-badge");
/** Card container class name */
export var TARIFF_CARD_CARD_CONTAINER_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__card-container");
/** Card container with flagbadge class name */
export var TARIFF_CARD_CARD_CONTAINER_WITH_FLAGBADGE_CLASSNAME = "".concat(TARIFF_CARD_CARD_CONTAINER_CLASSNAME, "--with-flagbadge");
/** Small headline class name */
export var TARIFF_CARD_TOP_SMALL_HEADLINE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__headline--top-small");
/** Large headline class name */
export var TARIFF_CARD_HEADLINE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__headline");
/** txtContent class name */
export var TARIFF_CARD_TXT_CONTENT_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__txt-content");
/** Promo Price class name */
export var TARIFF_CARD_PROMO_PRICE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__promo-price");
/** Action button classname */
export var TARIFF_CARD_ACTION_BUTTON_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__action-button");
/** Action button container classname */
export var TARIFF_CARD_ACTION_BUTTON_CONTAINER_CLASSNAME = "".concat(TARIFF_CARD_ACTION_BUTTON_CLASSNAME, "-container");
/** Button link classname */
export var TARIFF_CARD_BUTTON_LINK_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "__button-link");
/** Action button container classname */
export var TARIFF_CARD_BUTTON_LINK_CONTAINER_CLASSNAME = "".concat(TARIFF_CARD_BUTTON_LINK_CLASSNAME, "-container");
/** Marker class for flagbadge variant */
export var TARIFF_CARD_HAS_FLAGBADGE_CLASSNAME = "".concat(TARIFF_CARD_BASE_CLASSNAME, "--has-flag-badge");
/** Class for ws10 flag badge */
export var WS10_FLAG_BADGE_CLASSNAME = 'ws10-flag-badge';
