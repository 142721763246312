/**
 * This class provides a function to render Handlebars
 */
var HandlebarRenderer = /** @class */ (function () {
    function HandlebarRenderer() {
    }
    /**
     * This function compiles hbs partial and returns html as a string
     */
    HandlebarRenderer.render = function (renderTargetElement, compiledData) {
        renderTargetElement.innerHTML = compiledData;
    };
    return HandlebarRenderer;
}());
export { HandlebarRenderer };
