/**
 * Pattern class name
 */
/* istanbul ignore next - That can be used in sails, standalone or other context to select an image pattern */
export var IMAGE_BASE_CLASSNAME = 'ws10-image';
/**
 * Image object-fit variation: default none
 */
var IMAGE_OBJECT_FIT_DEFAULT = 'none';
/**
 * Image object-fit variation: contain
 */
export var IMAGE_OBJECT_FIT_CONTAIN = 'contain';
/**
 * Image object-fit variation: cover
 */
var IMAGE_OBJECT_FIT_COVER = 'cover';
/**
 * Image object-fit variation: scale-down
 */
export var IMAGE_OBJECT_FIT_SCALE_DOWN = 'scale-down';
/**
 * Object-fit variastions
 */
export var IMAGE_OBJECT_FIT_VARIATIONS = [
    IMAGE_OBJECT_FIT_DEFAULT,
    IMAGE_OBJECT_FIT_CONTAIN,
    IMAGE_OBJECT_FIT_COVER,
    IMAGE_OBJECT_FIT_SCALE_DOWN,
];
export var IMAGE_WIDTH_FULL = 'full';
export var IMAGE_WIDTH_AUTO = 'auto';
export var IMAGE_WIDTH = [
    IMAGE_WIDTH_FULL,
    IMAGE_WIDTH_AUTO,
];
