/**
 * Button link base class name
 */
export var WS10_BUTTON_LINK_BASE_CLASSNAME = 'ws10-button-link';
/**
 * class name for disabled  button link
 */
export var WS10_BUTTON_LINK_DISABLED_CLASSNAME = "".concat(WS10_BUTTON_LINK_BASE_CLASSNAME, "--disabled");
/**
 * class name for icons in button link
 */
export var WS10_BUTTON_LINK_ICON_CLASSNAME = "".concat(WS10_BUTTON_LINK_BASE_CLASSNAME, "__icon");
/**
 * class name the actual link text
 */
export var WS10_BUTTON_LINK_TEXT_CLASSNAME = "".concat(WS10_BUTTON_LINK_BASE_CLASSNAME, "__text");
/**
 * class name for left icons
 */
export var WS10_BUTTON_LINK_LEFT_ICON_CLASSNAME = "".concat(WS10_BUTTON_LINK_ICON_CLASSNAME, "--left");
/**
 * class name for right icons
 */
export var WS10_BUTTON_LINK_RIGHT_ICON_CLASSNAME = "".concat(WS10_BUTTON_LINK_ICON_CLASSNAME, "--right");
/**
 * Enum for optIconPosition
 */
export var ButtonLinkColorVariant;
(function (ButtonLinkColorVariant) {
    ButtonLinkColorVariant["Primary200"] = "color-primary-200";
    ButtonLinkColorVariant["Monochrome600"] = "color-monochrome-600";
    ButtonLinkColorVariant["Primary200Inverse"] = "color-primary-200-inverse";
    ButtonLinkColorVariant["Monochrome600Inverse"] = "color-monochrome-600-inverse";
})(ButtonLinkColorVariant || (ButtonLinkColorVariant = {}));
/**
 * Enum for optIconPosition
 */
export var OptIconPosition;
(function (OptIconPosition) {
    OptIconPosition["RIGHT"] = "right";
    OptIconPosition["LEFT"] = "left";
})(OptIconPosition || (OptIconPosition = {}));
