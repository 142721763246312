var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './collapsable-card.scss';
import renderTemplate from './_collapsable-card.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { COLLAPSABLE_CARD_BASE_CLASSNAME, CARD_COLLAPSED_CLASS, COLLAPSABLE_CARD_TOGGLE, COLLAPSABLE_CARD_CONTENT, } from './Constants';
import { WS10_BUTTON_LINK_BASE_CLASSNAME, WS10_BUTTON_LINK_TEXT_CLASSNAME, } from '@vfde-brix/ws10/button-link';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var CollapsableCard = /** @class */ (function (_super) {
    __extends(CollapsableCard, _super);
    function CollapsableCard() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /**
         * This function uses arrow syntax so that we can get the context (this)
         * from the class and NOT the DOM element of the event.
         */
        _this.handleOnClick = function (event) {
            event.preventDefault();
            var _a = _this.properties, optCollapsed = _a.optCollapsed, stdLabelLess = _a.stdLabelLess, stdLabelMore = _a.stdLabelMore;
            var card = _this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_BASE_CLASSNAME)[0];
            var cardToggle = _this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_TOGGLE)[0];
            var buttonLink = cardToggle.getElementsByClassName(WS10_BUTTON_LINK_TEXT_CLASSNAME)[0];
            if (optCollapsed) {
                card.classList.remove(CARD_COLLAPSED_CLASS);
                cardToggle.setAttribute('aria-label', stdLabelLess);
                buttonLink.innerHTML = stdLabelLess;
            }
            else {
                card.classList.add(CARD_COLLAPSED_CLASS);
                cardToggle.setAttribute('aria-label', stdLabelMore);
                buttonLink.innerHTML = stdLabelMore;
            }
            _this.update({ optCollapsed: !optCollapsed }, true);
        };
        return _this;
    }
    /**
     * Intentionally return same props because no props to get defaults
     */
    CollapsableCard.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    CollapsableCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    CollapsableCard.prototype.initEvents = function () {
        var toogleContainer = this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_TOGGLE)[0];
        var buttonLink = toogleContainer.getElementsByClassName(WS10_BUTTON_LINK_BASE_CLASSNAME)[0];
        if (buttonLink) {
            buttonLink.addEventListener('click', this.handleOnClick);
        }
    };
    /**
     * his is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * you normally do not require this when you do not need to perform an update
     * ake this function only return undefined if you do not need properties.
     */
    CollapsableCard.prototype.readDom = function (business) {
        var collapsableCardElement = this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_BASE_CLASSNAME)[0];
        // white background is the default and only white and dark-grey are allowed, so checking for ws10-collapsable-card--dark-grey is sufficient
        var optBackgroundColor = (collapsableCardElement.classList.contains('ws10-collapsable-card--dark-grey'))
            ? 'dark-grey'
            : 'white';
        // optInverse is a boolean and class is only present when card is inversed
        var optInverse = collapsableCardElement.classList.contains('ws10-collapsable-card--inverse');
        // optCollapsed is a boolean and class is only present when card is collapsed
        var optCollapsed = collapsableCardElement.classList.contains('ws10-collapsable-card--collapsed');
        /**
         * No matter if folded or not: One label is in the aria-attribute and one in the button link.
         * So first I get both labels and then assign them to
         */
        var labelOne = this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_TOGGLE)[0]
            .getAttribute('aria-label');
        var labelTwo = this.containerElement.getElementsByClassName(WS10_BUTTON_LINK_TEXT_CLASSNAME)[0].innerHTML.trim();
        var stdLabelLess = (!optCollapsed) ? labelOne : labelTwo;
        var stdLabelMore = (optCollapsed) ? labelOne : labelTwo;
        var containerAnyComponent = this.containerElement.getElementsByClassName(COLLAPSABLE_CARD_CONTENT)[0].innerHTML;
        return removeUndefinedFieldsFromObject({
            optBackgroundColor: optBackgroundColor,
            optInverse: optInverse,
            optCollapsed: optCollapsed,
            stdLabelLess: stdLabelLess,
            stdLabelMore: stdLabelMore,
            containerAnyComponent: containerAnyComponent,
            business: business,
        });
    };
    return CollapsableCard;
}(Pattern));
export { CollapsableCard };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of CollapsableCard
 */
export var createCollapsableCard = function (containerElement, businessLogicOrProperties) {
    var collapsableCard = new CollapsableCard(containerElement, businessLogicOrProperties);
    collapsableCard.init();
    return collapsableCard;
};
