var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optType") || (depth0 != null ? lookupProperty(depth0,"optType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optType","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":82}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "text";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-option-picker--with-flag-badge";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <legend class=\"ws10-option-picker__legend ws10-sr-only\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdScreenreaderLegend") || (depth0 != null ? lookupProperty(depth0,"stdScreenreaderLegend") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdScreenreaderLegend","hash":{},"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":89}}}) : helper)))
    + "</legend>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-option-picker__item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":44},"end":{"line":6,"column":212}}})) != null ? stack1 : "")
    + "\">\n            <input id=\"ws10-option-picker-item-"
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"stdName") : depths[1]), depth0))
    + "-"
    + alias3(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":7,"column":62},"end":{"line":7,"column":72}}}) : helper)))
    + "\" class=\"ws10-option-picker__input ws10-sr-only\" type=\"radio\" name=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"stdName") : depths[1]), depth0))
    + "\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"stdValue") || (depth0 != null ? lookupProperty(depth0,"stdValue") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"stdValue","hash":{},"data":data,"loc":{"start":{"line":7,"column":163},"end":{"line":7,"column":175}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":176},"end":{"line":7,"column":273}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optChecked") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":273},"end":{"line":7,"column":306}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":306},"end":{"line":7,"column":341}}})) != null ? stack1 : "")
    + " autocomplete=\"off\">\n            <label class=\"ws10-option-picker__label\" for=\"ws10-option-picker-item-"
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"stdName") : depths[1]), depth0))
    + "-"
    + alias3(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":8,"column":97},"end":{"line":8,"column":107}}}) : helper)))
    + "\">\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]),"!==","text",{"name":"ifCond","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"ws10-option-picker__text-container\">\n                    <span class=\"ws10-option-picker__text ws10-option-picker__text--primary\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdPrimaryLabel") || (depth0 != null ? lookupProperty(depth0,"stdPrimaryLabel") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"stdPrimaryLabel","hash":{},"data":data,"loc":{"start":{"line":29,"column":93},"end":{"line":29,"column":114}}}) : helper))) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]),"===","text",{"name":"ifCond","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":34,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-option-picker__item--with-flag-badge"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0)) != null ? lookupProperty(stack1,"optFullwidth") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":112},"end":{"line":6,"column":205}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return " ws10-option-picker__item--with-full-flag-badge";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-controls=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdAriaControls") || (depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdAriaControls","hash":{},"data":data,"loc":{"start":{"line":7,"column":215},"end":{"line":7,"column":234}}}) : helper)))
    + "\" aria-expanded=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optChecked") || (depth0 != null ? lookupProperty(depth0,"optChecked") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optChecked","hash":{},"data":data,"loc":{"start":{"line":7,"column":251},"end":{"line":7,"column":265}}}) : helper)))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return " checked";
},"17":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"ws10-option-picker__visual\">\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]),"===","image",{"name":"ifCond","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":15,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]),"===","icon",{"name":"ifCond","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":20,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]),"===","color",{"name":"ifCond","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":25,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-option-picker__image-container\">\n                                <img class=\"ws10-option-picker__image\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"imgSrc") || (depth0 != null ? lookupProperty(depth0,"imgSrc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imgSrc","hash":{},"data":data,"loc":{"start":{"line":13,"column":76},"end":{"line":13,"column":86}}}) : helper)))
    + "\" alt=\"\">\n                            </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-option-picker__icon-container\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optSize":"size-inherit","stdName":(depth0 != null ? lookupProperty(depth0,"stdSystemIconName") : depth0)},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-option-picker__color-container\">\n                                <div class=\"ws10-option-picker__color-swatch\" style=\"background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdColor") || (depth0 != null ? lookupProperty(depth0,"stdColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdColor","hash":{},"data":data,"loc":{"start":{"line":23,"column":103},"end":{"line":23,"column":115}}}) : helper)))
    + ";\" data-color=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdColor") || (depth0 != null ? lookupProperty(depth0,"stdColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdColor","hash":{},"data":data,"loc":{"start":{"line":23,"column":130},"end":{"line":23,"column":142}}}) : helper)))
    + "\"></div>\n                            </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stdSecondaryLabel") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":33,"column":31}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"ws10-option-picker__text ws10-option-picker__text--secondary\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdSecondaryLabel") || (depth0 != null ? lookupProperty(depth0,"stdSecondaryLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSecondaryLabel","hash":{},"data":data,"loc":{"start":{"line":32,"column":103},"end":{"line":32,"column":126}}}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-option-picker__flag-badge"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0)) != null ? lookupProperty(stack1,"optFullwidth") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":58},"end":{"line":38,"column":141}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0)) != null ? lookupProperty(stack1,"optSize") : stack1),"===","small",{"name":"ifCond","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":141},"end":{"line":38,"column":242}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../flag-badge/_flag-badge.hbs"),(depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0),{"name":"_flag-badge","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return " ws10-option-picker__flag-badge--full";
},"32":function(container,depth0,helpers,partials,data) {
    return " ws10-option-picker__flag-badge--small";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"ws10-option-picker ws10-option-picker--"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optType") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":56},"end":{"line":1,"column":101}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optWithFlagBadge") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":168}}})) != null ? stack1 : "")
    + "\" data-option-count=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdScreenreaderLegend") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":43,"column":13}}})) != null ? stack1 : "")
    + "</fieldset>\n";
},"usePartial":true,"useData":true,"useDepths":true});