var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdTooltipId") || (depth0 != null ? lookupProperty(depth0,"stdTooltipId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdTooltipId","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":25}}}) : helper)))
    + "_bubble\" class=\"ws10-tooltip-bubble ws10-tooltip-bubble--small ws10-tooltip-fade\" role=\"tooltip\" tabindex=\"0\">\n    <div class=\"ws10-tooltip-bubble__content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtContent") || (depth0 != null ? lookupProperty(depth0,"txtContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtContent","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":62}}}) : helper))) != null ? stack1 : "")
    + "</div>\n</div>\n<div class=\"ws10-tooltip-nose ws10-tooltip-nose--small ws10-tooltip-fade\"></div>\n";
},"useData":true});