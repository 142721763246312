export var LOADING_ANIMATION_BASE_CLASSNAME = 'ws10-loading-animation';
export var LOADING_ANIMATION_ALERT_CLASSNAME = "".concat(LOADING_ANIMATION_BASE_CLASSNAME, "__alert");
/**
 * LoadingAnimationSize
 */
export var LoadingAnimationSize;
(function (LoadingAnimationSize) {
    LoadingAnimationSize["Medium"] = "medium";
    LoadingAnimationSize["Large"] = "large";
})(LoadingAnimationSize || (LoadingAnimationSize = {}));
export var LOADING_ANIMATION_SIZE_MEDIUM = LoadingAnimationSize.Medium;
export var LOADING_ANIMATION_SIZE_LARGE = LoadingAnimationSize.Large;
export var LOADING_ANIMATION_SIZES = [
    LOADING_ANIMATION_SIZE_MEDIUM,
    LOADING_ANIMATION_SIZE_LARGE,
];
