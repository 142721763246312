var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-unordered-vertical-list--inverse";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li\n            class=\"ws10-unordered-vertical-list__item\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":81}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optSize") : depth0),"===","default",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":14,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optSize") : depth0),"===","small",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                <h4 class=\"ws10-unordered-vertical-list__headline\">\n                    <span class=\"ws10-unordered-vertical-list__headline__text--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optSize") || (depth0 != null ? lookupProperty(depth0,"optSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optSize","hash":{},"data":data,"loc":{"start":{"line":23,"column":79},"end":{"line":23,"column":90}}}) : helper)))
    + "\n                        "
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optSize") : depth0),"===","small",{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":77}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":143}}})) != null ? stack1 : "")
    + "\n                        ws10-text-bold\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":41}}}) : helper)))
    + "\n                    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":41,"column":27}}})) != null ? stack1 : "")
    + "                </h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"txtText") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdLinkText") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " ws10-unordered-vertical-list__item--inverse";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":"true","optColor":"color-monochrome-600-inverse","optSize":"size-200","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-unordered-vertical-list__icon ws10-unordered-vertical-list__icon--default ws10-unordered-vertical-list__icon--list"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":"true","optColor":"color-monochrome-600","optSize":"size-200","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-unordered-vertical-list__icon ws10-unordered-vertical-list__icon--default ws10-unordered-vertical-list__icon--list"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":20,"column":27}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":"true","optColor":"color-monochrome-600-inverse","optSize":"size-150","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-unordered-vertical-list__icon ws10-unordered-vertical-list-icon--small ws10-unordered-vertical-list__icon--list"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":"true","optColor":"color-monochrome-600","optSize":"size-150","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),"stdClassName":"ws10-unordered-vertical-list__icon ws10-unordered-vertical-list__icon--small ws10-unordered-vertical-list__icon--list"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "ws10-text";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "ws10-text-inverse ws10-unordered-vertical-list__headline__text--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optSize") || (depth0 != null ? lookupProperty(depth0,"optSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optSize","hash":{},"data":data,"loc":{"start":{"line":25,"column":106},"end":{"line":25,"column":117}}}) : helper)))
    + " headline--inverse ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-unordered-vertical-list__tooltip-trigger\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + "\"\n                            aria-describedby=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0)) != null ? lookupProperty(stack1,"stdTooltipId") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":38,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n"
    + ((stack1 = container.invokePartial(require("../tooltip/_tooltip.hbs"),(depth0 != null ? lookupProperty(depth0,"containerTooltip") : depth0),{"name":"_tooltip","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "ws10-unordered-vertical-list__tooltip-trigger--inverse\n                        ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-600-inverse","optSize":"size-150","stdName":"info-circle","stdClassName":"ws10-unordered-vertical-list__tooltipIcon ws10-unordered-vertical-list__tooltipIcon--tooltip"},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-600","optSize":"size-150","stdName":"info-circle","stdClassName":"ws10-unordered-vertical-list__tooltipIcon ws10-unordered-vertical-list__tooltipIcon--tooltip"},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"ws10-unordered-vertical-list__text ws10-text"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":76},"end":{"line":44,"column":109}}})) != null ? stack1 : "")
    + " ws10-spacing-0\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtText") || (depth0 != null ? lookupProperty(depth0,"txtText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"txtText","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":39}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "-inverse";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":49,"column":29},"end":{"line":49,"column":43}}}) : helper)))
    + "\"\n                        target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optTarget") || (depth0 != null ? lookupProperty(depth0,"optTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTarget","hash":{},"data":data,"loc":{"start":{"line":50,"column":32},"end":{"line":50,"column":47}}}) : helper)))
    + "\" rel=\"noopener noreferrer\" class=\"ws10-unordered-vertical-list__link ws10-text-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":131},"end":{"line":50,"column":164}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLinkText") || (depth0 != null ? lookupProperty(depth0,"stdLinkText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLinkText","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":41}}}) : helper)))
    + "\n                    </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-unordered-vertical-list__container\">\n    <ul class=\"ws10-unordered-vertical-list\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":71}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"listUnorderedVerticalListItems") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":55,"column":13}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});