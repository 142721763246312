var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// import styles
import './icon-strip.scss';
import renderTemplate from './_icon-strip.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { ICON_STRIP_ITEM_CLASSNAME, ICON_STRIP_SPAN_CLASSNAME, } from './Constants';
import { getSystemIconName } from '../system-icon';
/**
 * IconStrip
 */
var IconStrip = /** @class */ (function (_super) {
    __extends(IconStrip, _super);
    function IconStrip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IconStrip.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    IconStrip.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    IconStrip.prototype.readDom = function () {
        var e_1, _a;
        var _b;
        var iconStripItemElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(ICON_STRIP_ITEM_CLASSNAME)), false);
        var properties = {
            business: {},
            items: [],
        };
        try {
            for (var iconStripItemElements_1 = __values(iconStripItemElements), iconStripItemElements_1_1 = iconStripItemElements_1.next(); !iconStripItemElements_1_1.done; iconStripItemElements_1_1 = iconStripItemElements_1.next()) {
                var itemElement = iconStripItemElements_1_1.value;
                var stdLabel = (_b = itemElement.getElementsByClassName(ICON_STRIP_SPAN_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var stdIconName = getSystemIconName(itemElement);
                var linkHref = itemElement.getAttribute('href');
                var optTarget = itemElement.getAttribute('target');
                var business = {};
                properties.items.push(removeUndefinedFieldsFromObject({
                    stdLabel: stdLabel,
                    stdIconName: stdIconName,
                    linkHref: linkHref,
                    optTarget: optTarget,
                    business: business,
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (iconStripItemElements_1_1 && !iconStripItemElements_1_1.done && (_a = iconStripItemElements_1.return)) _a.call(iconStripItemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    return IconStrip;
}(Pattern));
export { IconStrip };
/**
 * This function returns an instance of Iconstrip
 */
export var createIconStrip = function (containerElement, businessLogicOrProperties) {
    var iconStrip = new IconStrip(containerElement, businessLogicOrProperties);
    iconStrip.init();
    return iconStrip;
};
