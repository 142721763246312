import { attempt, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { SYSTEM_ICON_COLORS } from '../Constants';
/**
 * Returns the name of a system icon from its path
 */
export var getSystemIconName = function (containerElement) {
    return attempt(function () {
        var iconHref = containerElement.getElementsByTagName('use')[0].getAttribute('xlink:href');
        return iconHref
            .split('/')
            .pop()
            .split('.')[0];
    });
};
/**
 * Returns the color of a system icon from its class
 */
export var getSystemIconColor = function (containerElement) {
    return attempt(function () {
        var svg = containerElement.getElementsByTagName('svg')[0];
        var props = getPropertiesFromClassName(svg.getAttribute('class'), {
            color: SYSTEM_ICON_COLORS,
        });
        return props.color;
    });
};
