/**
 * Notification vertical position: top
 */
export var NOTIFICATION_ARROW_POSITION_TOP = 'arrow-top';
/**
 * Notification vertical position: bottom
 */
export var NOTIFICATION_ARROW_POSITION_BOTTOM = 'arrow-bottom';
/**
 * Notification arrow variations
 */
export var NOTIFICATION_ARROW_VERTICAL_POSITIONS = [
    NOTIFICATION_ARROW_POSITION_BOTTOM,
    NOTIFICATION_ARROW_POSITION_TOP,
];
/**
 * Base classname
 */
export var NOTIFICATION_BASE_CLASSNAME = 'ws10-notification';
/**
 * Text classname
 */
export var NOTIFICATION_ARROW_CLASSNAME = "".concat(NOTIFICATION_BASE_CLASSNAME, "__arrow");
/**
 * Text classname
 */
export var NOTIFICATION_TEXT_CLASSNAME = "".concat(NOTIFICATION_BASE_CLASSNAME, "__text");
/**
 * Headline classname
 */
export var NOTIFICATION_HEADLINE_CLASSNAME = "".concat(NOTIFICATION_BASE_CLASSNAME, "__headline");
/**
 * Classname of wrapper around optional
 */
export var NOTIFICATION_BUTTONS_CLASSNAME = "".concat(NOTIFICATION_BASE_CLASSNAME, "__btns");
/**
 * Transparent background
 */
export var NOTIFICATION_COLOR_TRANSPARENT = 'transparent';
/**
 * Transparent background
 */
export var NOTIFICATION_COLOR_WHITE = 'white';
/**
 * Small size
 */
export var NOTIFICATION_SIZE_SMALL = 'small';
/**
 * Medium size
 */
export var NOTIFICATION_SIZE_MEDIUM = 'medium';
/**
 * Hide notification classname
 */
export var NOTIFICATION_IS_HIDDEN = 'ws10-is-hidden';
