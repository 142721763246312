var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-search-input-wrapper--disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-search-input-wrapper--inverse";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"\n    ws10-search-input-wrapper\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":66}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":64}}})) != null ? stack1 : "")
    + "\n\">\n    <div class=\"ws10-form-text-input-container\">\n"
    + ((stack1 = container.invokePartial(require("../form-text-input/_form-text-input.hbs"),(depth0 != null ? lookupProperty(depth0,"containerFormTextInput") : depth0),{"name":"_form-text-input","hash":{"optAutoCompleteOff":(depth0 != null ? lookupProperty(depth0,"optAutoCompleteOff") : depth0),"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"ws10-button-icon-only-container\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),(depth0 != null ? lookupProperty(depth0,"containerButtonIconOnly") : depth0),{"name":"_button-icon-only","hash":{"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});