var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './flag-badge.scss';
import renderTemplate from './_flag-badge.hbs';
import { Pattern, removeUndefinedFieldsFromObject, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { FLAG_BADGE_BASE_CLASSNAME, FLAG_BADGE_COLORS, FLAG_BADGE_FULL_WITH_CLASSNAME, FLAG_BADGE_INVERSE_CLASSNAME, FLAG_BADGE_SIZES, } from './Constants';
/**
 * WS10 FlagBadge
 */
var FlagBadge = /** @class */ (function (_super) {
    __extends(FlagBadge, _super);
    function FlagBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FlagBadge.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    FlagBadge.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    FlagBadge.prototype.readDom = function (business) {
        var flagBadge = this.containerElement.getElementsByClassName(FLAG_BADGE_BASE_CLASSNAME)[0];
        var className = flagBadge.className;
        var stdLabel = flagBadge.innerHTML.trim();
        var _a = getPropertiesFromClassName(className, {
            optColor: FLAG_BADGE_COLORS,
            optSize: FLAG_BADGE_SIZES,
        }), optColor = _a.optColor, optSize = _a.optSize;
        var optFullwidth = flagBadge.classList.contains(FLAG_BADGE_FULL_WITH_CLASSNAME) ? true : undefined;
        var optInverse = flagBadge.classList.contains(FLAG_BADGE_INVERSE_CLASSNAME) ? true : undefined;
        var properties = removeUndefinedFieldsFromObject({
            optColor: optColor,
            optSize: optSize,
            stdLabel: stdLabel,
            optFullwidth: optFullwidth,
            optInverse: optInverse,
            business: business,
        });
        return properties;
    };
    /**
     * Get flagBadge properties from DOM
     */
    FlagBadge.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createFlagBadge);
    };
    return FlagBadge;
}(Pattern));
export { FlagBadge };
/**
 * This function returns an instance of FlagBadge
 */
export var createFlagBadge = function (containerElement, properties) {
    var flagBadge = new FlagBadge(containerElement, properties);
    flagBadge.init();
    return flagBadge;
};
