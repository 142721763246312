var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './headline.scss';
import renderTemplate from './_headline.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { HEADLINE_BASE_CLASSNAME, HEADLINE_ALIGNMENTS, HEADLINE_CLASSNAME_COUNT_ME, HEADLINE_MOBILE_STYLES, HEADLINE_TAGS, } from './Constants';
import { getContainerSpacing } from '../core/utils/getContainerSpacing';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var Headline = /** @class */ (function (_super) {
    __extends(Headline, _super);
    function Headline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Headline.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optAutomaticCounter === undefined) {
            newProperties.optAutomaticCounter = false;
        }
        return newProperties;
    };
    Headline.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    Headline.prototype.readDom = function (headlineBusinessLogic) {
        var patternElement = this.containerElement.getElementsByClassName(HEADLINE_BASE_CLASSNAME)[0];
        var optTag = patternElement.tagName.toLowerCase();
        var stdContent = patternElement.innerHTML.trim();
        var propertiesFromClassName = getPropertiesFromClassName(patternElement.className, {
            optClassName: HEADLINE_TAGS,
            optAlign: HEADLINE_ALIGNMENTS,
            optMobileStyle: HEADLINE_MOBILE_STYLES,
        });
        var spacing = getContainerSpacing(patternElement.className);
        var optVisualLevel = propertiesFromClassName.optClassName;
        var optAlign = propertiesFromClassName.optAlign;
        var optMobileStyle = propertiesFromClassName.optMobileStyle;
        // read style as stdAdditionalStyle or set to undefined if null a.k.a. style attribute not present
        var stdAdditionalStyle = (patternElement.getAttribute('style') !== null) ? patternElement.getAttribute('style') : undefined;
        var optManualNumber = parseInt(patternElement.dataset.number, 10) || undefined;
        var optAutomaticCounter = patternElement.classList.contains(HEADLINE_CLASSNAME_COUNT_ME);
        return removeUndefinedFieldsFromObject({
            optTag: optTag,
            optVisualLevel: optVisualLevel,
            optAlign: optAlign,
            optMobileStyle: optMobileStyle,
            stdContent: stdContent,
            stdAdditionalStyle: stdAdditionalStyle,
            business: headlineBusinessLogic,
            optManualNumber: optManualNumber,
            optAutomaticCounter: optAutomaticCounter,
            spacing: spacing,
        });
    };
    /**
     * Get headline properties from DOM
     */
    Headline.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createHeadline);
    };
    return Headline;
}(Pattern));
export { Headline };
/**
 * factory method to create a headline pattern
 */
export var createHeadline = function (containerElement, businessLogicOrProperties) {
    var headline = new Headline(containerElement, businessLogicOrProperties);
    headline.init();
    return headline;
};
