var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// import styles
import './carousel.scss';
import renderTemplate from './_carousel.hbs';
import { createComponents, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { CAROUSEL_CONTENT_GAP, CAROUSEL_SLIDER_ARROWS, CAROUSEL_SLIDER_ARROW_BASE_CLASSNAME, CAROUSEL_SLIDER_ARROW_NEXT, CAROUSEL_SLIDER_ARROW_PREV, CAROUSEL_SLIDER_BASE_CLASSNAME, CAROUSEL_SLIDER_LIST, CAROUSEL_SLIDER_PAGINATION_BASE_CLASSNAME, CAROUSEL_SLIDER_PAGINATION_PAGE, CAROUSEL_SLIDER_SLIDE, CAROUSEL_SLIDER_TRACK, CROUSEL_LOOPING_TYPE, CAROUSEL_NAVIGATION_BUTTONS_PROPS, CAROUSEL_ARROWS_DISPLAY, CAROUSEL_SLIDER_PAGINATION_PAGE_INVERSE, CAROUSEL_INVERSE, I18N, SPLIDE_ARROWS, BUTTON_ICON_ONLY, } from './Constants';
import Splide from '@splidejs/splide';
import { BUTTON_ICON_ONLY_BASE_CLASSNAME, createButtonIconOnly, } from '../button-icon-only';
import { createTariffCards, TARIFF_CARD_BASE_CLASSNAME, } from '@vfde-brix/ws10/tariff-card';
/**
 * Carousel
 */
var Carousel = /** @class */ (function (_super) {
    __extends(Carousel, _super);
    function Carousel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get config for the main slider
     */
    Carousel.prototype.getSliderConfig = function () {
        var _a, _b, _c;
        return {
            perPage: ((_a = this.properties) === null || _a === void 0 ? void 0 : _a.stdSizeOfContentPerContainerLarge) <
                this.totalNumberOfContentElements
                ? (_b = this.properties) === null || _b === void 0 ? void 0 : _b.stdSizeOfContentPerContainerLarge
                : this.totalNumberOfContentElements,
            focus: 0,
            perMove: 1,
            gap: CAROUSEL_CONTENT_GAP,
            updateOnMove: true,
            paginationDirection: 'ltr',
            type: this.properties.optWithLoop ? CROUSEL_LOOPING_TYPE : 'slide',
            arrows: !this.properties.optHideArrow,
            classes: {
                root: CAROUSEL_SLIDER_BASE_CLASSNAME,
                track: CAROUSEL_SLIDER_TRACK,
                list: CAROUSEL_SLIDER_LIST,
                pagination: CAROUSEL_SLIDER_PAGINATION_BASE_CLASSNAME,
                page: ((_c = this.properties) === null || _c === void 0 ? void 0 : _c.optInverse) ? CAROUSEL_SLIDER_PAGINATION_PAGE_INVERSE : CAROUSEL_SLIDER_PAGINATION_PAGE,
                slide: CAROUSEL_SLIDER_SLIDE,
                arrows: CAROUSEL_SLIDER_ARROWS,
                arrow: CAROUSEL_SLIDER_ARROW_BASE_CLASSNAME,
                prev: CAROUSEL_SLIDER_ARROW_PREV,
                next: CAROUSEL_SLIDER_ARROW_NEXT,
            },
            trimSpace: false,
            paginationKeyboard: false,
            keyboard: false,
            drag: !!this.properties.optWithLoop,
            dragMinThreshold: 10,
            snap: false,
            i18n: {
                prev: I18N.prev,
                next: I18N.next,
                first: I18N.first,
                last: I18N.last,
                slide: I18N.slide,
                slideLabel: I18N.slideLabel,
                carousel: I18N.carousel,
                select: '',
            },
            breakpoints: {
                1023: {
                    gap: CAROUSEL_CONTENT_GAP,
                    perPage: this.properties.stdSizeOfContentPerContainerMedium <
                        this.totalNumberOfContentElements
                        ? this.properties.stdSizeOfContentPerContainerMedium
                        : this.totalNumberOfContentElements,
                },
                767: {
                    gap: CAROUSEL_CONTENT_GAP,
                    perPage: this.properties.stdSizeOfContentPerContainerSmall <
                        this.totalNumberOfContentElements
                        ? this.properties.stdSizeOfContentPerContainerSmall
                        : this.totalNumberOfContentElements,
                },
            },
        };
    };
    /**
     * Get the Slider Element from the DOM
     */
    Carousel.prototype.getSliderElement = function () {
        var sliderElement = this.containerElement.getElementsByClassName(CAROUSEL_SLIDER_BASE_CLASSNAME)[0];
        return sliderElement;
    };
    /** get number of carousel pages from input properties */
    Carousel.prototype.getNumberOfCarouselPages = function (numberOfElementsPerPage) {
        if (this.totalNumberOfContentElements === numberOfElementsPerPage) {
            return 1;
        }
        if (this.properties.optWithLoop) {
            return this.totalNumberOfContentElements;
        }
        var numberOfPages = this.totalNumberOfContentElements - numberOfElementsPerPage + 1;
        return Number.isInteger(numberOfPages)
            ? numberOfPages
            : Math.ceil(numberOfPages);
    };
    /** get starting index from props */
    Carousel.prototype.getStartingContentFromProps = function (numberOfElementsPerPage) {
        var startingIndex = 0;
        if (this.properties.contentList.length && numberOfElementsPerPage > 0) {
            this.properties.contentList.forEach(function (element, i) {
                if (element.optStarterContent) {
                    startingIndex = i;
                }
            });
            return this.getStartingPageIndex(startingIndex, numberOfElementsPerPage);
        }
        return 0;
    };
    /** get starting page index */
    Carousel.prototype.getStartingPageIndex = function (startingElementIndex, numberOfElementsPerPage) {
        if (this.numberOfcarouselPages === 1 || startingElementIndex < this.numberOfElementsDisplayedPerPage) {
            return 0;
        }
        if (startingElementIndex >= this.numberOfcarouselPages - numberOfElementsPerPage - 1) {
            return this.numberOfcarouselPages - 1;
        }
        return startingElementIndex;
    };
    /**
     * init slider
     * initiate the slider configurations
     */
    Carousel.prototype.initSlider = function () {
        var _this = this;
        this.splide = new Splide(this.getSliderElement(), this.getSliderConfig());
        this.onMounted();
        this.onPagintationMounted();
        // reset buttons status on window resized
        this.onUpdated();
        this.splide.mount();
        setTimeout(function () {
            _this.splide.go(">".concat(_this.startingIndex));
        }, 1);
        this.onMoved();
    };
    /**
     * on slider mounted
     */
    Carousel.prototype.onMounted = function () {
        var _this = this;
        this.splide.on('mounted', function () {
            var dots = _this.containerElement.querySelectorAll(".".concat(CAROUSEL_SLIDER_PAGINATION_PAGE) || ".".concat(CAROUSEL_SLIDER_PAGINATION_PAGE_INVERSE));
            dots.forEach(function (dot) {
                dot.removeAttribute('aria-label');
                dot.setAttribute('aria-hidden', 'true');
            });
            if (_this.numberOfcarouselPages <= 1 && _this.splide.Components.Arrows) {
                _this.splide.Components.Arrows.arrows.prev.style.display =
                    'none';
                _this.splide.Components.Arrows.arrows.next.style.display =
                    'none';
                _this.splide.options.type = 'slide';
                _this.splide.Components.Clones.destroy();
                _this.splide.Components.Pagination.destroy();
                _this.splide.Components.Arrows.destroy();
                _this.splide.Components.Elements.arrows.style.display = 'none';
            }
            if (!_this.properties.optWithLoop) {
                if (_this.startingIndex === 0 && _this.splide.Components.Arrows) {
                    _this.splide.Components.Arrows.arrows.prev.style.display =
                        'none';
                }
                if (_this.startingIndex >= _this.numberOfcarouselPages - 1) {
                    _this.splide.Components.Arrows.arrows.next.style.display =
                        'none';
                }
            }
        });
    };
    /**
     * on slider pagination mounted
     */
    Carousel.prototype.onPagintationMounted = function () {
        var _this = this;
        this.splide.on('pagination:mounted', function (data) {
            _this.numberOfcarouselPages = _this.getNumberOfCarouselPages(_this.splide.options.perPage);
            _this.startingIndex = _this.getStartingContentFromProps(_this.splide.options.perPage);
            var paginationList = data.list;
            var dots = __spreadArray([], __read(paginationList.childNodes), false);
            if (_this.numberOfcarouselPages <= 1 && _this.splide.Components.Arrows) {
                paginationList.innerHTML = '';
                _this.splide.Components.Arrows.arrows.next.style.display =
                    'none';
                _this.splide.Components.Arrows.arrows.prev.style.display =
                    'none';
                _this.splide.options.type = 'slide';
                _this.splide.Components.Clones.destroy();
            }
            // reset pagination dots to avoid the issue of extra dot casued by the normal behaviour of splide
            if (!_this.properties.optWithLoop) {
                data.items = data.items.slice(0, _this.numberOfcarouselPages);
                paginationList.innerHTML = '';
                if (_this.numberOfcarouselPages > 1) {
                    dots = dots.slice(0, _this.numberOfcarouselPages);
                    dots.forEach(function (dot, i) {
                        paginationList.appendChild(dots[i]);
                    });
                }
            }
            if (!_this.numberOfElementsDisplayedPerPage) {
                _this.numberOfElementsDisplayedPerPage =
                    _this.splide.options.perPage;
            }
            else if (_this.numberOfElementsDisplayedPerPage !==
                _this.splide.options.perPage) {
                _this.numberOfElementsDisplayedPerPage =
                    _this.splide.options.perPage;
                // reset pagination status after removing the extra pagination dots
                setTimeout(function () {
                    __spreadArray([], __read(paginationList.getElementsByClassName('is-active')), false).forEach(function (element) {
                        element.classList.remove('is-active');
                    });
                    _this.splide.go(">".concat(_this.startingIndex));
                });
            }
            // `items` contains all dot items
            data.items.forEach(function (item) {
                item.button.disabled = true;
                item.button.ariaHidden;
            });
        });
    };
    /**
     * on slide moved
     */
    Carousel.prototype.onMoved = function () {
        var _this = this;
        this.splide.on('moved', function (currentIndex) {
            var e_1, _a;
            if (!_this.properties.optWithLoop && _this.splide.Components.Arrows) {
                if (currentIndex >= _this.numberOfcarouselPages - 1) {
                    _this.splide.Components.Arrows.arrows.next.style.display = 'none';
                }
                else {
                    _this.splide.Components.Arrows.arrows.next.style.display = 'block';
                }
                if (currentIndex >= 1) {
                    _this.splide.Components.Arrows.arrows.prev.style.display = 'block';
                }
                else {
                    _this.splide.Components.Arrows.arrows.prev.style.display = 'none';
                }
            }
            var buttons = _this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_BASE_CLASSNAME);
            try {
                // Loop through each button and set a different aria-label
                for (var buttons_1 = __values(buttons), buttons_1_1 = buttons_1.next(); !buttons_1_1.done; buttons_1_1 = buttons_1.next()) {
                    var button = buttons_1_1.value;
                    if (button.parentElement.classList.contains(CAROUSEL_SLIDER_ARROW_NEXT)) {
                        button.setAttribute('aria-label', _this.splide.Components.Arrows.arrows.next.ariaLabel);
                    }
                    if (button.parentElement.classList.contains(CAROUSEL_SLIDER_ARROW_PREV)) {
                        button.setAttribute('aria-label', _this.splide.Components.Arrows.arrows.prev.ariaLabel);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (buttons_1_1 && !buttons_1_1.done && (_a = buttons_1.return)) _a.call(buttons_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
        this.splide.Components.Elements.slides.forEach(function (slideElement) {
            slideElement.addEventListener('mousedown', function (event) {
                _this.dragStartX = event.clientX;
            });
            slideElement.addEventListener('mouseup', function (event) {
                _this.dragEndX = event.clientX;
                if (_this.dragEndX < _this.dragStartX) {
                    _this.goNext();
                }
                else if (_this.dragEndX > _this.dragStartX) {
                    _this.goPrev();
                }
            });
        });
        window.document.addEventListener('keydown', function (event) {
            switch (event.key) {
                case 'ArrowRight':
                    _this.goNext();
                    break;
                case 'ArrowLeft':
                    _this.goPrev();
                    break;
                default:
                    break;
            }
        });
    };
    /** go to next slide  */
    Carousel.prototype.goNext = function () {
        // eslint-disable-next-line max-len
        if (this.splide.index + 1 < (this.properties.optWithLoop ? this.splide.Components.Controller.getEnd() + 1 : this.numberOfcarouselPages)) {
            this.splide.Components.Controller.go("".concat(this.splide.index + 1));
            this.splide.Components.Controller.go('>');
        }
        else if (this.properties.optWithLoop) {
            this.splide.Components.Controller.go(0);
        }
        else {
            this.splide.Components.Controller.go("".concat(this.splide.Components.Controller.getEnd() - 1));
        }
    };
    /** got to prev slide */
    Carousel.prototype.goPrev = function () {
        // eslint-disable-next-line max-len
        if (this.splide.index + 1 >= (this.properties.optWithLoop ? this.splide.Components.Controller.getEnd() - 1 : this.numberOfcarouselPages)) {
            this.splide.Components.Controller.go("".concat(this.splide.index - 1));
            this.splide.Components.Controller.go('<');
        }
        else if (this.properties.optWithLoop) {
            this.splide.Components.Controller.go(this.splide.Components.Controller.getEnd());
        }
        else {
            this.splide.Components.Controller.go("-".concat(this.startingIndex));
        }
    };
    /**
     * on slider view updated
     */
    Carousel.prototype.onUpdated = function () {
        var _this = this;
        this.splide.on('updated', function () {
            _this.splide.options.type = _this.properties.optWithLoop
                ? CROUSEL_LOOPING_TYPE
                : 'slide';
            _this.splide.Components.Clones.mount();
            _this.numberOfcarouselPages = _this.getNumberOfCarouselPages(_this.splide.options.perPage);
            if (_this.numberOfcarouselPages > 1) {
                _this.splide.Components.Pagination.mount();
                _this.splide.Components.Arrows.mount();
                _this.splide.Components.Elements.arrows.style.display = CAROUSEL_ARROWS_DISPLAY;
            }
            else {
                _this.splide.Components.Pagination.destroy();
                _this.splide.Components.Arrows.destroy();
                _this.splide.Components.Elements.arrows.style.display = 'none';
            }
            if (!_this.properties.optHideArrow) {
                _this.splide.Components.Arrows.arrows.next.style.display =
                    'block';
                _this.splide.Components.Arrows.arrows.prev.style.display =
                    'block';
                if (!_this.properties.optWithLoop) {
                    if (_this.startingIndex === 0) {
                        _this.splide.Components.Arrows.arrows.prev.style.display =
                            'none';
                    }
                    if (_this.startingIndex >= _this.numberOfcarouselPages - 1) {
                        _this.splide.Components.Arrows.arrows.next.style.display =
                            'none';
                    }
                }
            }
        });
    };
    /**
     * This function remove focus on mouse leave
     */
    Carousel.prototype.removeFocusOnMouseLeave = function (items) {
        var e_2, _a;
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                item.addEventListener('mouseleave', function (event) {
                    return event.currentTarget.blur();
                });
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Carousel.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /** create arrows */
    Carousel.prototype.initDomElements = function () {
        var _this = this;
        var buttonIconOnlyPrevContainer = this.containerElement.getElementsByClassName(CAROUSEL_SLIDER_ARROW_PREV)[0];
        var buttonIconOnlyNextContainer = this.containerElement.getElementsByClassName(CAROUSEL_SLIDER_ARROW_NEXT)[0];
        var nextButtonProps = __assign({}, CAROUSEL_NAVIGATION_BUTTONS_PROPS.next);
        var prvButtonProps = __assign({}, CAROUSEL_NAVIGATION_BUTTONS_PROPS.prev);
        var buttons = this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_BASE_CLASSNAME);
        buttonIconOnlyPrevContainer && createButtonIconOnly(buttonIconOnlyPrevContainer, prvButtonProps);
        buttonIconOnlyNextContainer && createButtonIconOnly(buttonIconOnlyNextContainer, nextButtonProps);
        setTimeout(function () {
            var e_3, _a, e_4, _b;
            try {
                // Loop through each button and set a different aria-label
                for (var buttons_2 = __values(buttons), buttons_2_1 = buttons_2.next(); !buttons_2_1.done; buttons_2_1 = buttons_2.next()) {
                    var button = buttons_2_1.value;
                    if (button.parentElement.classList.contains(CAROUSEL_SLIDER_ARROW_NEXT)) {
                        button.setAttribute('aria-label', _this.splide.Components.Arrows.arrows.next.ariaLabel);
                    }
                    if (button.parentElement.classList.contains(CAROUSEL_SLIDER_ARROW_PREV)) {
                        button.setAttribute('aria-label', _this.splide.Components.Arrows.arrows.prev.ariaLabel);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (buttons_2_1 && !buttons_2_1.done && (_a = buttons_2.return)) _a.call(buttons_2);
                }
                finally { if (e_3) throw e_3.error; }
            }
            var icons = _this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY);
            try {
                for (var icons_1 = __values(icons), icons_1_1 = icons_1.next(); !icons_1_1.done; icons_1_1 = icons_1.next()) {
                    var icon = icons_1_1.value;
                    icon.setAttribute('aria-hidden', 'true');
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (icons_1_1 && !icons_1_1.done && (_b = icons_1.return)) _b.call(icons_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }, 0);
        // initialize tariff-cards to align them vertically depending on if they have a flag-badge or not
        createComponents(".".concat(TARIFF_CARD_BASE_CLASSNAME), createTariffCards, NO_PATTERN_BUSINESS_LOGIC, this.containerElement);
    };
    /**
     * All rendering gets done with this function
     */
    Carousel.prototype.writeDom = function () {
        var _a, _b, _c;
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.totalNumberOfContentElements =
            (_b = (_a = this.properties.contentList) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
        if ((_c = this.properties) === null || _c === void 0 ? void 0 : _c.optInverse) {
            this.properties.nextBtn.optInverse = true;
            this.properties.prevBtn.optInverse = true;
        }
        else {
            this.properties.nextBtn.optInverse = false;
            this.properties.prevBtn.optInverse = false;
        }
        this.initDomElements();
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    Carousel.prototype.initEvents = function () {
        var _this = this;
        var _a;
        this.initSlider();
        var sliderItems = this.containerElement.getElementsByClassName(CAROUSEL_SLIDER_SLIDE);
        this.removeFocusOnMouseLeave(sliderItems);
        var slidingContainer = this.containerElement.getElementsByClassName("".concat(CAROUSEL_SLIDER_LIST))[0];
        slidingContainer.focus();
        if ((_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) {
            var nextBtnBusiness = {
                onClick: function (event) { var _a; return (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business.onClickNext(event); },
            };
            var prevBtnBusiness = {
                onClick: function (event) { var _a; return (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business.onClickPrev(event); },
            };
            this.properties.nextBtn.business = nextBtnBusiness;
            this.properties.prevBtn.business = prevBtnBusiness;
        }
    };
    /**
     * getting properties from DOM
     */
    Carousel.prototype.readDom = function (carouselBusinessLogic) {
        var _a;
        this.carouselElementList = this.containerElement.getElementsByClassName(CAROUSEL_SLIDER_LIST)[0];
        var withLoop = this.carouselElementList.dataset.withloop === 'true';
        this.totalNumberOfContentElements =
            this.carouselElementList.childElementCount;
        var numberOfElementsDisplayedPerLargePage = parseInt(this.carouselElementList.dataset.contentNumberLarge, 10);
        var numberOfElementsDisplayedPerMediumPage = parseInt(this.carouselElementList.dataset.contentNumberMedium, 10);
        var numberOfElementsDisplayedPerSmallPage = parseInt(this.carouselElementList.dataset.contentNumberSmall, 10);
        var hideArrow = this.carouselElementList.dataset.hideArrows === 'true';
        var contentList = [];
        __spreadArray([], __read(this.carouselElementList.children), false).forEach(function (element) {
            if (!element.classList.contains('splide__slide--clone')) {
                contentList.push({
                    optStarterContent: element.dataset.startingContent === 'true',
                    stdContent: element.innerHTML,
                });
            }
        });
        var optInverse = (_a = this.containerElement.getElementsByClassName(SPLIDE_ARROWS)[0]) === null || _a === void 0 ? void 0 : _a.classList.contains(CAROUSEL_INVERSE);
        if (optInverse) {
            CAROUSEL_NAVIGATION_BUTTONS_PROPS.next.optInverse = true;
            CAROUSEL_NAVIGATION_BUTTONS_PROPS.prev.optInverse = true;
        }
        else {
            CAROUSEL_NAVIGATION_BUTTONS_PROPS.next.optInverse = false;
            CAROUSEL_NAVIGATION_BUTTONS_PROPS.prev.optInverse = false;
        }
        this.initDomElements();
        return removeUndefinedFieldsFromObject({
            stdSizeOfContentPerContainerLarge: numberOfElementsDisplayedPerLargePage,
            stdSizeOfContentPerContainerMedium: numberOfElementsDisplayedPerMediumPage,
            stdSizeOfContentPerContainerSmall: numberOfElementsDisplayedPerSmallPage,
            optWithLoop: withLoop,
            optHideArrow: hideArrow,
            contentList: contentList,
            nextBtn: CAROUSEL_NAVIGATION_BUTTONS_PROPS.next,
            prevBtn: CAROUSEL_NAVIGATION_BUTTONS_PROPS.prev,
            optInverse: optInverse,
            business: carouselBusinessLogic,
        });
    };
    return Carousel;
}(Pattern));
export { Carousel };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of carousel
 */
export var createCarousel = function (containerElement, properties) {
    var carousel = new Carousel(containerElement, properties);
    carousel.init();
    return carousel;
};
