export var ifCondHelper = function (v1, operator, v2, options) {
    /**
     * Attention
     * Brix, jest and rollup expect slightly different exports
     * Therefor this helper exists three times:
     *
     * brix/helpers/ifCond.js
     * handlebars-helper/rollup/ifCond.js
     * handlebars-helper/jest/ifCond.js
     *
     * Make sure to do every change in every of these files
     *
     * Or do the right thing and remove duplicated code
     * https://jira.wf-de.vodafone.com/browse/BRIX-42
     */
    /*eslint-disable */
    /**
     * Allow handlebar if conditions
     * https://stackoverflow.com/questions/8853396/logical-operator-in-a-handlebars-js-if-conditional
     */
    switch (operator) {
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        case 'NOR':
            return (!v1 && !v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
    /* eslint-enable */
};
var registerIfCondHelper = function (Handlebars) {
    Handlebars.registerHelper('ifCond', ifCondHelper);
};
export default registerIfCondHelper;
