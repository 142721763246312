var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "     <div class=\"splide__arrows splide__arrows--ltr ws10-carousel-slider__arrows "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":128}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"prevBtn") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nextBtn") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":9},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n     </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "ws10-carousel--inverse";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div id=\"prev-arrow\" class=\" splide__arrow splide__arrow--prev ws10-carousel-slider__arrow ws10-carousel-slider__arrow--prev\" aria-controls=\"ws10-carousel-slider__track\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),(depth0 != null ? lookupProperty(depth0,"prevBtn") : depth0),{"name":"_button-icon-only","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "         </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"splide__arrow splide__arrow--next ws10-carousel-slider__arrow ws10-carousel-slider__arrow--next\" aria-controls=\"ws10-carousel-slider__track\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),(depth0 != null ? lookupProperty(depth0,"nextBtn") : depth0),{"name":"_button-icon-only","data":data,"indent":"                 ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-carousel__hide-Arrow-slide ";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-hide-arrows=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optHideArrow") || (depth0 != null ? lookupProperty(depth0,"optHideArrow") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optHideArrow","hash":{},"data":data,"loc":{"start":{"line":23,"column":177},"end":{"line":23,"column":193}}}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-withloop=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optWithLoop") || (depth0 != null ? lookupProperty(depth0,"optWithLoop") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optWithLoop","hash":{},"data":data,"loc":{"start":{"line":23,"column":237},"end":{"line":23,"column":252}}}) : helper)))
    + "\" ";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-content-number-large=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSizeOfContentPerContainerLarge") || (depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerLarge") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSizeOfContentPerContainerLarge","hash":{},"data":data,"loc":{"start":{"line":23,"column":330},"end":{"line":23,"column":367}}}) : helper)))
    + "\"";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-content-number-medium=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSizeOfContentPerContainerMedium") || (depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerMedium") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSizeOfContentPerContainerMedium","hash":{},"data":data,"loc":{"start":{"line":23,"column":446},"end":{"line":23,"column":484}}}) : helper)))
    + "\"";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-content-number-small=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSizeOfContentPerContainerSmall") || (depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerSmall") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSizeOfContentPerContainerSmall","hash":{},"data":data,"loc":{"start":{"line":23,"column":561},"end":{"line":23,"column":598}}}) : helper)))
    + "\" ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li draggable=\"true\" class=\"ws10-column  splide__slide ws10-carousel-slider__slide\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optStarterContent") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":94},"end":{"line":25,"column":172}}})) != null ? stack1 : "")
    + " role=\"tab\">\n\n             "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdContent") || (depth0 != null ? lookupProperty(depth0,"stdContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stdContent","hash":{},"data":data,"loc":{"start":{"line":27,"column":13},"end":{"line":27,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n\n\n\n          </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-starting-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optStarterContent") || (depth0 != null ? lookupProperty(depth0,"optStarterContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optStarterContent","hash":{},"data":data,"loc":{"start":{"line":25,"column":143},"end":{"line":25,"column":164}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-carousel ws10-belt-container\">\n    <div class=\"ws10-grid splide ws10-carousel-slider\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optHideArrow") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":9},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n\n      <div class=\"splide__track ws10-carousel-slider__track ws10-grid__inner\">\n\n        <ul class=\"splide__list ws10-carousel-slider__list ws10-grid__inner "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optHideArrow") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":76},"end":{"line":23,"column":136}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optHideArrow") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":138},"end":{"line":23,"column":201}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optWithLoop") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":202},"end":{"line":23,"column":261}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerLarge") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":262},"end":{"line":23,"column":375}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerMedium") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":376},"end":{"line":23,"column":492}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdSizeOfContentPerContainerSmall") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":493},"end":{"line":23,"column":607}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contentList") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "\n        </ul>\n\n      </div>\n\n\n    </div>\n\n</div>\n\n\n\n";
},"usePartial":true,"useData":true});