import { assignWith, isPlainObject, } from 'lodash';
var customizer = function (objValue, srcValue, key, target, source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
        // If object is sealed (NO_PATTERN_BUSINESS_LOGIC, or primitve values) dont merge props, overwrite them
        if (Object.isSealed(srcValue)) {
            return srcValue;
        }
        // If both objValue and srcValue are objects, continue deep merging
        if (isPlainObject(objValue) && isPlainObject(srcValue)) {
            return assignWith({}, objValue, srcValue, customizer);
        }
        // For any values other than undefined and object, return srcValue to override objValue
        return srcValue;
    }
};
/**
 * Merge old and new properties
 * Allows merging where oldProperties has a defined value, but newProperties would override it with undefined (unsupported natively by lodash)
 */
export var mergeProperties = function (oldProperties, newProperties) {
    return assignWith(oldProperties, newProperties, customizer);
};
