/**
 * Pattern class name
 */
export var MEDIA_TEXT_BASE_CLASSNAME = 'ws10-media-text';
/**
 * Media wrapper
 */
export var MEDIA_CLASSNAME = "".concat(MEDIA_TEXT_BASE_CLASSNAME, "__media");
/**
 * Segment wrapper
 */
export var SEGMENT_CLASSNAME = "".concat(MEDIA_TEXT_BASE_CLASSNAME, "__segment");
/**
 * Segment headline
 */
export var SEGMENT_HEADLINE = "".concat(MEDIA_TEXT_BASE_CLASSNAME, "__headline");
/**
 * Segment copytext
 */
export var SEGMENT_COPYTEXT = "".concat(MEDIA_TEXT_BASE_CLASSNAME, "__copytext");
/**
 * Segment buttons
 */
export var SEGMENT_BUTTONS_CONTAINER = "".concat(MEDIA_TEXT_BASE_CLASSNAME, "__button-container");
/**
 * Media position variation: media-left
 */
var MEDIA_TEXT_IMAGE_POSITION_MEDIA_LEFT = 'media-left';
/**
 * Media position variation: media-right
 */
var MEDIA_TEXT_IMAGE_POSITION_MEDIA_RIGHT = 'media-right';
/**
 * Media position variation: media-top
 */
var MEDIA_TEXT_IMAGE_POSITION_MEDIA_TOP = 'media-top';
/**
 * Media position variations
 */
export var MEDIA_TEXT_IMAGE_POSITION_VARIATIONS = [
    MEDIA_TEXT_IMAGE_POSITION_MEDIA_LEFT,
    MEDIA_TEXT_IMAGE_POSITION_MEDIA_RIGHT,
    MEDIA_TEXT_IMAGE_POSITION_MEDIA_TOP,
];
/**
 * OptColumnWidth: 3
 */
var COLUMN_WIDTH_3 = '3';
/**
 * OptColumnWidth: 4
 */
var COLUMN_WIDTH_4 = '4';
/**
 * OptColumnWidth: 6
 */
var COLUMN_WIDTH_6 = '6';
/**
 * OptColumnWidth: 12
 */
var COLUMN_WIDTH_12 = '12';
/**
 * OptColumnWidths
 */
export var COLUMN_WIDTH_VARIATIONS = [
    COLUMN_WIDTH_3,
    COLUMN_WIDTH_4,
    COLUMN_WIDTH_6,
    COLUMN_WIDTH_12,
];
