/**
 * Base class name
 */
export var LINEAR_STEPPER_BASE_CLASSNAME = 'ws10-linear-stepper';
/**
 * ClassName for linear stepper container
 */
export var LINEAR_STEPPER_CONTAINER_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__container");
/**
 * ClassName for linear stepper inverse mode
 */
export var LINEAR_STEPPER_INVERSE_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "--inverse");
/**
 * ClassName for linear stepper when it is centered from medium to x large screens
 */
export var LINEAR_STEPPER_CENTERED_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "--center");
/**
 * ClassName for linear stepper step
 */
export var LINEAR_STEPPER_STEP_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__step");
/**
 * ClassName for linear stepper steps container
 */
export var LINEAR_STEPPER_STEPS_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__steps");
/**
 * ClassName for linear stepper dynamic steps container
 */
export var LINEAR_STEPPER_STEP_DYNAMIC_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__steps--dynamic");
/**
 * ClassName for linear stepper static steps container
 */
export var LINEAR_STEPPER_STEP_STATIC_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__steps--static");
/**
 * ClassName for linear stepper active step outer circle
 */
export var LINEAR_STEPPER_ACTIVE_STEP_CIRCLE_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__step--active-circle");
/**
 * ClassName for linear stepper finished step
 */
export var LINEAR_STEPPER_FINISHED_STEP_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__step--finished");
/**
 * ClassName for linear stepper active step
 */
export var LINEAR_STEPPER_ACTIVE_STEP_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__step--active");
/**
 * ClassName for the footnote (Step) text if it is provided by user
 */
export var LINEAR_STEPPER_FOOTNOTE_STEP_TEXT_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__footnote-step-text");
/**
 * ClassName for the footnote Step (of) text if it is provided by user
 */
export var LINEAR_STEPPER_FOOTNOTE_TOTAL_TEXT_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__footnote-total-text");
/**
 * ClassName for the footnote (Step) text container
 */
export var LINEAR_STEPPER_FOOTNOTE_STEP_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__footnote-step");
/**
 * ClassName for the footnote Step (of) text container
 */
export var LINEAR_STEPPER_FOOTNOTE_TOTAL_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__footnote-total");
/**
 * ClassName for linear stepper current step number
 */
export var LINEAR_STEPPER_STEP_NUMBER_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__current-step");
/**
 * ClassName for linear stepper steps length
 */
export var LINEAR_STEPPER_STEPS_LENGTH_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__steps-number");
/**
 * ClassName for linear stepper steps button item
 */
export var LINEAR_STEPPER_STEP_BUTTON_ITEM_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__step-item");
/**
 * ClassName for dynamic linear stepper buttons container that has prev button
 */
export var LINEAR_STEPPER_DYNAMIC_BTNS_WITH_PREV_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-buttons--with-prev-btn");
/**
 * ClassName for dynamic linear stepper buttons container
 */
export var LINEAR_STEPPER_DYNAMIC_BUTTONS_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-buttons");
/**
 * ClassName for dynamic linear stepper next button
 */
export var LINEAR_STEPPER_DYNAMIC_NEXT_BUTTON_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-button-next");
/**
 * ClassName for dynamic linear stepper prev button
 */
export var LINEAR_STEPPER_DYNAMIC_PREV_BUTTON_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-button-prev");
/**
 * ClassName for dynamic linear stepper for each step content
 */
export var LINEAR_STEPPER_DYNAMIC_CONTENT_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-content");
/**
 * ClassName for dynamic linear stepper for steps dynamic content container
 */
export var LINEAR_STEPPER_DYNAMIC_CONTENT_CONTAINER_CLASSNAME = "".concat(LINEAR_STEPPER_BASE_CLASSNAME, "__dynamic-contents");
/**
 * Static keys
 */
export var STATIC_KEYS = {
    enter: 'Enter',
    add: 'add',
    remove: 'remove',
    none: 'none',
    block: 'block',
    flexEnd: 'flex-end',
    flexSpaceBetween: 'space-between',
    space100: '16px',
};
/**
 * Prefix for dynamic step content ids
 */
export var STEPS_CONTENT_ID_PREFIX = 'content-';
/**
 * Mobilethreshold
 */
export var MOBILE_THRESHOLD = 768;
/**
 * linear stepper attriute for no of grid system columns
 */
export var LINEAR_STEPPER_CONTENT_COLUMNS_ATTR = 'data-linear-stepper-contentColumns';
/**
 * linear stepper attriute for stepper current active step
 */
export var LINEAR_STEPPER_ACTIVE_STEP_ATTR = 'data-active-step';
/**
 * linear stepper attriute for static stepper current step linkHref
 */
export var LINEAR_STEPPER_STATIC_STEP_LINK_HREF_ATTR = 'data-link-href';
/**
 * linear stepper attriute for dynamic stepper next btn text (entered from user not default one)
 */
export var LINEAR_STEPPER_DYNAMIC_NEXT_BTN_TEXT_ATTR = 'data-next-btn-text';
/**
 * linear stepper attriute for dynamic stepper prev btn text (entered from user not default one)
 */
export var LINEAR_STEPPER_DYNAMIC_PREV_BTN_TEXT_ATTR = 'data-prev-btn-text';
/**
 * default value for dynamic linear stepper prev button text
 */
export var LINEAR_STEPPER_DYNAMIC_PREV_BTN_DEFAULT_TEXT = 'Zurück';
/**
 * default value for dynamic linear stepper next button text
 */
export var LINEAR_STEPPER_DYNAMIC_NEXT_BTN_DEFAULT_TEXT = 'Weiter';
