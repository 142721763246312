var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './grid.scss';
import renderTemplate from './_grid.hbs';
import { Pattern, getPropertiesFromClassName, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { getVariationsMap } from '@vfde-brix/ws10/grid/getVariationsMap';
import { GRID_BASE_CLASSNAME, GRID_NO_VERTICAL_CUTTER_CLASSNAME, } from '@vfde-brix/ws10/grid/Constants';
/**
 * WS10 Grid
 */
var Grid = /** @class */ (function (_super) {
    __extends(Grid, _super);
    function Grid() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    Grid.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    Grid.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    Grid.prototype.readDom = function (business) {
        var containerColumns = [];
        var gridElement = this.containerElement.getElementsByClassName(GRID_BASE_CLASSNAME)[0];
        var columnElements = this.containerElement.getElementsByClassName('ws10-column');
        var optVerticalGutter;
        if (gridElement.classList.contains(GRID_NO_VERTICAL_CUTTER_CLASSNAME)) {
            optVerticalGutter = false;
        }
        var variantionsMap = getVariationsMap();
        var getViewportWidth = function (viewportPrefix, width) {
            if (!width) {
                return undefined;
            }
            width = width.replace(viewportPrefix, '');
            var columnWidth = parseInt(width, 10);
            return columnWidth;
        };
        for (var i = 0, x = columnElements.length; i < x; i += 1) {
            var columnElement = columnElements[i];
            var containerContent = columnElement.innerHTML.trim();
            var propertiesFromClassName = getPropertiesFromClassName(columnElement.className, variantionsMap);
            var width = getViewportWidth('', propertiesFromClassName.width);
            var widthSmall = getViewportWidth('small-', propertiesFromClassName.widthSmall);
            var widthMedium = getViewportWidth('medium-', propertiesFromClassName.widthMedium);
            var widthLarge = getViewportWidth('large-', propertiesFromClassName.widthLarge);
            var widthXLarge = getViewportWidth('x-large-', propertiesFromClassName.widthXLarge);
            var columnProperties = removeUndefinedFieldsFromObject({
                containerContent: containerContent,
                width: width,
                widthSmall: widthSmall,
                widthMedium: widthMedium,
                widthLarge: widthLarge,
                widthXLarge: widthXLarge,
            });
            containerColumns.push(columnProperties);
        }
        var properties = {
            optVerticalGutter: optVerticalGutter,
            containerColumns: containerColumns,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return Grid;
}(Pattern));
export { Grid };
/**
 * This function returns an instance of Grid
 */
export var createGrid = function (containerElement, properties) {
    var grid = new Grid(containerElement, properties);
    grid.init();
    return grid;
};
