/**
 * The possible Promo-Price Sizes
 */
export var PromoPriceVariant;
(function (PromoPriceVariant) {
    PromoPriceVariant["Small"] = "small";
    PromoPriceVariant["Medium"] = "medium";
})(PromoPriceVariant || (PromoPriceVariant = {}));
/**
 * Size Variation small
 */
export var PROMO_PRICE_SIZE_SMALL = PromoPriceVariant.Small;
/**
 * Size Variation medium
 */
export var PROMO_PRICE_SIZE_MEDIUM = PromoPriceVariant.Medium;
/**
 * Size Variations
 */
export var PROMO_PRICE_SIZES = [
    PROMO_PRICE_SIZE_SMALL,
    PROMO_PRICE_SIZE_MEDIUM,
];
/**
 * Alignment variations for v2
 */
export var PromoPriceAlign;
(function (PromoPriceAlign) {
    PromoPriceAlign["Left"] = "left";
    PromoPriceAlign["Center"] = "center";
    PromoPriceAlign["Right"] = "right";
})(PromoPriceAlign || (PromoPriceAlign = {}));
export var PROMO_PRICE_ALIGN_LEFT = PromoPriceAlign.Left;
export var PROMO_PRICE_ALIGN_CENTER = PromoPriceAlign.Center;
export var PROMO_PRICE_ALIGN_RIGHT = PromoPriceAlign.Right;
/**
 * Size Variations
 */
export var PROMO_PRICE_ALIGNS = [
    PROMO_PRICE_ALIGN_LEFT,
    PROMO_PRICE_ALIGN_CENTER,
    PROMO_PRICE_ALIGN_RIGHT,
];
export var PROMO_PRICE_BASE_CLASSNAME = 'ws10-promo-price';
export var PROMO_PRICE_STRIKE_PRICE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__strike-price");
export var PROMO_PRICE_PRICE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__price");
export var PROMO_PRICE_CONDITION_BEFORE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__condition-before");
export var PROMO_PRICE_CONDITION_AFTER_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__condition-after");
export var PROMO_PRICE_INVERSE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "--inverse");
