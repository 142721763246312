var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "ws10-icon-text-banner__headlines-container ";
},"3":function(container,depth0,helpers,partials,data) {
    return "ws10-icon-text-banner__headlines-container-centered ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-icon-text-banner__icon-text-container\">\n                <div class=\"ws10-grid__inner\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"iconTextItems") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":15,"column":29}}})) != null ? stack1 : "")
    + "                </div >\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"ws10-icon-text-banner__icon-text ws10-column ws10-column--12 ws10-column--small-6\">\n"
    + ((stack1 = container.invokePartial(require("../icon-text/_icon-text.hbs"),depth0,{"name":"_icon-text","hash":{"optAlignment":"left","optIconSize":"medium"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-icon-text-banner__buttons-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":25,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"ws10-icon-text-banner__buttons\" >\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),depth0,{"name":"_button","hash":{"optWidthVariation":"hybrid","optSize":"standard"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-icon-text-banner\">\n    <div class=\"ws10-icon-text-banner__container ws10-icon-text-banner--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optBackgroundColor") || (depth0 != null ? lookupProperty(depth0,"optBackgroundColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optBackgroundColor","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":94}}}) : helper)))
    + "\">\n        <div class=\"ws10-grid ws10-belt-container ws10-belt-container__belt\">\n            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconTextItems") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":95}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconTextItems") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":184}}})) != null ? stack1 : "")
    + "\">\n                  <h2 class=\"ws10-icon-text-banner__headline\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":80}}}) : helper)))
    + "</h2>\n                <div class=\"ws10-icon-text-banner__sub-headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdSubHeadline") || (depth0 != null ? lookupProperty(depth0,"stdSubHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSubHeadline","hash":{},"data":data,"loc":{"start":{"line":6,"column":65},"end":{"line":6,"column":85}}}) : helper)))
    + "</div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconTextItems") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});