import { TABS_URL_PARAM_NAME } from './Constants';
/**
 * Class handles parameters for deeplink
 */
var TabsParamService = /** @class */ (function () {
    function TabsParamService() {
    }
    /**
     * to add param value to the url
     */
    TabsParamService.prototype.setActiveTabUrlParam = function (tabId) {
        var url = new URL(window.location.href);
        url.searchParams.set(TABS_URL_PARAM_NAME, tabId);
        window.history.replaceState({}, '', url.href);
    };
    /**
     * to return the value of a given param from the url
     */
    TabsParamService.prototype.getActiveTabUrlParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        return urlParams.get(TABS_URL_PARAM_NAME);
    };
    return TabsParamService;
}());
export { TabsParamService };
