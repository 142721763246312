/**
 * Base class name
 */
export var NOTIFICATION_ICON_BASE_CLASSNAME = 'ws10-notification-icon';
/**
 * Color Variations
 */
export var NOTIFICATION_ICON_STATES = [
    'success',
    'info',
    'error',
];
/**
 * Enum for Color Variations
 */
export var NotificationIconStates;
(function (NotificationIconStates) {
    NotificationIconStates["NotificationSuccess"] = "success";
    NotificationIconStates["NotificationInfo"] = "info";
    NotificationIconStates["NotificationWarn"] = "warn";
    NotificationIconStates["NotificationError"] = "error";
})(NotificationIconStates || (NotificationIconStates = {}));
