var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './form-textarea.scss';
import renderTemplate from './_form-textarea.hbs';
import { Pattern, getPropertiesFromClassName, removeUndefinedFieldsFromObject, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { FORM_TEXTAREA_TEXTAREA_CLASSNAME, FORM_TEXTAREA_CLASSNAME, FORM_TEXTAREA_CLASSNAME_DISABLED, FORM_TEXTAREA_CLASSNAME_ERROR, FORM_TEXTAREA_CLASSNAME_SUCCESS, FORM_TEXTAREA_CLASSNAME_WARN, FORM_TEXTAREA_AUTO_HEIGHT_CLASSNAME, FORM_TEXTAREA_NOTIFICATION_ICON_CLASSNAME, } from './Constants';
import { addFormElementEventHandlers, elementRemoveStateOnDownEventHandler, FORM_ELEMENT_STATES, } from '@vfde-brix/ws10/form-text-input';
import { createNotificationIcon, } from '@vfde-brix/ws10/notification-icon';
/**
 * Class FormTextarea.
 */
var FormTextarea = /** @class */ (function (_super) {
    __extends(FormTextarea, _super);
    function FormTextarea() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FormTextarea.prototype.getDefaultProperties = function (newProperties) {
        /**
         * All form elements must have a type.
         */
        newProperties.optType = 'textarea';
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     */
    FormTextarea.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * returns always an empty object or with the necessary properties.
     * Dynamic: this.properties.optState
     * Static: empty object
     */
    FormTextarea.prototype.getNotificationIconProperties = function () {
        if (this.properties) {
            return {
                optState: this.properties.optState,
                business: NO_PATTERN_BUSINESS_LOGIC,
            };
        }
        return NO_PATTERN_BUSINESS_LOGIC;
    };
    /**
     * Returns the input element
     */
    FormTextarea.prototype.getInputElement = function () {
        return this.containerElement.getElementsByClassName(FORM_TEXTAREA_TEXTAREA_CLASSNAME)[0];
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    FormTextarea.prototype.initDomElements = function () {
        var notificationIconProps = this.getNotificationIconProperties();
        this.notificationIconContainerElement = this.containerElement
            .getElementsByClassName(FORM_TEXTAREA_NOTIFICATION_ICON_CLASSNAME)[0];
        /* istanbul ignore else */
        if (this.notificationIconContainerElement) {
            this.notificationIconComponent = createNotificationIcon(this.notificationIconContainerElement, notificationIconProps);
        }
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FormTextarea.prototype.initEvents = function () {
        var _this = this;
        var element = this.containerElement.getElementsByClassName(FORM_TEXTAREA_CLASSNAME)[0];
        var textarea = this.containerElement.getElementsByClassName(FORM_TEXTAREA_TEXTAREA_CLASSNAME)[0];
        addFormElementEventHandlers(this.getProperties(), textarea);
        textarea.addEventListener('keydown', function () {
            elementRemoveStateOnDownEventHandler(_this.getProperties(), element, FORM_TEXTAREA_CLASSNAME);
        });
        if (this.properties.optAutoHeight === true) {
            textarea.addEventListener('input', function () {
                var style = getComputedStyle(textarea);
                var borderTop = parseInt(style.borderTopWidth, 10);
                var borderBottom = parseInt(style.borderBottomWidth, 10);
                var paddingTop = parseInt(style.paddingTop, 10);
                var paddingBottom = parseInt(style.paddingBottom, 10);
                var lineHeight = parseInt(style.lineHeight, 10);
                var combined = borderTop + borderBottom + paddingTop + paddingBottom;
                var maxHeight = (5 * lineHeight) + combined;
                // set height of textarea to something small ;)
                textarea.style.height = '0px';
                // grab scrollHeight of this very small textarea
                var newHeight = textarea.scrollHeight;
                if (maxHeight < newHeight) {
                    newHeight = maxHeight;
                    textarea.style.overflowY = 'scroll';
                }
                else {
                    textarea.style.overflowY = 'hidden';
                }
                textarea.style.height = "".concat(newHeight, "px");
            });
        }
    };
    /**
     * Read properties from DOM
     */
    FormTextarea.prototype.readDom = function (business) {
        this.initDomElements();
        var element = this.containerElement.getElementsByClassName(FORM_TEXTAREA_CLASSNAME)[0];
        var textarea = this.containerElement.getElementsByClassName(FORM_TEXTAREA_TEXTAREA_CLASSNAME)[0];
        var propertiesFromClassName = getPropertiesFromClassName(element.className, {
            optState: FORM_ELEMENT_STATES,
        });
        var optState = propertiesFromClassName.optState;
        var optDisabled = element.classList.contains(FORM_TEXTAREA_CLASSNAME_DISABLED);
        var optAutoHeight = element.classList.contains(FORM_TEXTAREA_AUTO_HEIGHT_CLASSNAME) || undefined;
        var id = textarea.id, name = textarea.name, value = textarea.value, placeholder = textarea.placeholder, maxLength = textarea.maxLength;
        var stdPlaceholder = placeholder.length > 0 ? placeholder.trim() : undefined;
        var properties = {
            stdId: id,
            stdName: name,
            stdValue: value.trim(),
            stdPlaceholder: stdPlaceholder,
            optDisabled: optDisabled,
            optState: optState,
            optAutoHeight: optAutoHeight,
            business: business,
        };
        // since we don't want to include a default value or -1 if it's not found let's add it with a condition
        maxLength && (properties.stdMaxLength = maxLength);
        return (removeUndefinedFieldsFromObject(properties));
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormTextarea.prototype.handleUpdateState = function (optState) {
        var _a;
        var stateClasses = {
            error: FORM_TEXTAREA_CLASSNAME_ERROR,
            warn: FORM_TEXTAREA_CLASSNAME_WARN,
            success: FORM_TEXTAREA_CLASSNAME_SUCCESS,
        };
        var baseElement = this.containerElement.getElementsByClassName(FORM_TEXTAREA_CLASSNAME)[0];
        (_a = baseElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        if (optState) {
            baseElement.classList.add(stateClasses[optState]);
        }
        this.handleUpdateNotificationIcon(optState);
    };
    /**
     * Update the notification icon based on the component's `optState` property
     */
    FormTextarea.prototype.handleUpdateNotificationIcon = function (optState) {
        this.notificationIconComponent.update({ optState: optState }, false);
    };
    FormTextarea.prototype.didReceiveProps = function (newProps, oldProperties) {
        var optState = newProps.optState;
        /* istanbul ignore else */
        if (oldProperties.optState !== optState) {
            this.handleUpdateState(optState);
        }
    };
    return FormTextarea;
}(Pattern));
export { FormTextarea };
/**
 * This function returns an instance of FormTextarea
 */
export var createFormTextarea = function (containerElement, businessLogicOrProperties) {
    var formTextarea = new FormTextarea(containerElement, businessLogicOrProperties);
    formTextarea.init();
    return formTextarea;
};
