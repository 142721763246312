/**
 * Base class name
 */
export var HIGHLIGHT_BADGE_BASE_CLASSNAME = 'ws10-highlight-badge';
/**
 * Tooltip classname
 */
export var HIGHLIGHT_BADGE_TOOLTIP_CONTAINER = 'ws10-tooltip';
/**
 * Base classname of the icon of Highlight Badge
 */
export var HIGHLIGHT_BADGE_ICON = "".concat(HIGHLIGHT_BADGE_BASE_CLASSNAME, "__icon");
export var HIGHLIGHT_BADGE_TEXT_CLASSNAME = "".concat(HIGHLIGHT_BADGE_BASE_CLASSNAME, "__text");
/**
 * Classname of the icon on the left side of Highlight Badge
 */
export var HIGHLIGHT_BADGE_ICON_LEFT = "".concat(HIGHLIGHT_BADGE_BASE_CLASSNAME, "__icon--left");
export var HIGHLIGHT_BADGE_COLOR_GREEN = 'green';
export var HIGHLIGHT_BADGE_COLOR_YELLOW = 'yellow';
export var HIGHLIGHT_BADGE_COLOR_RED = 'red';
export var HIGHLIGHT_BADGE_COLOR_BURGUNDY = 'burgundy';
export var HIGHLIGHT_BADGE_COLOR_BLACK = 'black';
export var HIGHLIGHT_BADGE_COLOR_GREEN_INVERSE = 'green-inverse';
export var HIGHLIGHT_BADGE_COLOR_YELLOW_INVERSE = 'yellow-inverse';
export var HIGHLIGHT_BADGE_COLOR_RED_INVERSE = 'red-inverse';
export var HIGHLIGHT_BADGE_COLOR_BURGUNDY_INVERSE = 'burgundy-inverse';
export var HIGHLIGHT_BADGE_COLOR_BLACK_INVERSE = 'black-inverse';
export var HIGHLIGHT_BADGE_SIZE_SMALL = 'small';
export var HIGHLIGHT_BADGE_SIZE_STANDARD = 'standard';
/**
 * Color Variations
 */
export var HIGHLIGHT_BADGE_COLORS = [
    HIGHLIGHT_BADGE_COLOR_GREEN,
    HIGHLIGHT_BADGE_COLOR_YELLOW,
    HIGHLIGHT_BADGE_COLOR_RED,
    HIGHLIGHT_BADGE_COLOR_BURGUNDY,
    HIGHLIGHT_BADGE_COLOR_BLACK,
    HIGHLIGHT_BADGE_COLOR_GREEN_INVERSE,
    HIGHLIGHT_BADGE_COLOR_YELLOW_INVERSE,
    HIGHLIGHT_BADGE_COLOR_RED_INVERSE,
    HIGHLIGHT_BADGE_COLOR_BURGUNDY_INVERSE,
    HIGHLIGHT_BADGE_COLOR_BLACK_INVERSE,
];
/**
 * Size Variations
 */
export var HIGHLIGHT_BADGE_SIZES = [
    'standard', 'small',
];
