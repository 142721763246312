var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, attempt, } from '@vfde-brix/ws10/core';
import { DISCOVERY_CARD_ACTION_BUTTON_CLASSNAME, DISCOVERY_CARD_BASE_CLASSNAME, DISCOVERY_CARD_FULL_WIDTH, DISCOVERY_CARD_HEADLINE_CLASSNAME, DISCOVERY_CARD_HIGHLIGHT_BADGE_CLASSNAME, DISCOVERY_CARD_ITEM_CLASSNAME, DISCOVERY_CARD_TXTCONTENT_CLASSNAME, OptContentAlignment, OptOrientation, } from './Constants';
import { createButtonLink, } from '@vfde-brix/ws10/button-link';
import { HighlightBadge, } from '@vfde-brix/ws10/highlight-badge';
import { PromoPrice } from '@vfde-brix/ws10/promo-price';
import renderTemplate from './_discovery-card.hbs';
import './discovery-card.scss';
/**
 * All components should inherit Component
 */
var DiscoveryCard = /** @class */ (function (_super) {
    __extends(DiscoveryCard, _super);
    function DiscoveryCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Sets the default properties if not given.
     * optContentAlignment: Defaults to OptContentAlignment.CENTER if not set
     * optOrientation: Defaults to OptOrientation.VERTICAL if not set
     *
     */
    DiscoveryCard.prototype.getDefaultProperties = function (newProperties) {
        newProperties.discoveryCardItems.forEach(function (cardItem) {
            if (!cardItem.optContentAlignment) {
                cardItem.optContentAlignment = OptContentAlignment.CENTER;
            }
            if (!cardItem.optOrientation) {
                cardItem.optOrientation = OptOrientation.VERTICAL;
            }
        });
        return newProperties;
    };
    /** writeDom */
    DiscoveryCard.prototype.writeDom = function () {
        var _this = this;
        this.containerElement.innerHTML = renderTemplate(this.properties);
        // Init highlight badges in dynamic mode as well, to init tooltips within them
        __spreadArray([], __read(this.containerElement.getElementsByClassName(DISCOVERY_CARD_ITEM_CLASSNAME)), false).forEach(function (item) { return _this.getHighlightBadgePropertiesFromDOM(item); });
    };
    /** readDom */
    DiscoveryCard.prototype.readDom = function (discoveryCardBusinessLogic) {
        var e_1, _a;
        var _b, _c;
        var properties = {
            discoveryCardItems: [],
            business: discoveryCardBusinessLogic,
        };
        var discoveryCard = this.containerElement.getElementsByClassName(DISCOVERY_CARD_BASE_CLASSNAME)[0];
        var discoveryCardItemElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(DISCOVERY_CARD_ITEM_CLASSNAME)), false);
        properties.optFullWidth = this.getOptFullWidth(discoveryCard);
        try {
            for (var discoveryCardItemElements_1 = __values(discoveryCardItemElements), discoveryCardItemElements_1_1 = discoveryCardItemElements_1.next(); !discoveryCardItemElements_1_1.done; discoveryCardItemElements_1_1 = discoveryCardItemElements_1.next()) {
                var itemElement = discoveryCardItemElements_1_1.value;
                var contentHighlightBadge = this.getHighlightBadgePropertiesFromDOM(itemElement);
                var sourceElement = itemElement.getElementsByTagName('source')[0];
                var imgSrcMobile = sourceElement.getAttribute('srcset');
                var imgElement = itemElement.getElementsByTagName('img')[0];
                var imgSrcDesktop = imgElement.getAttribute('src');
                var stdImgAlt = imgElement.getAttribute('alt');
                var stdHeadline = (_b = itemElement.getElementsByClassName(DISCOVERY_CARD_HEADLINE_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var txtContent = (_c = itemElement.getElementsByClassName(DISCOVERY_CARD_TXTCONTENT_CLASSNAME)[0]) === null || _c === void 0 ? void 0 : _c.innerHTML.trim();
                var optOrientation = this.getOptOrientation(itemElement);
                var optContentAlignment = this.getOptContentAlignment(itemElement);
                var containerPromoPrice = PromoPrice.getPropertiesFromDom(itemElement);
                var buttonLinkItems = this.getButtonLinkPropertiesFromDOM(itemElement);
                properties.discoveryCardItems.push(removeUndefinedFieldsFromObject({
                    imgSrcDesktop: imgSrcDesktop,
                    imgSrcMobile: imgSrcMobile,
                    stdImgAlt: stdImgAlt,
                    optContentAlignment: optContentAlignment,
                    optOrientation: optOrientation,
                    contentHighlightBadge: contentHighlightBadge,
                    stdHeadline: stdHeadline,
                    txtContent: txtContent,
                    containerPromoPrice: containerPromoPrice,
                    buttonLinkItems: buttonLinkItems,
                    business: discoveryCardBusinessLogic,
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (discoveryCardItemElements_1_1 && !discoveryCardItemElements_1_1.done && (_a = discoveryCardItemElements_1.return)) _a.call(discoveryCardItemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    /**
     * Searches the _discovery-card.hbs for classnames that are used for optOrientations
     * Returns either one that is present.
     * @param itemElement | Anchor Element of an individual discovery card
     * @returns the found size variant depending on occurence
     */
    DiscoveryCard.prototype.getOptOrientation = function (itemElement) {
        var propertiesFromClassname = getPropertiesFromClassName(itemElement.className, {
            optOrientation: [OptOrientation.VERTICAL, OptOrientation.HORIZONTAL],
        });
        return propertiesFromClassname.optOrientation;
    };
    /**
     * Searches the _discovery-card.hbs for classnames that are used to indicate left alignment.
     * @param itemElement | Anchor Element of an individual discovery card
     * @returns Returns the corresponding enum that represents the alignment
     */
    DiscoveryCard.prototype.getOptContentAlignment = function (itemElement) {
        var propertiesFromClassname = getPropertiesFromClassName(itemElement.className, {
            optContentAlignment: [OptContentAlignment.LEFT, OptContentAlignment.CENTER],
        });
        return propertiesFromClassname.optContentAlignment;
    };
    DiscoveryCard.prototype.getOptFullWidth = function (containerElement) {
        return containerElement.classList.contains(DISCOVERY_CARD_FULL_WIDTH);
    };
    DiscoveryCard.prototype.getHighlightBadgePropertiesFromDOM = function (itemElement) {
        var _a = __read(itemElement.getElementsByClassName(DISCOVERY_CARD_HIGHLIGHT_BADGE_CLASSNAME), 1), highlightBadgeContainer = _a[0];
        return HighlightBadge.getPropertiesFromDom(highlightBadgeContainer);
    };
    DiscoveryCard.prototype.getButtonLinkPropertiesFromDOM = function (discoveryCard) {
        var items = [];
        return attempt(function () {
            var buttonLinkItems = discoveryCard.getElementsByClassName(DISCOVERY_CARD_ACTION_BUTTON_CLASSNAME);
            var buttonLinkArray = Array.from(buttonLinkItems);
            buttonLinkArray.forEach(function (buttonLinkAnchor) {
                var buttonLink = createButtonLink(buttonLinkAnchor, NO_PATTERN_BUSINESS_LOGIC);
                items.push(buttonLink.getProperties());
            });
            return items.length ? items : undefined;
        });
    };
    return DiscoveryCard;
}(Pattern));
export { DiscoveryCard };
/**
 * a factory function to create the component
 */
export var createDiscoveryCard = function (containerElement, businessLogicOrProperties) {
    var discoveryCard = new DiscoveryCard(containerElement, businessLogicOrProperties);
    discoveryCard.init();
    return discoveryCard;
};
