var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-promo-price--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optSizeVariation") || (depth0 != null ? lookupProperty(depth0,"optSizeVariation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optSizeVariation","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":91}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-promo-price--inverse";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":183},"end":{"line":1,"column":242}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return " ws10-promo-price--integer";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-promo-price--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAlign") || (depth0 != null ? lookupProperty(depth0,"optAlign") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAlign","hash":{},"data":data,"loc":{"start":{"line":1,"column":288},"end":{"line":1,"column":300}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-live=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAriaLive") || (depth0 != null ? lookupProperty(depth0,"optAriaLive") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAriaLive","hash":{},"data":data,"loc":{"start":{"line":1,"column":339},"end":{"line":1,"column":354}}}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    return " itemtype=\"https://schema.org/Product\" itemscope";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<meta itemprop=\"name\" content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdOfferProductName") || (depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdOfferProductName","hash":{},"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":86}}}) : helper)))
    + "\">";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"stdStrikePriceText") || (depth0 != null ? lookupProperty(depth0,"stdStrikePriceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdStrikePriceText","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":34}}}) : helper)))
    + " "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":11,"column":55},"end":{"line":11,"column":167}}})) != null ? stack1 : "")
    + "&#32;&euro; &ndash; "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdOfferPriceText") || (depth0 != null ? lookupProperty(depth0,"stdOfferPriceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdOfferPriceText","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":53}}}) : helper)))
    + "&#32;";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ","
    + container.escapeExpression(__default(require("../../internal/handlebars/helper/webpack/leadingZero.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"leadingZero","hash":{},"data":data,"loc":{"start":{"line":11,"column":80},"end":{"line":11,"column":112}}}));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optShowDecimalsAlways") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":120},"end":{"line":11,"column":159}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return ",00";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optPriceConditionBefore") || (depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optPriceConditionBefore","hash":{},"data":data,"loc":{"start":{"line":14,"column":40},"end":{"line":14,"column":67}}}) : helper)))
    + " ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ","
    + container.escapeExpression(__default(require("../../internal/handlebars/helper/webpack/leadingZero.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"leadingZero","hash":{},"data":data,"loc":{"start":{"line":15,"column":41},"end":{"line":15,"column":67}}}));
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdConditionAfter") || (depth0 != null ? lookupProperty(depth0,"stdConditionAfter") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdConditionAfter","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":58}}}) : helper))) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return " itemprop=\"offers\" itemtype=\"https://schema.org/Offer\" itemscope";
},"30":function(container,depth0,helpers,partials,data) {
    return "<meta itemprop=\"priceCurrency\" content=\"EUR\">";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <s class=\"ws10-promo-price__strike-price\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":23,"column":37},"end":{"line":23,"column":148}}})) != null ? stack1 : "")
    + "&nbsp;&euro;\n            </s>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " itemprop=\"price\" content=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + "."
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":26,"column":123},"end":{"line":26,"column":185}}})) != null ? stack1 : "")
    + "\"";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../internal/handlebars/helper/webpack/leadingZero.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"leadingZero","hash":{},"data":data,"loc":{"start":{"line":26,"column":141},"end":{"line":26,"column":167}}}));
},"37":function(container,depth0,helpers,partials,data) {
    return "00";
},"39":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"ws10-promo-price__condition-before\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optPriceConditionBefore") || (depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optPriceConditionBefore","hash":{},"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":49}}}) : helper)))
    + "</span>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":41,"column":29}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-promo-price__condition-after\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdConditionAfter") || (depth0 != null ? lookupProperty(depth0,"stdConditionAfter") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdConditionAfter","hash":{},"data":data,"loc":{"start":{"line":47,"column":59},"end":{"line":47,"column":82}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-promo-price"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSizeVariation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":98}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":98},"end":{"line":1,"column":149}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optShowDecimalsAlways") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":149},"end":{"line":1,"column":253}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAlign") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":253},"end":{"line":1,"column":307}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAriaLive") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":308},"end":{"line":1,"column":362}}})) != null ? stack1 : "")
    + " data-strike-price-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdStrikePriceText") || (depth0 != null ? lookupProperty(depth0,"stdStrikePriceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdStrikePriceText","hash":{},"data":data,"loc":{"start":{"line":1,"column":387},"end":{"line":1,"column":409}}}) : helper)))
    + "\" data-offer-price-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdOfferPriceText") || (depth0 != null ? lookupProperty(depth0,"stdOfferPriceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdOfferPriceText","hash":{},"data":data,"loc":{"start":{"line":1,"column":434},"end":{"line":1,"column":455}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":456},"end":{"line":1,"column":538}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":95}}})) != null ? stack1 : "")
    + "\n    <p class=\"ws10-sr-only\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":76}}})) != null ? stack1 : "")
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":122}}})) != null ? stack1 : "")
    + "&#32;&euro;"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdConditionAfter") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":66}}})) != null ? stack1 : "")
    + "</p>\n    <div class=\"ws10-promo-price__wrapper\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":140}}})) != null ? stack1 : "")
    + " aria-hidden=\"true\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":88}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"strikePrice") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"ws10-promo-price__price-container\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdOfferProductName") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":54},"end":{"line":26,"column":193}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"ws10-promo-price__price\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond2.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),"||",(depth0 != null ? lookupProperty(depth0,"optShowDecimalsAlways") : depth0),{"name":"ifCond2","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":42,"column":29}}})) != null ? stack1 : "")
    + "                <span class=\"ws10-promo-price__currency\">&euro;</span>\n            </span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdConditionAfter") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});