var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-is-hidden";
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-sticky-price-bar--visible";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":20,"column":21}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-sticky-price-bar__item\">\n                    <div class=\"ws10-sticky-price-bar__item-text\">\n                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stdText") : depth0), depth0))
    + "\n                    </div>\n                    <div class=\"ws10-sticky-price-bar__item-price-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":14,"column":31}}})) != null ? stack1 : "")
    + "                        <span class=\"ws10-sticky-price-bar__price\">\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":109}}})) != null ? stack1 : "")
    + "&nbsp;&euro;\n                        </span>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"ws10-sticky-price-bar__condition-before\">\n                                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0), depth0))
    + "\n                            </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ","
    + container.escapeExpression(__default(require("../../internal/handlebars/helper/webpack/leadingZero.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1),{"name":"leadingZero","hash":{},"data":data,"loc":{"start":{"line":16,"column":71},"end":{"line":16,"column":102}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-sticky-price-bar"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isVisible") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":97},"end":{"line":1,"column":152}}})) != null ? stack1 : "")
    + "\" aria-live=\"polite\">\n    <div class=\"ws10-sticky-price-bar__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});