var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './unordered-vertical-list.scss';
import renderTemplate from './_unordered-vertical-list.hbs';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
import { createTooltip, Tooltip, } from '@vfde-brix/ws10/tooltip';
import { UNORDERED_VERTICAL_LIST_HEADLINE_TEXT_CLASSNAME, UNORDERED_VERTICAL_LIST_ICON_CLASSNAME, UNORDERED_VERTICAL_LIST_INVERSE_CLASSNAME, UNORDERED_VERTICAL_LIST_ITEM_CLASSNAME, UNORDERED_VERTICAL_LIST_LINK_CLASSNAME, UNORDERED_VERTICAL_LIST_TEXT_CLASSNAME, UNORDERED_VERTICAL_LIST_ITEM_SIZES, UNORDERED_VERTICAL_LIST_BASE_CLASSNAME, } from './Constants';
/**
 * Unordered Vertical List
 */
var UnorderedVerticalList = /** @class */ (function (_super) {
    __extends(UnorderedVerticalList, _super);
    function UnorderedVerticalList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    UnorderedVerticalList.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Render components to DOM
     */
    UnorderedVerticalList.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        var itemElements = this.containerElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_ITEM_CLASSNAME);
        var itemsLength = this.properties.listUnorderedVerticalListItems.length;
        for (var i = 0; i < itemsLength; i++) {
            if (this.properties.listUnorderedVerticalListItems[i].containerTooltip) {
                createTooltip(itemElements[i], NO_PATTERN_BUSINESS_LOGIC);
            }
        }
    };
    /**
     * gets the properties from the DOM elements
     */
    UnorderedVerticalList.prototype.readDom = function (unorderedVerticalListBusinessLogic) {
        var _a, _b;
        var properties = {
            listUnorderedVerticalListItems: [],
            business: unorderedVerticalListBusinessLogic,
        };
        var listElement = this.containerElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_BASE_CLASSNAME)[0];
        var className = listElement.className;
        var unorderedVerticalListElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_ITEM_CLASSNAME)), false);
        var unorderedVerticalListElementsLength = unorderedVerticalListElements.length;
        for (var i = 0; i < unorderedVerticalListElementsLength; i++) {
            var listItemElement = unorderedVerticalListElements[i];
            var linkElement = listItemElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_LINK_CLASSNAME)[0];
            var listItem = removeUndefinedFieldsFromObject({
                stdIcon: getSystemIconName(listItemElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_ICON_CLASSNAME)[0]),
                stdHeadline: (_a = listItemElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_HEADLINE_TEXT_CLASSNAME)[0]) === null || _a === void 0 ? void 0 : _a.innerHTML,
                txtText: (_b = listItemElement.getElementsByClassName(UNORDERED_VERTICAL_LIST_TEXT_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML,
                stdLinkText: linkElement === null || linkElement === void 0 ? void 0 : linkElement.innerHTML,
                linkHref: linkElement === null || linkElement === void 0 ? void 0 : linkElement.getAttribute('href'),
                optTarget: linkElement === null || linkElement === void 0 ? void 0 : linkElement.getAttribute('target'),
                optInverse: listItemElement.classList.contains(UNORDERED_VERTICAL_LIST_INVERSE_CLASSNAME),
                containerTooltip: Tooltip.getPropertiesFromDom(listItemElement),
                optSize: getPropertiesFromClassName(className, {
                    optSize: UNORDERED_VERTICAL_LIST_ITEM_SIZES,
                }).optSize,
                business: unorderedVerticalListBusinessLogic,
            });
            properties.listUnorderedVerticalListItems.push(listItem);
        }
        return properties;
    };
    return UnorderedVerticalList;
}(Pattern));
export { UnorderedVerticalList };
/**
 * factory function to create the component
 */
export var createUnorderedVerticalList = function (containerElement, businessLogicOrProperties) {
    var unorderedVerticalList = new UnorderedVerticalList(containerElement, businessLogicOrProperties);
    unorderedVerticalList.init();
    return unorderedVerticalList;
};
