/**
 * Loads an image and returns a Promise
 * @param imgSrc The image source
 * @returns A promise which is fulfilled when the image was loaded
 * or rejected when the image failed to load.
 */
export var loadImage = function (imgSrc) {
    return new Promise(function (resolve, reject) {
        var img = document.createElement('img');
        img.dataset.src = imgSrc; // helper property for unit test
        img.addEventListener('load', function () {
            resolve(img);
        });
        img.addEventListener('error', function (event) {
            reject(new Error(event.message));
        });
        img.src = imgSrc;
    });
};
