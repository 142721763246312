var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  ws10-upload-text_spaced ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"<=",3,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":66,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":64,"column":21}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                    <div class=\"ws10-upload-file "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorExists") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":49},"end":{"line":13,"column":105}}})) != null ? stack1 : "")
    + "\">\n                        <div class=\"ws10-upload-file-icon-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorExists") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":68},"end":{"line":14,"column":133}}})) != null ? stack1 : "")
    + "\">\n                        <span >\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optSize":"size-200","stdClassName":"ws10-upload-file__icon","stdName":"document"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </span>\n                        </div>\n                        <div class=\"ws10-upload-file-data-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorExists") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":68},"end":{"line":19,"column":151}}})) != null ? stack1 : "")
    + "\">\n                        <p class=\"ws10-text-large ws10-upload-file-data\">\n                            <span  class=\"ws10-upload-file-data__name\"> "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"fileName") : depth0), depth0))
    + " </span>\n                            <span class=\"ws10-upload-file-data__size\"> ("
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"fileSize") : depth0), depth0))
    + " MB) </span>\n                        </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorExists") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":40,"column":31}}})) != null ? stack1 : "")
    + "                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optLoading") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":61,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-file--err";
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-file-icon-container-err";
},"10":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-file-data-container_success ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorLabel") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":29,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optReUploadBtn") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":39,"column":35}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ws10-upload-label-container ws10-text-smaller\">\n"
    + ((stack1 = container.invokePartial(require("../form-label/_form-label.hbs"),(depth0 != null ? lookupProperty(depth0,"optErrorLabel") : depth0),{"name":"_form-label","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"containerReUploadFormTextInput") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":32},"end":{"line":35,"column":39}}})) != null ? stack1 : "")
    + "                                <div class=\"ws10-upload-reupload-button-container\">\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),(depth0 != null ? lookupProperty(depth0,"optReUploadBtn") : depth0),{"name":"_button","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ws10-upload-reupload-form-text-input-container ws10-upload-form-text-input-container--hidden \">\n"
    + ((stack1 = container.invokePartial(require("../form-text-input/_form-text-input.hbs"),(depth0 != null ? lookupProperty(depth0,"containerReUploadFormTextInput") : depth0),{"name":"_form-text-input","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"ws10-upload-loading-animation-container\">\n"
    + ((stack1 = container.invokePartial(require("../loading-animation/_loading-animation.hbs"),depth0,{"name":"_loading-animation","hash":{"stdClassName":"hello","optSize":"large"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optViewTick") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":47,"column":24},"end":{"line":60,"column":31}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-upload-delete "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optReUploadBtn") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":55},"end":{"line":48,"column":113}}})) != null ? stack1 : "")
    + "\">\n                                <div class=\"ws10-upload-tick-container\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optSize":"size-200","optColor":"color-secondary-500","stdName":"tick-outline"},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n\n";
},"22":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-delete-err ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-upload-delete "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optReUploadBtn") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":59},"end":{"line":55,"column":117}}})) != null ? stack1 : "")
    + "\">\n                                <div class=\"ws10-upload-button-icon-only-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optReUploadBtn") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":83},"end":{"line":56,"column":161}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),(depth0 != null ? lookupProperty(depth0,"stdFileBtn") : depth0),{"name":"_button-icon-only","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-button-icon-only-container-err ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalFilesSize") : depth0),">",4,{"name":"ifCond","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":69,"column":16},"end":{"line":69,"column":163}}})) != null ? stack1 : "")
    + " \">\n"
    + ((stack1 = container.invokePartial(require("../notification/_notification.hbs"),(depth0 != null ? lookupProperty(depth0,"optErrorNotification") : depth0),{"name":"_notification","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-size-error-notification-container ";
},"30":function(container,depth0,helpers,partials,data) {
    return " ws10-upload-length-error-notification-container ";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",3,{"name":"ifCond","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":73,"column":4},"end":{"line":86,"column":15}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"ws10-upload-length-error-notification-container\">\n"
    + ((stack1 = container.invokePartial(require("../notification/_notification.hbs"),(depth0 != null ? lookupProperty(depth0,"optErrorNotification") : depth0),{"name":"_notification","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalFilesSize") : depth0),">",4,{"name":"ifCond","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":4},"end":{"line":84,"column":15}}})) != null ? stack1 : "")
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"ws10-upload-size-error-notification-container\">\n"
    + ((stack1 = container.invokePartial(require("../notification/_notification.hbs"),(depth0 != null ? lookupProperty(depth0,"optErrorNotification") : depth0),{"name":"_notification","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<main class=\"ws10-upload ws10-grid__inner\">\n    <div class=\"ws10-column ws10-column--small-12 ws10-column--medium-8 ws10-column--large-5\">\n    <h4 class=\"ws10-upload-headline ws10-h4\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdHeadline") || (depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHeadline","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n    </h4>\n    <p class=\"ws10-upload-text ws10-text-large "
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0),"&&",((stack1 = (depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":131}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdSubHeadline") || (depth0 != null ? lookupProperty(depth0,"stdSubHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSubHeadline","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\n    </p>\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0),"&&",((stack1 = (depth0 != null ? lookupProperty(depth0,"uploadFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":67,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optErrorNotification") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":87,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"ws10-upload-form-text-input-container ws10-upload-form-text-input-container--hidden\">\n"
    + ((stack1 = container.invokePartial(require("../form-text-input/_form-text-input.hbs"),(depth0 != null ? lookupProperty(depth0,"containerFormTextInput") : depth0),{"name":"_form-text-input","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"ws10-upload-button-container\">\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),(depth0 != null ? lookupProperty(depth0,"containerButton") : depth0),{"name":"_button","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n</main>\n";
},"usePartial":true,"useData":true});