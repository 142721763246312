var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-text-input--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":55}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-form-text-input--disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-form-text-input--has-system-icon";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-text-input--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAutoFormatType") || (depth0 != null ? lookupProperty(depth0,"optAutoFormatType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAutoFormatType","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":73}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optType") || (depth0 != null ? lookupProperty(depth0,"optType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optType","hash":{},"data":data,"loc":{"start":{"line":11,"column":29},"end":{"line":11,"column":40}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    return "text";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdValue") || (depth0 != null ? lookupProperty(depth0,"stdValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":44}}}) : helper)))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdPlaceholder") || (depth0 != null ? lookupProperty(depth0,"stdPlaceholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdPlaceholder","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":62}}}) : helper)))
    + "\"";
},"19":function(container,depth0,helpers,partials,data) {
    return " autocomplete=\"off\"";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " role=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optRole") || (depth0 != null ? lookupProperty(depth0,"optRole") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optRole","hash":{},"data":data,"loc":{"start":{"line":16,"column":30},"end":{"line":16,"column":41}}}) : helper)))
    + "\"";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-controls=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdAriaControls") || (depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdAriaControls","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":66}}}) : helper)))
    + "\"";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-autocomplete=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdAriaAutocomplete") || (depth0 != null ? lookupProperty(depth0,"stdAriaAutocomplete") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdAriaAutocomplete","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":78}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-form-text-input\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":62}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":61}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSystemIcon") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":70}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAutoFormatType") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":80}}})) != null ? stack1 : "")
    + "\"\n>\n    <input\n        id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":21}}}) : helper)))
    + "\"\n        class=\"ws10-form-text-input__input\"\n        name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdName") || (depth0 != null ? lookupProperty(depth0,"stdName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdName","hash":{},"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":10,"column":25}}}) : helper)))
    + "\"\n        type=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optType") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":59}}})) != null ? stack1 : "")
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdValue") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":52}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":43}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdPlaceholder") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":70}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAutoCompleteOff") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":60}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optRole") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":49}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":74}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdAriaAutocomplete") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":86}}})) != null ? stack1 : "")
    + "\n    />\n\n    <span class=\"ws10-form-text-input__notification_icon-container\">\n"
    + ((stack1 = container.invokePartial(require("../notification-icon/_notification-icon.hbs"),depth0,{"name":"_notification-icon","hash":{"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </span>\n\n    <span class=\"ws10-form-text-input__system_icon-container\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-600","stdName":(depth0 != null ? lookupProperty(depth0,"optSystemIcon") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </span>\n</div>\n";
},"usePartial":true,"useData":true});