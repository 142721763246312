var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './loading-animation.scss';
import renderTemplate from './_loading-animation.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { LOADING_ANIMATION_ALERT_CLASSNAME, LOADING_ANIMATION_BASE_CLASSNAME, LOADING_ANIMATION_SIZES, } from './Constants';
/**
 * Loading animation component
 */
var LoadingAnimation = /** @class */ (function (_super) {
    __extends(LoadingAnimation, _super);
    /**
     * Constructor
     */
    function LoadingAnimation(containerElement, properties) {
        var _this = _super.call(this, containerElement, properties) || this;
        _this.loadingAnimationElement = null;
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    LoadingAnimation.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     */
    LoadingAnimation.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.hide();
        this.getElements();
    };
    /**
     * Read properties from DOM
     */
    LoadingAnimation.prototype.readDom = function (loadingAnimationBusinessLogic) {
        var _a, _b;
        this.hide();
        this.getElements();
        var stdAlert = (_a = this.loadingAnimationElement.getElementsByClassName(LOADING_ANIMATION_ALERT_CLASSNAME)[0]) === null || _a === void 0 ? void 0 : _a.textContent.trim();
        var optSize = (_b = getPropertiesFromClassName(this.loadingAnimationElement.className, {
            optSize: LOADING_ANIMATION_SIZES.map(function (size) { return "size-".concat(size); }),
        }).optSize) === null || _b === void 0 ? void 0 : _b.replace('size-', '');
        return removeUndefinedFieldsFromObject({
            stdAlert: stdAlert,
            optSize: optSize,
            business: loadingAnimationBusinessLogic,
        });
    };
    /**
     * Reads the elements from DOM
     * and assigns them to the
     * class properties
     */
    LoadingAnimation.prototype.getElements = function () {
        this.loadingAnimationElement = this.containerElement.getElementsByClassName(LOADING_ANIMATION_BASE_CLASSNAME)[0];
    };
    /**
     * Show component
     */
    LoadingAnimation.prototype.show = function () {
        this.toggleContainer(false, false);
    };
    /**
     * Hide component
     */
    LoadingAnimation.prototype.hide = function () {
        this.toggleContainer(true, false);
    };
    /**
     * Checks if component is visible
     */
    LoadingAnimation.prototype.isVisible = function () {
        return this.isContainerVisible();
    };
    return LoadingAnimation;
}(Pattern));
export { LoadingAnimation };
/**
 * Factory function to create an instance of LoadingAnimation
 */
export var createLoadingAnimation = function (containerElement, businessLogicOrProperties) {
    var loadingAnimation = new LoadingAnimation(containerElement, businessLogicOrProperties);
    loadingAnimation.init();
    return loadingAnimation;
};
