var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "ws10-overlay--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAnimation") || (depth0 != null ? lookupProperty(depth0,"optAnimation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAnimation","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":58}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "ws10-overlay--stay-open";
},"5":function(container,depth0,helpers,partials,data) {
    return "ws10-overlay--spacing";
},"7":function(container,depth0,helpers,partials,data) {
    return "left";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n    aria-modal=\"true\"\n    role=\"dialog\"\n    class=\"ws10-overlay ws10-fade\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAnimation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":65}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optStayOpenOnClickOutside") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":71}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optPaddingLayout") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":60}}})) != null ? stack1 : "")
    + "\n        ws10-overlay--align-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optContentAlign") || (depth0 != null ? lookupProperty(depth0,"optContentAlign") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"optContentAlign","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":47}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optContentAlign") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":89}}})) != null ? stack1 : "")
    + "\n\">\n    <div class=\"ws10-overlay__container\">\n        <div class=\"ws10-overlay__close\">\n"
    + ((stack1 = container.invokePartial(require("../button-icon-only/_button-icon-only.hbs"),depth0,{"name":"_button-icon-only","hash":{"optSize":"standard","optShape":"circle","optColor":"tertiary","stdAriaLabel":"Schließen-Schaltfläche","stdIconName":"close"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"ws10-overlay__content\"></div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});