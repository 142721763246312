/**
 * Base ClassName
 */
export var COLOR_SWATCH_BASE_CLASSNAME = 'ws10-color-swatch';
/**
 * ClassName for item
 */
export var CLASSNAME_ITEM = "".concat(COLOR_SWATCH_BASE_CLASSNAME, "__item");
/**
 * ClassName for item color
 */
export var CLASSNAME_COLOR = "".concat(COLOR_SWATCH_BASE_CLASSNAME, "__color");
