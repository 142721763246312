import { BUTTON_COLOR_TERTIARY, BUTTON_ICON_POSITION_LEFT, BUTTON_SIZE_STANDARD, BUTTON_WIDTH_VARIATION_FULLWIDTH, } from '@vfde-brix/ws10/button/Constants';
export var FEATURE_CARD_DEFAULT_CTA_TEXT = 'Hinzufügen';
export var FEATURE_CARD_DEFAULT_CTA_TEXT_SELECTED = 'Hinzugefügt';
export var FEATURE_CARD_DEFAULT_CTA_TEXT_UNSELECTABLE = 'Inklusive';
export var FEATURE_CARD_DEFAULT_CTA_TEXT_DISABLED = 'Nicht verfügbar';
export var FEATURE_CARD_BASE_CLASSNAME = 'ws10-feature-card';
export var FEATURE_CARD_LIST_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "-list");
export var FEATURE_CARD_HEADLINE_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__headline");
export var FEATURE_CARD_LINK_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__text-link");
export var FEATURE_CARD_BUTTON_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__button");
export var FEATURE_CARD_IMAGE_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__img");
export var FEATURE_CARD_PROMO_PRICE_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__promo-price");
export var FEATURE_CARD_HIGHLIGHT_BADGE_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "__highlight-badge");
export var FEATURE_CARD_CHECKED_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "--checked");
export var FEATURE_CARD_UNSELECTABLE_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "--unselectable");
export var FEATURE_CARD_DISABLED_CLASSNAME = "".concat(FEATURE_CARD_BASE_CLASSNAME, "--disabled");
export var buttonProperties = {
    optColor: BUTTON_COLOR_TERTIARY,
    optSize: BUTTON_SIZE_STANDARD,
    optWidthVariation: BUTTON_WIDTH_VARIATION_FULLWIDTH,
    optRole: 'checkbox',
    icon: {
        optPosition: BUTTON_ICON_POSITION_LEFT,
        stdIconName: 'tick',
    },
};
