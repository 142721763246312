/**
 * Large Size
 */
export var IMAGE_HEADER_SIZE_LARGE = 'large';
/**
 * Medium Size
 */
export var IMAGE_HEADER_SIZE_MEDIUM = 'medium';
export var IMAGE_HEADER_SIZES = [
    IMAGE_HEADER_SIZE_LARGE,
    IMAGE_HEADER_SIZE_MEDIUM,
];
/**
 * Right Content Align
 */
export var IMAGE_HEADER_ALIGNMENT_RIGHT = 'right';
/**
 * Left Content Align
 */
export var IMAGE_HEADER_ALIGNMENT_LEFT = 'left';
/**
 * mobile screen size
 */
export var IMAGE_HEADER_MOBILE_SIZE = 600;
export var IMAGE_HEADER_ALIGNMENTS = [
    IMAGE_HEADER_ALIGNMENT_LEFT,
    IMAGE_HEADER_ALIGNMENT_RIGHT,
];
/**
 * Base class name
 */
export var IMAGE_HEADER_BASE_CLASSNAME = 'ws10-image-header';
/**
 * left classname
 */
export var IMAGE_HEADER_LEFT_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--left");
/**
 * right classname
 */
export var IMAGE_HEADER_RIGHT_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--right");
/**
 * large classname
 */
export var IMAGE_HEADER_LARGE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--large");
/**
 * medium classname
 */
export var IMAGE_HEADER_MEDIUM_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--medium");
/**
 * image container classname
 */
export var IMAGE_HEADER_IMAGE_CONTAINER_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__image-container");
/**
 * picture classname
 */
export var IMAGE_HEADER_PICTURE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__picture");
/**
 * img classname
 */
export var IMAGE_HEADER_IMG_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__img");
/**
 * img-small classname
 */
export var IMAGE_HEADER_IMG_SMALL_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__small-img");
/**
 * content container classname
 */
export var IMAGE_HEADER_CONTENT_CONTAINER_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__content-container");
/**
 * content container with solid background classname
 */
export var IMAGE_HEADER_CONTENT_CONTAINER_SOLID_CLASSNAME = "".concat(IMAGE_HEADER_CONTENT_CONTAINER_CLASSNAME, "--solid");
/**
 * highlight badge classname
 */
export var IMAGE_HEADER_HIGHLIGHT_BADGE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__highlight-badge");
/**
 * highlight badge classname
 */
export var IMAGE_HEADER_PRICE_PROMO_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__promo-price-container");
/**
 * headline container classname
 */
export var IMAGE_HEADER_HEADLINE_CONTAINER_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__headline-container");
/**
 * headline classname
 */
export var IMAGE_HEADER_HEADLINE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__headline");
/**
 * subline classname
 */
export var IMAGE_HEADER_SUBLINE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__subline");
/**
 * black headline classname
 */
export var IMAGE_HEADER_BLACK_HEADLINE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__headline--black");
/**
 * black subline classname
 */
export var IMAGE_HEADER_BLACK_SUBLINE_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__subline--black");
/**
 * button container classname
 */
export var IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__buttons-container");
/**
 * button container with no margins classname
 */
export var IMAGE_HEADER_BUTTON_CONTAINER_NO_MARGIN_CLASSNAME = "".concat(IMAGE_HEADER_BUTTON_CONTAINER_CLASSNAME, "--no-margin");
/**
 * split classname
 */
export var IMAGE_HEADER_SPLIT_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--split");
/**
 * split container classname
 */
export var IMAGE_HEADER_SPLIT_CONTAINER_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "__split-container");
/**
 * split width-60 classname
 */
export var IMAGE_HEADER_WIDTH_60_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--7");
/**
 * split width-50 classname
 */
export var IMAGE_HEADER_WIDTH_50_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--6");
/**
 * split width-40 classname
 */
export var IMAGE_HEADER_WIDTH_40_CLASSNAME = "".concat(IMAGE_HEADER_BASE_CLASSNAME, "--5");
