/**
 * Create VariationsMap to be used in readdom
 */
export var getVariationsMap = function () {
    var width = [];
    var widthSmall = [];
    var widthMedium = [];
    var widthLarge = [];
    var widthXLarge = [];
    for (var i = 1, x = 12; i <= x; i += 1) {
        width.push("".concat(i));
        widthSmall.push("small-".concat(i));
        widthMedium.push("medium-".concat(i));
        widthLarge.push("large-".concat(i));
        widthXLarge.push("x-large-".concat(i));
    }
    /**
     * VariationMap to get all columns in different viewports
     */
    var gridVariationMap = {
        width: width,
        widthSmall: widthSmall,
        widthMedium: widthMedium,
        widthLarge: widthLarge,
        widthXLarge: widthXLarge,
    };
    return gridVariationMap;
};
