var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * This is a basic component which does not have component inside component.
 * To see an example of a component inside component, check OptionTabs.ts. Option Tabs
 * has RibbonBadge used inside of it. Pay careful attention to the render function,
 * the ribbon badge import and the added dependency in the package.json of option tabs.
 */
import './search-input.scss';
import renderTemplate from './_search-input.hbs';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { BUTTON_ICON_ONLY_CONTAINER, FORM_TEXT_INPUT_CONTAINER, SEARCH_INPUT_BASE_CLASSNAME, SEARCH_INPUT_DISABLED_CLASS_NAME, SEARCH_INPUT_INVERSE_CLASS_NAME, } from './Constants';
import { createFormTextInput, FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME, } from '@vfde-brix/ws10/form-text-input';
import { BUTTON_ICON_ONLY_BASE_CLASSNAME, BUTTON_ICON_ONLY_DISABLED, createButtonIconOnly, } from '@vfde-brix/ws10/button-icon-only';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var SearchInput = /** @class */ (function (_super) {
    __extends(SearchInput, _super);
    function SearchInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    SearchInput.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get form text input properties
     */
    SearchInput.prototype.getFormTextInputProperties = function () {
        var _this = this;
        var business = {
            onChange: function (event) { return _this.handleChangeInput(event); },
        };
        if (this.properties) {
            return __assign(__assign({}, this.properties.containerFormTextInput), { business: business });
        }
        return business;
    };
    /**
     * Get button icon only properties
     */
    SearchInput.prototype.getButtonIconOnlyProperties = function () {
        var _this = this;
        var buttonIconOnlyElement = this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_BASE_CLASSNAME)[0];
        var optDisabled = buttonIconOnlyElement.classList.contains(BUTTON_ICON_ONLY_DISABLED);
        var business = {
            onClick: function (event) { return _this.handleSubmit(event); },
        };
        var buttonIconOnlyProps = {
            stdAriaLabel: 'Search',
            stdIconName: 'search-system',
            business: business,
        };
        optDisabled && (buttonIconOnlyProps.optDisabled = true);
        return buttonIconOnlyProps;
    };
    /**
     * Handle change of form text input value
     */
    SearchInput.prototype.handleChangeInput = function (event) {
        var _a, _b;
        var input = this.formTextInputComponent.getInputElement();
        var value = input.value;
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onChange) {
            this.properties.business.onChange(event, value);
        }
    };
    /**
     * Handle click on the clear icon
     */
    SearchInput.prototype.handleResetInput = function () {
        var systemIcon = this.formTextInputComponent.getPropertyByKey('optSystemIcon');
        /* istanbul ignore else */
        if (systemIcon === 'close') {
            var input = this.formTextInputComponent.getInputElement();
            input.value = '';
            var inputEvent = new Event('input');
            input.dispatchEvent(inputEvent);
        }
    };
    /**
     * Handle appearence and disappearence of clear icon
     */
    SearchInput.prototype.handleClearIconVisibility = function () {
        var input = this.formTextInputComponent.getInputElement();
        var value = input.value;
        this.formTextInputComponent.update({ optSystemIcon: value ? 'close' : undefined }, true);
        this.formTextInputElement
            .getElementsByClassName(FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME)[0]
            .setAttribute('tabindex', value ? '0' : '-1');
    };
    /**
     * Handle submit search value (button icon only)
     */
    SearchInput.prototype.handleSubmit = function (event) {
        var _a, _b;
        var input = this.formTextInputComponent.getInputElement();
        var value = input.value;
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onSubmit) {
            this.properties.business.onSubmit(event, value);
        }
    };
    /**
     * Handle keypress on clear icon
     */
    SearchInput.prototype.handleKeypressClearIcon = function (event) {
        /* istanbul ignore else */
        if (event.code === 'Enter' || event.code === 'Space') {
            event.preventDefault();
            var clearIcon = this.formTextInputElement.getElementsByClassName(FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME)[0];
            var clickEvent = new Event('click');
            clearIcon.dispatchEvent(clickEvent);
            var input = this.formTextInputComponent.getInputElement();
            input.focus();
        }
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    SearchInput.prototype.initDomElements = function () {
        var formTextInputProperties = this.getFormTextInputProperties();
        var buttonIconOnlyProperties = this.getButtonIconOnlyProperties();
        this.formTextInputElement = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CONTAINER)[0];
        this.buttonIconOnlyContainer = this.containerElement.getElementsByClassName(BUTTON_ICON_ONLY_CONTAINER)[0];
        this.searchInputElement = this.containerElement.getElementsByClassName(SEARCH_INPUT_BASE_CLASSNAME)[0];
        this.formTextInputComponent = createFormTextInput(this.formTextInputElement, formTextInputProperties);
        createButtonIconOnly(this.buttonIconOnlyContainer, buttonIconOnlyProperties);
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    SearchInput.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    SearchInput.prototype.initEvents = function () {
        var _this = this;
        var input = this.formTextInputComponent.getInputElement();
        input.addEventListener('input', function () {
            _this.handleClearIconVisibility();
        });
        var value = input.value;
        if (value) {
            var inputEvent = new Event('input');
            input.dispatchEvent(inputEvent);
        }
        var clearIcon = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME)[0];
        clearIcon.addEventListener('click', function () {
            _this.handleResetInput();
        });
        clearIcon.addEventListener('keydown', function (e) {
            _this.handleKeypressClearIcon(e);
        });
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    SearchInput.prototype.readDom = function (business) {
        this.initDomElements();
        var optInverse = this.searchInputElement.classList.contains(SEARCH_INPUT_INVERSE_CLASS_NAME) ? true : undefined;
        var optDisabled = this.searchInputElement.classList.contains(SEARCH_INPUT_DISABLED_CLASS_NAME) ? true : undefined;
        var properties = removeUndefinedFieldsFromObject({
            optInverse: optInverse,
            optDisabled: optDisabled,
            business: business,
        });
        return properties;
    };
    return SearchInput;
}(Pattern));
export { SearchInput };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of SearchInput
 */
export var createSearchInput = function (containerElement, businessLogicOrProperties) {
    var searchInput = new SearchInput(containerElement, businessLogicOrProperties);
    searchInput.init();
    return searchInput;
};
