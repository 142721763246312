var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdClassName") || (depth0 != null ? lookupProperty(depth0,"stdClassName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":48}}}) : helper)))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return " aria-hidden=\"true\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdClassName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":56}}})) != null ? stack1 : "")
    + "ws10-system-icon ws10-system-icon--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optSize") || (depth0 != null ? lookupProperty(depth0,"optSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optSize","hash":{},"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":102}}}) : helper)))
    + " ws10-system-icon--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optColor") || (depth0 != null ? lookupProperty(depth0,"optColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optColor","hash":{},"data":data,"loc":{"start":{"line":1,"column":121},"end":{"line":1,"column":133}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAriaHidden") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":134},"end":{"line":1,"column":181}}})) != null ? stack1 : "")
    + ">\n    <use xlink:href=\"/simplicity/icons/"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdName") || (depth0 != null ? lookupProperty(depth0,"stdName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdName","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":50}}}) : helper)))
    + ".svg#icon\"></use>\n</svg>\n";
},"useData":true});