/**
 * Base class name, also marks the container that holds the cards
 */
export var DISCOVERY_CARD_BASE_CLASSNAME = 'ws10-discovery-card';
/**
 * full width variation classname
 */
export var DISCOVERY_CARD_FULL_WIDTH = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "--full-width");
/**
 * Class name for the individual card itself
 */
export var DISCOVERY_CARD_ITEM_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__item");
/**
 * Class name for content
 */
export var DISCOVERY_CARD_CONTENT_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__content");
/**
 * Class name for content
 */
export var DISCOVERY_CARD_CONTENT_CONTAINER_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__content-container");
/**
 * Class name for the text, lists, or other types of desired content as stringified HTML.
 */
export var DISCOVERY_CARD_TXTCONTENT_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__txt-content");
/**
 * Class name for headline
 */
export var DISCOVERY_CARD_HEADLINE_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__headline");
/**
 * Class name for highlight badge
 */
export var DISCOVERY_CARD_HIGHLIGHT_BADGE_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__highlight-badge");
/**
 * Class name for the actions section if there's any.
 * This contains the buttons/ buttonlinks/ etc.
 */
export var DISCOVERY_CARD_ACTIONS_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__actions");
/**
 * Class name for the individual action buttons.
 */
export var DISCOVERY_CARD_ACTION_BUTTON_CLASSNAME = "".concat(DISCOVERY_CARD_BASE_CLASSNAME, "__action-button");
/**
 * Enum for optOrientation
 */
export var OptOrientation;
(function (OptOrientation) {
    OptOrientation["VERTICAL"] = "vertical";
    OptOrientation["HORIZONTAL"] = "horizontal";
})(OptOrientation || (OptOrientation = {}));
/**
 * Enum for optContentAlignment
 */
export var OptContentAlignment;
(function (OptContentAlignment) {
    OptContentAlignment["LEFT"] = "left";
    OptContentAlignment["CENTER"] = "center";
})(OptContentAlignment || (OptContentAlignment = {}));
