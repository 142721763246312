/**
 * Class name base element
 */
export var OVERLAY_BASE_CLASSNAME = 'ws10-overlay';
/**
 * State opened
 */
export var OVERLAY_STATE_OPEN = 'opened';
/**
 * State closed
 */
export var OVERLAY_STATE_CLOSED = 'closed';
/**
 * Animation slide in
 */
export var OVERLAY_ANIMATION_SLIDE = 'slide';
/**
 * Class name display overlay
 */
export var OVERLAY_CLASSNAME_SHOW = 'ws10-in';
/**
 * Class name backdrop element
 */
export var OVERLAY_CLASSNAME_BACKDROP = "".concat(OVERLAY_BASE_CLASSNAME, "__backdrop");
/**
 * Class name container element
 */
export var OVERLAY_CLASSNAME_CONTAINER = "".concat(OVERLAY_BASE_CLASSNAME, "__container");
/**
 * Class name close button
 */
export var OVERLAY_CLOSE_BUTTON_CONTAINER_CLASSNAME = "".concat(OVERLAY_BASE_CLASSNAME, "__close");
/**
 * Class name content
 */
export var OVERLAY_CONTENT_CLASSNAME = "".concat(OVERLAY_BASE_CLASSNAME, "__content");
/**
 * Class name animation overlay slide
 */
export var OVERLAY_CLASSNAME_ANIMATION_SLIDE = "".concat(OVERLAY_BASE_CLASSNAME, "-").concat(OVERLAY_ANIMATION_SLIDE);
/**
 * Class name animation brix fade
 */
export var OVERLAY_CLASSNAME_ANIMATION_FADE = 'ws10-fade';
/**
 * Class name ws10 no scroll
 */
export var OVERLAY_CLASSNAME_NO_SCROLL = 'ws10-no-scroll';
/**
 * Class name for stay open on click outside of overlay
 */
export var OVERLAY_STAY_OPEN_CLASSNAME = "".concat(OVERLAY_BASE_CLASSNAME, "--stay-open");
/**
 * Class name for overlay variant with paddings
 */
export var OVERLAY_SPACING_CLASSNAME = "".concat(OVERLAY_BASE_CLASSNAME, "--spacing");
/**
 * Animation Variations
 */
export var OVERLAY_ANIMATIONS = [
    OVERLAY_ANIMATION_SLIDE,
];
/**
 * Left alignment
 */
export var OVERLAY_ALIGNMENT_LEFT = 'left';
/**
 * Center alignment
 */
export var OVERLAY_ALIGNMENT_CENTER = 'center';
/**
 * Overlay alignment
 */
export var OVERLAY_ALIGNMENT = [
    OVERLAY_ALIGNMENT_LEFT,
    OVERLAY_ALIGNMENT_CENTER,
];
