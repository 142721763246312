export var SPACING_0 = '0';
export var SPACING_15 = '15';
export var SPACING_25 = '25';
export var SPACING_50 = '50';
export var SPACING_75 = '75';
export var SPACING_100 = '100';
export var SPACING_150 = '150';
export var SPACING_200 = '200';
export var SPACING_400 = '400';
export var SPACING_450 = '450';
export var SPACING_500 = '500';
/**
 * Spacing Variations
 */
export var SPACING_VARIANTS = [
    SPACING_0,
    SPACING_15,
    SPACING_25,
    SPACING_50,
    SPACING_75,
    SPACING_100,
    SPACING_150,
    SPACING_200,
    SPACING_400,
    SPACING_450,
    SPACING_500,
];
