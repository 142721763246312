import { BUTTON_SIZE_SMALL, BUTTON_WIDTH_VARIATION_HYBRID, } from '../button';
import { NO_PATTERN_BUSINESS_LOGIC } from '../core';
/**
 * State opened
 */
export var FULLWIDTH_NOTIFICATION_STATE_OPENED = 'opened';
/**
 * State closed
 */
export var FULLWIDTH_NOTIFICATION_STATE_CLOSED = 'closed';
/**
 * Base class name
 */
export var FULLWIDTH_NOTIFICATION_BASE_CLASSNAME = 'ws10-fullwidth-notification';
/**
 * Wrapper class name
 */
export var FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME = FULLWIDTH_NOTIFICATION_BASE_CLASSNAME;
/**
 * Inner part class name
 */
export var FULLWIDTH_NOTIFICATION_INNER_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__inner");
/**
 * Class name close button
 */
export var FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__close");
/**
 * Class name content
 */
export var FULLWIDTH_NOTIFICATION_CONTENT_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__content");
/**
 * Class name button
 */
export var FULLWIDTH_NOTIFICATION_BUTTON_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__button");
/**
 * Class name txtContent
 */
export var FULLWIDTH_NOTIFICATION_TEXT_CONTENT_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__text");
/**
 * Class name txtSubTextContent
 */
export var FULLWIDTH_NOTIFICATION_SUBTEXT_CONTENT_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__subtext");
/**
 * Class name icon
 */
export var FULLWIDTH_NOTIFICATION_ICON_CLASSNAME = "".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, "__icon");
/**
 * Primary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_PRIMARY = 'primary';
/**
 * Secondary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_SECONDARY = 'secondary';
/**
 * Tertiary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_TERTIARY = 'tertiary';
/**
 * Color variants
 */
export var FULLWIDTH_NOTIFICATION_COLOR_VARIANTS = [
    FULLWIDTH_NOTIFICATION_COLOR_PRIMARY,
    FULLWIDTH_NOTIFICATION_COLOR_SECONDARY,
    FULLWIDTH_NOTIFICATION_COLOR_TERTIARY,
];
/**
 * Primary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_CLASS_PRIMARY = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_COLOR_PRIMARY);
/**
 * Secondary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_CLASS_SECONDARY = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_COLOR_SECONDARY);
/**
 * Tertiary color variant
 */
export var FULLWIDTH_NOTIFICATION_COLOR_CLASS_TERTIARY = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_COLOR_TERTIARY);
/**
 * Color variants
 */
export var FULLWIDTH_NOTIFICATION_COLOR_CLASS_VARIANTS = [
    FULLWIDTH_NOTIFICATION_COLOR_CLASS_PRIMARY,
    FULLWIDTH_NOTIFICATION_COLOR_CLASS_SECONDARY,
    FULLWIDTH_NOTIFICATION_COLOR_CLASS_TERTIARY,
];
/**
 * Key stored in the Session Storage. FullWidth Notification is rendered when returns true.
 */
export var FULLWIDTH_NOTIFICATION_SESSION_KEY = 'brix-ws10-fullwidth-notification-status';
/**
 * Place it above the navbar
 */
export var FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR = 'above-navbar';
/**
 * Place it below the navbar
 */
export var FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR = 'below-navbar';
/**
 * Place it to the top as sticky
 */
export var FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP = 'sticky-top';
/**
 * Place it to the bottom as sticky
 */
export var FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM = 'sticky-bottom';
/**
 * Place it above the footer
 */
export var FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER = 'above-footer';
/**
 * Position variants
 */
export var FULLWIDTH_NOTIFICATION_POSITION_VARIANTS = [
    FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR,
    FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR,
    FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP,
    FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM,
    FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER,
];
/**
 * Class when we place it above the navbar
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR);
/**
 * Class when we place it below the navbar
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR);
/**
 * Class when we place it to the top as sticky
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP);
/**
 * Class when we place it to the bottom as sticky
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_BOTTOM = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM);
/**
 * Class when we place it above the footer
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_FOOTER = "".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, "--").concat(FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER);
/**
 * Position class variants
 */
export var FULLWIDTH_NOTIFICATION_POSITION_CLASS_VARIANTS = [
    FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR,
    FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR,
    FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP,
    FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_BOTTOM,
    FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_FOOTER,
];
/**
 * Class for the Navbar element.
 */
export var FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME = 'main-nav';
/**
 * Class for the Navbar Wrapper element.
 */
export var FULLWIDTH_NOTIFICATION_NAVBAR_WRAPPER_CLASSNAME = 'glnav';
/**
 * Class for the elements that should have a transition animation when their respective top & margin-top properties are altered.
 */
export var FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME = 'transition-top';
/**
 * ID for the HTML Element that contains the content of the entire page
 */
export var FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID = 'top';
/**
 * Tag name of a Header element.
 */
export var FULLWIDTH_NOTIFICATION_HEADER_TAGNAME = 'header';
/**
 * Tag name of a Footer element.
 */
export var FULLWIDTH_NOTIFICATION_FOOTER_TAGNAME = 'footer';
/**
 * Can be used when we want to add transition to an element that would be out of the scope of the scss file.
 */
export var FULLWIDTH_NOTIFICATION_INLINE_TRANSITION_CSS_PROPERTY = '.5s cubic-bezier(.445, .05, .55, .95)';
export var FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_ARIA_LABEL = 'Schließen-Schaltfläche';
export var buttonProperties = {
    optWidthVariation: BUTTON_WIDTH_VARIATION_HYBRID,
    optSize: BUTTON_SIZE_SMALL,
    business: NO_PATTERN_BUSINESS_LOGIC,
};
export var closeButtonProperties = {
    stdAriaLabel: FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_ARIA_LABEL,
    stdIconName: 'close',
    optColor: 'secondary-inverse',
    optSize: 'standard',
    optShape: 'transparent',
};
