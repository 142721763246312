/**
 * Base class name
 */
export var PROMOTIONAL_SUMMARY_CARD_BASE_CLASSNAME = 'ws10-promotional-summary-card';
/**
 * Headline class name
 */
export var PROMOTIONAL_SUMMARY_CARD_HEADLINE_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_BASE_CLASSNAME, "__headline");
/**
 * Card class name
 */
export var PROMOTIONAL_SUMMARY_CARD_CARD_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_BASE_CLASSNAME, "__card");
/**
 * Items List class name
 */
export var PROMOTIONAL_SUMMARY_CARD_LIST_ITEMS_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_BASE_CLASSNAME, "__list-items");
/**
 * List Item class name
 */
export var PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_BASE_CLASSNAME, "__list-item");
/**
 * Checkbox list item class name
 */
export var PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CHECKBOX_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CLASSNAME, "--checkbox");
/**
 * Icon Text list item class name
 */
export var PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_ICON_TEXT_CLASSNAME = "".concat(PROMOTIONAL_SUMMARY_CARD_LIST_ITEM_CLASSNAME, "--icon-text");
