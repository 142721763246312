/**
 * Base class name
 */
export var PRODUCT_CARD_BASE_CLASSNAME = 'ws10-product-card-list';
/** PRODUCT_CARD_ITEM_CLASSNAME */
export var PRODUCT_CARD_ITEM_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__item");
/** PRODUCT_CARD_HEADLINE_CLASSNAME */
export var PRODUCT_CARD_HEADLINE_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__headline");
/** PRODUCT_CARD_DESCRIPTION_CLASSNAME */
export var PRODUCT_CARD_DESCRIPTION_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__description");
/** PRODUCT_CARD_ALMOST_BUTTON_LINK_CLASSNAME */
export var PRODUCT_CARD_TEXT_ELEMENT_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__text-element");
/** PRODUCT_CARD_CORNER_ICON_CLASSNAME */
export var PRODUCT_CARD_CORNER_ICON_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__corner-icon");
/** PRODUCT_CARD_PRODUCT_IMAGE_CLASSNAME */
export var PRODUCT_CARD_IMAGE_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__image");
/** PRODUCT_CARD_COLOR_SWATCH_CLASSNAME */
export var PRODUCT_CARD_COLOR_SWATCH_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__color-swatch");
export var PRODUCT_CARD_HIGHLIGHT_BADGE_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__highlight-badge");
/** PRODUCT_CARD_PRICES_CLASSNAME */
export var PRODUCT_CARD_PRICES_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__prices");
/** PRODUCT_CARD_PRICE_CLASSNAME */
export var PRODUCT_CARD_PRICE_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__price");
/** PRODUCT_CARD_CONTENT_CLASSNAME */
export var PRODUCT_CARD_CONTENT_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__content");
/** PRODUCT_CARD_BORDER_CLASSNAME */
export var PRODUCT_CARD_CONTENT_BORDER_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__content--border");
/** PRODUCT_CARD_CONTAINER_STYLE_CLASSLIST */
export var PRODUCT_CARD_CONTAINER_STYLE_CLASSLIST = ['ws10-column', 'ws10-column--12', 'ws10-column--small-6', 'ws10-column--medium-4'];
/** MODIFIER CLASSNAME TO SET MARGINS PROPERLY */
export var MODIFIER_CLASS_NAME = 'ws10-product-card--no-flag-badge-in-row';
/** PRODUCT CARD FLAG BADGE CONTAINER CLASSNAME */
export var PRODUCT_CARD_FLAG_BADGE_CONTAINER_CLASSNAME = "".concat(PRODUCT_CARD_BASE_CLASSNAME, "__flag-badge");
