export var OFFER_SUMMARY_CARD_BASE_CLASSNAME = 'ws10-offer-summary-card';
export var OFFER_SUMMARY_CARD_CONTENT_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__content");
export var OFFER_SUMMARY_CARD_CONTENT_TOP_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_CONTENT_CLASSNAME, "--top");
export var OFFER_SUMMARY_CARD_CONTENT_MIDDLE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_CONTENT_CLASSNAME, "--middle");
export var OFFER_SUMMARY_CARD_CONTENT_BOTTOM_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_CONTENT_CLASSNAME, "--bottom");
export var OFFER_SUMMARY_CARD_HIGHLIGHT_BADGE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__highlight-badge");
export var OFFER_SUMMARY_CARD_HEADLINE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__headline");
export var OFFER_SUMMARY_CARD_SUBLINE_TEXT_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__subline-text");
export var OFFER_SUMMARY_CARD_PRICES_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__prices");
export var OFFER_SUMMARY_CARD_PRICE_TABLE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__price-table");
export var OFFER_SUMMARY_CARD_PRICE_TABLE_CAPTION_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_PRICE_TABLE_CLASSNAME, "-caption");
export var OFFER_SUMMARY_CARD_PRICE_ROW_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__price-row");
export var OFFER_SUMMARY_CARD_PROMO_PRICE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_PRICE_ROW_CLASSNAME, "--promo-price");
export var OFFER_SUMMARY_CARD_ADDITIONAL_PRICE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_PRICE_ROW_CLASSNAME, "--additional-price");
export var OFFER_SUMMARY_CARD_PRICE_LABEL_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__price-label");
export var OFFER_SUMMARY_CARD_PRICE_VALUE_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__price-value");
export var OFFER_SUMMARY_CARD_LEGAL_TEXT_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__legal-text");
export var OFFER_SUMMARY_CARD_BUTTONS_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__buttons");
export var OFFER_SUMMARY_CARD_BUTTON_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__button");
export var OFFER_SUMMARY_CARD_ADDITIONAL_TEXT_CLASSNAME = "".concat(OFFER_SUMMARY_CARD_BASE_CLASSNAME, "__additional-text");
