/**
 * Base class name
 */
export var ACCORDION_BASE_CLASSNAME = 'ws10-accordion';
/**
 * ACCORDION_ITEM_DETAILS_CLASSNAME
 */
export var ACCORDION_ITEM_DETAILS_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-details");
/**
 * ACCORDION_ITEM_DETAILS_CLASSNAME
 */
export var ACCORDION_ITEM_DETAILS_OPEN = "".concat(ACCORDION_ITEM_DETAILS_CLASSNAME, "--open");
/**
 * ACCORDION_ITEM_DETAILS_ID_PREFIX
 */
export var ACCORDION_ITEM_DETAILS_ID_PREFIX = "".concat(ACCORDION_BASE_CLASSNAME, "-item-details-");
/**
 * ACCORDION_ITEM_SUMMARY_CLASSNAME
 */
export var ACCORDION_ITEM_SUMMARY_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-summary");
/**
 * ACCORDION_ITEM_ICON_CLASSNAME
 */
export var ACCORDION_ITEM_ICON_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-icon");
/**
 * ACCORDION_ITEM_HEADLINE_CLASSNAME
 */
export var ACCORDION_ITEM_HEADLINE_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-headline");
/**
 * ACCORDION_ITEM_CONTENT_CLASSNAME
 */
export var ACCORDION_ITEM_CONTENT_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-content");
/**
 * ACCORDION_ITEM_CONTENT_INNER_CLASSNAME
 */
export var ACCORDION_ITEM_CONTENT_INNER_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "__item-content-inner");
/**
 * ACCORDION_ITEM_CONTENT_ID_PREFIX
 */
export var ACCORDION_ITEM_CONTENT_ID_PREFIX = "".concat(ACCORDION_BASE_CLASSNAME, "-item-content-");
/**
 * ACCORDION_OPEN_INDIVIDUALLY_JS_CLASSNAME
 */
export var ACCORDION_OPEN_INDIVIDUALLY_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "--open-individually");
/**
 * ACCORDION_SCROLL_TO_OPENED_ITEM_CLASSNAME
 */
export var ACCORDION_SCROLL_TO_OPENED_ITEM_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "--scroll-to-opened-item");
/**
 * ACCORDION_DEEPLINK_CLASSNAME
 */
export var ACCORDION_DEEPLINK_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "--deeplink");
/**
 * ACCORDION_VARIANT_CARD_CLASSNAME
 */
export var ACCORDION_VARIANT_CARD_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "--card");
/**
 * ACCORDION_VARIANT_FLAT_CLASSNAME
 */
export var ACCORDION_VARIANT_FLAT_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "--flat");
/**
 * Name of the accordion param
 */
export var ACCORDION_PARAM_NAME = 'accordion';
/**
 * Name of the accordion param
 */
export var ACCORDION_PARAM_VALUE_SEPARATOR = ',';
/**
 * Variants of a interaction list
 */
export var AccordionVariant;
(function (AccordionVariant) {
    AccordionVariant["Flat"] = "flat";
    AccordionVariant["Card"] = "card";
})(AccordionVariant || (AccordionVariant = {}));
/**
 * ACCORDION_TIMEOUT_SCROLL_INTO_VIEW
 */
export var ACCORDION_TIMEOUT_SCROLL_INTO_VIEW = 650;
/**
 * Animation Options
 */
export var ACCORDION_ANIMATION_OPTIONS = {
    duration: 500,
    easing: 'ease-in-out',
};
