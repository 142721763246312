/**
 * Base class name
 */
export var LIST_CARD_BASE_CLASSNAME = 'ws10-list-card';
/**
 * ClassName for Item
 */
export var CLASSNAME_LIST_CARD_ITEM = "".concat(LIST_CARD_BASE_CLASSNAME, "__item");
/**
 * ClassName for Item icon
 */
export var CLASSNAME_LIST_CARD_ITEM_ICON = "".concat(LIST_CARD_BASE_CLASSNAME, "__item__icon");
/**
 * ClassName for Headline
 */
export var CLASSNAME_LIST_CARD_HEADLINE = "".concat(CLASSNAME_LIST_CARD_ITEM, "__headline");
/**
 * ClassName for Text
 */
export var CLASSNAME_LIST_CARD_TEXT = "".concat(CLASSNAME_LIST_CARD_ITEM, "__text");
/**
 * ClassName for More info text
 */
export var CLASSNAME_LIST_CARD_MORE_INFO_TEXT = "".concat(CLASSNAME_LIST_CARD_ITEM, "__more-info__text");
