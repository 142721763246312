/**
 * Button Variation: primary
 */
export var BUTTON_COLOR_PRIMARY = 'primary';
/**
 * Button Variation: secondary
 */
export var BUTTON_COLOR_SECONDARY = 'secondary';
/**
 * Button Variation: tertiary
 */
export var BUTTON_COLOR_TERTIARY = 'tertiary';
/**
 * Color Variations
 */
export var BUTTON_COLORS = [
    BUTTON_COLOR_PRIMARY,
    BUTTON_COLOR_SECONDARY,
    BUTTON_COLOR_TERTIARY,
];
/**
 * Button Size: standard
 */
export var BUTTON_SIZE_STANDARD = 'standard';
/**
 * Button Size: small
 */
export var BUTTON_SIZE_SMALL = 'small';
/**
 * Size Variations
 */
export var BUTTON_SIZES = [
    BUTTON_SIZE_STANDARD,
    BUTTON_SIZE_SMALL,
];
/**
 * Button Icon Position: left
 */
export var BUTTON_ICON_POSITION_LEFT = 'left';
/**
 * Button Icon Position: right
 */
export var BUTTON_ICON_POSITION_RIGHT = 'right';
/**
 * Size Variations
 */
export var BUTTON_ICON_POSITIONS = [
    BUTTON_ICON_POSITION_LEFT,
    BUTTON_ICON_POSITION_RIGHT,
];
/**
 * Classname spacing
 */
export var BUTTON_CLASSNAME_SPACING = '200';
/**
 * Button base class name
 */
export var BUTTON_BASE_CLASSNAME = 'ws10-button';
/**
 * BUTTON_TEXT_CLASSNAME
 */
export var BUTTON_TEXT_CLASSNAME = "".concat(BUTTON_BASE_CLASSNAME, "__text");
/**
 * Classname to check if color is inverse
 */
export var BUTTON_CLASSNAME_INVERSE = '-inverse';
/**
 * Classname to check disabled button
 */
export var BUTTON_CLASSNAME_DISABLED = "".concat(BUTTON_BASE_CLASSNAME, "--disabled");
/**
 * Classname to check disabled inverse button
 */
export var BUTTON_CLASSNAME_DISABLED_INVERSE = "".concat(BUTTON_BASE_CLASSNAME, "--disabled-inverse");
/**
 * Button Icon Position: left
 */
export var BUTTON_WIDTH_VARIATION_AUTO = 'auto';
/**
 * Button Icon Position: right
 */
export var BUTTON_WIDTH_VARIATION_FULLWIDTH = 'fullwidth';
/**
 * Button Icon Position: icon only
 */
export var BUTTON_WIDTH_VARIATION_HYBRID = 'hybrid';
/**
 * Size Variations
 */
export var BUTTON_WIDTH_VARIATIONS = [
    BUTTON_WIDTH_VARIATION_HYBRID,
    BUTTON_WIDTH_VARIATION_AUTO,
    BUTTON_WIDTH_VARIATION_FULLWIDTH,
];
