var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_related-links.hbs';
import './related-links.scss';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, getPropertiesFromClassName, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { RELATED_LINKS_BASE_CLASSNAME, RELATED_LINKS_HEADLINE_CLASSNAME, RELATED_LINKS_INTERACTION_LIST_CLASSNAME, RELATED_LINKS_ITEM, RelatedLinksVariant, } from '@vfde-brix/ws10/related-links/Constants';
import { createInteractionList } from '@vfde-brix/ws10/interaction-list';
import { createHeadline, } from '@vfde-brix/ws10/headline';
/**
 * Component
 */
var RelatedLinks = /** @class */ (function (_super) {
    __extends(RelatedLinks, _super);
    function RelatedLinks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    RelatedLinks.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     */
    RelatedLinks.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.setColumnsCount();
    };
    /**
     * Searches the relatedLinks.hbs for classnames that are used to indicate the wrapper type.
     * @param wrapper | Anchor Element of an individual Item related link
     * @returns Returns the corresponding enum that represents the alignment card | flat
     */
    RelatedLinks.prototype.getOptVariant = function (wrapper) {
        var propertiesFromClassname = getPropertiesFromClassName(wrapper.className, {
            optVariant: [RelatedLinksVariant.Card, RelatedLinksVariant.Flat],
        });
        return propertiesFromClassname.optVariant;
    };
    /**
     * This is an abstract function so you have to implement it.
     */
    RelatedLinks.prototype.readDom = function (relatedLinksBusinessLogic) {
        var e_1, _a;
        var relatedLinks = [];
        this.setColumnsCount();
        var subheadLineContainers = __spreadArray([], __read(this.containerElement.getElementsByClassName(RELATED_LINKS_ITEM)), false);
        var itemRelatedLinksWrapperElement = this.containerElement.getElementsByClassName(RELATED_LINKS_BASE_CLASSNAME)[0];
        var interactionlistContainers = this.containerElement.getElementsByClassName(RELATED_LINKS_INTERACTION_LIST_CLASSNAME);
        var i = 0;
        var subHeadline;
        try {
            for (var interactionlistContainers_1 = __values(interactionlistContainers), interactionlistContainers_1_1 = interactionlistContainers_1.next(); !interactionlistContainers_1_1.done; interactionlistContainers_1_1 = interactionlistContainers_1.next()) {
                var itemContainer = interactionlistContainers_1_1.value;
                if (subheadLineContainers.length !== 0 && undefined !== subheadLineContainers[i]) {
                    subHeadline = subheadLineContainers[i].getElementsByClassName('ws10-related-links__sub-headline')[0];
                }
                var item = createInteractionList(itemContainer, NO_PATTERN_BUSINESS_LOGIC);
                relatedLinks.push(removeUndefinedFieldsFromObject({
                    stdSubHeadline: subHeadline && subHeadline.textContent.trim(),
                    interactionListItems: item.getProperties(),
                }));
                i++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (interactionlistContainers_1_1 && !interactionlistContainers_1_1.done && (_a = interactionlistContainers_1.return)) _a.call(interactionlistContainers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var optVariant = itemRelatedLinksWrapperElement && this.getOptVariant(itemRelatedLinksWrapperElement);
        var properties = {
            relatedLinks: relatedLinks,
            business: relatedLinksBusinessLogic,
            optVariant: optVariant,
        };
        var headline = this.containerElement.getElementsByClassName(RELATED_LINKS_HEADLINE_CLASSNAME)[0];
        var headlineContainer;
        if (headline) {
            var headlineComponent = createHeadline(headline, NO_PATTERN_BUSINESS_LOGIC);
            headlineContainer = headlineComponent.getProperties();
            properties.stdHeadline = headlineContainer;
        }
        return properties;
    };
    /**
     * set Columns Count @TODO: refactor, is it needed to count via js at all?
     */
    RelatedLinks.prototype.setColumnsCount = function () {
        var e_2, _a;
        // @TODO: refactor to use one identifier
        var listItemContainers = this.containerElement.getElementsByClassName('ws10-column  ws10-column--small-12');
        // @TODO: there is no case when there are 4? ws10-column is already on the element why add it?
        var interactionListPerRow = 4;
        var largeColumn = ' ws10-column  ws10-column--large-';
        var xLargeColumn = ' ws10-column  ws10-column--x-large-';
        var medium = ' ws10-column ws10-column--medium-';
        if (listItemContainers.length > 0) {
            interactionListPerRow = 12 / listItemContainers.length;
        }
        try {
            // @TODO: Classlist.add instead of classname, string interpolation can be done in once
            for (var listItemContainers_1 = __values(listItemContainers), listItemContainers_1_1 = listItemContainers_1.next(); !listItemContainers_1_1.done; listItemContainers_1_1 = listItemContainers_1.next()) {
                var itemContainer = listItemContainers_1_1.value;
                itemContainer.className += "".concat(largeColumn + interactionListPerRow, " ");
                itemContainer.className += "".concat(xLargeColumn + interactionListPerRow, " ");
                if (listItemContainers.length > 0 && listItemContainers.length < 3) {
                    itemContainer.className += "".concat(medium + interactionListPerRow, " ");
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (listItemContainers_1_1 && !listItemContainers_1_1.done && (_a = listItemContainers_1.return)) _a.call(listItemContainers_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return interactionListPerRow;
    };
    return RelatedLinks;
}(Pattern));
export { RelatedLinks };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of RelatedLinks
 */
export var createRelatedLinks = function (containerElement, businessLogicOrProperties) {
    var relatedLinks = new RelatedLinks(containerElement, businessLogicOrProperties);
    relatedLinks.init();
    return relatedLinks;
};
