var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// import styles
import './info-overlay.scss';
import renderTemplate from './_info-overlay.hbs';
import { Pattern, } from '@vfde-brix/ws10/core';
/**
 * WS10 InfoOverlay
 */
var InfoOverlay = /** @class */ (function (_super) {
    __extends(InfoOverlay, _super);
    function InfoOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * No default props, so just return
     */
    InfoOverlay.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Render info overlay
     */
    InfoOverlay.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /** This component is not CMS enabled so we don't need readDOM */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    InfoOverlay.prototype.readDom = function (business) {
        return undefined;
    };
    return InfoOverlay;
}(Pattern));
export { InfoOverlay };
/**
 * This function returns an instance of InfoOverlay
 */
export var createInfoOverlay = function (containerElement, properties) {
    var infoOverlay = new InfoOverlay(containerElement, properties);
    infoOverlay.init();
    return infoOverlay;
};
