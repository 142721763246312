var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import renderTemplate from './_loading-spinner.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { toggleElementVisibility } from '../core/utils/toggleElementVisibility';
import { LOADING_SPINNER_BASE_CLASSNAME, LOADING_SPINNER_CLASSNAME_INVERSE, LOADING_SPINNER_CLASSNAME_NESTED, LOADING_SPINNER_COLOR_PRIMARY, LOADING_SPINNER_COLORS, LOADING_SPINNER_DEFAULT_HIDE_DELAY, LOADING_SPINNER_DEFAULT_SHOW_DELAY, LOADING_SPINNER_SIZE_MEDIUM, LOADING_SPINNER_SIZES, } from './Constants';
/**
 * Loading Spinner main class
 */
var LoadingSpinner = /** @class */ (function (_super) {
    __extends(LoadingSpinner, _super);
    /**
     * LoadingSpinner Constructor
     */
    function LoadingSpinner(containerElement, businessLogicOrProperties) {
        var _this = _super.call(this, containerElement, businessLogicOrProperties) || this;
        _this.isVisible = false;
        _this.shouldBecomeVisible = false;
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    LoadingSpinner.prototype.getDefaultProperties = function (newProperties) {
        newProperties.optColor || (newProperties.optColor = LOADING_SPINNER_COLOR_PRIMARY);
        newProperties.optSize || (newProperties.optSize = LOADING_SPINNER_SIZE_MEDIUM);
        return newProperties;
    };
    /**
     * Show the loading spinner
     * @param delay A delay in ms before actually showing the loadingSpinner (useful to avoid loadingSpinner-flickering).
     * Defaults to `LOADING_SPINNER_DEFAULT_SHOW_DELAY`.
     */
    LoadingSpinner.prototype.show = function (delay) {
        var _this = this;
        if (delay === void 0) { delay = LOADING_SPINNER_DEFAULT_SHOW_DELAY; }
        this.shouldBecomeVisible = true;
        setTimeout(function () {
            // after the delay make sure that the loadingSpinner should still become visible (i. e. it was not hidden in the meantime)
            if (_this.shouldBecomeVisible && !_this.isVisible) {
                toggleElementVisibility(_this.loadingSpinnerElement, true);
                _this.isVisible = true;
            }
        }, delay);
    };
    /**
     * Hide the loading spinner
     * @param delay A delay in ms before actually hiding the loadingSpinner (useful to avoid loadingSpinner-flickering).
     * Defaults to `LOADING_SPINNER_DEFAULT_HIDE_DELAY`.
     */
    LoadingSpinner.prototype.hide = function (delay) {
        var _this = this;
        if (delay === void 0) { delay = LOADING_SPINNER_DEFAULT_HIDE_DELAY; }
        this.shouldBecomeVisible = false;
        setTimeout(function () {
            // after the delay make sure that the loadingSpinner should still become hidden (i. e. it was not shown in the meantime)
            if (!_this.shouldBecomeVisible && _this.isVisible) {
                toggleElementVisibility(_this.loadingSpinnerElement, false);
                _this.isVisible = false;
            }
        }, delay);
    };
    /**
     * Toggle the loading spinner
     * @param shouldShow A boolean to force show the loadingSpinner
     * @param delay A delay in ms before actually showing/hiding the loadingSpinner (useful to avoid loadingSpinner-flickering).
     * Defaults to `LOADING_SPINNER_DEFAULT_SHOW_DELAY` and `LOADING_SPINNER_DEFAULT_HIDE_DELAY`.
     */
    LoadingSpinner.prototype.toggle = function (shouldShow, delay) {
        var actualShouldShow = typeof shouldShow === 'boolean' ? shouldShow : !this.isVisible;
        if (actualShouldShow) {
            this.show(delay);
        }
        else {
            this.hide(delay);
        }
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    LoadingSpinner.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.loadingSpinnerElement = this.containerElement.getElementsByClassName(LOADING_SPINNER_BASE_CLASSNAME)[0];
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    LoadingSpinner.prototype.readDom = function (business) {
        this.loadingSpinnerElement = this.containerElement.getElementsByClassName(LOADING_SPINNER_BASE_CLASSNAME)[0];
        var optInverse = this.loadingSpinnerElement.classList.contains(LOADING_SPINNER_CLASSNAME_INVERSE);
        var optNested = this.loadingSpinnerElement.classList.contains(LOADING_SPINNER_CLASSNAME_NESTED);
        // arrays saved in the contants can be used to look for all variation values
        // using brix core getPropsFromClass fn
        var _a = getPropertiesFromClassName(this.loadingSpinnerElement.className, {
            color: LOADING_SPINNER_COLORS,
            size: LOADING_SPINNER_SIZES,
        }), color = _a.color, size = _a.size;
        var properties = {
            optInverse: optInverse,
            optColor: color,
            optSize: size,
            optNested: optNested,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return LoadingSpinner;
}(Pattern));
export { LoadingSpinner };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of LoadingSpinner
 */
export var createLoadingSpinner = function (containerElement, businessLogicOrProperties) {
    var loadingSpinner = new LoadingSpinner(containerElement, businessLogicOrProperties);
    loadingSpinner.init();
    return loadingSpinner;
};
