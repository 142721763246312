/**
 * Base class name
 */
export var FLAG_BADGE_BASE_CLASSNAME = 'ws10-flag-badge';
/**
 * classname for full width variant
 */
export var FLAG_BADGE_FULL_WITH_CLASSNAME = "".concat(FLAG_BADGE_BASE_CLASSNAME, "--fullwidth");
/**
 * classname for inverse variant
 */
export var FLAG_BADGE_INVERSE_CLASSNAME = "".concat(FLAG_BADGE_BASE_CLASSNAME, "--inverse");
/**
 * All valid colors for a flag badge
 */
export var FLAG_BADGE_COLORS = [
    'yellow', 'red', 'green', 'dark-red', 'black',
];
/**
 * All valid sizes for a flag badge
 */
export var FLAG_BADGE_SIZES = [
    'small',
];
