var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, } from '@vfde-brix/ws10/core';
import { InteractionListVariant } from './Constants';
import renderTemplate from './_interaction-list.hbs';
import './interaction-list.scss';
import { createInteractionListItem } from '@vfde-brix/ws10/interaction-list/InteractionListItem';
/**
 * ws10 interaction list component class
 * This component is a 'Container' component.
 * Dynamic: It is rendered first and then other components can be rendered into it
 * Static: It all comes rendered, but this component is not responsible for calling it's childrens readDOM functions
 */
var InteractionList = /** @class */ (function (_super) {
    __extends(InteractionList, _super);
    function InteractionList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    InteractionList.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    InteractionList.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * getting properties from DOM
     */
    InteractionList.prototype.readDom = function (interactionListBusinessLogic) {
        var e_1, _a;
        var listElement = this.containerElement.getElementsByClassName('ws10-interaction-list')[0];
        var listVariant = getPropertiesFromClassName(listElement.className, {
            optVariant: Object.values(InteractionListVariant),
        });
        var props = {
            optVariant: listVariant.optVariant,
            listItems: [],
            business: interactionListBusinessLogic,
        };
        var listItemContainers = this.containerElement.getElementsByClassName('ws10-interaction-list__item');
        try {
            for (var listItemContainers_1 = __values(listItemContainers), listItemContainers_1_1 = listItemContainers_1.next(); !listItemContainers_1_1.done; listItemContainers_1_1 = listItemContainers_1.next()) {
                var itemContainer = listItemContainers_1_1.value;
                var item = createInteractionListItem(itemContainer, NO_PATTERN_BUSINESS_LOGIC);
                props.listItems.push(item.getProperties());
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (listItemContainers_1_1 && !listItemContainers_1_1.done && (_a = listItemContainers_1.return)) _a.call(listItemContainers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return props;
    };
    return InteractionList;
}(Pattern));
export { InteractionList };
/**
 * This function returns an instance of interaction list
 */
export var createInteractionList = function (containerElement, businessLogicOrProperties) {
    var interactionList = new InteractionList(containerElement, businessLogicOrProperties);
    interactionList.init();
    return interactionList;
};
