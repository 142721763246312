var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './option-picker.scss';
import renderTemplate from './_option-picker.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { OPTION_PICKER_BASE_CLASSNAME, OPTION_PICKER_COLOR_SWATCH_CLASSNAME, OPTION_PICKER_IMAGE_CLASSNAME, OPTION_PICKER_INPUT_CLASSNAME, OPTION_PICKER_ITEM_CLASSNAME, OPTION_PICKER_LEGEND_CLASSNAME, OPTION_PICKER_PRIMARY_TEXT_CLASSNAME, OPTION_PICKER_SECONDARY_TEXT_CLASSNAME, OPTION_PICKER_TYPES, OPTION_PICKER_TYPE_COLOR, OPTION_PICKER_TYPE_ICON, OPTION_PICKER_TYPE_IMAGE, OPTION_PICKER_FLAG_BADGE_CLASSNAME, } from './Constants';
import { getSystemIconName, SYSTEM_ICON_BASE_CLASSNAME, } from '@vfde-brix/ws10/system-icon';
import { FlagBadge } from '@vfde-brix/ws10/flag-badge';
/**
 * OptionPicker class
 */
var OptionPicker = /** @class */ (function (_super) {
    __extends(OptionPicker, _super);
    function OptionPicker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get default properties
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    OptionPicker.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optWithFlagBadge === undefined) {
            newProperties.optWithFlagBadge = newProperties.items.some(function (item) { return !!item.containerFlagBadge; });
        }
        return newProperties;
    };
    /**
     * Renders the component
     */
    OptionPicker.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Initialize the event listeners
     */
    OptionPicker.prototype.initEvents = function () {
        var e_1, _a;
        var _this = this;
        var inputElements = this.containerElement.getElementsByClassName(OPTION_PICKER_INPUT_CLASSNAME);
        try {
            for (var inputElements_1 = __values(inputElements), inputElements_1_1 = inputElements_1.next(); !inputElements_1_1.done; inputElements_1_1 = inputElements_1.next()) {
                var inputElement = inputElements_1_1.value;
                inputElement.addEventListener('change', function (e) {
                    _this.handleInputChange(e);
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (inputElements_1_1 && !inputElements_1_1.done && (_a = inputElements_1.return)) _a.call(inputElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Handle input change event
     */
    OptionPicker.prototype.handleInputChange = function (e) {
        var checkedIndex = this.getCheckedOptionIndex();
        // sync properties (and DOM, but DOM is not changed in this case)
        this.syncAttributesAndProps(checkedIndex);
        if (this.properties.business.onChange) {
            this.properties.business.onChange(e);
        }
    };
    /**
     * Read properties from DOM
     */
    OptionPicker.prototype.readDom = function (optionPickerBusinessLogic) {
        var e_2, _a;
        var optionPickerElement = this.containerElement.getElementsByClassName(OPTION_PICKER_BASE_CLASSNAME)[0];
        var optType = getPropertiesFromClassName(optionPickerElement.className, {
            type: OPTION_PICKER_TYPES,
        }).type;
        var legendElement = optionPickerElement.getElementsByClassName(OPTION_PICKER_LEGEND_CLASSNAME)[0];
        var itemElements = optionPickerElement.getElementsByClassName(OPTION_PICKER_ITEM_CLASSNAME);
        var items = [];
        var stdScreenreaderLegend;
        var stdName;
        if (legendElement) {
            stdScreenreaderLegend = legendElement.textContent.trim();
        }
        try {
            for (var itemElements_1 = __values(itemElements), itemElements_1_1 = itemElements_1.next(); !itemElements_1_1.done; itemElements_1_1 = itemElements_1.next()) {
                var itemElement = itemElements_1_1.value;
                var inputElement = itemElement.getElementsByClassName(OPTION_PICKER_INPUT_CLASSNAME)[0];
                if (!stdName) {
                    // each radio button has the same name so we only use the name of the first one
                    stdName = inputElement.name;
                }
                var stdValue = inputElement.value;
                var stdAriaControls = inputElement.getAttribute('aria-controls') || undefined;
                var optChecked = inputElement.hasAttribute('checked');
                var optDisabled = inputElement.hasAttribute('disabled');
                var primaryTextElement = itemElement.getElementsByClassName(OPTION_PICKER_PRIMARY_TEXT_CLASSNAME)[0];
                var containerFlagBadge = FlagBadge.getPropertiesFromDom(itemElement.getElementsByClassName(OPTION_PICKER_FLAG_BADGE_CLASSNAME)[0]);
                var itemProperties = {
                    stdValue: stdValue,
                    stdAriaControls: stdAriaControls,
                    stdPrimaryLabel: primaryTextElement.innerHTML.trim(),
                    optChecked: optChecked,
                    optDisabled: optDisabled,
                    containerFlagBadge: containerFlagBadge,
                };
                switch (optType) {
                    case OPTION_PICKER_TYPE_IMAGE: {
                        // type=image
                        var image = itemElement.getElementsByClassName(OPTION_PICKER_IMAGE_CLASSNAME)[0];
                        itemProperties.imgSrc = image.getAttribute('src');
                        break;
                    }
                    case OPTION_PICKER_TYPE_ICON: {
                        // type=icon
                        var iconElement = itemElement.getElementsByClassName(SYSTEM_ICON_BASE_CLASSNAME)[0];
                        itemProperties.stdSystemIconName = iconElement && getSystemIconName(iconElement);
                        break;
                    }
                    case OPTION_PICKER_TYPE_COLOR: {
                        // type=color
                        var colorSwatchElement = itemElement.getElementsByClassName(OPTION_PICKER_COLOR_SWATCH_CLASSNAME)[0];
                        itemProperties.stdColor = colorSwatchElement.dataset.color;
                        break;
                    }
                    default: {
                        // type=text
                        var secondaryTextElement = itemElement.getElementsByClassName(OPTION_PICKER_SECONDARY_TEXT_CLASSNAME)[0];
                        itemProperties.stdSecondaryLabel = secondaryTextElement === null || secondaryTextElement === void 0 ? void 0 : secondaryTextElement.innerHTML.trim();
                        break;
                    }
                }
                items.push(removeUndefinedFieldsFromObject(itemProperties));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (itemElements_1_1 && !itemElements_1_1.done && (_a = itemElements_1.return)) _a.call(itemElements_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        var properties = removeUndefinedFieldsFromObject({
            optType: optType,
            stdName: stdName,
            stdScreenreaderLegend: stdScreenreaderLegend,
            items: items,
            optWithFlagBadge: items.some(function (item) { return !!item.containerFlagBadge; }),
            business: optionPickerBusinessLogic,
        });
        return properties;
    };
    /**
     * Returns all input elements
     * @returns The HTMLCollection of input elements
     */
    OptionPicker.prototype.getOptionPickerInputElements = function () {
        return this.containerElement.getElementsByClassName(OPTION_PICKER_INPUT_CLASSNAME);
    };
    /**
     * This method executes the given callback function
     * for each input element. The index, the input element
     * and the original properties item object are passed
     * to the callback function.
     * @param callback The callback function which is executed for each option
     */
    OptionPicker.prototype.forEachOption = function (callback) {
        var inputElements = this.getOptionPickerInputElements();
        for (var i = 0, iLen = inputElements.length; i < iLen; i++) {
            callback(i, inputElements[i], this.properties.items[i]);
        }
    };
    // #region Public methods
    /**
     * Returns the index of the checked option.
     * If no option is checked, `-1` is returned.
     */
    OptionPicker.prototype.getCheckedOptionIndex = function () {
        var inputElements = this.getOptionPickerInputElements();
        for (var i = 0, iLen = inputElements.length; i < iLen; i++) {
            if (inputElements[i].checked) {
                return i;
            }
        }
        return -1;
    };
    /**
     * Syncs attributes and props after one input was selected.
     * If index is out of range, a `RangeError` is thrown.
     */
    OptionPicker.prototype.syncAttributesAndProps = function (index) {
        var inputElements = this.getOptionPickerInputElements();
        if (!inputElements[index]) {
            throw new RangeError("No option found on index '".concat(index, "'"));
        }
        for (var i = 0, iLen = inputElements.length; i < iLen; i++) {
            var inputElement = inputElements[i];
            var itemProps = this.properties.items[i];
            var isChecked = i === index;
            var ariaExpanded = inputElement.hasAttribute('aria-controls') ? isChecked.toString() : '';
            inputElement.checked = isChecked;
            if (ariaExpanded) {
                inputElement.setAttribute('aria-expanded', ariaExpanded);
            }
            itemProps.optChecked = isChecked;
        }
    };
    /**
     * Returns the value of the checked radio-button or null if no radio-button is checked
     */
    OptionPicker.prototype.getValue = function () {
        var e_3, _a;
        var inputElements = this.getOptionPickerInputElements();
        try {
            for (var inputElements_2 = __values(inputElements), inputElements_2_1 = inputElements_2.next(); !inputElements_2_1.done; inputElements_2_1 = inputElements_2.next()) {
                var inputElement = inputElements_2_1.value;
                if (inputElement.checked) {
                    return inputElement.value;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (inputElements_2_1 && !inputElements_2_1.done && (_a = inputElements_2.return)) _a.call(inputElements_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return null;
    };
    /**
     * Sets the value (i.e. selects the radio button with the given value).
     * If a radio button with the given value was found, `true` is returned.
     * If no radio button with the given value was found,
     * no change to an existing selection will be made and `false` is returned.
     */
    OptionPicker.prototype.setValue = function (value) {
        var inputElements = this.getOptionPickerInputElements();
        var optionWasFound = false;
        for (var i = 0, iLen = inputElements.length; i < iLen; i++) {
            var inputElement = inputElements[i];
            if (inputElement.value === value) {
                inputElement.checked = true;
                optionWasFound = true;
            }
        }
        if (optionWasFound) {
            // keep all items of the properties in sync
            for (var i = 0, iLen = inputElements.length; i < iLen; i++) {
                this.properties.items[i].optChecked = inputElements[i].checked;
            }
        }
        return optionWasFound;
    };
    /**
     * Clears the value (i. e. unselects all radio buttons)
     */
    OptionPicker.prototype.clearValue = function () {
        this.forEachOption(function (i, input, item) {
            input.checked = false;
            item.optChecked = input.checked;
        });
    };
    /**
     * Disables all options. If you also want to uncheck all options,
     * please also call the `clearValue` method.
     */
    OptionPicker.prototype.disableAllOptions = function () {
        this.forEachOption(function (i, input, item) {
            input.disabled = true;
            item.optDisabled = true;
        });
    };
    /**
     * Enables options if they are present in value array, disables if not.
     */
    OptionPicker.prototype.enableOptionsByValues = function (values) {
        this.forEachOption(function (i, input, item) {
            var disabled = !values.includes(input.value);
            input.disabled = disabled;
            item.optDisabled = disabled;
        });
    };
    /**
     * Enables all options. If you also want to check an option,
     * please use the methods `setValue` or `setCheckedOptionByIndex`.
     * @param value Optional value of the input element to set to 'checked'.
     */
    OptionPicker.prototype.enableAllOptions = function (value) {
        this.forEachOption(function (i, input, item) {
            input.disabled = false;
            item.optDisabled = false;
        });
        if (value !== undefined) {
            this.setValue(value);
        }
    };
    /**
     * Sets focus to given option
     */
    OptionPicker.prototype.focusOptionByValue = function (value) {
        var _a;
        (_a = __spreadArray([], __read(this.getOptionPickerInputElements()), false).find(function (input) { return input.value === value; })) === null || _a === void 0 ? void 0 : _a.focus();
    };
    /**
     * Return all possible values from this option picker
     */
    OptionPicker.prototype.getAllPossibleValues = function () {
        var inputElements = this.getOptionPickerInputElements();
        return __spreadArray([], __read(inputElements), false).map(function (input) { return input.value; });
    };
    return OptionPicker;
}(Pattern));
export { OptionPicker };
/**
 * This function returns a new instance of OptionPicker
 */
export var createOptionPicker = function (containerElement, businessLogicOrProperties) {
    var optionPicker = new OptionPicker(containerElement, businessLogicOrProperties);
    optionPicker.init();
    return optionPicker;
};
