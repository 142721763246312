var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './form-label.scss';
import renderTemplate from './_form-label.hbs';
import { Pattern, removeUndefinedFieldsFromObject, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { FORM_LABEL_CLASSNAME, FORM_LABEL_CLASSNAME_DISABLED, FORM_LABEL_CLASSNAME_ERROR, FORM_LABEL_CLASSNAME_SUCCESS, FORM_LABEL_CLASSNAME_WARN, } from './Constants';
import { FORM_ELEMENT_STATES, } from '@vfde-brix/ws10/form-text-input';
/**
 * WS10 FormLabel
 */
var FormLabel = /** @class */ (function (_super) {
    __extends(FormLabel, _super);
    function FormLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FormLabel.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    FormLabel.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    FormLabel.prototype.readDom = function (labelBusinessLogic) {
        var labelElement = this.containerElement.getElementsByClassName(FORM_LABEL_CLASSNAME)[0];
        var stdIdRelatedElement = labelElement.getAttribute('for');
        var txtText = labelElement.innerHTML.trim();
        var propertiesFromClassName = getPropertiesFromClassName(labelElement.className, {
            optState: FORM_ELEMENT_STATES,
        });
        var optState = propertiesFromClassName.optState;
        var optDisabled = labelElement.classList.contains(FORM_LABEL_CLASSNAME_DISABLED);
        return removeUndefinedFieldsFromObject(__assign(__assign({}, this.properties), { stdIdRelatedElement: stdIdRelatedElement, txtText: txtText, optDisabled: optDisabled, optState: optState, business: labelBusinessLogic }));
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormLabel.prototype.handleStateUpdate = function (optState) {
        var _a;
        var stateClasses = {
            error: FORM_LABEL_CLASSNAME_ERROR,
            warn: FORM_LABEL_CLASSNAME_WARN,
            success: FORM_LABEL_CLASSNAME_SUCCESS,
        };
        var baseElement = this.containerElement.getElementsByClassName(FORM_LABEL_CLASSNAME)[0];
        (_a = baseElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        baseElement.classList.add(stateClasses[optState]);
    };
    FormLabel.prototype.didReceiveProps = function (newProps, oldProperties) {
        var optState = newProps.optState;
        /* istanbul ignore else */
        if (oldProperties.optState !== optState) {
            this.handleStateUpdate(optState);
        }
    };
    return FormLabel;
}(Pattern));
export { FormLabel };
/**
 * This function returns an instance of FormLabel
 */
export var createFormLabel = function (containerElement, properties) {
    var formLabel = new FormLabel(containerElement, properties);
    formLabel.init();
    return formLabel;
};
