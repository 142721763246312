var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './tooltip.scss';
import { removeUndefinedFieldsFromObject, Pattern, } from '@vfde-brix/ws10/core';
import { TOOLTIP_CLASSNAME_ANIMATION_UNFADE, TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_LEFT, TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_RIGHT, TOOLTIP_CLASSNAME_SMALL_NOSE_FLIPPED, TOOLTIP_CLASSNAME_STANDARD_NOSE_FLIPPED, TOOLTIP_CLASSNAME_TRIGGER_ELEMENT, TOOLTIP_MIN_SIDE_MARGIN, TOOLTIP_NOSE_BORDER, TOOLTIP_NOSE_HEIGHT, TOOLTIP_NOSE_OFFSET, TOOLTIP_NOSE_WIDTH, TOOLTIP_BASE_CLASSNAME, TooltipAlignment, TooltipSizeVariant, } from './Constants';
import renderSmallBubbleNoseTemplate from './_tooltip-variant-small.hbs';
import renderStandardBubbleNoseTemplate from './_tooltip-variant-standard.hbs';
/**
 * Tooltip to show explanatory info
 */
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.isOpen = false;
        _this.mouseOverBubble = false;
        _this.mouseOverTrigger = false;
        _this.bubbleFocused = false;
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    Tooltip.prototype.afterInit = function () {
        this.renderBubbleAndNose();
        this.initTriggerElement();
    };
    Tooltip.prototype.renderBubbleAndNose = function () {
        if (this.bubbleElement) {
            document.body.removeChild(this.bubbleElement);
            document.body.removeChild(this.noseElement);
        }
        var rendererFunction = TooltipSizeVariant.Small === this.properties.stdSizeVariant
            ? renderSmallBubbleNoseTemplate
            : renderStandardBubbleNoseTemplate;
        document.body.insertAdjacentHTML('beforeend', rendererFunction(this.properties));
        this.bubbleElement = document.getElementById("".concat(this.properties.stdTooltipId, "_bubble"));
        this.noseElement = this.bubbleElement.nextElementSibling;
    };
    Tooltip.prototype.initTriggerElement = function () {
        var tooltipId = this.properties.stdTooltipId;
        this.triggerElement = document.querySelector("[aria-describedby=\"".concat(tooltipId, "\"]"));
        if (!this.triggerElement) {
            throw new Error("Tooltip [id=\"".concat(tooltipId, "\"] component requires an owner element having [aria-describedby=\"").concat(tooltipId, "\"] attribute."));
        }
        // Tells accessibility tools like screen readers, to read the tooltip bubble content
        this.triggerElement.setAttribute('aria-labelledby', this.bubbleElement.id);
        // Trigger element focus style
        this.triggerElement.classList.add(TOOLTIP_CLASSNAME_TRIGGER_ELEMENT);
        // Have the trigger element be focusable
        this.triggerElement.tabIndex = 0;
    };
    /**
     * Set default props
     */
    Tooltip.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optAlignment === undefined) {
            newProperties.optAlignment = TooltipAlignment.CENTER;
        }
        if (newProperties.stdSizeVariant === undefined) {
            newProperties.stdSizeVariant = TooltipSizeVariant.Standard;
        }
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    Tooltip.prototype.writeDom = function () {
        this.renderBubbleAndNose();
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    Tooltip.prototype.initEvents = function () {
        var _this = this;
        // Mouse hovering events
        this.triggerElement.addEventListener('mouseenter', function () {
            _this.mouseOverTrigger = true;
            _this.open();
        });
        this.triggerElement.addEventListener('mouseleave', function () {
            _this.mouseOverTrigger = false;
            setTimeout(function () {
                if (!_this.mouseOverBubble) {
                    _this.close();
                }
            }, 200);
        });
        this.bubbleElement.addEventListener('mouseenter', function () {
            _this.mouseOverBubble = true;
        });
        this.bubbleElement.addEventListener('mouseleave', function () {
            _this.mouseOverBubble = false;
            setTimeout(function () {
                if (!_this.mouseOverTrigger) {
                    _this.close();
                }
            }, 200);
        });
        // Touch events
        this.triggerElement.addEventListener('touchstart', function () {
            if (_this.isOpen === false) {
                _this.open();
            }
            else {
                _this.close();
            }
        });
        // Focus events
        this.triggerElement.addEventListener('focus', function () {
            _this.open();
        });
        this.triggerElement.addEventListener('blur', function () {
            if (!_this.bubbleFocused && !_this.mouseOverBubble) {
                _this.close();
            }
        });
        this.bubbleElement.addEventListener('focus', function () {
            _this.bubbleFocused = true;
        });
        this.bubbleElement.addEventListener('blur', function () {
            _this.bubbleFocused = false;
        });
        // Key events
        this.triggerElement.addEventListener('keyup', function (event) {
            if (event.type === 'keyup' && event.key === 'Escape') {
                // works only on ui, not from unit tests
                _this.triggerElement.dispatchEvent(new Event('blur'));
                // without this, the focus styling does not disappear
                _this.triggerElement.blur();
            }
        });
        this.bubbleElement.addEventListener('keyup', function (event) {
            if (event.type === 'keyup' && event.key === 'Escape') {
                _this.close();
                _this.bubbleFocused = false;
                _this.mouseOverBubble = false;
            }
        });
    };
    /**
     * Get properties from DOM
     */
    Tooltip.prototype.readDom = function (tooltipBusinessLogic) {
        var tooltipElement = this.containerElement.getElementsByClassName(TOOLTIP_BASE_CLASSNAME)[0];
        var properties = removeUndefinedFieldsFromObject({
            business: tooltipBusinessLogic,
            stdTooltipId: tooltipElement.getAttribute('id'),
            stdHeadline: tooltipElement.getAttribute('data-headline'),
            txtContent: tooltipElement.getAttribute('data-content'),
            optAlignment: tooltipElement.getAttribute('data-alignement'),
            stdSizeVariant: tooltipElement.getAttribute('data-size-variant'),
        });
        return properties;
    };
    /**
     * Show Tooltip bubble with content
     */
    Tooltip.prototype.open = function () {
        // To avoid moueenter and focus events interference
        if (this.isOpen === true) {
            return;
        }
        var bubblePosition = this.getTooltipPosition();
        this.bubbleElement.style.top = "".concat(bubblePosition.bubbleTop, "px");
        this.bubbleElement.style.left = "".concat(bubblePosition.bubbleLeft, "px");
        this.noseElement.style.top = "".concat(bubblePosition.noseTop, "px");
        this.noseElement.style.left = "".concat(bubblePosition.noseLeft, "px");
        // Show bubble and nose with fading animation
        this.bubbleElement.classList.toggle(TOOLTIP_CLASSNAME_ANIMATION_UNFADE);
        this.noseElement.classList.toggle(TOOLTIP_CLASSNAME_ANIMATION_UNFADE);
        // Activate nose side class if needed
        this.toggleNoseSideClass();
        this.triggerElement.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
    };
    /**
     * Hide tolltip bubble and remove content
     */
    Tooltip.prototype.close = function () {
        if (!this.isOpen) {
            return;
        }
        this.bubbleElement.style.top = '-500px';
        this.bubbleElement.style.left = '-500px';
        this.noseElement.style.top = '-50px';
        this.noseElement.style.left = '-50px';
        this.bubbleElement.classList.toggle(TOOLTIP_CLASSNAME_ANIMATION_UNFADE);
        this.noseElement.classList.toggle(TOOLTIP_CLASSNAME_ANIMATION_UNFADE);
        this.toggleNoseSideClass();
        this.triggerElement.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
    };
    Tooltip.prototype.toggleNoseSideClass = function () {
        if (TooltipAlignment.SIDE_LEFT === this.properties.optAlignment) {
            this.noseElement.classList.toggle(TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_LEFT);
        }
        if (TooltipAlignment.SIDE_RIGHT === this.properties.optAlignment) {
            this.noseElement.classList.toggle(TOOLTIP_CLASSNAME_NOSE_ELEMENT_SIDE_RIGHT);
        }
    };
    /**
     * Returns position of bubble element
     */
    Tooltip.prototype.getTooltipPosition = function () {
        if (this.properties.optAlignment === TooltipAlignment.SIDE_LEFT ||
            this.properties.optAlignment === TooltipAlignment.SIDE_RIGHT) {
            // Tooltip shall be positioned top/bottom of triggerElement
            return this.getSideTooltipPosition();
        }
        return this.getVerticalTooltipPosition();
    };
    Tooltip.prototype.getVerticalTooltipPosition = function () {
        var triggerElementRect = this.triggerElement.getBoundingClientRect();
        var bubbleLeft;
        if (TooltipAlignment.LEFT === this.properties.optAlignment) {
            bubbleLeft = this.getBubbleLeftForLeftPosition(triggerElementRect);
        }
        else if (TooltipAlignment.RIGHT === this.properties.optAlignment) {
            bubbleLeft = this.getBubbleLeftForRightPosition(triggerElementRect);
        }
        else { // center aligned (default positioning)
            bubbleLeft = this.getBubbleLeftForCenterPosition(triggerElementRect);
        }
        var noseLeft = triggerElementRect.left + (triggerElementRect.width / 2) - (TOOLTIP_NOSE_WIDTH / 2);
        var offsetTop = this.getTriggerElementOffsetTop(this.triggerElement);
        var bubbleHeight = this.bubbleElement.offsetHeight;
        var bubbleTop = offsetTop - TOOLTIP_NOSE_BORDER - TOOLTIP_NOSE_HEIGHT - bubbleHeight;
        // This -1 is a workaround, to keep up the consistent fading effect
        var noseTop = offsetTop - TOOLTIP_NOSE_BORDER - TOOLTIP_NOSE_HEIGHT - 1;
        if ((triggerElementRect.top - TOOLTIP_NOSE_HEIGHT) < bubbleHeight) {
            /**
             * if the newly calculated top position is above the
             * visible area, we flip the Tooltip from upper to below
             */
            noseTop = offsetTop + triggerElementRect.height;
            bubbleTop = offsetTop + triggerElementRect.height + TOOLTIP_NOSE_HEIGHT + TOOLTIP_NOSE_BORDER;
            this.noseElement.classList.add(this.getFlipperClass());
        }
        else {
            this.noseElement.classList.remove(this.getFlipperClass());
        }
        return {
            bubbleLeft: Math.ceil(bubbleLeft),
            bubbleTop: Math.ceil(bubbleTop),
            noseLeft: Math.ceil(noseLeft),
            noseTop: Math.ceil(noseTop),
        };
    };
    Tooltip.prototype.getSideTooltipPosition = function () {
        var triggerElementRect = this.triggerElement.getBoundingClientRect();
        var bubbleLeft;
        var noseLeft;
        if (TooltipAlignment.SIDE_LEFT === this.properties.optAlignment) {
            bubbleLeft = triggerElementRect.left - TOOLTIP_NOSE_HEIGHT - TOOLTIP_NOSE_BORDER - this.bubbleElement.offsetWidth;
            // This -1 is a workaround, to keep up the consistent fading effect
            noseLeft = triggerElementRect.left - TOOLTIP_NOSE_HEIGHT - TOOLTIP_NOSE_BORDER - 1;
        }
        else {
            // TooltipAlignment.SIDE_RIGHT
            bubbleLeft = triggerElementRect.right + TOOLTIP_NOSE_BORDER + TOOLTIP_NOSE_HEIGHT;
            // This -2 is a workaround, to keep up the consistent fading effect
            noseLeft = triggerElementRect.right - 2;
        }
        var offsetTop = this.getTriggerElementOffsetTop(this.triggerElement);
        var bubbleTop = offsetTop + (triggerElementRect.height / 2) - (this.bubbleElement.offsetHeight / 2);
        var noseTop = offsetTop + (triggerElementRect.height / 2) - (TOOLTIP_NOSE_WIDTH / 2);
        return {
            bubbleLeft: Math.floor(bubbleLeft),
            bubbleTop: Math.floor(bubbleTop),
            noseLeft: Math.floor(noseLeft),
            noseTop: Math.floor(noseTop),
        };
    };
    Tooltip.prototype.getBubbleLeftForLeftPosition = function (triggerElementRect) {
        var bubbleWidth = this.bubbleElement.offsetWidth;
        var bubbleLeft = TOOLTIP_MIN_SIDE_MARGIN;
        if (Tooltip.hasSpaceToAlignBubbleLeft(triggerElementRect, bubbleWidth)) {
            bubbleLeft = triggerElementRect.left + (triggerElementRect.width / 2) + TOOLTIP_NOSE_OFFSET - bubbleWidth;
        }
        return bubbleLeft;
    };
    Tooltip.prototype.getBubbleLeftForRightPosition = function (triggerElementRect) {
        var bubbleWidth = this.bubbleElement.offsetWidth;
        var bubbleLeft = window.innerWidth - bubbleWidth - TOOLTIP_MIN_SIDE_MARGIN;
        if (Tooltip.hasSpaceToAlignBubbleRight(triggerElementRect, bubbleWidth)) {
            bubbleLeft = triggerElementRect.left + (triggerElementRect.width / 2) - TOOLTIP_NOSE_OFFSET;
        }
        return bubbleLeft;
    };
    Tooltip.prototype.getBubbleLeftForCenterPosition = function (triggerElementRect) {
        var bubbleWidth = this.bubbleElement.offsetWidth;
        if (Tooltip.isCenterAlignedBubbleFlowsOutAtRight(triggerElementRect, bubbleWidth)) {
            return window.innerWidth - bubbleWidth - TOOLTIP_MIN_SIDE_MARGIN;
        }
        if (Tooltip.isCenterAlignedBubbleFlowsOutAtLeft(triggerElementRect, bubbleWidth)) {
            return TOOLTIP_MIN_SIDE_MARGIN;
        }
        return triggerElementRect.left - (bubbleWidth / 2) + (triggerElementRect.width / 2);
    };
    Tooltip.prototype.getFlipperClass = function () {
        var noseClassNameFlipped = TOOLTIP_CLASSNAME_STANDARD_NOSE_FLIPPED;
        if (this.properties.stdSizeVariant === 'small') {
            noseClassNameFlipped = TOOLTIP_CLASSNAME_SMALL_NOSE_FLIPPED;
        }
        return noseClassNameFlipped;
    };
    Tooltip.isCenterAlignedBubbleFlowsOutAtLeft = function (triggerElementRect, bubbleWidth) {
        return triggerElementRect.left + (triggerElementRect.width / 2) - (bubbleWidth / 2) <= TOOLTIP_MIN_SIDE_MARGIN;
    };
    Tooltip.isCenterAlignedBubbleFlowsOutAtRight = function (triggerElementRect, bubbleWidth) {
        return triggerElementRect.left + (triggerElementRect.width / 2) + (bubbleWidth / 2) > window.innerWidth;
    };
    Tooltip.hasSpaceToAlignBubbleRight = function (triggerElementRect, bubbleWidth) {
        return triggerElementRect.left + (triggerElementRect.width / 2) - TOOLTIP_NOSE_OFFSET + bubbleWidth <= window.innerWidth;
    };
    Tooltip.hasSpaceToAlignBubbleLeft = function (triggerElementRect, bubbleWidth) {
        return triggerElementRect.left + (triggerElementRect.width / 2) + TOOLTIP_NOSE_OFFSET > bubbleWidth;
    };
    /**
     * Calculate bubble position from top depends on scrolling
     */
    Tooltip.prototype.getTriggerElementOffsetTop = function (el) {
        var result = el.offsetTop;
        /**
         * when Tooltip element is embedded in another elements
         * with relative positions, we need the offsetParent to calculate the top
         * position
         */
        var parentEl = el.offsetParent;
        if (parentEl && parentEl.tagName.toLocaleUpperCase() !== 'BODY') {
            result += this.getTriggerElementOffsetTop(parentEl);
        }
        return result;
    };
    /**
     * Get tooltip properties from DOM
     */
    Tooltip.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createTooltip);
    };
    return Tooltip;
}(Pattern));
export { Tooltip };
/**
 * This function returns an instance of Tooltip
 */
export var createTooltip = function (containerElement, properties) {
    var tooltip = new Tooltip(containerElement, properties);
    tooltip.init();
    return tooltip;
};
