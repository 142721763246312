/** Base classname */
export var ICON_STRIP_BASE_CLASSNAME = 'ws10-icon-strip';
/** Item classname */
export var ICON_STRIP_ITEM_CLASSNAME = "".concat(ICON_STRIP_BASE_CLASSNAME, "__item");
/** 'Container for the items' classname */
export var ICON_STRIP_ITEMS_CONTAINERS_CLASSNAME = "".concat(ICON_STRIP_BASE_CLASSNAME, "__items-container");
/** Icon Container classname */
export var ICON_STRIP_ICON_CONTAINER_CLASSNAME = "".concat(ICON_STRIP_BASE_CLASSNAME, "__icon-container");
/** Icon Container classname */
export var ICON_STRIP_SPAN_CLASSNAME = "".concat(ICON_STRIP_BASE_CLASSNAME, "__span");
